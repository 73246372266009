import Avatar from 'react-avatar';
import { Lucide, Menu, ModalDialog } from '@kerplunkai/common-components';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { NAV_ROUTES } from '@constants';
import { resetUser } from '@store/slices';
import { selectCurrentUser } from '@store/selectors';
import { useAppDispatch } from '@store/hooks';
import { useForgotPasswordMutation } from '@store/services';

import avatarLogo from '@assets/images/logos/Kerplunk-Mark-blk.svg';

function ProfileMenu() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [sendForgotPassword] = useForgotPasswordMutation();

  const currentUser = useSelector(selectCurrentUser);

  const [showConfirmLogoutDialog, setShowConfirmLogoutDialog] = useState(false);

  const handleLogout = () => {
    setShowConfirmLogoutDialog(false);
    dispatch(resetUser());
  };

  const handleResetPassword = useCallback(async () => {
    if (!currentUser) return;

    try {
      await sendForgotPassword(currentUser?.email).unwrap();

      enqueueSnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        children: (
          <div className="flex flex-col gap-1">
            <p className="text-sm text-[#364154]">Forgot Password Email Sent</p>
            <p className="text-sm text-[#677489]">
              Instructions to reset your password will be sent to you.
            </p>
          </div>
        ),
        icon: 'LockKeyhole',
        variant: 'toast-notification',
      });
    } catch (e) {
      enqueueSnackbar({
        message: 'Error sending reset password email. Please try again.',
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, currentUser, sendForgotPassword]);

  return (
    <>
      <Menu className="ml-5">
        <Menu.Button className="image-fit size-[36px] overflow-hidden rounded-full border-[3px] border-white/[0.15]">
          {currentUser && <Avatar src={currentUser?.avatar_url} size="100%" />}
          {!currentUser && <img alt="Default Avatar Logo" src={avatarLogo} />}
        </Menu.Button>
        <Menu.Items className="mt-1 w-56">
          <Menu.Item onClick={handleResetPassword}>
            <Lucide icon="Lock" className="mr-2 size-4" />
            Reset Password
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            onClick={() => {
              navigate(NAV_ROUTES.PROFILE);
            }}
          >
            <Lucide icon="Users" className="mr-2 size-4" />
            Profile Info
          </Menu.Item>
          <Menu.Item onClick={() => setShowConfirmLogoutDialog(true)}>
            <Lucide icon="Power" className="mr-2 size-4" />
            Logout
          </Menu.Item>
        </Menu.Items>
      </Menu>

      <ModalDialog
        open={showConfirmLogoutDialog}
        onConfirm={handleLogout}
        onCancel={() => setShowConfirmLogoutDialog(false)}
        title="Log Out"
        description="Do you really want to log out?"
        confirmText="Log Out"
        cancelText="Cancel"
      />
    </>
  );
}

export { ProfileMenu };
