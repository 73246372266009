import { BASE_URL } from '@constants';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { RootState } from '@store/store';
import {
  StripeProducts,
  SubscribeRequest,
  SubscribeResponse,
  Subscription,
} from '@typings';

export const stripeService = createApi({
  reducerPath: 'stripeService',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/organizations`,
    prepareHeaders: (headers, { getState }) => {
      const { token } = (getState() as RootState).auth;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: builder => ({
    products: builder.query<StripeProducts, string>({
      query: orgId => ({
        url: `${orgId}/products`,
        method: 'GET',
      }),
    }),
    subscriptions: builder.query<Subscription, string>({
      query: orgId => ({
        url: `${orgId}/subscriptions`,
        method: 'GET',
      }),
    }),
    subscribeToPlan: builder.mutation<SubscribeResponse, SubscribeRequest>({
      query: ({ orgId, price_id }) => ({
        url: `${orgId}/subscriptions`,
        method: 'POST',
        body: {
          price_id,
        },
      }),
    }),
  }),
});

export const {
  useProductsQuery,
  useLazyProductsQuery,
  useSubscriptionsQuery,
  useLazySubscriptionsQuery,
  useSubscribeToPlanMutation,
} = stripeService;
