// src/modules/stripe/associateSession.component.tsx
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, FormSelect, Lucide } from '@kerplunkai/common-components';
import { selectOrganizations } from '@store/selectors';
import { setSelectedOrg } from '@store/slices';
import { useAppDispatch } from '@store/hooks';
import { useAssociateSessionMutation } from '@store/services/organization.service';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export function AssociateStripeSession() {
  const navigate = useNavigate();
  const organizations = useSelector(selectOrganizations());
  const dispatch = useAppDispatch();
  const memoizedOrganizations = useMemo(
    () =>
      organizations?.filter(org => !org.subscription?.has_subscription) || [],
    [organizations],
  );
  const [selectedOrgId, setSelectedOrgId] = useState<string>('');
  const [updateOrganization] = useAssociateSessionMutation();

  const associateSession = useCallback(
    (orgId: string, sessionId: string) => {
      updateOrganization({ organizationId: orgId, stripeSessionId: sessionId })
        .unwrap()
        .then(response => {
          if (response.subscription) {
            localStorage.removeItem('stripe_session_id');
            dispatch(setSelectedOrg(response));
            navigate('/');
          }
        })
        .catch(error => {
          console.error('Failed to associate Stripe session:', error);
        });
    },
    [updateOrganization, navigate, dispatch],
  );

  useEffect(() => {
    const sessionId = localStorage.getItem('stripe_session_id');
    if (sessionId) {
      if (memoizedOrganizations.length === 1) {
        // Automatically associate the session if the user is only associated with one organization
        const singleOrgId = memoizedOrganizations[0].id;
        associateSession(singleOrgId, sessionId);
      }
    }
  }, [selectedOrgId, memoizedOrganizations, associateSession]);

  if (!localStorage.getItem('stripe_session_id')) {
    return null;
  }

  return (
    <div className="grid grid-cols-12 gap-x-6 gap-y-10">
      <div className="col-span-12">
        <div className="box box--stacked flex flex-col gap-4 p-5">
          <div className="flex items-center gap-2">
            <Lucide className="mr-2 size-5 text-sky-600" icon="CreditCard" />
            <h2 className="text-base font-semibold text-sky-800">
              Select Organization for Subscription
            </h2>
          </div>
          <p className="text-xs text-slate-500 opacity-80">
            Please choose the organization you want to associate with your new
            subscription. This step is necessary because your account is
            associated with multiple organizations, and we need to know which
            one to link with the subscription.
          </p>
          <div className="mt-4">
            <FormSelect
              value={selectedOrgId}
              onChange={e => setSelectedOrgId(e.target.value)}
              className="w-full"
            >
              <option value="">Select an organization</option>
              {Array.isArray(memoizedOrganizations) &&
                memoizedOrganizations.map(
                  (org: { id: string; name: string }) => (
                    <option key={org.id} value={org.id}>
                      {org.name}
                    </option>
                  ),
                )}
            </FormSelect>
          </div>
          <div className="mt-6">
            <Button
              className="w-full"
              disabled={!selectedOrgId}
              onClick={() => {
                const sessionId = localStorage.getItem('stripe_session_id');
                if (sessionId) {
                  associateSession(selectedOrgId, sessionId);
                }
              }}
            >
              Associate Subscription
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
