import { Outlet } from 'react-router-dom';

function JobsLayout() {
  return (
    <div className="grid grid-cols-12 gap-x-6 gap-y-10">
      <div className="col-span-12 flex flex-col gap-2.5">
        <Outlet />
      </div>
    </div>
  );
}

export { JobsLayout };
