/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
import { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';

import { Button, FormCard, Lucide } from '@kerplunkai/common-components';
import { CodeMirrorBlock } from '@components';
import { JobRequest } from '@typings';
import { selectJobQCategoryTypes } from '@store/selectors';

interface JobPreviewQuestionsProps {
  job: JobRequest;
  orgId: string;

  onStepChange: Dispatch<SetStateAction<number>>;
}

function JobPreviewQuestions({
  job,
  orgId,
  onStepChange,
}: JobPreviewQuestionsProps) {
  const categoryTypes = useSelector(selectJobQCategoryTypes(orgId));

  return (
    <FormCard title="Interview Questions&nbsp;✨">
      {job.interview_questions_attributes.map((question, index) => {
        if (question._destroy) return null;

        return (
          <div
            key={question.position}
            className="block flex-col pt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center"
          >
            <div className="mb-2 inline-block sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
              <div className="text-left">
                <div className="flex items-center">
                  <div className="font-medium text-slate-600">
                    Question&nbsp;{index + 1}
                  </div>
                  <div className="ml-2.5 rounded-md border border-sky-800/10 bg-sky-800/10 px-2 py-0.5 text-xs text-sky-800">
                    {(categoryTypes || {})[question.category]}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3 w-full flex-1 font-medium text-slate-600 xl:mt-0">
              {question.content}
              {question.code_blocks
                ?.filter(({ content, language }) => content && language)
                .map((codeBlock, curIndex) => (
                  <div key={`code-block-${curIndex}`} className="mt-2">
                    <CodeMirrorBlock block={codeBlock} editable={false} />
                  </div>
                ))}
            </div>
          </div>
        );
      })}
      <Button
        className="mt-12 w-full border-dashed"
        variant="soft-sky-outline"
        onClick={() => onStepChange(1)}
      >
        <Lucide icon="PenLine" className="mr-2 size-4 stroke-[1.3]" />
        Edit AI Questions
      </Button>
    </FormCard>
  );
}

export { JobPreviewQuestions };
