import * as Yup from 'yup';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { FormActions, FormCard } from '@kerplunkai/common-components';
import { isEmpty } from 'lodash';
import { useFormikContext } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { InterviewAttendee, InterviewRequest, Job } from '@typings';
import {
  InterviewAttendeesForm,
  InterviewDetailsForm,
  InterviewTypeForm,
} from '@modules/meetings/meetingForms';
import { MeetingPreview } from '@modules/meetings/meetingPreview';
import { NAV_ROUTES } from '@constants';
import { mapToInterviewRequest } from '@utilities/interview';
import { selectSelectedOrgId } from '@store/selectors';
import {
  useCreateInterviewMutation,
  useUpdateInterviewMutation,
} from '@store/services';

interface InterviewFormProps {
  activeStep: number;
  isLastStep: boolean;
  validationSchema: Yup.AnyObjectSchema;
  onStepChange: Dispatch<SetStateAction<number>>;
}

function InterviewForm({
  activeStep,
  isLastStep,
  validationSchema,
  onStepChange,
}: InterviewFormProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const {
    values: interview,
    setFieldValue,
    validateForm,
    validateField,
  } = useFormikContext<InterviewRequest>();

  const [createInterview, { isLoading: isCreating }] =
    useCreateInterviewMutation();
  const [updateInterview, { isLoading: isUpdating }] =
    useUpdateInterviewMutation();

  const orgId = useSelector(selectSelectedOrgId);

  const handleInterviewChange = useCallback(
    async (
      name: string,
      value: string | boolean | InterviewAttendee[] | undefined | Job,
    ) => {
      await setFieldValue(name, value);

      if (validationSchema.fields[name]) validateField(name);
    },
    [setFieldValue, validateField, validationSchema],
  );

  const handleSubmitJob = useCallback(async () => {
    if (!orgId) return;

    const payload = mapToInterviewRequest(interview, orgId);

    try {
      if (payload.id) await updateInterview(payload).unwrap();
      else await createInterview(payload).unwrap();

      navigate(`${NAV_ROUTES.MEETINGS}`, {
        replace: true,
      });
    } catch (err) {
      enqueueSnackbar({
        message: 'An unknown error occurred.',
        variant: 'error',
      });
    }
  }, [
    enqueueSnackbar,
    navigate,
    interview,
    orgId,
    createInterview,
    updateInterview,
  ]);

  const handleBack = useCallback(() => {
    if (activeStep === 0) navigate('/');

    onStepChange(activeStep - 1);
  }, [navigate, activeStep, onStepChange]);

  const handleNext = useCallback(async () => {
    if (validateForm) {
      const errors = await validateForm();

      if (!isEmpty(errors)) return;
    }

    if (!isLastStep) {
      onStepChange(activeStep + 1);
      return;
    }

    await handleSubmitJob();
  }, [activeStep, isLastStep, validateForm, handleSubmitJob, onStepChange]);

  const isLoading = isCreating || isUpdating;

  const submitText = useMemo(() => {
    if (isLastStep) return isLoading ? 'Scheduling' : 'Schedule Meeting';

    return 'Next';
  }, [isLastStep, isLoading]);

  const renderedStep = useMemo(() => {
    switch (activeStep) {
      case 0:
        return (
          <>
            <FormCard title="Meeting Information">
              <div className="w-full rounded-lg bg-white p-5">
                <InterviewDetailsForm
                  isLoading={isLoading}
                  interview={interview as InterviewRequest}
                  onChange={handleInterviewChange}
                />
              </div>
            </FormCard>
            <FormCard title="Meeting Type">
              <div className="w-full rounded-lg bg-white p-5">
                <InterviewTypeForm
                  isLoading={isLoading}
                  interview={interview as InterviewRequest}
                  onChange={handleInterviewChange}
                />
              </div>
            </FormCard>
            <FormCard title="Meeting Guest">
              <div className="w-full rounded-lg bg-white p-5">
                <InterviewAttendeesForm
                  isLoading={isLoading}
                  interview={interview as InterviewRequest}
                  onChange={handleInterviewChange}
                />
              </div>
            </FormCard>
          </>
        );

      default:
        return <MeetingPreview interview={interview} />;
    }
  }, [activeStep, isLoading, interview, handleInterviewChange]);

  return (
    <div className="col-span-12 flex flex-col gap-y-7">
      {renderedStep}
      <FormActions
        cancel={{
          className: '!w-[175px]',
          disabled: isLoading,
          icon: activeStep === 0 ? 'X' : 'ArrowLeft',
          text: activeStep === 0 ? 'Cancel' : 'Back',
          onClick: handleBack,
        }}
        submit={{
          className: '!w-[175px]',
          variant: isLastStep ? 'greenGradient' : 'primary',
          disabled: isLoading,
          icon: isLastStep ? 'Send' : 'PenLine',
          text: submitText,
          onClick: handleNext,
        }}
      />
    </div>
  );
}

export { InterviewForm };
