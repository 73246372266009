import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { VideoSdkRecordingStart, VideoSdkRecordingStop } from '@typings';

export const videoSdkService = createApi({
  reducerPath: 'videoSdkService',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.videosdk.live/v2/recordings/participant/',
    prepareHeaders: headers => {
      headers.set('Authorization', import.meta.env.VITE_VIDEO_SDK_TOKEN);
      headers.set('Content-Type', 'application/json');
    },
  }),
  endpoints: builder => ({
    startParticipantRecording: builder.mutation<void, VideoSdkRecordingStart>({
      query: body => ({
        url: 'start',
        method: 'POST',
        body,
      }),
    }),
    stopParticipantRecording: builder.mutation<void, VideoSdkRecordingStop>({
      query: body => ({
        url: 'stop',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useStartParticipantRecordingMutation,
  useStopParticipantRecordingMutation,
} = videoSdkService;
