/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Button,
  ConfirmDialog,
  Lucide,
  TableBase,
  Tippy,
} from '@kerplunkai/common-components';
import { CodeBlock, JobQuestion, JobRequest } from '@typings';
import { JobInterviewQuestion } from '@modules/jobs/jobForms/jobInterviewQuestions/jobInterviewQuestion.component';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { selectSelectedOrgId } from '@store/selectors';
import { sortObjByBoolean } from '@utilities';

interface JobInterviewQuestionsTableProps {
  isLoading: boolean;
  job: JobRequest;
  onChange: (name: string, value: JobQuestion[]) => void;
}

function JobInterviewQuestionsTable({
  isLoading,
  job,
  onChange,
}: JobInterviewQuestionsTableProps) {
  const orgId = useSelector(selectSelectedOrgId);

  const [deleteAll, setDeleteAll] = useState(false);

  const draggableItems = useMemo(
    () => job.interview_questions_attributes.map(({ position }) => position),
    [job.interview_questions_attributes],
  );

  const handleQuestionChange = useCallback(
    (value: string | CodeBlock[], name: string, index: number) => {
      const jobQuestions = [...job.interview_questions_attributes];
      jobQuestions[index] = { ...jobQuestions[index], [name]: value };

      onChange('interview_questions_attributes', jobQuestions);
    },
    [job, onChange],
  );

  const handleAnswerChange = useCallback(
    (value: string, answerIndex: number, index: number) => {
      const jobQuestions = [...job.interview_questions_attributes];
      const suggestedAnswers = [
        ...(jobQuestions?.[index]?.suggested_answers || []),
      ];
      suggestedAnswers[answerIndex] = value;

      jobQuestions[index] = {
        ...jobQuestions[index],
        suggested_answers: suggestedAnswers,
      };
      onChange('interview_questions_attributes', jobQuestions);
    },
    [job, onChange],
  );

  const handleClearAnswers = useCallback(
    (index: number) => {
      const jobQuestions = [...job.interview_questions_attributes];

      jobQuestions[index] = {
        ...jobQuestions[index],
        suggested_answers: [],
      };
      onChange('interview_questions_attributes', jobQuestions);
    },
    [job, onChange],
  );

  const handleToggleLockQuestion = useCallback(
    (index: number) => {
      const jobQuestions = [...job.interview_questions_attributes];
      const jobQuestion = { ...jobQuestions[index] };
      jobQuestion.locked = !jobQuestion.locked;

      jobQuestions[index] = jobQuestion;

      onChange('interview_questions_attributes', jobQuestions);
    },
    [job, onChange],
  );

  const handleRemoveQuestion = useCallback(
    (index: number) => {
      let jobQuestions = [...job.interview_questions_attributes];
      const jobQuestion = { ...jobQuestions[index] };

      if (jobQuestion.id) {
        jobQuestion._destroy = true;
        jobQuestions[index] = jobQuestion;
      } else jobQuestions.splice(index, 1);

      // reset positions after deletion
      jobQuestions = jobQuestions.map((jq, i) => {
        return {
          ...jq,
          position: i + 1,
        };
      });

      onChange(
        'interview_questions_attributes',
        jobQuestions.sort(sortObjByBoolean('_destroy')),
      );
    },
    [job, onChange],
  );

  const handleRemoveAnswer = useCallback(
    (questionIndex: number, answerIndex: number) => {
      const updatedQuestions = job.interview_questions_attributes.map(
        (question, index) => {
          if (index === questionIndex) {
            const suggestedAnswers = (question.suggested_answers || []).filter(
              (_, idx) => idx !== answerIndex,
            );
            return { ...question, suggested_answers: suggestedAnswers };
          }
          return question;
        },
      );

      onChange('interview_questions_attributes', updatedQuestions);
    },
    [job, onChange],
  );

  const allLocked = useMemo(
    () => job.interview_questions_attributes.every(question => question.locked),
    [job.interview_questions_attributes],
  );

  const handleToggleLockAll = useCallback(() => {
    onChange(
      'interview_questions_attributes',
      job.interview_questions_attributes.map(question => ({
        ...question,
        locked: !allLocked,
      })),
    );
  }, [allLocked, job.interview_questions_attributes, onChange]);

  const handleRemoveUnlocked = useCallback(() => {
    const newQuestions = job.interview_questions_attributes.map(question => ({
      ...question,
      _destroy: !question.locked,
    }));

    onChange('interview_questions_attributes', newQuestions);

    setDeleteAll(false);
  }, [job.interview_questions_attributes, onChange]);

  const numJobsRemovable = useMemo(
    () =>
      job.interview_questions_attributes.reduce((acc, { locked, _destroy }) => {
        if (!locked && !_destroy) {
          acc += 1;
        }
        return acc;
      }, 0),
    [job.interview_questions_attributes],
  );

  return (
    <>
      <TableBase className="border-separate border-spacing-0 rounded-md border border-b-0">
        <TableBase.Thead className="w-full bg-slate-100">
          <TableBase.Tr>
            <TableBase.Th className="w-24 rounded-tl-md border-b  text-slate-500">
              Position
            </TableBase.Th>
            <TableBase.Th className="w-24 border-b text-slate-500">
              Category
            </TableBase.Th>
            <TableBase.Th className="w-24 border-b text-slate-500" />
            <TableBase.Th className="border-b text-slate-500">
              Questions
            </TableBase.Th>
            <TableBase.Th className="w-24 rounded-tr-md border-b text-slate-500">
              <div className="flex w-full justify-between">
                <Button
                  disabled={isLoading}
                  variant="text"
                  onClick={handleToggleLockAll}
                >
                  <Lucide
                    icon={allLocked ? 'LockKeyhole' : 'UnlockKeyhole'}
                    className="size-5 stroke-[1.3]"
                    color={allLocked ? '#558408' : '#64748B'}
                  />
                </Button>
                <Tippy
                  as={Button}
                  disabled={isLoading}
                  content={`Delete ${numJobsRemovable} Questions`}
                  variant="text"
                  onClick={() => setDeleteAll(true)}
                >
                  <Lucide icon="Trash2" className="size-5 stroke-[1.3]" />
                </Tippy>
              </div>
            </TableBase.Th>
          </TableBase.Tr>
        </TableBase.Thead>
        <TableBase.Tbody>
          <SortableContext
            items={draggableItems}
            strategy={verticalListSortingStrategy}
          >
            {job.interview_questions_attributes.map((question, index) => {
              if (question._destroy) return null;

              return (
                <JobInterviewQuestion
                  key={question.position}
                  index={index}
                  isLoading={isLoading}
                  question={question}
                  orgId={orgId as string}
                  onQuestionChange={handleQuestionChange}
                  onAnswerChange={handleAnswerChange}
                  onRemoveQuestion={handleRemoveQuestion}
                  onRemoveAnswer={handleRemoveAnswer}
                  onToggleLock={handleToggleLockQuestion}
                  onAnswerclear={handleClearAnswers}
                />
              );
            })}
          </SortableContext>
        </TableBase.Tbody>
      </TableBase>
      <ConfirmDialog
        body="Do you really want to delete these questions?"
        cancelAction={{
          onClick: () => setDeleteAll(false),
        }}
        confirmAction={{
          onClick: handleRemoveUnlocked,
        }}
        open={deleteAll}
        onClose={() => setDeleteAll(false)}
      />
    </>
  );
}

export { JobInterviewQuestionsTable };
