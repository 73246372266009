import { Button, Lucide } from '@kerplunkai/common-components';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { convert } from 'html-to-text';
import { useSelector } from 'react-redux';

import PlacholderLogo from '@assets/images/logo-placeholder.png';
import { JobRequest } from '@typings';
import { JobSummaryGroup } from '@modules/jobs/jobPreview/jobSummaryGroup.component';
import { JobSummaryGroupItem } from '@modules/jobs/jobPreview/jobSummaryGroupItem.component';
import { formatLocation } from '@utilities';
import {
  selectJobCategories,
  selectJobExperienceLevels,
  selectJobOrgBenefits,
  selectJobSchedule,
  selectJobSubcategories,
  selectJobTypes,
  selectOrgClients,
  selectSelectedOrg,
} from '@store/selectors';

interface JobSummaryProps {
  job: JobRequest;
  orgId: string;

  onStepChange?: Dispatch<SetStateAction<number>>;
}

function JobSummary({ job, orgId, onStepChange }: JobSummaryProps) {
  const jobTypes = useSelector(selectJobTypes(orgId));
  const experienceLevels = useSelector(selectJobExperienceLevels(orgId));
  const schedules = useSelector(selectJobSchedule(orgId));
  const orgBenefits = useSelector(selectJobOrgBenefits(orgId));
  const categories = useSelector(selectJobCategories(orgId));
  const subCategories = useSelector(selectJobSubcategories(orgId));
  const selectedOrg = useSelector(selectSelectedOrg);
  const clients = useSelector(
    selectOrgClients({
      orgId: orgId as string,
      pagination: {
        pageIndex: 1,
        pageSize: 1000,
      },
      filters: '',
    }),
  );

  const renderCompensation = useCallback(() => {
    const minPay = job.min_pay ? Number(job.min_pay) : 'N/A';
    const maxPay = job.max_pay ? Number(job.max_pay) : 'N/A';
    const payRange = `$${minPay}-$${maxPay}`;

    return job.pay_type === 'per_hour'
      ? `${payRange} Per Hour`
      : `${payRange} Per Year`;
  }, [job]);

  const hiringOrg = useMemo(() => {
    if (job.organization_id === job.hiring_organization_id) return selectedOrg;

    return clients?.find(c => c.id === job.hiring_organization_id);
  }, [clients, job, selectedOrg]);

  return (
    <div className="box box--stacked flex flex-col gap-4 p-5">
      <div className="flex gap-2.5">
        <img
          className="size-[44px]"
          src={hiringOrg?.logo_url || PlacholderLogo}
          alt="Preview Logo"
        />
        <div className="self-center">
          <p className="text-base font-medium text-slate-600">
            {hiringOrg?.name || 'Org Name Placeholder'}
          </p>
          <p className="text-xs text-slate-400">
            {formatLocation({
              city: job.city,
              country: job.country,
              state: job.state,
            })}
          </p>
        </div>
      </div>
      <div>
        <JobSummaryGroup borderRadius="8px 8px 0px 0px" title="Job Information">
          <JobSummaryGroupItem label="Job Title" value={job.title} />
          <JobSummaryGroupItem
            label="Job Organization"
            value={hiringOrg?.name || 'Org Name Placeholder'}
          />
          <JobSummaryGroupItem
            label="Job Category"
            value={
              <div className="flex flex-col gap-1.5">
                {job.job_category_ids.map(category => (
                  <p
                    key={category}
                    className="text-xs font-medium text-slate-500"
                  >
                    {categories
                      ? categories.find(curCat => curCat.id === category)?.name
                      : category}
                  </p>
                ))}
              </div>
            }
          />
          <JobSummaryGroupItem
            label="Job SubCategory"
            value={
              <div className="flex flex-col gap-1.5">
                {job.job_subcategory_ids.map(subCategory => (
                  <p
                    key={subCategory}
                    className="text-xs font-medium text-slate-500"
                  >
                    {subCategories
                      ? subCategories.find(curCat => curCat.id === subCategory)
                          ?.name
                      : subCategory}
                  </p>
                ))}
              </div>
            }
          />
        </JobSummaryGroup>
        <JobSummaryGroup borderWidth="0px 1px 1px" title="Job Description">
          <p className="line-clamp-3 text-xs font-medium text-slate-500">
            {convert(job.description)}
          </p>
        </JobSummaryGroup>
        <JobSummaryGroup borderWidth="0px 1px 1px" title="Additional Details">
          <JobSummaryGroupItem
            label="Job Type"
            value={jobTypes ? jobTypes[job.job_type] : job.job_type}
          />
          <JobSummaryGroupItem
            label="Experience Level"
            value={
              <div className="flex flex-col gap-1.5">
                {job.experience_levels.map(level => (
                  <p key={level} className="text-xs font-medium text-slate-500">
                    {experienceLevels ? experienceLevels[level] : level}
                  </p>
                ))}
              </div>
            }
          />
          <JobSummaryGroupItem
            label="Schedule"
            value={
              <div className="flex flex-col gap-1.5">
                {job.schedules.map(schedule => (
                  <p
                    key={schedule}
                    className="text-xs font-medium text-slate-500"
                  >
                    {schedules ? schedules[schedule] : schedule}
                  </p>
                ))}
              </div>
            }
          />
        </JobSummaryGroup>
        <JobSummaryGroup
          borderRadius="0px 0px 8px 8px"
          borderWidth="0px 1px 1px"
          title="Compensation"
        >
          <JobSummaryGroupItem
            label="Display Compensation"
            value={job.display_compensation ? 'Yes' : 'No'}
          />
          {job.display_compensation && (
            <JobSummaryGroupItem label="Pay" value={renderCompensation()} />
          )}
          <JobSummaryGroupItem
            label="Benefits"
            value={
              <div className="flex flex-col gap-1.5">
                {(job.benefit_ids || []).map(benefit => (
                  <p
                    key={benefit}
                    className="text-xs font-medium text-slate-500"
                  >
                    {orgBenefits ? orgBenefits[benefit] : benefit}
                  </p>
                ))}
              </div>
            }
          />
        </JobSummaryGroup>
      </div>
      {onStepChange && (
        <Button
          className="w-full border-dashed"
          variant="soft-sky-outline"
          onClick={() => onStepChange(0)}
        >
          <Lucide icon="PenLine" className="mr-2 size-4 stroke-[1.3]" />
          Edit Job Information
        </Button>
      )}
    </div>
  );
}

export { JobSummary };
