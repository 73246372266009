const getGoogleAuthUrl = () => {
  const url = new URL(window.location.href);
  url.hash = '';
  const origin = encodeURIComponent(url.toString());
  const baseUrl = new URL(import.meta.env.VITE_API_URL);
  baseUrl.pathname += baseUrl.pathname.endsWith('/') ? '' : '/';
  return `${baseUrl.toString()}users/auth/google_oauth2?origin=${origin}`;
};

export { getGoogleAuthUrl };
