/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { Loading } from '@kerplunkai/common-components';
import { NAV_ROUTES } from '@constants';
import { selectCurrentUser } from '@store/selectors';
import { setInviteToken, setSelectedOrg } from '@store/slices';
import {
  useAcceptInviteMutation,
  useCheckTokenMutation,
  useLazyOrganizationsQuery,
} from '@store/services';
import { useAppDispatch } from '@store/hooks';

function AcceptInviation() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [acceptInvite] = useAcceptInviteMutation();
  const [getOrgs] = useLazyOrganizationsQuery();
  const [checkToken] = useCheckTokenMutation();

  const currentUser = useSelector(selectCurrentUser);

  useEffect(() => {
    const invitation_token = searchParams.get('token');

    if (!invitation_token || !currentUser) {
      if (invitation_token) dispatch(setInviteToken(invitation_token));

      navigate(NAV_ROUTES.LOGIN);
      return;
    }

    (async () => {
      try {
        await acceptInvite(invitation_token as string).unwrap();

        const [tokenOrg] = await checkToken(invitation_token).unwrap();
        const { results: organizations } = await await getOrgs().unwrap();

        enqueueSnackbar({
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          children: (
            <div className="flex flex-col gap-1">
              <p className="text-sm text-[#364154]">Invite Accepted</p>
              <p className="text-sm text-[#677489]">
                You have accepted the invitation.
              </p>
            </div>
          ),
          icon: 'BellRing',
          variant: 'toast-notification',
        });

        const acceptedOrg = (organizations || []).find(
          ({ id }) => id === tokenOrg.organization_id,
        );

        if (acceptedOrg) dispatch(setSelectedOrg(acceptedOrg));
      } catch (e) {
        enqueueSnackbar({
          message: 'Unable to accept invite. Please try again.',
          variant: 'error',
        });
      }

      dispatch(setInviteToken(undefined));
      navigate('/');
    })();
  }, [
    dispatch,
    enqueueSnackbar,
    navigate,
    currentUser,
    searchParams,
    acceptInvite,
    checkToken,
    getOrgs,
  ]);

  return <Loading />;
}

export { AcceptInviation };
