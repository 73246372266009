import { configureStore } from '@reduxjs/toolkit';

import {
  applicationService,
  authService,
  benefitService,
  categoryService,
  clientService,
  integrationService,
  interviewService,
  jobService,
  orgUserService,
  organizationService,
  stripeService,
  userService,
  videoSdkService,
} from '@store/services';
import {
  authReducer,
  interviewReducer,
  jobReducer,
  layoutReducer,
} from '@store/slices';

export const store = configureStore({
  reducer: {
    [applicationService.reducerPath]: applicationService.reducer,
    [authService.reducerPath]: authService.reducer,
    [benefitService.reducerPath]: benefitService.reducer,
    [categoryService.reducerPath]: categoryService.reducer,
    [clientService.reducerPath]: clientService.reducer,
    [integrationService.reducerPath]: integrationService.reducer,
    [interviewService.reducerPath]: interviewService.reducer,
    [jobService.reducerPath]: jobService.reducer,
    [orgUserService.reducerPath]: orgUserService.reducer,
    [organizationService.reducerPath]: organizationService.reducer,
    [stripeService.reducerPath]: stripeService.reducer,
    [userService.reducerPath]: userService.reducer,
    [videoSdkService.reducerPath]: videoSdkService.reducer,
    auth: authReducer,
    job: jobReducer,
    layout: layoutReducer,
    interview: interviewReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(
      applicationService.middleware,
      authService.middleware,
      benefitService.middleware,
      categoryService.middleware,
      clientService.middleware,
      integrationService.middleware,
      interviewService.middleware,
      jobService.middleware,
      orgUserService.middleware,
      organizationService.middleware,
      stripeService.middleware,
      userService.middleware,
      videoSdkService.middleware,
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
