import { Meeting, Participant, VideoSDK } from '@videosdk.live/js-sdk';
import { cloneDeep } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { IKerplunkMediaService } from '@typings';
import {
  selectActiveCameraStream,
  selectActiveMicStream,
} from '@store/selectors';
import {
  useStartParticipantRecordingMutation,
  useStopParticipantRecordingMutation,
} from '@store/services';

const VIDEO_SDK_TOKEN = import.meta.env.VITE_VIDEO_SDK_TOKEN;

const RECORD_DELAY_TIMEOUT_MS = 1500;

// Configure authentication token
VideoSDK.config(VIDEO_SDK_TOKEN);

export const useVideoSDKJSService = (): IKerplunkMediaService => {
  const [startRecording] = useStartParticipantRecordingMutation();
  const [stopRecording] = useStopParticipantRecordingMutation();

  const [awsPath, setAWSPath] = useState<string | null>(null);
  const [meeting, setMeeting] = useState<Meeting | null>(null);
  const [isMeetingConnected, setIsMeetingConnected] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [localParticipant, setLocalParticipant] = useState<Participant>();
  const [isRecordingTriggered, setRecordingIsTriggered] = useState(false);

  // this is done to wait until recording state is correct, before actually recording
  const [recordingState, setRecordingState] = useState<
    | 'RECORDING_STARTED'
    | 'RECORDING_STARTING'
    | 'RECORDING_STOPPING'
    | 'RECORDING_STOPPED'
  >('RECORDING_STOPPED');

  console.log('PARTICIPANTS', participants, recordingState);
  const cameraStream = useSelector(selectActiveCameraStream);
  const micStream = useSelector(selectActiveMicStream);

  const recordingConfig: {
    layout: {
      type: 'GRID' | 'SPOTLIGHT' | 'SIDEBAR';
      priority: 'SPEAKER' | 'PIN';
      gridSize: number;
    };
    theme: 'DARK' | 'LIGHT' | 'DEFAULT';
    mode: 'video-and-audio' | 'audio';
    quality: 'low' | 'med' | 'high';
  } = useMemo(() => {
    return {
      layout: {
        type: 'SPOTLIGHT',
        priority: 'SPEAKER',
        gridSize: 1,
      },
      theme: 'DEFAULT',
      mode: 'video-and-audio',
      quality: 'high',
    };
  }, []);

  // console.log('participant:', meeting?.localParticipant);
  // console.log('meeting.recordingState:', meeting?.recordingState);

  // listen for recording trigger changes
  useEffect(() => {
    // start recording only after meeting state is ready for it
    if (isRecordingTriggered && awsPath && localParticipant && meeting) {
      // only allow starting recording when recording is stopped
      // or when we first join, when recordingState is awesomely undefined, cool.
      if (recordingState === 'RECORDING_STOPPED') {
        (async () => {
          try {
            setRecordingState('RECORDING_STARTING');

            await startRecording({
              roomId: meeting.id,
              participantId: localParticipant.id,
              bucketDirPath: awsPath,
              fileFormat: 'webm',
            }).unwrap();

            setRecordingState('RECORDING_STARTED');
          } catch (e) {
            console.log('ERROR', e);
            setRecordingState('RECORDING_STOPPED');
          }

          setRecordingIsTriggered(false);
        })();
      }
    }
  }, [
    awsPath,
    isRecordingTriggered,
    meeting,
    recordingConfig,
    recordingState,
    localParticipant,
    startRecording,
  ]);

  // update meeting streams when new ones are available
  useEffect(() => {
    if (meeting && cameraStream) {
      meeting.changeWebcam(cameraStream);
    }

    if (meeting && micStream) {
      meeting.changeMic(micStream);
    }
  }, [cameraStream, meeting, micStream]);

  // manage setting isRecording state, to show an indicator to user that we're recording
  useEffect(() => {
    // helps with delay in starting capture
    if (recordingState === 'RECORDING_STARTED') {
      setTimeout(() => {
        setIsRecording(true);
      }, RECORD_DELAY_TIMEOUT_MS);
    } else {
      setIsRecording(false);
    }
  }, [recordingState]);

  // set up event listeners and manage recording state based on events from sdk
  useEffect(() => {
    if (meeting) {
      meeting?.on('meeting-joined', data => {
        console.log('videosdk meeting joined', data);
        setIsMeetingConnected(true);
      });

      meeting?.on('connection-close', data => {
        console.log('videosdk connection closed: ', data);
      });

      // meeting?.on('recording-state-changed', data => {
      //   console.log('videosdk recording state changed: ', data, data.status);
      //   setRecordingState(data.status);
      // });

      meeting?.on('video-state-changed', data => {
        console.log('videosdk video state changed: ', data);
      });

      meeting?.on('error', data => {
        console.error('videosdk error: ', data);
      });

      meeting?.localParticipant.on('media-status-changed', data => {
        console.log('participant media status changed:', data);
      });

      meeting?.localParticipant.on('stream-enabled', data => {
        console.log('participant media stream enabled:', data);
      });

      meeting?.localParticipant.on('stream-disabled', data => {
        console.log('participant media stream disabled:', data);
      });

      meeting?.on('participant-joined', data => {
        console.log('participant joined:', data);
        const curParticipantIndex = participants.findIndex(
          participant => participant.id === (data as Participant).id,
        );
        if (curParticipantIndex === -1)
          setParticipants([...participants, data as Participant]);
      });

      meeting?.on('participant-left', data => {
        console.log('participant left:', data);
        const curParticipantIndex = participants.findIndex(
          participant => participant.id === (data as Participant).id,
        );
        const newParticipants = cloneDeep(participants);
        if (curParticipantIndex !== -1)
          newParticipants?.splice(curParticipantIndex, 1);

        setParticipants(newParticipants);
      });
    }
  }, [meeting, participants]);

  const resetState = () => {
    setAWSPath(null);
    setIsMeetingConnected(false);
    setIsRecording(false);
    setMeeting(null);
  };

  return useMemo(
    () => ({
      initClient: async ({ room_id, awsStoragePath, participantName }) => {
        if (participantName && awsStoragePath && room_id) {
          try {
            setAWSPath(awsStoragePath);
            if (!meeting) {
              console.log('videosdk initializing...');
              // Initialise meeting
              const meetingInstance = VideoSDK.initMeeting({
                meetingId: room_id, // required
                name: participantName, // required
                // participantId: 'Id-of-participant', // optional, default: SDK will generate
                micEnabled: true, // optional, default: true
                webcamEnabled: true, // optional, default: true
                autoConsume: true,
                // mode: 'CONFERENCE',
                // maxResolution: "<Maximum-resolution>", // optional, default: "hd"
                customCameraVideoTrack: cameraStream || undefined,
                customMicrophoneAudioTrack: micStream || undefined,
                // multiStream: false, // optional, default: true
                // debugMode: true,
              });

              console.log('videosdk joining meeting...');
              setMeeting(meetingInstance);
              setLocalParticipant(meetingInstance?.localParticipant);
              meetingInstance?.join();
            }
          } catch (e) {
            console.log('videosdk error creating meeting: ', e);
          }
        }
      },
      startRecording: () => {
        setRecordingIsTriggered(true);
      },
      stopRecording: () => {
        if (meeting && localParticipant)
          stopRecording({
            roomId: meeting?.id,
            participantId: localParticipant.id,
          });
      },
      enableWebcam: () => meeting?.enableWebcam(),
      disableWebcam: () => meeting?.disableWebcam(),
      muteMic: () => meeting?.muteMic(),
      unmuteMic: () => meeting?.unmuteMic(),
      close: () => {
        console.log(
          'videosdk stopping recording and ending meeting. goodbye and good luck with your interviews!',
        );
        meeting?.stopRecording();
        setTimeout(() => {
          // calling this immediately after stopping recording causes the recording to continue, cool
          meeting?.end();
        }, 2000);
        resetState();
      },
      isRecording,
      isMeetingConnected,
      participants,
      localParticipant,
    }),
    [
      cameraStream,
      isMeetingConnected,
      isRecording,
      meeting,
      micStream,
      participants,
      localParticipant,
      stopRecording,
    ],
  );
};
