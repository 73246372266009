interface HighlightableTextProps {
  text: string;
  searchTerm?: string;
}

export function HighlightableText({
  text,
  searchTerm,
}: HighlightableTextProps) {
  if (!searchTerm) return text;

  const escapedSearchTerm = searchTerm.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

  const regex = new RegExp(`(${escapedSearchTerm})`, 'gi');
  const parts = text.split(regex);

  return (
    <>
      {parts.map((part, index) =>
        regex.test(part) ? (
          // eslint-disable-next-line react/no-array-index-key
          <span key={index} className="bg-[#A9F72A]">
            {part}
          </span>
        ) : (
          part
        ),
      )}
    </>
  );
}
