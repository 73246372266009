// import { ChartDataset } from 'chart.js';
import { Disclosure, Lucide } from '@kerplunkai/common-components';
import { HighlightableText } from '@modules/interviewDetails/components';
import { InterviewQuestionAnalysisDetail } from '@typings';

// import { getInterviewScoringDetails } from '@utilities';
// import { useCallback } from 'react';

interface InterviewQuestionTranscriptNotesProps {
  interviewQuestion: InterviewQuestionAnalysisDetail;
  searchTerm?: string;
}

function InterviewQuestionTranscriptNotes({
  interviewQuestion,
  searchTerm,
}: InterviewQuestionTranscriptNotesProps) {
  // const getRadarDataSets = useCallback(() => {
  //   const dataSets: ChartDataset<'radar'>[] = [];
  //   if (interviewQuestion.score) {
  //     const radarScoringDetails = getInterviewScoringDetails(
  //       interviewQuestion.score,
  //     );

  //     if (radarScoringDetails.resume) {
  //       dataSets.push({
  //         label: 'Resume',
  //         data: mapBreakdownSummaryToRadar(radarScoringDetails.resume),
  //         fill: true,
  //         borderWidth: 1,
  //         backgroundColor: 'rgba(7, 89, 133, 0.2)',
  //         borderColor: 'rgba(7, 89, 133, 0.6)',
  //         pointBackgroundColor: 'rgba(7, 89, 133, 0.6)',
  //         pointBorderColor: 'transparent',
  //         pointHoverBackgroundColor: '#fff',
  //         pointHoverBorderColor: 'rgba(7, 89, 133, 0.6)',
  //       });
  //     }

  //     if (radarScoringDetails.interview) {
  //       dataSets.push({
  //         label: 'Interview',
  //         data: mapBreakdownSummaryToRadar(radarScoringDetails.interview),
  //         fill: true,
  //         borderWidth: 1,
  //         backgroundColor: 'rgba(85, 132, 8, .2)',
  //         borderColor: 'rgba(85, 132, 8, .6)',
  //         pointBackgroundColor: 'rgba(85, 132, 8, .6)',
  //         pointBorderColor: 'transparent',
  //         pointHoverBackgroundColor: '#fff',
  //         pointHoverBorderColor: 'rgba(85, 132, 8, .6)',
  //       });
  //     }
  //   }

  //   return dataSets;
  // }, [interview]);

  return (
    <Disclosure className="rounded-[0.6rem]">
      <Disclosure.Button>
        <div className="flex border-b border-dashed border-slate-200/60 pb-5 text-[0.94rem] font-medium">
          <Lucide icon="ChevronDown" className="mr-2 size-5 stroke-[1.3]" />
          <div className="text-base">AI Notes</div>
        </div>
      </Disclosure.Button>
      <Disclosure.Panel className="text-sm font-normal leading-relaxed text-slate-600 dark:text-slate-500">
        <div className="grid grid-cols-12 gap-4">
          {/* TODO: adjust this grid and add radar chart */}
          {interviewQuestion.commentary && (
            <div className="col-span-12 px-4">
              <ul className="list-disc">
                {interviewQuestion.commentary?.map(text => {
                  return (
                    <li key={text} className="mb-4">
                      <HighlightableText text={text} searchTerm={searchTerm} />
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          {!interviewQuestion.commentary && (
            <div className="col-span-12">No AI Notes available</div>
          )}
          {/* <div className="col-span-5 bg-gray-300 px-4">
            <RadarChart
              labels={[
                'X-Factor',
                'Soft Skills',
                'Resume Score',
                'Culture Fit',
                'Technical',
              ]}
              datasets={getRadarDataSets()}
            />
          </div> */}
        </div>
      </Disclosure.Panel>
    </Disclosure>
  );
}

export { InterviewQuestionTranscriptNotes };
