import { JobQuestion, JobRequest } from '@typings';

export const INITIAL_JOB: JobRequest = {
  benefit_ids: [],
  city: '',
  description: '',
  display_compensation: true,
  show_hiring_organization: true,
  experience_levels: [],
  job_category_ids: [],
  job_location: '',
  job_subcategory_ids: [],
  job_type: '',
  max_pay: '0',
  min_pay: '0',
  industry_id: '',
  interview_questions_attributes: [],
  organization_id: '',
  hiring_organization_id: '',
  pay_type: 'per_hour',
  schedules: [],
  state: '',
  status: 'draft',
  title: '',
  location_type: '',
  country: '',
  generate_coding_questions: false,
  intro_video_url: '',
};

export const INITIAL_JOB_QUESTION: JobQuestion = {
  category: 'culture',
  content: '',
  generated_by_ai: false,
  locked: false,
  position: 1,
};
