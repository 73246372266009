import { NAV_ROUTES } from '@constants';
import { icons } from '@kerplunkai/common-components';

export const QUICK_LINKS: {
  borderClassName: string; // border color
  icon: keyof typeof icons;
  btnClassName?: string;
  iconClassName: string; // icon color
  route: string;
  text: string;
}[] = [
  {
    borderClassName: 'border-cyan-600/10 bg-cyan-600/10 rounded-full border ',
    icon: 'Clock',
    iconClassName: 'fill-cyan-600/10 stroke-cyan-600',
    route: NAV_ROUTES.RECENT_INTERVIEWS,
    text: 'Recent Interviews',
  },
  {
    borderClassName: 'border-cyan-600/10 bg-cyan-600/10 rounded-full border',
    icon: 'GanttChartSquare',
    iconClassName: 'fill-cyan-600/10 stroke-cyan-600',
    route: NAV_ROUTES.JOBS,
    text: 'All Jobs',
  },
  {
    borderClassName: 'border-sky-800/10 bg-sky-800/10 rounded-full border',
    icon: 'PenLine',
    iconClassName: 'fill-sky-800/10 stroke-sky-800/70',
    btnClassName: 'hidden md:flex',
    route: `${NAV_ROUTES.JOBS}/new`,
    text: 'Post a Job',
  },

  // NEW feature
  // {
  //   borderClassName: 'border-sky-800/10 bg-sky-800/10 rounded-full border',
  //   icon: 'NotebookText',
  //   iconClassName: 'fill-sky-800/10 stroke-sky-800/70',
  //   route: ``,
  //   text: 'Start Meeting',
  // },
  {
    borderClassName: 'border-pending/10 bg-pending/10 rounded-full border',
    icon: 'Users',
    iconClassName: 'fill-pending/10 stroke-pending/70',
    route: NAV_ROUTES.SEARCH,
    text: 'Candidate Search',
  },
  {
    borderClassName: 'border-warning/10 bg-warning/10 rounded-full border',
    icon: 'Settings',
    iconClassName: 'fill-warning/10 stroke-warning/70',
    route: NAV_ROUTES.SETTINGS,
    text: 'Settings',
  },
];
