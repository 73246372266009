/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { useCallback, useMemo, useState } from 'react';

import {
  Button,
  ConfirmDialog,
  Lucide,
  TableBase,
  Tippy,
} from '@kerplunkai/common-components';
import { InterviewAttendee, InterviewRequest } from '@typings';
import { InterviewAttendeeTableRow } from '@modules/meetings/components';
import { sortObjByBoolean } from '@utilities';

interface InterviewAttendeesTableProps {
  isLoading: boolean;
  interview: InterviewRequest;
  onChange: (name: string, value: InterviewAttendee[]) => void;
}

function InterviewAttendeesTable({
  isLoading,
  interview,
  onChange,
}: InterviewAttendeesTableProps) {
  const [deleteAll, setDeleteAll] = useState(false);

  const handleAttendeeChange = useCallback(
    (value: string, name: string, index: number) => {
      const interviewAttendees = [...interview.interview_attendees_attributes];
      interviewAttendees[index] = {
        ...interviewAttendees[index],
        [name]: value,
      };

      onChange('interview_attendees_attributes', interviewAttendees);
    },
    [interview, onChange],
  );

  const handleRemoveAttendee = useCallback(
    (index: number) => {
      const interviewAttendees = [...interview.interview_attendees_attributes];
      const interviewAttendee = { ...interviewAttendees[index] };

      if (interviewAttendee.id) {
        interviewAttendee._destroy = true;
        interviewAttendees[index] = interviewAttendee;
      } else interviewAttendees.splice(index, 1);

      onChange(
        'interview_attendees_attributes',
        interviewAttendees.sort(sortObjByBoolean('_destroy')),
      );
    },
    [interview, onChange],
  );

  const numAttendeesRemovable = useMemo(
    () =>
      interview.interview_attendees_attributes.reduce(
        (acc, { locked, _destroy }) => {
          if (!locked && !_destroy) {
            acc += 1;
          }
          return acc;
        },
        0,
      ),
    [interview.interview_attendees_attributes],
  );

  const handleRemoveAll = useCallback(() => {
    const newAttendees = interview.interview_attendees_attributes.map(
      question => ({
        ...question,
        _destroy: !question.locked,
      }),
    );

    onChange('interview_attendees_attributes', newAttendees);

    setDeleteAll(false);
  }, [interview.interview_attendees_attributes, onChange]);

  return (
    <>
      <TableBase className="border-separate border-spacing-0 rounded-md border border-b-0">
        <TableBase.Thead className="w-full bg-slate-100">
          <TableBase.Tr>
            <TableBase.Th className="rounded-tl-md border-b  text-slate-500">
              Email Address
            </TableBase.Th>
            <TableBase.Th className="w-5 rounded-tr-md border-b text-slate-500">
              <div className="flex w-full justify-between">
                <Tippy
                  as={Button}
                  disabled={isLoading}
                  content={`Delete ${numAttendeesRemovable} Attendees`}
                  variant="text"
                  onClick={() => setDeleteAll(true)}
                >
                  <Lucide icon="Trash2" className="size-5 stroke-[1.3]" />
                </Tippy>
              </div>
            </TableBase.Th>
          </TableBase.Tr>
        </TableBase.Thead>
        <TableBase.Tbody>
          {interview.interview_attendees_attributes.map((attendee, index) => {
            if (attendee._destroy) return null;

            return (
              <InterviewAttendeeTableRow
                key={index}
                index={index}
                isLoading={isLoading}
                attendee={attendee}
                onAttendeeChange={handleAttendeeChange}
                onRemoveAttendee={handleRemoveAttendee}
              />
            );
          })}
        </TableBase.Tbody>
      </TableBase>
      <ConfirmDialog
        body="Do you really want to delete these attendees?"
        cancelAction={{
          onClick: () => setDeleteAll(false),
        }}
        confirmAction={{
          onClick: handleRemoveAll,
        }}
        open={deleteAll}
        onClose={() => setDeleteAll(false)}
      />
    </>
  );
}

export { InterviewAttendeesTable };
