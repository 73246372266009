import { Lucide } from '@kerplunkai/common-components';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { formatLocation } from '@utilities';
import { selectSelectedOrg } from '@store/selectors';

function SettingsHeader() {
  const selectedOrg = useSelector(selectSelectedOrg);

  const orgLocation = useMemo(() => {
    if (!selectedOrg) return '';

    return formatLocation({
      city: selectedOrg.city,
      country: selectedOrg.country,
      state: selectedOrg.state,
    });
  }, [selectedOrg]);

  return (
    <div className="box box--stacked flex flex-col p-1.5">
      <div className="relative h-36 w-full rounded-[0.6rem]">
        <div className="absolute inset-x-0 top-0 mx-auto mt-12 size-32">
          <div className="box image-fit size-full overflow-hidden rounded-full border-[6px] border-white">
            <img alt="Org Logo" src={selectedOrg?.logo_url || ''} />
          </div>
        </div>
      </div>
      <div className="rounded-[0.6rem] pb-6 pt-12">
        <div className="flex items-center justify-center text-xl font-medium">
          {selectedOrg?.name}
        </div>
        <div className="mt-2.5 flex flex-col items-center justify-center gap-x-5 gap-y-2 sm:flex-row">
          <div className="flex items-center text-slate-500">
            <Lucide icon="Briefcase" className="mr-1.5 size-3.5 stroke-[1.3]" />
            {selectedOrg?.industry.name}
          </div>
          {orgLocation && (
            <div className="flex items-center text-slate-500">
              <Lucide icon="MapPin" className="mr-1.5 size-3.5 stroke-[1.3]" />
              {orgLocation}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export { SettingsHeader };
