import { Breadcrumb } from '@kerplunkai/common-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { NAV_ROUTES } from '@constants';
import { selectOrgCategory, selectSelectedOrgId } from '@store/selectors';
import { useMemo } from 'react';

interface CategoryBreadcrumbProps {
  categoryId: string;
  key?: number;
}

function CategoryBreadcrumb({
  categoryId,
  key,
  ...props
}: CategoryBreadcrumbProps) {
  const orgId = useSelector(selectSelectedOrgId);
  const category = useSelector(selectOrgCategory(orgId as string, categoryId));

  const to = useMemo(() => {
    if (categoryId === 'new') return `${NAV_ROUTES.CATEGORIES}/${categoryId}`;

    return `${NAV_ROUTES.CATEGORIES}/${category?.id}`;
  }, [categoryId, category]);

  const linkText = useMemo(() => {
    if (categoryId === 'new') return 'Add a Category';

    return category?.name;
  }, [categoryId, category]);

  if (categoryId !== 'new' && !category) return null;

  return (
    <Breadcrumb.Link forwardedKey={key} {...props}>
      <Link to={to}>{linkText}</Link>
    </Breadcrumb.Link>
  );
}

export { CategoryBreadcrumb };
