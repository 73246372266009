import { PagedFilterSortRequest } from '@kerplunkai/common-components';
import { useSelector } from 'react-redux';

import { selectOrgClients } from '@store/selectors';

export const useOrgClients = (key: PagedFilterSortRequest) => {
  const clients = useSelector(selectOrgClients(key));

  return (clients || []).map(({ id, name }) => ({
    name,
    value: id,
  }));
};
