import spacetime from 'spacetime';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import { ColumnDef, getCoreRowModel } from '@tanstack/react-table';
import {
  DEFAULT_PAGE_INFO,
  FormSelect,
  Lucide,
  ModalDialog,
  TableFilterPredicatesEnum,
  TableFilters,
  TableRefetchFn,
  TanstackTable,
  TanstackTableActionColumn,
} from '@kerplunkai/common-components';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { NAV_ROUTES, NEW_ROUTES } from '@constants';
import { Organization } from '@typings';
import { selectSelectedOrgId } from '@store/selectors';
import {
  useDeleteOrgClientMutation,
  useLazyOrgClientsQuery,
} from '@store/services';
import { useIndustryOptions } from '@hooks';

const getColumns = (
  navigate: NavigateFunction,
  onDelete: Dispatch<SetStateAction<string[] | null>>,
): ColumnDef<Organization>[] => [
  {
    header: 'Client',
    accessorKey: 'name',
    cell: ({ getValue, row: { original } }) => (
      <div className="flex items-center">
        <div className="relative mr-5 flex size-10 items-center justify-center overflow-hidden rounded-full border border-primary/10 bg-primary/5">
          {original.logo_url ? (
            <img alt="Client Logo" src={original?.logo_url || ''} />
          ) : (
            <Lucide
              icon="User"
              className="size-[65%] fill-slate-300/70 stroke-slate-400/50 stroke-[0.5]"
            />
          )}
        </div>
        {getValue<string>()}
      </div>
    ),
  },
  {
    header: 'Industry',
    accessorKey: 'industry_name',
    accessorFn: row => row.industry.name,
  },
  {
    accessorKey: 'created_at',
    header: 'Date',
    cell: ({ getValue }) => {
      const dt = spacetime(getValue<Date>());

      return (
        <div className="flex flex-col gap-1">
          <p className="text-xs text-slate-400">Date</p>
          <p className="text-sm text-slate-600">
            {dt.format('{month} {date}, {year}')}
          </p>
        </div>
      );
    },
  },
  {
    accessorKey: 'actions',
    header: () => <div className="flex justify-center">Action</div>,
    maxSize: 50,
    cell: ({ row }) => (
      <TanstackTableActionColumn
        data={row}
        actions={[
          {
            icon: 'CheckSquare',
            text: 'Edit',
            onClick: () => navigate(`${NAV_ROUTES.CLIENTS}/${row.original.id}`),
          },
          {
            icon: 'Trash2',
            text: 'Delete',
            onClick: () => onDelete([row.original.id]),
          },
        ]}
      />
    ),
    enableSorting: false,
  },
];

function ClientsTable() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const industries = useIndustryOptions();

  const orgId = useSelector(selectSelectedOrgId);

  const [getClients, { data: clientsData, isFetching }] =
    useLazyOrgClientsQuery();
  const [deleteClient, { isLoading: isDeleting }] =
    useDeleteOrgClientMutation();

  const [itemsToDelete, setItemsToDelete] = useState<string[] | null>(null);

  const handleDeleteClients = useCallback(
    (clientsToDelete: string[]) => {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise<void>(async (resolve, reject) => {
        const results = await Promise.all(
          clientsToDelete?.map(clientToDelete =>
            deleteClient({
              organizationId: orgId as string,
              orgClientId: clientToDelete,
            }),
          ),
        );
        const validResults = results.filter(
          result => !(result instanceof Error),
        );

        if (validResults.length === clientsToDelete.length) {
          enqueueSnackbar({
            message: 'Deleting client(s) was successful',
            variant: 'success',
          });
          resolve();
        } else {
          enqueueSnackbar({
            message:
              'Unable to delete some or all client(s). Please try again.',
            variant: 'error',
          });
          reject();
        }

        setItemsToDelete(null);
      });
    },
    [enqueueSnackbar, orgId, deleteClient],
  );

  const handleRefetch: TableRefetchFn = useCallback(
    (pagination, { filters, searchTerm, sorting }) => {
      const hasSearch = searchTerm && searchTerm.length > 0;
      let filterQueryParms = '';

      if (searchTerm && searchTerm.length > 0)
        filterQueryParms = `q[name_${TableFilterPredicatesEnum.cont}]=${searchTerm}`;
      if (sorting) filterQueryParms += `${hasSearch ? '&' : ''}q[s]=${sorting}`;

      Object.keys(filters || {}).forEach(filter => {
        filterQueryParms += `${filterQueryParms !== '' ? '&' : ''}q[${filter}_${TableFilterPredicatesEnum.eq}]=${(filters || {})[filter]}`;
      });

      getClients({
        orgId: orgId as string,
        pagination,
        filters: filterQueryParms,
      });
    },
    [getClients, orgId],
  );

  return (
    <>
      <TanstackTable
        actionsHeaderItems={{
          addItem: {
            text: 'Add a Client',
            onClick: () => navigate(NEW_ROUTES.NEW_CLIENT),
          },
        }}
        emptyText="No Client(s) Found"
        initialSorting={{
          initialField: 'name',
          initialOrder: 'ASC',
        }}
        isFetching={isFetching}
        pageInfo={clientsData ? clientsData.meta : DEFAULT_PAGE_INFO}
        searchPlaceholder="Search Clients..."
        selection
        selectionModalProps={{
          title: 'Delete Client(s)',
          description: 'Do you really want to delete these client(s)?',
          confirmText: 'Delete',
          cancelText: 'Cancel',
        }}
        showSearch
        tableOptions={{
          data: clientsData ? clientsData.results : [],
          columns: getColumns(navigate, setItemsToDelete),
          getCoreRowModel: getCoreRowModel(),
          getRowId: row => row.id,
        }}
        onDelete={handleDeleteClients}
        onRefetch={handleRefetch}
        renderFilters={(
          tempFilters: TableFilters,
          setTempFilters: Dispatch<SetStateAction<TableFilters>>,
        ) => (
          <div>
            <div className="text-left text-slate-500">Industry</div>
            <FormSelect
              className="mt-2 flex-1"
              value={tempFilters.industry_id as string}
              onChange={({
                target: { value },
              }: ChangeEvent<HTMLSelectElement>) =>
                setTempFilters({ ...tempFilters, industry_id: value })
              }
            >
              <option value="">Industry</option>
              {industries.map(industry => (
                <option key={industry.value} value={industry.value}>
                  {industry.name}
                </option>
              ))}
            </FormSelect>
          </div>
        )}
      />
      <ModalDialog
        open={Boolean(itemsToDelete)}
        title="Delete Client(s)"
        description="Do you really want to delete these client(s)?"
        confirmText="Delete"
        cancelText="Cancel"
        isLoading={isDeleting}
        onConfirm={() => handleDeleteClients(itemsToDelete as string[])}
        onCancel={() => setItemsToDelete(null)}
      />
    </>
  );
}

export { ClientsTable };
