/* eslint-disable @typescript-eslint/naming-convention */
import clsx from 'clsx';
import {
  SetPasswordForm,
  SetPassword as SetPasswordType,
} from '@kerplunkai/common-components';
import { useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { NAV_ROUTES } from '@constants';
import { resetUser, setInitialRoute } from '@store/slices';
import { useAppDispatch } from '@store/hooks';
import { useSetPasswordMutation, useUserQuery } from '@store/services';

import logo from '@assets/images/logos/Kerplunk-Logo-blk.svg';

function ResetPassword() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [setPassword, { isLoading }] = useSetPasswordMutation();
  const { data: currentUser, isLoading: isGettingUser } = useUserQuery();

  useEffect(() => {
    dispatch(setInitialRoute(null));
  }, [dispatch]);

  useEffect(() => {
    const reset_password_token = searchParams.get('token');

    if (!reset_password_token) navigate(NAV_ROUTES.LOGIN);
  }, [navigate, currentUser, searchParams]);

  const handleSetPassword = useCallback(
    (setPasswordData: SetPasswordType) => {
      const reset_password_token = searchParams.get('token');

      if (!reset_password_token) return;

      setPassword({
        ...setPasswordData,
        reset_password_token,
      }).unwrap();
    },
    [setPassword, searchParams],
  );

  const handleSuccess = useCallback(async () => {
    if (currentUser) dispatch(resetUser());

    navigate(NAV_ROUTES.LOGIN);
  }, [dispatch, navigate, currentUser]);

  return (
    <div className="fixed inset-0 h-screen w-screen">
      <div
        className={clsx([
          'h-full',
          "before:absolute before:inset-y-0 before:left-0 before:w-screen before:bg-gradient-to-b before:from-theme-1 before:to-theme-2 before:content-[''] before:lg:-ml-10 before:lg:w-[800%]",
          "after:absolute after:inset-y-0 after:left-0 after:w-screen after:bg-texture-white after:bg-fixed after:bg-center after:bg-no-repeat after:content-[''] after:lg:w-[800%] after:lg:bg-[25rem_-25rem]",
        ])}
      >
        <div className="relative z-10 flex h-screen items-center justify-center">
          <SetPasswordForm
            isLoading={isLoading || isGettingUser}
            logo={logo}
            snackbar={{
              success: {
                children: (
                  <div className="flex flex-col gap-1">
                    <p className="text-sm text-[#364154]">
                      Password Reset Successful
                    </p>
                    <p className="text-sm text-[#677489]">
                      You have successfully reset your password.
                    </p>
                  </div>
                ),
              },
              error: {
                message: 'Error resetting password. Please try again.',
              },
            }}
            submitText="Reset Password"
            title="Reset your password?"
            onSubmit={handleSetPassword}
            onSuccess={handleSuccess}
          />
        </div>
      </div>
    </div>
  );
}

export { ResetPassword };
