import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

import { Button, Lucide } from '@kerplunkai/common-components';
import { QUICK_LINKS } from '@constants';

function QuickLinks() {
  const navigate = useNavigate();

  return (
    <div className="col-span-12 flex flex-col gap-y-10 2xl:col-span-9">
      <div>
        <div className="flex flex-col gap-y-3 md:h-10 md:flex-row md:items-center">
          <div className="text-base font-medium">Quick Links</div>
        </div>
        <div className="box mt-3.5">
          <div className="grid grid-cols-1 gap-y-5  border-b p-5 md:grid-cols-3 lg:h-48 lg:grid-cols-5 lg:px-5 lg:py-2">
            {QUICK_LINKS.map(
              ({
                borderClassName,
                icon,
                iconClassName,
                route,
                text,
                btnClassName,
              }) => (
                <Button
                  key={text}
                  className={clsx(
                    'flex items-start justify-start rounded-lg border  border-transparent transition duration-300 ease-in-out hover:border-slate-200 hover:bg-slate-50 md:flex-col md:items-center md:justify-center',
                    text === 'Post a Job' && 'flex flex-col items-center',
                    btnClassName,
                  )}
                  variant="text"
                  onClick={() => navigate(route)}
                >
                  <div
                    className={clsx(
                      'flex items-center justify-center',
                      text === 'Post a Job'
                        ? 'size-[75px]'
                        : 'size-[46px] md:size-12',
                      borderClassName,
                    )}
                  >
                    <Lucide
                      icon={icon}
                      className={clsx(
                        text === 'Post a Job' ? 'size-10' : 'size-6',
                        iconClassName,
                      )}
                    />
                  </div>
                  <div className="ml-3 mt-3 text-slate-400 md:ml-0">{text}</div>
                </Button>
              ),
            )}
          </div>
          <div className="flex flex-col items-center gap-5 rounded-b-lg bg-slate-50 px-6 pb-6 pt-5 md:flex-row">
            <div className="flex items-start text-left md:w-auto">
              <div className="mr-5 size-[46px] rounded-full border border-cyan-600 border-opacity-10  bg-cyan-600 bg-opacity-10 p-2.5">
                <Lucide icon="Box" className="size-6 stroke-cyan-600" />
              </div>
              <div className="flex flex-col items-start">
                <div className="text-lg font-medium md:mt-1">
                  Just getting started?
                </div>
                <div className="mt-1 text-sm font-normal text-slate-400">
                  Check out our full library of tips and tricks on how to make
                  the most out of Kerplunk.
                </div>
              </div>
            </div>
            <div className="flex w-full items-center justify-center gap-3 sm:w-auto sm:flex-row md:ml-auto">
              <Button
                as="a"
                rounded
                className="h-[38px] w-[200px] border border-slate-300 p-3 text-sm text-slate-400 md:h-9 md:w-28 "
              >
                Not Now
              </Button>
              <Button
                as="a"
                href="https://www.youtube.com/channel/UCkUlgYJrMbOVBh3SIPn60jA"
                target="_blank"
                rel="noopener noreferrer"
                rounded
                className="h-[38px] w-[200px]  border border-sky-800 border-opacity-60 px-3 py-2 text-sm text-sky-800 md:h-9 md:w-28 "
              >
                Learn More
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { QuickLinks };
