import { useSelector } from 'react-redux';

import {
  Button,
  FormInput,
  Lucide,
  TableBase,
} from '@kerplunkai/common-components';
import { JobQuestion } from '@typings';
import { selectJobQCategoryTypes } from '@store/selectors';

interface StaticJobInterviewQuestionProps {
  orgId: string;
  question: JobQuestion;
}

function StaticJobInterviewQuestion({
  orgId,
  question,
}: StaticJobInterviewQuestionProps) {
  const categoryTypes = useSelector(selectJobQCategoryTypes(orgId as string));

  return (
    <TableBase.Tr
      style={{
        boxShadow:
          'rgb(0 0 0 / 10%) 0px 20px 25px -5px, rgb(0 0 0 / 30%) 0px 10px 10px -5px',
      }}
    >
      <TableBase.Td className="whitespace-nowrap bg-white text-center" />
      <TableBase.Td className="min-w-40 whitespace-nowrap bg-white">
        {(categoryTypes || {})[question.category]}
      </TableBase.Td>
      <TableBase.Td className="w-full min-w-72 bg-white">
        <FormInput
          className={
            question.locked
              ? 'border-success/50 text-success disabled:bg-success/10'
              : ''
          }
          name="content"
          placeholder="Enter your question here"
          readOnly
          value={question.content}
          type="text"
        />
      </TableBase.Td>
      <TableBase.Td className="whitespace-nowrap bg-white !pl-4 text-slate-500">
        <Button variant="text">
          <Lucide
            icon={question.locked ? 'Lock' : 'Unlock'}
            className="h-5 w-5"
            fill={question.locked ? 'currentColor' : 'none'}
          />
        </Button>
        <Button className="ml-4" variant="text">
          <Lucide icon="Move" className="h-5 w-5" />
        </Button>
        <Button className="ml-4" variant="text">
          <Lucide icon="Trash2" className="h-5 w-5" />
        </Button>
      </TableBase.Td>
    </TableBase.Tr>
  );
}

export { StaticJobInterviewQuestion };
