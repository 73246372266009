import { Loading } from '@kerplunkai/common-components';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { InterviewsTable } from '@modules/interviews';
import { selectOrgJob, selectSelectedOrgId } from '@store/selectors';
import { useLazyGetOrgJobQuery } from '@store/services';

function JobApplications() {
  const { jobId } = useParams<{ jobId: string }>();

  const orgId = useSelector(selectSelectedOrgId);
  const job = useSelector(selectOrgJob(orgId as string, jobId as string));

  const [getOrgJob, { isFetching }] = useLazyGetOrgJobQuery();

  useEffect(() => {
    (async () => {
      if (orgId && jobId) await getOrgJob({ organizationId: orgId, jobId });
    })();
  }, [orgId, jobId, getOrgJob]);

  if (isFetching || job === undefined) return <Loading />;

  return (
    <>
      <div className="flex items-center justify-between pb-[7px] pr-[70px] text-2xl font-medium leading-10 group-[.mode--light]:text-white">
        <div className="flex flex-col">
          {job.title}
          <p className="text-base">{job.organization}</p>
        </div>
        {job?.created_by.display_name && (
          <div className="flex flex-col">
            <span className="mb-1 text-sm text-slate-400">Job Created by</span>
            <span className="text-sm text-white">
              {job?.created_by.display_name}
            </span>
          </div>
        )}
      </div>
      <div className="box box--stacked flex flex-col">
        <InterviewsTable allowUpload allowCopilot />
      </div>
    </>
  );
}

export { JobApplications };
