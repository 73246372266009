import { getScoringCategory, getScoringStyles } from '@utilities';
import { useMemo } from 'react';

interface InterviewScoringChipProps {
  score: number;
  className?: string;
}

function InterviewScoringChip({ score, className }: InterviewScoringChipProps) {
  const styles = useMemo(() => {
    const category = getScoringCategory(score);
    return getScoringStyles(category);
  }, [score]);

  return (
    <div
      className={`ml-2 w-fit content-center rounded-md border px-2 text-xs font-normal ${styles.background} ${styles.border} ${styles.text} ${className || ''}`}
    >
      {`AI Score: ${score}%`}
    </div>
  );
}

export { InterviewScoringChip };
