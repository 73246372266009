import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import {
  FormActions,
  FormCard,
  FormInputControl,
} from '@kerplunkai/common-components';
import { JobCategory } from '@typings';
import { NAV_ROUTES } from '@constants';
import { selectSelectedOrgId } from '@store/selectors';
import {
  useCreateJobCategoryMutation,
  useUpdateJobCategoryMutation,
} from '@store/services';

function CategoryForm({ categoryData }: { categoryData?: JobCategory }) {
  const navigate = useNavigate();
  const { categoryId } = useParams<{ categoryId: string }>();
  const { enqueueSnackbar } = useSnackbar();

  const [
    createJobCategory,
    { isLoading: isCreating, error: createError, isSuccess: isCreateSuccess },
  ] = useCreateJobCategoryMutation();
  const [
    updateJobCategory,
    { isLoading: isUpdating, error: updateError, isSuccess: isUpdateSuccess },
  ] = useUpdateJobCategoryMutation();

  const orgId = useSelector(selectSelectedOrgId);

  const [category, setCategory] = useState(categoryData || { name: '' });

  useEffect(() => {
    if (isCreateSuccess || isUpdateSuccess) navigate(NAV_ROUTES.CATEGORIES);
  }, [navigate, isCreateSuccess, isUpdateSuccess]);

  useEffect(() => {
    if (createError || updateError) {
      enqueueSnackbar({
        message: 'Unable to create category. Please try again.',
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, createError, updateError]);

  const isLoading = useMemo(
    () => isCreating || isUpdating,
    [isCreating, isUpdating],
  );

  const isNew = useMemo(() => categoryId === 'new', [categoryId]);

  const handleCreateJobCategory = useCallback(() => {
    createJobCategory({
      ...category,
      organizationId: orgId as string,
    });
  }, [category, orgId, createJobCategory]);

  const handleUpdateJobCategory = useCallback(() => {
    updateJobCategory({
      ...category,
      organizationId: orgId as string,
    });
  }, [category, orgId, updateJobCategory]);

  const handleSubmitJob = useCallback(() => {
    if (!isNew) {
      handleUpdateJobCategory();
      return;
    }

    handleCreateJobCategory();
  }, [isNew, handleCreateJobCategory, handleUpdateJobCategory]);

  return (
    <div className="relative col-span-12 flex flex-col gap-y-7 lg:col-span-9 xl:col-span-8">
      <FormCard hideAccordion title="Category Information">
        <div className="mt-5">
          <FormInputControl
            className="mt-5"
            description="Enter a name for this category."
            helpText={`Maximum characters ${category.name.length}/70`}
            inputProps={{
              disabled: isLoading,
              onChange: ({ target: { name, value } }) => {
                if (value.length <= 70) {
                  setCategory(curCategory => ({
                    ...curCategory,
                    [name]: value,
                  }));
                }
              },
            }}
            isRequired
            label="Category Name"
            name="name"
            value={category.name}
          />
        </div>
      </FormCard>
      <FormActions
        cancel={{
          disabled: isLoading,
          icon: 'X',
          text: 'Cancel',
          onClick: () => navigate(NAV_ROUTES.CATEGORIES),
        }}
        submit={{
          disabled: isLoading,
          icon: 'Send',
          text: isLoading ? 'Saving' : 'Save',
          onClick: handleSubmitJob,
        }}
      />
    </div>
  );
}

export { CategoryForm };
