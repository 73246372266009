import { Breadcrumb } from '@kerplunkai/common-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { NAV_ROUTES } from '@constants';
import { selectOrgSubCategory, selectSelectedOrgId } from '@store/selectors';
import { useMemo } from 'react';

interface SubcategoryBreadcrumbProps {
  subcategoryId: string;
  key?: number;
}

function SubcategoryBreadcrumb({
  subcategoryId,
  key,
  ...props
}: SubcategoryBreadcrumbProps) {
  const orgId = useSelector(selectSelectedOrgId);
  const subcategory = useSelector(
    selectOrgSubCategory(orgId as string, subcategoryId),
  );

  const to = useMemo(() => {
    if (subcategoryId === 'new')
      return `${NAV_ROUTES.CATEGORIES}/${subcategoryId}`;

    return `${NAV_ROUTES.CATEGORIES}/${subcategory?.id}`;
  }, [subcategoryId, subcategory]);

  const linkText = useMemo(() => {
    if (subcategoryId === 'new') return 'Add a Subcategory';

    return subcategory?.name;
  }, [subcategoryId, subcategory]);

  if (subcategoryId !== 'new' && !subcategory) return null;

  return (
    <Breadcrumb.Link forwardedKey={key} {...props}>
      <Link to={to}>{linkText}</Link>
    </Breadcrumb.Link>
  );
}

export { SubcategoryBreadcrumb };
