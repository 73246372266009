import { useSelector } from 'react-redux';

import { InterviewRequest } from '@typings';
import { JobSummary } from '@modules/jobs/jobPreview/jobSummary.component';
import { MeetingGuests } from '@modules/meetings/meetingPreview/meetingGuests.component';
import { MeetingQuestions } from '@modules/meetings/meetingPreview/meetingQuestions.component';
import { mapJobToJobForm } from '@utilities/jobs';
import { selectSelectedOrgId } from '@store/selectors';

interface MeetingPreviewProps {
  interview: InterviewRequest;
}

function MeetingPreview({ interview }: MeetingPreviewProps) {
  const orgId = useSelector(selectSelectedOrgId);

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-12 flex flex-col gap-y-7 lg:col-span-8">
        <MeetingGuests interview={interview} />
        <MeetingQuestions interview={interview} />
      </div>
      <div className="col-span-12 mt-10 lg:col-span-4 lg:mt-0 lg:pl-4">
        {interview.job_listing && (
          <JobSummary
            job={mapJobToJobForm(interview.job_listing)}
            orgId={orgId as string}
          />
        )}
      </div>
    </div>
  );
}

export { MeetingPreview };
