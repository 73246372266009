import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { selectCurrentUser } from '@store/selectors';
import { useEffect, useMemo } from 'react';
import { useIntercom } from 'react-use-intercom';
import { useSelector } from 'react-redux';

import { getRoutes } from '@router/router';

// use different routes depending on auth state
export function RouterContainer() {
  const currentUser = useSelector(selectCurrentUser);

  const { boot: bootIntercom, shutdown: shutdownIntercom } = useIntercom();

  useEffect(() => {
    if (currentUser) {
      bootIntercom({
        name: `${currentUser?.first_name} ${currentUser?.last_name}`,
        userId: currentUser?.id,
        email: currentUser?.email,
      });
      return;
    }
    shutdownIntercom();
  }, [bootIntercom, currentUser, shutdownIntercom]);

  const router = useMemo(() => {
    return createBrowserRouter(getRoutes(!!currentUser));
  }, [currentUser]);

  return <RouterProvider router={router} />;
}
