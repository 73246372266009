import { v4 as uuidv4 } from 'uuid';

import { CodeMirrorBlock } from '@modules/conference/interviewQuestions/components';
import { JobQuestion } from '@typings';

interface CodingQuestionTemplateProps {
  question: JobQuestion;
}

function CodingQuestionTemplate({ question }: CodingQuestionTemplateProps) {
  return (
    <div className="grow">
      <div className="mb-12 ml-2 pt-1 text-center text-base font-semibold text-white sm:text-xl lg:text-start">
        {question.content}
      </div>

      {question.code_blocks?.map(block => {
        const key = uuidv4();
        return (
          <div key={key} className="mb-4 rounded-md">
            <CodeMirrorBlock block={block} />
          </div>
        );
      })}
    </div>
  );
}

export { CodingQuestionTemplate };
