import { Lucide } from '@kerplunkai/common-components';

function CreateWelcomeCard() {
  return (
    <div className="sticky top-[104px]">
      <div className="box flex flex-col gap-4 p-5">
        <div className="flex items-center gap-2">
          <Lucide className="mr-2 h-5 w-5 text-yellow-600" icon="PartyPopper" />
          <h5 className="text-base font-semibold text-sky-800">
            Welcome aboard!
          </h5>
        </div>
        <h6 className="text-sm text-slate-600">Let&apos;s get started</h6>
        <p className="text-xs text-slate-500 opacity-80">
          Welcome to our platform! We&apos;re thrilled to have you join us. Your
          presence here is an exciting opportunity for us to learn more about
          your company and how we can support your goals.
        </p>
        <p className="text-xs text-slate-500 opacity-80">
          We encourage you to take a moment to fill out the form, sharing
          valuable insights about your business that will help us tailor our
          services to your specific needs. Thank you for choosing us as your
          partner on this journey.
        </p>
      </div>
    </div>
  );
}

export { CreateWelcomeCard };
