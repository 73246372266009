import { Button, Lucide } from '@kerplunkai/common-components';
import { isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';

import {
  CreateIntegrationRequest,
  Integration,
  OrgIntegration,
} from '@typings';
import { mapIntegrationToForm } from '@utilities';

export interface IntegrationFormWrapperProps {
  integration?: OrgIntegration;
  integrationType: Integration;
  isLoading: boolean;
  onClose: () => void;
  onDelete?: (integrationId: string) => Promise<void>;
  onSubmit: (values: CreateIntegrationRequest) => Promise<void>;
}

function IntegrationFormWrapper({
  integration,
  integrationType,
  isLoading,
  onDelete,
  onClose,
  onSubmit,
}: IntegrationFormWrapperProps) {
  const { enqueueSnackbar } = useSnackbar();

  const [isEdit, setIsEdit] = useState(false);

  const {
    errors,
    values,
    handleChange,
    validateForm,
    setFieldValue,
    setValues,
  } = useFormik<CreateIntegrationRequest>({
    initialValues: mapIntegrationToForm(integrationType, integration),
    validationSchema: integrationType.validationSchema,
    onSubmit: () => {},
  });

  const handleSubmit = useCallback(async () => {
    const validation = await validateForm();

    if (!isEmpty(validation)) return;

    try {
      await onSubmit(values);

      if (isEdit) setIsEdit(false);
      enqueueSnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        children: (
          <div className="flex flex-col gap-1">
            <p className="text-sm text-[#364154]">
              {integrationType?.name} Integration{' '}
              {values.id ? 'Updated' : 'Created'}
            </p>
            <p className="text-sm text-[#677489]">
              The integration has been successfully{' '}
              {values.id ? 'updated' : 'created'}.
            </p>
          </div>
        ),
        variant: 'toast-notification',
      });
    } catch (e) {
      enqueueSnackbar({
        message: `Unable to ${values.id ? 'updated' : 'created'} integration. Please try again.`,
        variant: 'error',
      });
    }
  }, [
    enqueueSnackbar,
    validateForm,
    integrationType,
    isEdit,
    values,
    onSubmit,
  ]);

  const handleDelete = useCallback(async () => {
    if (!onDelete || !integration) return;

    try {
      await onDelete(integration.id);

      enqueueSnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        children: (
          <div className="flex flex-col gap-1">
            <p className="text-sm text-[#364154]">
              {integration?.name} Integration Deleted
            </p>
            <p className="text-sm text-[#677489]">
              The integration has been successfully deleted.
            </p>
          </div>
        ),
        variant: 'toast-notification',
      });
    } catch (e) {
      enqueueSnackbar({
        message: 'Unable to create integration. Please try again.',
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, integration, onDelete]);

  const handleToggleEdit = useCallback(() => {
    if (!isEdit) {
      setIsEdit(true);
      return;
    }

    setValues(mapIntegrationToForm(integrationType, integration), true);
    setIsEdit(false);
  }, [integration, integrationType, isEdit, setValues]);

  const IntegrationForm = integrationType?.integrationForm ?? null;

  return (
    <div className="flex flex-col gap-6 rounded-lg border border-slate-200 p-5">
      {IntegrationForm && integrationType && (
        <IntegrationForm
          errors={errors}
          integration={integration}
          integrationType={integrationType}
          isEdit={isEdit}
          isLoading={isLoading}
          isNew={!integration}
          values={values}
          onEdit={handleToggleEdit}
          onChange={handleChange}
          onDelete={handleDelete}
          onSetFieldValue={setFieldValue}
        />
      )}
      {(!integration || isEdit) && (
        <div className="flex justify-end gap-4">
          <Button
            className="border-slate-200/100 px-3 py-2 text-slate-500"
            disabled={isLoading}
            variant="soft-sky-outline"
            onClick={isEdit ? handleToggleEdit : onClose}
          >
            <div className="flex gap-1.5 font-normal">
              <Lucide icon="X" />
              Cancel
            </div>
          </Button>
          <Button
            className="px-3 py-2"
            disabled={isLoading}
            variant="soft-sky"
            onClick={handleSubmit}
          >
            <div className="flex gap-1.5 font-normal">
              <Lucide icon={isEdit ? 'Save' : 'Plus'} />
              {isEdit ? 'Save' : 'Add'} Integration
            </div>
          </Button>
        </div>
      )}
    </div>
  );
}

export { IntegrationFormWrapper };
