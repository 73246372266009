import {
  Button,
  FormSelect,
  LoadingIcon,
  Lucide,
} from '@kerplunkai/common-components';
import { useSelector } from 'react-redux';

import { getColor } from '@utilities';
import {
  selectActiveCameraDevice,
  selectActiveMicDevice,
} from '@store/selectors';

interface MediaPermissionPreviewStepProps {
  hasVideoPermission?: boolean;
  hasAudioPermission?: boolean;
  handleSelectCam: (deviceId: string) => void;
  handleSelectMic: (deviceId: string) => void;
  isDisableCamSwitcher?: boolean;
  isDisableMicSwitcher?: boolean;
  availableCameraDevices?: MediaDeviceInfo[];
  availableMicDevices?: MediaDeviceInfo[];
  handleStartInterview: () => void;
  isLoading?: boolean;
  startButtonText?: string;
}

export function MediaPermissionPreviewStep({
  hasVideoPermission,
  hasAudioPermission,
  handleSelectCam,
  handleSelectMic,
  isDisableCamSwitcher,
  isDisableMicSwitcher,
  availableCameraDevices,
  availableMicDevices,
  handleStartInterview,
  isLoading,
  startButtonText = 'Start Interview',
}: MediaPermissionPreviewStepProps) {
  const reduxMicDeviceId = useSelector(selectActiveMicDevice);
  const reduxCameraDeviceId = useSelector(selectActiveCameraDevice);

  return (
    <div className="flex justify-center">
      <div className="box box--stacked grow">
        <div className="mt-4 w-full rounded-md p-2">
          <div className="text px-4 text-center text-base">
            <div className="mb-4 flex grow">
              {isDisableCamSwitcher && (
                <div className="mr-2.5 size-9">
                  <LoadingIcon
                    icon="oval"
                    className="mr-2 size-6 self-center"
                    color={getColor('primary')}
                  />
                </div>
              )}

              {!isDisableCamSwitcher && (
                <div className="mr-2.5 size-9 rounded-md border border-lime-400 bg-lime-400/10 p-2.5">
                  <Lucide
                    icon="Check"
                    className="size-4 self-center text-[#518400]"
                  />
                </div>
              )}

              <FormSelect
                className="focus:none rounded-full border-none bg-slate-400/20 font-semibold text-slate-500 focus:ring-0"
                value={reduxCameraDeviceId || undefined}
                onChange={e => handleSelectCam(e.target.value)}
                disabled={isDisableCamSwitcher}
              >
                {availableCameraDevices?.map(
                  ({ deviceId: id, label: name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ),
                )}
              </FormSelect>
            </div>
            <div className="flex grow">
              {isDisableMicSwitcher && (
                <div className="mr-2.5 size-9">
                  <LoadingIcon
                    icon="oval"
                    className="mr-2 size-6 self-center"
                    color={getColor('primary')}
                  />
                </div>
              )}

              {!isDisableMicSwitcher && (
                <div className="mr-2.5 size-9 rounded-md border border-lime-400 bg-lime-400/10 p-2.5">
                  <Lucide
                    icon="Check"
                    className="size-4 self-center text-[#518400]"
                  />
                </div>
              )}
              <FormSelect
                className="focus:none rounded-full border-none bg-slate-400/20 font-semibold text-slate-500 focus:ring-0"
                value={reduxMicDeviceId || undefined}
                onChange={e => handleSelectMic(e.target.value)}
                disabled={isDisableMicSwitcher}
              >
                {availableMicDevices?.map(({ deviceId: id, label: name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </FormSelect>
            </div>

            <div className="w-full pt-5">
              <Button
                onClick={() => {
                  handleStartInterview?.();
                }}
                className="w-full"
                variant="medprosPrimary"
                rounded
                disabled={
                  !hasAudioPermission ||
                  !hasVideoPermission ||
                  !reduxMicDeviceId ||
                  !reduxCameraDeviceId ||
                  isLoading
                }
              >
                <Lucide icon="LogIn" className="mr-1" /> {startButtonText}
              </Button>
            </div>

            {/* <div className="w-full pt-6">
              <Button
                onClick={() => {
                  navigate(NAV_ROUTES.DASHBOARD);
                }}
                className="w-full"
                variant="outline-secondary"
                rounded
              >
                <Lucide icon="Undo2" className="mr-1 text-slate-500" /> Return
                to Job Board
              </Button>
            </div>
            <div className="w-full pt-3">
              <Button
                onClick={handleToggleSaveJobFavorite}
                className="w-full"
                variant="outline-secondary"
                rounded
              >
                <Lucide
                  className={clsx([
                    'mr-2 size-6 self-center stroke-slate-500 stroke-[1.25]',
                    isJobSaved && 'text-success',
                  ])}
                  icon={isJobSaved ? 'Check' : 'Bookmark'}
                />
                {isJobSaved ? 'Saved Job' : 'Save for Later'}
              </Button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
