import clsx from 'clsx';
import { Disclosure, Loading, Lucide } from '@kerplunkai/common-components';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import {
  CandidateContactPanel,
  CandidateInfoPanel,
  IconInfoBox,
  InterviewAnalysisPanel,
  InterviewSummaryPanel,
  InterviewTranscriptPanel,
  InterviewVideoPlayer,
  JobPostingDetailsPanel,
} from '@modules/interviewDetails/components';
import { usePublicInterviewQuery } from '@store/services';

import defaultAvatar from '@assets/images/no-pic@2x.png';

function InterviewViewerDetails() {
  const { interviewId, shareToken } = useParams<{
    interviewId?: string;
    shareToken?: string;
  }>();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const hideRightColumn = searchParams.get('hideRightColumn') === 'true';

  const { enqueueSnackbar } = useSnackbar();

  const isSharedView = true; // This disable showing the candidates contact info

  const {
    data: interviewData,
    isLoading: isLoadingInterview,
    isError: isInterviewError,
  } = usePublicInterviewQuery({
    interviewId: interviewId as string,
    shareToken: shareToken as string,
  });

  useEffect(() => {
    if (isInterviewError) {
      enqueueSnackbar({
        message: isSharedView
          ? 'Your link is expired or invalid. Please try again with a new link.'
          : 'Interview details not available at this time. Please try again later.',
        variant: 'info',
      });
    }
  }, [isSharedView, isInterviewError, enqueueSnackbar]);

  const resumeDetails = interviewData?.resume?.analyses[0]?.details;

  if (isLoadingInterview) return <Loading />;

  return (
    <div
      className={clsx([
        'echo background group relative min-h-screen bg-gradient-to-b from-slate-200/70 to-slate-50',
        "before:fixed before:top-0 before:h-[370px] before:w-screen before:bg-gradient-to-t before:from-theme-1/80 before:to-theme-2 before:transition-[opacity,height] before:duration-300 before:ease-in-out before:content-[''] [&.background--hidden]:before:opacity-0",
        "after:fixed after:top-0 after:h-[370px] after:w-screen after:bg-texture-white after:bg-contain after:bg-fixed after:bg-[center_-13rem] after:bg-no-repeat after:transition-[opacity,height] after:duration-300 after:ease-in-out after:content-[''] [&.background--hidden]:after:opacity-0",
      ])}
    >
      <div
        className={clsx([
          'mode mode--light group relative z-10 pb-16 pt-[54px] transition-[margin,width] duration-100',
        ])}
      >
        <div className="mt-16 px-5">
          <div className="container">
            <div className="p-6">
              <div className="grid grid-cols-12 gap-x-6 gap-y-10">
                <div className="col-span-12">
                  <div className="flex">
                    <img
                      alt="avatar"
                      className="mr-4 !h-9 !w-9 self-center rounded-full"
                      src={
                        interviewData?.candidate_avatar_url.includes('gravatar')
                          ? defaultAvatar
                          : interviewData?.candidate_avatar_url
                      }
                    />
                    <div className="flex grow flex-col">
                      <div className="text-3xl font-medium group-[.mode--light]:text-white">
                        {`${interviewData?.candidate_name}`}
                      </div>
                      <div className="text-base group-[.mode--light]:text-white">
                        {`Interviewing for ${interviewData?.job_title}`}
                      </div>
                    </div>
                  </div>

                  <div className="mt-3 grid grid-cols-12 gap-x-3">
                    {/* LEFT COLUMN */}
                    <div
                      className={hideRightColumn ? 'col-span-12' : 'col-span-8'}
                    >
                      <div className="box box--stacked mb-6 flex flex-col ">
                        <div className="rounded-[0.6rem] border-dashed border-slate-200/60 p-5 dark:border-darkmode-400">
                          {resumeDetails && (
                            <Disclosure.Group variant="boxed">
                              <Disclosure className="rounded-[0.6rem]">
                                <Disclosure.Button>
                                  <div className="flex items-center border-b border-dashed border-slate-200/60 pb-5 text-[0.94rem] font-medium text-[#181D2C] ">
                                    <Lucide
                                      icon="ChevronDown"
                                      className="mr-2 size-5 stroke-[1.3]"
                                    />
                                    <div className="flex grow">
                                      {`About ${interviewData?.candidate_name}`}
                                    </div>
                                  </div>
                                </Disclosure.Button>

                                <Disclosure.Panel>
                                  <div className="mx-4 flex justify-between">
                                    <IconInfoBox
                                      containerClasses="my-3"
                                      icon="MapPinned"
                                      primaryText="Location"
                                      secondaryText={
                                        resumeDetails.current_location || '--'
                                      }
                                    />
                                    <IconInfoBox
                                      containerClasses="my-3"
                                      icon="User"
                                      primaryText="Current Title"
                                      secondaryText={
                                        resumeDetails.current_title || '--'
                                      }
                                    />
                                    <IconInfoBox
                                      containerClasses="my-3"
                                      icon="User"
                                      primaryText="Experience Level"
                                      secondaryText={
                                        resumeDetails.experience_level || '--'
                                      }
                                    />
                                  </div>
                                </Disclosure.Panel>
                              </Disclosure>
                            </Disclosure.Group>
                          )}

                          <div className="mt-3.5">
                            <div className="mb-3.5 text-base font-medium text-[#181D2C]">
                              Overview ✨
                            </div>
                            <InterviewAnalysisPanel interview={interviewData} />
                          </div>

                          <div className="mt-2">
                            <InterviewSummaryPanel
                              shortSummaryText={
                                interviewData?.detailed_commentary?.overview ||
                                'No summary available. Please check again later.'
                              }
                              longSummaryText=""
                              commentary={interviewData?.detailed_commentary}
                            />
                          </div>
                        </div>
                      </div>

                      {interviewData?.interview_question_analyses && (
                        <div className="mt-2">
                          <InterviewTranscriptPanel
                            analyses={interviewData.interview_question_analyses}
                            transcriptDownloadUrl={
                              interviewData.full_interview_transcript_url
                            }
                          />
                        </div>
                      )}
                    </div>

                    {/* RIGHT COLUMN */}
                    {!hideRightColumn && (
                      <div className="col-span-4">
                        <div>
                          {interviewData && (
                            <div className="relative w-full">
                              {interviewData.full_interview_video_url && (
                                <InterviewVideoPlayer
                                  interviewAnalyses={
                                    interviewData.interview_question_analyses
                                  }
                                  videoUrl={
                                    interviewData.full_interview_video_url
                                  }
                                  boxStyling
                                />
                              )}
                            </div>
                          )}
                        </div>

                        {resumeDetails && !isSharedView && (
                          <CandidateInfoPanel
                            candidateName={
                              interviewData?.candidate_name || 'Candidate'
                            }
                            candidateLocation={
                              resumeDetails?.current_location || '--'
                            }
                            candidateTitle={
                              resumeDetails?.current_title || '--'
                            }
                            candidateExperienceLevel={
                              resumeDetails?.experience_level || '--'
                            }
                            resumeUrl={interviewData?.resume?.url}
                          />
                        )}

                        {!isSharedView && (
                          <CandidateContactPanel
                            interview={interviewData}
                            candidatePhone={resumeDetails?.phone_number}
                            showSendMessageButton
                          />
                        )}

                        {interviewData?.job_listing && (
                          <JobPostingDetailsPanel
                            orgLogoUrl={interviewData?.organization_logo_url}
                            orgName={interviewData?.organization}
                            jobListing={interviewData?.job_listing}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { InterviewViewerDetails };
