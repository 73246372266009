import { RootState } from '@store/store';

export const selectActiveMobileMenu = (state: RootState) =>
  state.layout.activeMobileMenu;

export const selectCompactMenu = (state: RootState) => {
  if (localStorage.getItem('compactMenu') === null) {
    localStorage.setItem('compactMenu', 'true');
  }

  return state.layout.compactMenu;
};

export const selectCompactMenuOnHover = (state: RootState) =>
  state.layout.compactMenuOnHover;

export const selectPageLoader = (state: RootState) => state.layout.pageLoader;

export const selectSelectedOrgId = (state: RootState) =>
  state.layout.selectedOrg?.id;

export const selectSelectedOrg = (state: RootState) => state.layout.selectedOrg;

export const selectInitialRoute = (state: RootState) =>
  state.layout.initialRoute;
