import { ReactNode, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { useSelector } from 'react-redux';

import { enqueueSnackbar } from 'notistack';
import { getHashParam } from '@utilities';
import { resetUser, setAuthToken, setPageLoader } from '@store/slices';
import { selectPageLoader, selectToken } from '@store/selectors';
import { useAppDispatch } from '@store/hooks';
import { useLazyUserQuery } from '@store/services';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

interface AuthProps {
  children: ReactNode;
}

function Auth({ children }: AuthProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [getUser] = useLazyUserQuery();
  const [queryParams] = useSearchParams();

  const pageLoader = useSelector(selectPageLoader);
  const token = useSelector(selectToken);

  const oauthToken = getHashParam('token', location);
  const { state } = location;

  const urlMessage =
    queryParams.get('message') || getHashParam('message', location);

  const urlError = queryParams.get('errors');

  // handles navigation from signup when the user has a message before login is allowed
  // or handle showing message from API i.e. if one was sent back from oauth
  useEffect(() => {
    // react router dom state
    if (state && state.message) {
      enqueueSnackbar({
        message: state.message,
        variant: 'info',
      });
    }
  }, [state, urlMessage]);

  useEffect(() => {
    // react router dom state
    if (urlError) {
      enqueueSnackbar({
        message: urlError,
        variant: 'error',
      });
    }

    if (urlMessage) {
      enqueueSnackbar({
        message: urlMessage,
        variant: 'info',
      });
    }
  }, [urlError, urlMessage]);

  useEffect(() => {
    // handle callback from oauth login
    if (oauthToken) {
      const jwt = atob(oauthToken);
      dispatch(setAuthToken(jwt));
      localStorage.setItem('token', jwt);
    }
  }, [dispatch, navigate, oauthToken]);

  useEffect(() => {
    (async () => {
      if (token) {
        try {
          await getUser().unwrap();
        } catch (e) {
          dispatch(resetUser());
        }
      } else if (!oauthToken) {
        // check for presence of oauth token and prevent showing layout just yet
        // until after token has been fully loaded into state
        dispatch(setPageLoader(false));
      }
    })();
  }, [dispatch, getUser, oauthToken, token]);

  return (
    <>
      <Transition
        show={!pageLoader}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {children}
      </Transition>
      <Transition
        show={pageLoader}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <svg
          className="fixed inset-0 m-auto size-10 animate-spin text-theme-1"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      </Transition>
    </>
  );
}

export { Auth };
