import { useMemo } from 'react';

import {
  CodingQuestionTemplate,
  TextQuestionTemplate,
} from '@modules/conference/interviewQuestions/questionTemplates';
import { JobQuestion as InterviewQuestionType } from '@typings';

interface InterviewQuestionProps {
  question?: InterviewQuestionType;
}

function InterviewQuestion({ question }: InterviewQuestionProps) {
  const template = useMemo(() => {
    if (!question) return null;

    if (question.code_blocks) {
      return <CodingQuestionTemplate question={question} />;
    }

    return <TextQuestionTemplate question={question} />;
  }, [question]);

  return template;
}

export { InterviewQuestion };
