import * as Yup from 'yup';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { FormInput, FormLabel, FormModal } from '@kerplunkai/common-components';
import { FormikProps } from 'formik';
import { useSelector } from 'react-redux';

import { selectSelectedOrgId } from '@store/selectors';
import { useCreateOrgUserMutation } from '@store/services';

type AddUser = {
  email: string;
};

const INITIAL_VALUES: AddUser = {
  email: '',
};

interface OrgUserFormModalProps {
  open: boolean;
  toggleOpen: Dispatch<SetStateAction<boolean>>;
}

function OrgUserFormModal({ open, toggleOpen }: OrgUserFormModalProps) {
  const orgId = useSelector(selectSelectedOrgId);

  const [createUser, { isLoading }] = useCreateOrgUserMutation();

  const handleCreateUser = useCallback(
    (values: AddUser) => {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise<void>(async (resolve, reject) => {
        const result = await createUser({
          orgId: orgId as string,
          email: values.email,
        });

        if (result instanceof Error) {
          reject();
        } else {
          resolve();
        }
      });
    },
    [orgId, createUser],
  );

  return (
    <FormModal<AddUser>
      formikConfig={{
        initialValues: INITIAL_VALUES,
        validateOnChange: false,
        validationSchema: Yup.object({
          email: Yup.string()
            .email('Invalid email')
            .required('Email is required'),
        }),
        onSubmit: () => {},
      }}
      isLoading={isLoading}
      open={open}
      title="Invite New User"
      snackbar={{
        success: {
          children: (
            <div className="flex flex-col gap-1">
              <p className="text-sm text-[#364154]">Invitation Sent</p>
              <p className="text-sm text-[#677489]">
                The invitation has been successfully sent. The invitee will
                receive the invitation shortly.
              </p>
            </div>
          ),
        },
        error: { message: 'Unable to add user. Please try again.' },
      }}
      successButton={{ children: 'Invite User' }}
      onClose={() => toggleOpen(false)}
      onSubmit={handleCreateUser}
    >
      {({ values, errors, handleChange }: FormikProps<AddUser>) => (
        <div className="flex flex-col gap-3.5">
          <FormLabel htmlFor="email">
            <div className="flex">
              Email Address
              <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-400 dark:bg-darkmode-300 dark:text-slate-400">
                Required
              </div>
            </div>
            <div className="mt-1.5 text-left text-xs text-slate-500">
              Just input their email address below, and we&apos;ll send them an
              invitation to get started.
            </div>
          </FormLabel>
          <div>
            <FormInput
              id="email"
              error={Boolean(errors.email)}
              name="email"
              placeholder="jane@email.com"
              value={values.email}
              onChange={handleChange}
            />
            {Boolean(errors.email) && (
              <div className="mt-2 text-left text-xs text-danger">
                {errors.email}
              </div>
            )}
          </div>
        </div>
      )}
    </FormModal>
  );
}

export { OrgUserFormModal };
