import { InterviewQuestionAnalysis, InterviewQuestionCategory } from '@typings';

import { Tippy } from '@kerplunkai/common-components';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import ReactPlayer from 'react-player';
import { Transition } from '@headlessui/react';

import {
  InterviewCategoryChip,
  InterviewScoringChip,
} from '@modules/interviewDetails/components';

interface InterviewVideoPlayerProps {
  interviewAnalyses?: InterviewQuestionAnalysis[];
  videoUrl: string;
  boxStyling?: boolean;
}

function InterviewVideoPlayer({
  interviewAnalyses,
  videoUrl,
  boxStyling = false,
}: InterviewVideoPlayerProps) {
  const [totalDuration, setTotalDuration] = useState(0);

  const [currentTimestamp, setCurrentTimestamp] = useState<number>(0);
  const [showDots, setShowDots] = useState(false);
  const [isPlayerReady, setIsPlayerReady] = useState(false);

  const [currentQuestionAnalysis, setCurrentQuestionAnalysis] = useState(
    interviewAnalyses && interviewAnalyses[0],
  );

  const playerRef = useRef<ReactPlayer>(null);

  // don't show dots on firefox; can still show question panel though
  // has to do with native media controls; TODO: disable native controls and create custom controls
  const isFirefox = window.navigator.userAgent.includes('Firefox');

  const sortedAnalyses = useMemo(() => {
    return interviewAnalyses?.slice().sort((a, b) => a.position - b.position);
  }, [interviewAnalyses]);

  // extract interview clip start times for displaying dots
  const clipStartTimes = useMemo(() => {
    return sortedAnalyses?.map(ia => {
      return ia.start_time;
    });
  }, [sortedAnalyses]);

  const getCurrentClipIndex = useCallback(
    (clipTimes: number[]) => {
      for (let i = 0; i < clipTimes.length; i++) {
        if (i + 1 === clipTimes.length || currentTimestamp < clipTimes[i + 1]) {
          return i;
        }
      }
      return clipTimes.length - 1;
    },
    [currentTimestamp],
  );

  // set current question context based on playback timestamp
  useEffect(() => {
    if (clipStartTimes && sortedAnalyses) {
      const analysisIndex = getCurrentClipIndex(clipStartTimes);
      setCurrentQuestionAnalysis(sortedAnalyses[analysisIndex]);
    }
  }, [clipStartTimes, currentTimestamp, getCurrentClipIndex, sortedAnalyses]);

  const videoDots = useMemo(() => {
    if (!sortedAnalyses || !clipStartTimes) return [];

    return sortedAnalyses
      .map(a => {
        return {
          time: a.start_time,
          label: `Question ${a.position}`,
        };
      })
      .filter(item => item.time !== 0);
  }, [clipStartTimes, sortedAnalyses]);

  const seekTo = (seconds: number) => {
    playerRef?.current?.seekTo(seconds, 'seconds');
  };

  const isShowDots = showDots && !isFirefox && isPlayerReady;

  return (
    <div className="mb-2">
      <div
        className="relative"
        onMouseEnter={() => setShowDots(true)}
        onMouseLeave={() => setShowDots(false)}
      >
        <ReactPlayer
          ref={playerRef}
          url={videoUrl}
          width="100%"
          height="100%"
          controls
          style={{
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            overflow: 'hidden',
          }}
          onDuration={dur => setTotalDuration(dur)}
          onReady={() => setIsPlayerReady(true)}
          onProgress={({ playedSeconds }) => setCurrentTimestamp(playedSeconds)}
        />

        {/* DOTS CONTAINER */}
        <div
          className="absolute inset-x-0 bottom-7 m-auto w-full"
          style={{ width: 'calc(100% - 42px)' }} // react player has 42px of padding on left/right of timeline
        >
          <Transition
            show={isShowDots}
            enter="transition ease duration-500 transform"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease duration-300 transform"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            {videoDots.map(dot => {
              const leftPositionPct = (dot.time / totalDuration) * 100;
              return (
                <Tippy
                  className="absolute size-3 cursor-pointer rounded-full border-2 border-solid border-[#A9F72A] bg-[#0260C6]"
                  style={{
                    left: `${leftPositionPct}%`,
                    transform: 'translateX(-50%)',
                  }}
                  key={dot.label}
                  content={dot.label}
                  onClick={() => seekTo(dot.time)}
                />
              );
            })}
          </Transition>
        </div>
      </div>

      {/* AI SCORE AND QUESTION PANEL */}
      {boxStyling && (
        <div className="box !rounded-t-none bg-white p-4">
          <div className="mb-2 flex font-normal">
            {`Question ${currentQuestionAnalysis?.position}`}
            {currentQuestionAnalysis?.interview_question.category && (
              <InterviewCategoryChip
                categoryName={
                  InterviewQuestionCategory[
                    currentQuestionAnalysis.interview_question.category
                  ]
                }
              />
            )}
            {currentQuestionAnalysis?.interview_question.score !==
              undefined && (
              <InterviewScoringChip
                score={currentQuestionAnalysis.interview_question.score}
              />
            )}
          </div>
          <div className="text-base font-medium md:text-lg">
            {currentQuestionAnalysis?.content}
          </div>
        </div>
      )}

      {!boxStyling && (
        <div className="pt-4">
          <div className="text-base font-medium md:text-lg">
            {currentQuestionAnalysis?.content}
          </div>
          <div className="mt-2 flex text-xs font-medium text-[#94A3B8]">
            {`Question ${currentQuestionAnalysis?.position}`}
            {currentQuestionAnalysis?.interview_question.category && (
              <InterviewCategoryChip
                categoryName={
                  InterviewQuestionCategory[
                    currentQuestionAnalysis.interview_question.category
                  ]
                }
              />
            )}
            {currentQuestionAnalysis?.interview_question.score !==
              undefined && (
              <InterviewScoringChip
                score={currentQuestionAnalysis.interview_question.score}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export { InterviewVideoPlayer };
