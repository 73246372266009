import { InterviewScoringDetails } from '@typings';
import { Tippy } from '@kerplunkai/common-components';

interface InterviewScoringHoverBarsProps {
  scoringDetails?: InterviewScoringDetails | null;
}

function InterviewScoringHoverBars({
  scoringDetails,
}: InterviewScoringHoverBarsProps) {
  const isAllZeroes =
    scoringDetails &&
    scoringDetails.percentCultural === 0 &&
    scoringDetails.percentResume === 0 &&
    scoringDetails.percentSoftskills === 0 &&
    scoringDetails.percentTechnical === 0 &&
    scoringDetails.percentXFactor === 0;

  return (
    <>
      {/* Default scoring bars if we don't have scoring details */}
      {!scoringDetails && (
        <>
          <div
            className="h-full border border-sky-700/50 bg-sky-800/50 bg-clip-padding first:rounded-l last:rounded-r"
            style={{ width: `20%` }}
          />
          <div
            className="h-full border border-sky-600/50 bg-sky-600/50 bg-clip-padding first:rounded-l last:rounded-r"
            style={{ width: `20%` }}
          />
          <div
            className="h-full border border-orange-700/50 bg-orange-700/50 bg-clip-padding first:rounded-l last:rounded-r"
            style={{ width: `20%` }}
          />
          <div
            className="h-full border border-teal-700/50 bg-teal-700/50 bg-clip-padding first:rounded-l last:rounded-r"
            style={{ width: `20%` }}
          />
          <div
            className="h-full border border-yellow-500/50 bg-yellow-500/50 bg-clip-padding first:rounded-l last:rounded-r"
            style={{ width: `20%` }}
          />
        </>
      )}

      {scoringDetails && !isAllZeroes && (
        <>
          <Tippy
            as="div"
            content={`Cultural (${scoringDetails.percentCultural.toFixed(0)}%)`}
            className="h-full border border-sky-700/50 bg-sky-800/50 bg-clip-padding first:rounded-l last:rounded-r"
            style={{ width: `${scoringDetails.percentCultural}%` }}
          />

          <Tippy
            as="div"
            content={`Technical (${scoringDetails.percentTechnical.toFixed(0)}%)`}
            className="h-full border border-sky-600/50 bg-sky-600/50 bg-clip-padding first:rounded-l last:rounded-r"
            style={{ width: `${scoringDetails.percentTechnical}%` }}
          />

          <Tippy
            as="div"
            content={`Soft Skills (${scoringDetails.percentSoftskills.toFixed(0)}%)`}
            className="h-full border border-orange-700/50 bg-orange-700/50 bg-clip-padding first:rounded-l last:rounded-r"
            style={{ width: `${scoringDetails.percentSoftskills}%` }}
          />

          <Tippy
            as="div"
            content={`X-Factor (${scoringDetails.percentXFactor.toFixed(0)}%)`}
            className="h-full border border-teal-700/50 bg-teal-700/50 bg-clip-padding first:rounded-l last:rounded-r"
            style={{ width: `${scoringDetails.percentXFactor}%` }}
          />

          <Tippy
            as="div"
            content={`Resume (${scoringDetails.percentResume.toFixed(0)}%)`}
            className="h-full border border-yellow-500/50 bg-yellow-500/50 bg-clip-padding first:rounded-l last:rounded-r"
            style={{ width: `${scoringDetails.percentResume}%` }}
          />
        </>
      )}

      {scoringDetails && isAllZeroes && (
        <>
          <Tippy
            as="div"
            content="Cultural (0%)"
            className="h-full border border-sky-700/50 bg-sky-800/50 bg-clip-padding first:rounded-l last:rounded-r"
            style={{ width: `20%` }}
          />

          <Tippy
            as="div"
            content="Technical (0%)"
            className="h-full border border-sky-600/50 bg-sky-600/50 bg-clip-padding first:rounded-l last:rounded-r"
            style={{ width: `20%` }}
          />

          <Tippy
            as="div"
            content="Soft Skills (0%)"
            className="h-full border border-orange-700/50 bg-orange-700/50 bg-clip-padding first:rounded-l last:rounded-r"
            style={{ width: `20%` }}
          />

          <Tippy
            as="div"
            content="X-Factor (0%)"
            className="h-full border border-teal-700/50 bg-teal-700/50 bg-clip-padding first:rounded-l last:rounded-r"
            style={{ width: `20%` }}
          />

          <Tippy
            as="div"
            content="Resume (0%)"
            className="h-full border border-yellow-500/50 bg-yellow-500/50 bg-clip-padding first:rounded-l last:rounded-r"
            style={{ width: `20%` }}
          />
        </>
      )}
    </>
  );
}

export { InterviewScoringHoverBars };
