import { DetailedCommentary } from '@typings';
import { Disclosure, Lucide } from '@kerplunkai/common-components';

interface InterviewSummaryPanelProps {
  longSummaryText: string;
  shortSummaryText: string;
  commentary?: DetailedCommentary;
}

function InterviewSummaryPanel({
  longSummaryText,
  shortSummaryText,
  commentary,
}: InterviewSummaryPanelProps) {
  const summaryTextClasses =
    'mb-2 mt-5 w-fit rounded-full bg-slate-400/10 px-3 md:px-2 py-1 text-sm font-medium';

  const defaultText = 'No analysis is available. Please check again later.';

  return (
    <Disclosure.Group variant="boxed" openByDefault>
      <Disclosure className="rounded-[0.6rem]">
        <Disclosure.Button>
          <div className="flex items-center border-b border-dashed border-slate-200/60 pb-5 text-[0.94rem] font-medium">
            <Lucide icon="ChevronDown" className="mr-2 size-5 stroke-[1.3]" />
            <div className="flex grow text-[#181D2C] md:text-base">
              Interview Summary
            </div>
          </div>
        </Disclosure.Button>
        <Disclosure.Panel className="text-sm font-normal leading-relaxed text-slate-600 dark:text-slate-500">
          {longSummaryText}
          <div className={summaryTextClasses}>Experience and Expertise</div>
          {commentary?.experience || defaultText}
          <div className={summaryTextClasses}>Adaptability and Efficiency</div>
          {commentary?.adaptability || defaultText}
          <div className={summaryTextClasses}>Communication and Empathy</div>
          {commentary?.communication || defaultText}
          <div className={summaryTextClasses}>
            Professionalism and Dedication
          </div>
          {commentary?.professionalism || defaultText}
          <div className={summaryTextClasses}>
            Continuous Learning and Improvement
          </div>
          {commentary?.continuous_improvement || defaultText}
          <div className={summaryTextClasses}>
            Financial Consideration and Value Alignment
          </div>
          {commentary?.financial_impact || defaultText}
          <div className="my-5">{shortSummaryText || defaultText}</div>
        </Disclosure.Panel>
      </Disclosure>
    </Disclosure.Group>
  );
}

export { InterviewSummaryPanel };
