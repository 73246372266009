import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { FormTextField, ModalDialog } from '@kerplunkai/common-components';
import {
  useCreateJobBenefitMutation,
  useLazyGetJobDetailsQuery,
} from '@store/services';
import { useSnackbar } from 'notistack';

interface JobBenefitDialogFormProps {
  open: boolean;
  orgId: string;

  selectBenefit: (value: string) => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

function JobBenefitDialogForm({
  open,
  orgId,
  selectBenefit,
  setOpen,
}: JobBenefitDialogFormProps) {
  const { enqueueSnackbar } = useSnackbar();

  const [createJobBenefit, { isLoading }] = useCreateJobBenefitMutation();
  const [getJobDetails] = useLazyGetJobDetailsQuery();

  const [benefitToAdd, setBenefitToAdd] = useState('');

  const handleCancelCreateBenefit = useCallback(() => {
    setOpen(false);
    setBenefitToAdd('');
  }, [setOpen]);

  const handleCreateAndSelectBenefit = useCallback(async () => {
    try {
      const newBenefit = await createJobBenefit({
        name: benefitToAdd,
        organizationId: orgId,
      }).unwrap();
      await getJobDetails(orgId);

      selectBenefit(newBenefit.id);
      setBenefitToAdd('');
      setOpen(false);
    } catch (e) {
      enqueueSnackbar({
        message: 'Failed to create benefit. Please try again.',
        variant: 'error',
      });
    }
  }, [
    benefitToAdd,
    orgId,
    enqueueSnackbar,
    createJobBenefit,
    selectBenefit,
    getJobDetails,
    setOpen,
  ]);

  return (
    <ModalDialog
      isLoading={isLoading}
      open={open}
      onConfirm={handleCreateAndSelectBenefit}
      onCancel={handleCancelCreateBenefit}
      title="Add Benefit"
      confirmText="Add Benefit"
      cancelText="Cancel"
    >
      <div className="my-8">
        <FormTextField
          disabled={isLoading}
          placeholder="Benefit Name"
          label="Benefit Name"
          name="name"
          value={benefitToAdd}
          onChange={({ target: { value } }) => setBenefitToAdd(value)}
        />
      </div>
    </ModalDialog>
  );
}

export { JobBenefitDialogForm };
