import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Interview } from '@typings';
import { interviewService } from '@store/services';

type InterviewState = {
  jobApplicationId: string | null;
  jobListingId: string | null;
  interviewData: Interview | null;
  activeCameraStream: MediaStream | null;
  activeMicStream: MediaStream | null;
  selectedMicDeviceId: string | null;
  selectedCameraDeviceId: string | null;
  resumeInterviewCamDeviceId: string | null;
  resumeInterviewMicDeviceId: string | null;
  isCameraPaused: boolean;
  isMicPaused: boolean;
  selectedInterviewDocumentId: string | null;
  resumeInterviewId: string | null;
};

const getCurrentJobListingIdFromLS = () => {
  return localStorage.getItem('jobListingId');
};

const getSelectedCameraDeviceIdFromLS = () => {
  return localStorage.getItem('selectedCameraDeviceId');
};

const getSelectedMicDeviceIdFromLS = () => {
  return localStorage.getItem('selectedMicDeviceId');
};

const getResumeInterviewIdFromLS = () => {
  return localStorage.getItem('resumeInterviewId');
};

const slice = createSlice({
  name: 'interview',
  initialState: {
    jobApplicationId: null,
    jobListingId: getCurrentJobListingIdFromLS(),
    interviewData: null,
    activeCameraStream: null,
    activeMicStream: null,
    selectedMicDeviceId: getSelectedMicDeviceIdFromLS(),
    selectedCameraDeviceId: getSelectedCameraDeviceIdFromLS(),
    resumeInterviewCamDeviceId: null,
    resumeInterviewMicDeviceId: null,
    isCameraPaused: false,
    isMicPaused: false,
    selectedInterviewDocumentId: null,
    resumeInterviewId: getResumeInterviewIdFromLS(),
  } as InterviewState,
  extraReducers: builder => {
    builder.addMatcher(
      interviewService.endpoints.createInterview.matchFulfilled,
      (state, { payload }) => {
        state.interviewData = payload;
      },
    );
    builder.addMatcher(
      interviewService.endpoints.orgInterview.matchFulfilled,
      (state, { payload }) => {
        state.interviewData = payload;
        state.jobApplicationId = payload.job_application_id;
      },
    );
  },
  reducers: {
    resetDevicePreferences: state => {
      state.selectedMicDeviceId = null;
      state.selectedCameraDeviceId = null;
      localStorage.removeItem('selectedCameraDeviceId');
      localStorage.removeItem('selectedMicDeviceId');
    },
    resetInterviewState: state => {
      state.jobApplicationId = null;
      state.jobListingId = null;
      state.interviewData = null;
      state.isCameraPaused = false;
      state.isMicPaused = false;
      state.selectedInterviewDocumentId = null;
      state.resumeInterviewId = null;
      localStorage.removeItem('jobListingId');
      localStorage.removeItem('resumeInterviewId');
    },
    // use this for setting the current job listing id that you are currently interviewing for
    setInterviewJobListingId: (state, action: PayloadAction<string>) => {
      localStorage.setItem('jobListingId', action.payload);
      state.jobListingId = action.payload;
    },
    setResumeInterviewId: (state, action: PayloadAction<string>) => {
      localStorage.setItem('resumeInterviewId', action.payload);
      state.resumeInterviewId = action.payload;
    },
    setInterviewCameraStream: (
      state,
      action: PayloadAction<MediaStream | null>,
    ) => {
      state.activeCameraStream = action.payload;
    },
    setInterviewMicStream: (
      state,
      action: PayloadAction<MediaStream | null>,
    ) => {
      state.activeMicStream = action.payload;
    },
    setSelectedCameraDeviceId: (
      state,
      action: PayloadAction<string | null>,
    ) => {
      state.selectedCameraDeviceId = action.payload;
      if (action.payload) {
        localStorage.setItem('selectedCameraDeviceId', action.payload);
      } else {
        localStorage.removeItem('selectedCameraDeviceId');
      }
    },
    setSelectedMicDeviceId: (state, action: PayloadAction<string | null>) => {
      state.selectedMicDeviceId = action.payload;
      if (action.payload) {
        localStorage.setItem('selectedMicDeviceId', action.payload);
      } else {
        localStorage.removeItem('selectedMicDeviceId');
      }
    },
    stopInterviewStreams: state => {
      if (state.activeCameraStream?.getTracks) {
        state.activeCameraStream.getTracks().forEach(t => t.stop());
      }
      if (state.activeMicStream?.getTracks) {
        state.activeMicStream.getTracks().forEach(t => t.stop());
      }

      state.activeCameraStream = null;
      state.activeMicStream = null;
    },
    stopCameraStream: state => {
      if (state.activeCameraStream?.getTracks) {
        state.activeCameraStream.getTracks().forEach(t => t.stop());
      }
      state.activeCameraStream = null;
    },
    stopMicStream: state => {
      if (state.activeMicStream?.getTracks) {
        state.activeMicStream.getTracks().forEach(t => t.stop());
      }
      state.activeMicStream = null;
    },
    pauseMicStream: state => {
      if (state.activeMicStream?.getTracks) {
        state.activeMicStream.getTracks().forEach(t => {
          t.enabled = false;
        });
      }
      state.isMicPaused = true;
    },
    resumeMicStream: state => {
      if (state.activeMicStream?.getTracks) {
        state.activeMicStream.getTracks().forEach(t => {
          t.enabled = true;
        });
      }
      state.isMicPaused = false;
    },
    pauseCameraStream: state => {
      if (state.activeCameraStream?.getTracks) {
        state.activeCameraStream.getTracks().forEach(t => {
          t.enabled = false;
        });
      }
      state.isCameraPaused = true;
    },
    resumeCameraStream: state => {
      if (state.activeCameraStream?.getTracks) {
        state.activeCameraStream.getTracks().forEach(t => {
          t.enabled = true;
        });
      }
      state.isCameraPaused = false;
    },
    setSelectedInterviewDocumentId: (
      state,
      action: PayloadAction<string | null>,
    ) => {
      state.selectedInterviewDocumentId = action.payload;
    },
  },
});

export const {
  resetInterviewState,
  setInterviewJobListingId,
  setInterviewCameraStream,
  setInterviewMicStream,
  stopInterviewStreams,
  setSelectedCameraDeviceId,
  setSelectedMicDeviceId,
  resetDevicePreferences,
  stopMicStream,
  stopCameraStream,
  pauseCameraStream,
  resumeCameraStream,
  pauseMicStream,
  resumeMicStream,
  setSelectedInterviewDocumentId,
  setResumeInterviewId,
} = slice.actions;

export default slice.reducer;
