/* eslint-disable @typescript-eslint/naming-convention */
import { ChartDataset } from 'chart.js';
import { Disclosure, Lucide, RadarChart } from '@kerplunkai/common-components';
import { Interview } from '@typings';
import { getRadarScoringDetails, mapBreakdownSummaryToRadar } from '@utilities';
import { useCallback } from 'react';

interface InterviewAnalysisPanelProps {
  interview?: Interview;
}

function InterviewAnalysisPanel({ interview }: InterviewAnalysisPanelProps) {
  const getRadarDataSets = useCallback(() => {
    const dataSets: ChartDataset<'radar'>[] = [];
    if (interview) {
      const radarScoringDetails = getRadarScoringDetails(interview);

      if (radarScoringDetails.resume) {
        dataSets.push({
          label: 'Resume',
          data: mapBreakdownSummaryToRadar(radarScoringDetails.resume),
          fill: true,
          borderWidth: 1,
          backgroundColor: 'rgba(7, 89, 133, 0.2)',
          borderColor: 'rgba(7, 89, 133, 0.6)',
          pointBackgroundColor: 'rgba(7, 89, 133, 0.6)',
          pointBorderColor: 'transparent',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(7, 89, 133, 0.6)',
        });
      }

      if (radarScoringDetails.interview) {
        dataSets.push({
          label: 'Interview',
          data: mapBreakdownSummaryToRadar(radarScoringDetails.interview),
          fill: true,
          borderWidth: 1,
          backgroundColor: 'rgba(85, 132, 8, .2)',
          borderColor: 'rgba(85, 132, 8, .6)',
          pointBackgroundColor: 'rgba(85, 132, 8, .6)',
          pointBorderColor: 'transparent',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(85, 132, 8, .6)',
        });
      }
    }

    return dataSets;
  }, [interview]);

  return (
    <Disclosure.Group variant="boxed" openByDefault>
      <Disclosure className="rounded-[0.6rem]">
        <Disclosure.Button>
          <div className="flex items-center border-b border-dashed border-slate-200/60 pb-5 text-[0.94rem] font-medium">
            <Lucide icon="ChevronDown" className="mr-2 size-5 stroke-[1.3]" />
            <div className="text-base text-slate-600">AI Analysis ✨ </div>
          </div>
        </Disclosure.Button>
        <Disclosure.Panel className="text-sm font-normal leading-relaxed text-slate-600 dark:text-slate-500">
          <div className="rounded-md px-2 lg:px-5">
            {/* Analysis Section */}
            <div className="grid grid-cols-12">
              <div className="col-span-2 text-base font-semibold text-slate-600">
                Analysis
              </div>
              <div className="col-span-5 text-slate-500">
                {interview?.commentary?.analysis ||
                  'No analysis is available. Please check again later.'}
              </div>
              <div className="col-span-5">
                <div className="flex justify-center gap-2.5 py-2 text-slate-600">
                  <p className="text-base font-medium">Overall Score</p>
                  <p className="text-base font-medium">
                    {interview ? interview.ai_score.toFixed(0) || '-' : '-'}
                    &nbsp;%
                  </p>
                </div>
                <RadarChart
                  labels={[
                    'X-Factor',
                    'Soft Skills',
                    'Resume Score',
                    'Culture Fit',
                    'Technical',
                  ]}
                  datasets={getRadarDataSets()}
                />
              </div>
            </div>

            {/* Softskills Section */}
            <div className="mt-4 grid grid-cols-12 md:mt-8">
              <div className="col-span-12 text-base font-semibold text-slate-600 md:col-span-2">
                Softskills
              </div>
              <div className="col-span-12 text-slate-500 md:col-span-10">
                {interview?.commentary?.soft_skills ||
                  'No analysis is available. Please check again later.'}
              </div>
            </div>

            {/* Culture Section */}
            <div className="mt-4 grid grid-cols-12 md:mt-8">
              <div className="col-span-12 text-base font-semibold text-slate-600 md:col-span-2">
                Culture
              </div>
              <div className="col-span-12 text-slate-500 md:col-span-10">
                {interview?.commentary?.culture_fit ||
                  'No analysis is available. Please check again later.'}
              </div>
            </div>

            {/* Feedback Section */}
            <div className="mt-4 grid grid-cols-12 md:mt-8">
              <div className="col-span-12 text-base font-semibold text-slate-600 md:col-span-2">
                Feedback
              </div>
              <div className="col-span-12 text-slate-500 md:col-span-10">
                {interview?.commentary?.feedback ||
                  'No analysis is available. Please check again later.'}
              </div>
            </div>
          </div>
        </Disclosure.Panel>
      </Disclosure>
    </Disclosure.Group>
  );
}

export { InterviewAnalysisPanel };
