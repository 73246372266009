/* eslint-disable @typescript-eslint/no-explicit-any */
import clsx from 'clsx';
import {
  Button,
  Lucide,
  Modal,
  useViewPort,
} from '@kerplunkai/common-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  CompletedQuestionsList,
  ParticipantWindow,
  useInterviewActionContext,
} from '@modules/conference/components';
import { InterviewQuestion } from '@modules/conference/interviewQuestions';
import { InterviewQuestionStatus, InterviewStatus } from '@typings';
import { NAV_ROUTES } from '@constants';
import { getJobLocation } from '@utilities/jobs';
import {
  selectCurrentUser,
  selectInterviewDetails,
  selectInterviewJobApplicationId,
} from '@store/selectors';
import { stopInterviewStreams } from '@store/slices';
import { useAppDispatch } from '@store/hooks';
import {
  useCreateInterviewMeetingRoomMutation,
  useCreateQuestionMeetingRoomMutation,
  useSubmitQuestionResponseMutation,
  useUpdateMeetingInterviewMutation,
} from '@store/services';

import { useKerplunkMediaService } from '../hooks';

function InterviewQuestions() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // read initial question index from router state param
  const location = useLocation();
  const { state } = location;

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(
    state?.initialQuestionIndex || 0,
  );
  const [showEndInterviewModal, setShowInterviewModal] = useState(false);
  const [isClientInitialized, setIsClientInitialized] = useState(false);

  const interviewDetails = useSelector(selectInterviewDetails);
  const currentUser = useSelector(selectCurrentUser);
  const jobApplicationId = useSelector(selectInterviewJobApplicationId);

  const [getInterviewMeetingRoom] = useCreateInterviewMeetingRoomMutation();
  const [updateInterview] = useUpdateMeetingInterviewMutation();
  const [getQuestionMeetingRoom] = useCreateQuestionMeetingRoomMutation();
  const [submitQuestionResponse] = useSubmitQuestionResponseMutation();

  // used to set a cleanup function so other components can clean up on exit
  const { setCloseFunction } = useInterviewActionContext();

  const {
    close,
    initClient,
    isRecording,
    startRecording,
    isMeetingConnected,
    participants,
    enableWebcam,
    disableWebcam,
    muteMic,
    unmuteMic,
  } = useKerplunkMediaService(interviewDetails?.video_provider);

  const isMobile = useViewPort();

  useEffect(() => {
    const initRoom = async () => {
      const currentQuestion =
        interviewDetails?.interview_questions[currentQuestionIndex];
      if (
        currentUser &&
        currentQuestion &&
        interviewDetails &&
        !isClientInitialized
      ) {
        if (interviewDetails.video_provider === 'videosdk') {
          const room = await getInterviewMeetingRoom({
            interviewId: interviewDetails.id,
            userId: currentUser.id,
          }).unwrap();

          const awsFolderName = `${interviewDetails.id}/${currentUser.id}`;

          initClient({
            room_id: room.room_id,
            awsStoragePath: awsFolderName,
            participantName: `${currentUser?.first_name} ${currentUser.last_name}`,
          });
          setIsClientInitialized(true);
        } else {
          const room = await getQuestionMeetingRoom({
            questionId: currentQuestion?.id as string,
            userId: currentUser.id,
            interviewId: interviewDetails.id,
          }).unwrap();

          initClient(room);
          setIsClientInitialized(true);
        }
      }
    };
    initRoom();
  }, [
    currentQuestionIndex,
    currentUser,
    getInterviewMeetingRoom,
    getQuestionMeetingRoom,
    initClient,
    interviewDetails,
    isClientInitialized,
    jobApplicationId,
  ]);

  // keep track of the cleanup function we need to end a session
  // this hoists up to a context provider for the interview layout
  useEffect(() => {
    setCloseFunction(() => close);
  }, [close, setCloseFunction]);

  useEffect(() => {
    return () => {
      dispatch(stopInterviewStreams());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (interviewDetails) {
      // handle the case where we have a job listing defined, but no questions are associated with it
      if (
        interviewDetails.interview_questions &&
        interviewDetails.interview_questions.length <= 0
      ) {
        // eslint-disable-next-line no-alert
        alert(
          'There was a problem loading the interview questions. Please try again.',
        );
        navigate(-1);
      }
    }
  }, [interviewDetails, navigate]);

  useEffect(() => {
    // trigger recording if we are ready to
    // should happen on:
    // first question
    // next question
    // resuming after pause
    // should wait for:
    // meeting to be connected
    // mic and camera stream to be ready
    if (!isRecording && isMeetingConnected && isClientInitialized) {
      startRecording();
    }
    // excluding startRecording; hook regenerates when changing streams and fires this multiple times
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClientInitialized, isMeetingConnected, isRecording]);

  const handleNextQuestion = useCallback(
    (status: InterviewQuestionStatus) => {
      const currentQuestion =
        interviewDetails?.interview_questions[currentQuestionIndex];
      if (interviewDetails && currentQuestion && currentUser) {
        submitQuestionResponse({
          interviewId: interviewDetails.id,
          questionId: currentQuestion.id as string,
          status,
          userId: currentUser.id,
        });
      }

      setCurrentQuestionIndex(currentQuestionIndex + 1);
    },
    [
      currentQuestionIndex,
      currentUser,
      interviewDetails,
      submitQuestionResponse,
    ],
  );

  // opens end modal
  const handleOpenEndInterview = () => {
    setShowInterviewModal(true);
  };

  // ends the interview and redirects to end interview
  const handleEndInterview = () => {
    setShowInterviewModal(false);
    close();
    navigate(NAV_ROUTES.CONFERENCE_END);
  };

  const handleSubmitInterview = () => {
    const currentQuestion =
      interviewDetails?.interview_questions[currentQuestionIndex];
    if (interviewDetails && currentUser && currentQuestion) {
      // close the media client
      close();

      submitQuestionResponse({
        interviewId: interviewDetails?.id,
        questionId: currentQuestion?.id as string,
        status: InterviewQuestionStatus.completed,
        userId: currentUser?.id,
      });

      updateInterview({
        interviewId: interviewDetails?.id as string,
        status: InterviewStatus.completed.toLowerCase(),
        userId: currentUser?.id,
      });
    }
    navigate(NAV_ROUTES.CONFERENCE_END);
  };

  const isLastQuestion = useMemo(() => {
    if (interviewDetails && interviewDetails.interview_questions) {
      return (
        currentQuestionIndex >= interviewDetails.interview_questions.length - 1
      );
    }
    return true;
  }, [currentQuestionIndex, interviewDetails]);

  const completedQuestions = useMemo(
    () =>
      interviewDetails?.interview_questions
        .filter(q => q.position - 1 < currentQuestionIndex)
        .sort((a, b) => b.position - a.position),
    [currentQuestionIndex, interviewDetails?.interview_questions],
  );

  const orgLocation = useMemo(() => {
    if (!interviewDetails || !interviewDetails.job_location) return '';
    return getJobLocation(interviewDetails.job_location as string);
  }, [interviewDetails]);

  const currentQuestion = useMemo(
    () => interviewDetails?.interview_questions[currentQuestionIndex],
    [currentQuestionIndex, interviewDetails?.interview_questions],
  );

  return (
    <div className="grid h-[calc(100vh-166px)] grid-cols-12 gap-x-6 gap-y-10 sm:h-full ">
      <div className="order-2 col-span-12 col-start-2 mb-[-20px] self-end sm:order-1 sm:col-span-4 sm:self-start">
        <div className="hidden text-base text-white sm:block">
          {interviewDetails?.job_title}
        </div>
        <div className="my-4 hidden sm:flex">
          {interviewDetails?.show_hiring_organization && (
            <div className="self-center">
              <div className="text-xs text-white">
                {interviewDetails?.organization}
              </div>
              <div className="text-xs text-slate-400">{orgLocation}</div>
            </div>
          )}
        </div>
        <div className="mb-8 hidden w-full justify-center sm:flex sm:justify-start">
          <Button
            rounded
            variant="danger"
            disabled
            className={clsx([
              'my-2 w-32 disabled:opacity-100',
              !isRecording ? 'bg-slate-600' : 'bg-red-800',
              !isRecording ? 'border-slate-300' : 'unset',
            ])}
          >
            <Lucide
              icon="Dot"
              className={clsx([
                'mr-2 stroke-[14]',
                !isRecording ? 'text-slate-300' : 'text-red-500',
              ])}
            />{' '}
            {!isRecording ? 'Loading...' : 'Recording...'}
          </Button>
        </div>
        <div className="mr-4 flex w-full flex-col sm:hidden">
          <Button
            rounded
            variant="soft-dark"
            className="mb-2.5 text-white max-sm:px-6 max-sm:py-[15px]"
            onClick={() => handleNextQuestion(InterviewQuestionStatus.skipped)}
            disabled={isLastQuestion || !isRecording}
          >
            <Lucide icon="SkipForward" className="mr-2" />
            {isMobile ? 'Skip Question' : 'Skip This Question'}
          </Button>
          <Button
            rounded
            className="max-sm:px-6 max-sm:py-[15px]"
            variant="medprosPrimary"
            disabled={!isRecording}
            onClick={
              isLastQuestion
                ? handleSubmitInterview
                : () => handleNextQuestion(InterviewQuestionStatus.completed)
            }
          >
            <Lucide icon="ArrowRight" className="mr-2" />
            {`${isLastQuestion ? 'Submit ' : 'Next Question'}`}
          </Button>
        </div>
        <div className="grid h-full grid-flow-col gap-4">
          <div className="relative z-20 flex items-center justify-between">
            <ParticipantWindow
              isLoading={!isRecording}
              videoClassName="videoContainer"
              enableWebcam={enableWebcam}
              disableWebcam={disableWebcam}
              muteMic={muteMic}
              unmuteMic={unmuteMic}
            />
          </div>
          {participants.map(participant => (
            <div
              key={participant.id}
              className="relative z-20 flex items-center justify-between"
            >
              <ParticipantWindow
                isLoading={!isRecording}
                videoClassName="videoContainer"
                participant={participant}
                isLocalParticipant={false}
              />
            </div>
          ))}
        </div>
        <div className="bottom-0 left-0 z-10 mt-4 flex w-full items-center bg-[#0C1533] p-5 max-sm:fixed sm:bg-transparent sm:p-0">
          <Button
            rounded
            onClick={handleOpenEndInterview}
            variant="text"
            className="rounded-full border border-[#D82322] !px-7 !py-[15px] text-slate-400"
          >
            <div className="flex items-center">
              <Lucide className="stoke-slate-400 mr-1" icon="PhoneMissed" />
              <span className="hidden lg:block">End Interview</span>
            </div>
          </Button>
        </div>
      </div>

      <div className="order-1 col-span-12 flex flex-col sm:order-2 sm:col-span-8">
        <div className="flex min-h-40 grow flex-col pt-12 text-white sm:min-h-96 sm:flex-row">
          <div className="hidden sm:block">
            <Lucide
              className="size-10 self-start stroke-[1.5] text-lime-400"
              icon="MessageCircleQuestion"
            />
          </div>
          <div className="flex flex-col text-center text-base text-white sm:hidden">
            <div>{interviewDetails?.organization}</div>
            <div>{interviewDetails?.job_title}</div>
          </div>
          <div className="my-[18px] flex justify-center sm:hidden sm:grow">
            <Lucide
              className="mr-2 text-lime-400"
              icon="MessageCircleQuestion"
            />
            <div className="text-sm font-semibold text-white">
              {' '}
              Question {currentQuestionIndex + 1}{' '}
              <span className="text-xs font-medium text-white/35">
                of {interviewDetails?.interview_questions?.length || ''}
              </span>
            </div>
          </div>
          <InterviewQuestion question={currentQuestion} />
          <div className="flex justify-center sm:hidden">
            <Button
              rounded
              variant="danger"
              disabled
              className={clsx([
                'my-2 w-32 disabled:opacity-100 ',
                !isRecording ? 'bg-slate-600' : 'bg-red-800',
                !isRecording ? 'border-slate-300' : 'unset',
              ])}
            >
              <Lucide
                icon="Dot"
                className={clsx([
                  'mr-2 stroke-[14]',
                  !isRecording ? 'text-slate-300' : 'text-red-500',
                ])}
              />{' '}
              {!isRecording ? 'Loading...' : 'Recording...'}
            </Button>
          </div>
        </div>

        {/* ACTION BUTTONS */}
        <div className="text ml-2 flex flex-col justify-center text-sm font-semibold text-white md:flex-row">
          <div className="hidden grow self-center sm:flex sm:gap-2">
            <Lucide className="mr-2" icon="MessageCircleQuestion" />
            {`Question ${currentQuestionIndex + 1} of ${
              interviewDetails?.interview_questions?.length || ''
            }`}
          </div>
          <div className="hidden flex-row justify-center sm:flex">
            <Button
              rounded
              variant="soft-dark"
              className="mr-2 text-white"
              onClick={() =>
                handleNextQuestion(InterviewQuestionStatus.skipped)
              }
              disabled={isLastQuestion || !isRecording}
            >
              <Lucide icon="SkipForward" className="mr-2" />
              Skip This Question
            </Button>
            <Button
              rounded
              variant="medprosPrimary"
              disabled={!isRecording}
              onClick={
                isLastQuestion
                  ? handleSubmitInterview
                  : () => handleNextQuestion(InterviewQuestionStatus.completed)
              }
            >
              <Lucide icon="ArrowRight" />
              {`${isLastQuestion ? 'Submit Interview' : 'Next Question'}`}
            </Button>
          </div>
        </div>

        <div className="hidden sm:block">
          <CompletedQuestionsList completedQuestions={completedQuestions} />
        </div>

        {/* END INTERVIEW MODAL */}
        <Modal
          backgroundVariant="redGradient"
          open={showEndInterviewModal}
          iconName="PhoneMissed"
          title="End Interview?"
          confirmExitText="End Interview"
          resumeText="Resume Interview"
          description="Not feeling the interview right now? You can jump back into this interview later from your Kerplunk dashboard."
          onResume={() => setShowInterviewModal(false)}
          onConfirm={handleEndInterview}
        />
      </div>
    </div>
  );
}

export { InterviewQuestions };
