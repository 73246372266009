import { icons } from '@kerplunkai/common-components';

export const INITIAL_LOGIN = {
  email: '',
  password: '',
};

export const INITIAL_SIGNUP = {
  email: '',
  first_name: '',
  last_name: '',
  password: '',
};

interface IDataItem {
  id: number;
  text: string;
  icon: keyof typeof icons;
}
export const COMPLIANCE_DATA: IDataItem[] = [
  { id: 1, text: 'GDPR', icon: 'Check' },
  { id: 2, text: 'CCPA', icon: 'Check' },
  { id: 3, text: 'LGPD', icon: 'Check' },
  { id: 4, text: 'PIPEDA', icon: 'Check' },
];
