import clsx from 'clsx';
import {
  Button,
  FormInput,
  FormLabel,
  Lucide,
  Tippy,
} from '@kerplunkai/common-components';
import { useCopyToClipboard } from 'usehooks-ts';

import { IntegrationFormProps } from '@typings';
import { darkGreen, lightGreen, medGreen } from '@theme';

export interface ZapierFormProps extends IntegrationFormProps {}

function ZapierForm({
  errors,
  integration,
  integrationType,
  isLoading,
  isNew = false,
  values,
  onChange,
  onDelete,
}: ZapierFormProps) {
  const [copiedText, copy] = useCopyToClipboard();

  return (
    <div className="flex flex-col gap-6">
      {isNew && (
        <div className="flex max-w-full flex-col gap-3.5">
          <FormLabel htmlFor="name">
            <div className="flex">
              Integration Name
              <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-400 dark:bg-darkmode-300 dark:text-slate-400">
                Required
              </div>
            </div>
            <div className="mt-1.5 text-left text-xs text-slate-500">
              Input the name of your&nbsp;{integrationType?.name}
              &nbsp;integration
            </div>
          </FormLabel>
          <div>
            <FormInput
              id="name"
              disabled={isLoading}
              error={Boolean(errors.name)}
              name="name"
              placeholder="Integration Name"
              value={values.name}
              onChange={onChange}
            />
            {Boolean(errors.name) && (
              <div className="mt-2 text-left text-xs text-danger">
                {errors.name}
              </div>
            )}
          </div>
        </div>
      )}
      {integration && (
        <div className="flex max-w-full flex-col gap-3.5">
          <div className="text-md font-medium">{integration.name}</div>

          <div className="flex gap-6">
            <div className="flex flex-1">
              <FormInput
                containerClass="flex-1 flex gap-3"
                className={clsx(
                  'mr-2 flex-1',
                  Boolean(copiedText) &&
                    `disabled:border-[${medGreen}] disabled:bg-[${lightGreen}]/10 disabled:text-[${darkGreen}]`,
                )}
                disabled
                name="api_key"
                value={integration?.zapier_api_key || ''}
              />
              {!integration?.zapier_api_key?.startsWith('...') && (
                <Tippy
                  as={Button}
                  className={clsx(
                    'mr-2 border-slate-200 bg-slate-50 p-1.5',
                    Boolean(copiedText) &&
                      `disabled:border-[${medGreen}] disabled:bg-[${lightGreen}]/10 disabled:text-[${darkGreen}]`,
                  )}
                  disabled={Boolean(copiedText)}
                  content="Copy Api Key"
                  variant="outline-secondary"
                  onClick={() => copy(integration?.zapier_api_key || '')}
                >
                  <Lucide
                    icon={copiedText ? 'Check' : 'Copy'}
                    className={clsx(
                      'size-6 stroke-[1.5] ',
                      copiedText ? `stroke-[${darkGreen}]` : 'stroke-slate-500',
                    )}
                  />
                </Tippy>
              )}
            </div>
            <Button
              className="border-orange-700 bg-orange-700/80 px-3 py-2 text-white"
              disabled={isLoading}
              variant="soft-sky-outline"
              onClick={onDelete}
            >
              <div className="flex gap-1.5 font-normal">
                <Lucide icon="X" />
                Disconnect
              </div>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export { ZapierForm };
