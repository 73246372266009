import { Outlet, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { NAV_ROUTES } from '@constants';
import { selectSelectedOrg } from '@store/selectors';

function ClientsLayout() {
  const navigate = useNavigate();

  const selectedOrg = useSelector(selectSelectedOrg);

  useEffect(() => {
    if (selectedOrg?.industry.name !== 'Recruiting') navigate(NAV_ROUTES.JOBS);
  }, [navigate, selectedOrg]);

  return (
    <div>
      <Outlet />
    </div>
  );
}

export { ClientsLayout };
