import { FormActions, FormCard } from '@kerplunkai/common-components';
import { FormikProps } from 'formik';
import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { OrganizationDetailsForm } from '@modules/organization/organizationCreateStep/organizationDetailsForm.component';
import { OrganizationRequest } from '@typings';
import { mapOrganizationToRequest } from '@utilities';
import { selectSelectedOrg } from '@store/selectors';
import { useUpdateOrganizationMutation } from '@store/services';

import { setSelectedOrg } from '@store/slices';
import { useAppDispatch } from '@store/hooks';

function OrganizationDetails() {
  const orgFormRef = useRef<FormikProps<OrganizationRequest>>(null);
  const dispatch = useAppDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const selectedOrg = useSelector(selectSelectedOrg);

  const [updateOrg, { isLoading }] = useUpdateOrganizationMutation();

  useEffect(() => {
    if (selectedOrg)
      orgFormRef.current?.setValues(mapOrganizationToRequest(selectedOrg));
  }, [selectedOrg]);

  const handleSubmitOrg = useCallback(async () => {
    const validation = await orgFormRef.current?.validateForm();
    const values = orgFormRef.current?.values;

    if (
      (validation &&
        !Object.keys(validation).every(
          key =>
            validation && (validation as { [key: string]: string })[key] === '',
        )) ||
      !values
    )
      return;

    try {
      const result = await updateOrg({
        organizationId: selectedOrg?.id as string,
        ...values,
      }).unwrap();

      enqueueSnackbar({
        message: 'Organization updated successfully!',
        variant: 'success',
      });

      dispatch(setSelectedOrg(result));
    } catch (e) {
      enqueueSnackbar({
        message: 'Unable to update organization. Please try again.',
        variant: 'error',
      });
    }
  }, [updateOrg, selectedOrg?.id, dispatch, enqueueSnackbar]);

  return (
    <div className="relative col-span-12 flex flex-col gap-y-7 lg:col-span-9 xl:col-span-8">
      <FormCard
        className="border-0 p-0"
        hideAccordion
        title="Organization Information"
      >
        <OrganizationDetailsForm
          formRef={orgFormRef}
          inputsDisabled={{ industry_id: true }}
          isLoading={isLoading}
          isUpdate // modifies validation logic for allowing updates
        />
      </FormCard>
      <FormActions
        submit={{
          disabled: isLoading,
          icon: 'Send',
          text: isLoading ? 'Saving' : 'Save',
          onClick: handleSubmitOrg,
        }}
      />
    </div>
  );
}

export { OrganizationDetails };
