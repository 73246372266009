import { Disclosure } from '@kerplunkai/common-components';
import { InterviewQuestionAnalysis } from '@typings';
import { InterviewTranscriptAnalysis } from '@modules/interviewDetails/components';

interface InterviewTranscriptAnalysesProps {
  analyses: InterviewQuestionAnalysis[];
  searchTerm?: string;
}

function InterviewTranscriptAnalyses({
  analyses,
  searchTerm,
}: InterviewTranscriptAnalysesProps) {
  return (
    <Disclosure.Group variant="boxed" openByDefault>
      {[...analyses]
        .sort((a, b) => a.position - b.position)
        .map(analysis => {
          return (
            <InterviewTranscriptAnalysis
              key={analysis.id}
              analysis={analysis}
              searchTerm={searchTerm}
            />
          );
        })}
    </Disclosure.Group>
  );
}

export { InterviewTranscriptAnalyses };
