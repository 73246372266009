import { OrgUser, OrgUserStatus, UserRoleStyles } from '@typings';

export const getRoleStyles = (user: OrgUser): UserRoleStyles => {
  switch (user.status) {
    case OrgUserStatus.accepted:
      return {
        background: 'bg-sky-800/10',
        border: 'border-sky-800/10',
        text: 'text-sky-800',
        labelText: 'Admin',
      };

    case OrgUserStatus.invited:
      return {
        background: 'bg-[#075985]/10',
        border: 'border-[#075985]/10',
        text: 'text-[#075985]',
        labelText: 'Invited',
      };

    case OrgUserStatus.owner:
      return {
        background: 'bg-[#558408]/10',
        border: 'border-[#558408]/10',
        text: 'text-[#558408]',
        labelText: 'Owner',
      };

    default:
      return {
        background: 'bg-orange-700/10',
        border: 'border-orange-700/10',
        text: 'text-orange-700',
        labelText: 'Removed',
      };
  }
};
