/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
import {
  Button,
  FormSelect,
  FormTextarea,
  Lucide,
  TableBase,
} from '@kerplunkai/common-components';
import { CSS } from '@dnd-kit/utilities';
import { cloneDeep } from 'lodash';
import { twMerge } from 'tailwind-merge';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useSortable } from '@dnd-kit/sortable';

import { CodeBlock, JobQuestion } from '@typings';
import { CodeMirrorBlock } from '@components';
import { CorrectAnswers } from '@modules/jobs/jobForms/jobInterviewQuestions/correctAnswers.component';
import { selectJobQCategoryTypes } from '@store/selectors';

interface JobInterviewQuestionProps {
  index: number;
  isLoading: boolean;
  orgId: string;
  question: JobQuestion;
  onQuestionChange: (
    value: string | CodeBlock[],
    name: string,
    index: number,
  ) => void;
  onAnswerChange: (value: string, answerIndex: number, index: number) => void;
  onRemoveQuestion: (index: number) => void;
  onRemoveAnswer: (questionIndex: number, answerIndex: number) => void;
  onToggleLock: (index: number) => void;
  onAnswerclear: (index: number) => void;
}

function JobInterviewQuestion({
  index,
  isLoading,
  orgId,
  question,
  onQuestionChange,
  onRemoveQuestion,
  onToggleLock,
  onAnswerclear,
  onRemoveAnswer,
  onAnswerChange,
}: JobInterviewQuestionProps) {
  const isInputFilled = !!question.suggested_answers?.length;
  const hasSuggestedAnswers = !!question?.suggested_answers?.some(
    answer => answer,
  );
  const handleAddCorrectAnswer = () => {
    onAnswerChange('', question.suggested_answers?.length || 0, index);
  };

  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
    isDragging,
  } = useSortable({
    id: question.position,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '38px';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [question.content]);

  const categoryTypes = useSelector(selectJobQCategoryTypes(orgId as string));

  const handleBookmarkClick = () => {
    if (!isInputFilled) {
      handleAddCorrectAnswer();
      return;
    }

    if (!hasSuggestedAnswers) {
      onAnswerclear(index);
    }
  };

  return (
    <>
      <TableBase.Tr ref={setNodeRef} style={style}>
        {isDragging ? (
          <TableBase.Td
            style={{ background: 'rgba(127, 207, 250, 0.3)' }}
            colSpan={4}
          >
            &nbsp;
          </TableBase.Td>
        ) : (
          <>
            <TableBase.Td className="whitespace-nowrap text-center">
              {index + 1}
            </TableBase.Td>
            <TableBase.Td
              className={twMerge([
                question.generated_by_ai || question.locked
                  ? 'min-w-40 text-left !pr-0'
                  : 'min-w-40 !pr-0',
              ])}
            >
              {question.generated_by_ai || question.locked ? (
                (categoryTypes || {})[question.category]
              ) : (
                <FormSelect
                  disabled={isLoading}
                  name="category"
                  className="text-slate-500"
                  value={question.category}
                  onChange={({ target: { name, value } }) =>
                    onQuestionChange(value, name, question.position - 1)
                  }
                >
                  {categoryTypes &&
                    Object.keys(categoryTypes).map(value => (
                      <option key={value} value={value}>
                        {categoryTypes[value]}
                      </option>
                    ))}
                </FormSelect>
              )}
            </TableBase.Td>

            <TableBase.Td
              className="whitespace-nowrap !pr-0 text-right "
              onClick={handleBookmarkClick}
            >
              <Lucide
                icon="BookmarkCheck"
                className={twMerge([
                  isInputFilled
                    ? 'fill-[#558408] text-white'
                    : 'fill-none text-slate-400',
                  hasSuggestedAnswers &&
                    'pointer-events-none fill-slate-400 opacity-50',
                ])}
              />
            </TableBase.Td>
            <TableBase.Td className="w-full min-w-72 !pl-3">
              <div className="flex items-center">
                <FormTextarea
                  ref={textareaRef}
                  className={
                    question.locked
                      ? 'resize-none  disabled:border-[#A0D846] disabled:bg-lime-400/10 disabled:text-[#558408] disabled:placeholder-[#558408]'
                      : 'resize-none text-slate-500'
                  }
                  disabled={isLoading || question.locked}
                  name="content"
                  placeholder="Enter your question here"
                  value={question.content}
                  onChange={({ target: { name, value } }) =>
                    onQuestionChange(value, name, question.position - 1)
                  }
                />
              </div>
              {question.code_blocks
                ?.filter(({ content, language }) => content && language)
                .map((codeBlock, curIndex) => (
                  <div key={`code-block-${curIndex}`} className="mt-2">
                    <CodeMirrorBlock
                      block={codeBlock}
                      onChange={(value: string) => {
                        const newCodeBlocks = cloneDeep(
                          question.code_blocks || [],
                        );

                        newCodeBlocks[curIndex].content = value;

                        onQuestionChange(
                          newCodeBlocks,
                          'code_blocks',
                          question.position - 1,
                        );
                      }}
                    />
                  </div>
                ))}
            </TableBase.Td>
            <TableBase.Td className="min-w-32 whitespace-nowrap !pl-4 text-slate-500">
              <div className="flex justify-between">
                <Button
                  disabled={isLoading}
                  variant="text"
                  onClick={() => onToggleLock(index)}
                >
                  <Lucide
                    color={question.locked ? '#558408' : '#64748B'}
                    icon={question.locked ? 'LockKeyhole' : 'UnlockKeyhole'}
                    className={`size-5 stroke-[1.3] ${
                      question.locked ? 'text-[#558408]' : 'stroke-slate-500'
                    }`}
                  />
                </Button>
                <Button
                  disabled={isLoading}
                  variant="text"
                  {...attributes}
                  {...listeners}
                >
                  <Lucide
                    icon="Move"
                    className="size-5 stroke-slate-500 stroke-[1.3]"
                  />
                </Button>
                <Button
                  disabled={isLoading}
                  variant="text"
                  onClick={() => onRemoveQuestion(index)}
                >
                  <Lucide
                    icon="Trash2"
                    className="size-5 stroke-slate-500 stroke-[1.3]"
                  />
                </Button>
              </div>
            </TableBase.Td>
          </>
        )}
      </TableBase.Tr>
      {isInputFilled && (
        <TableBase.Tr className="bg-[#F4F6F8]">
          <TableBase.Td className="!whitespace-normal align-top" colSpan={2}>
            <div className="flex flex-col">
              <div className="flex items-start font-medium text-slate-600">
                Suggested Answers
                <span className="ml-2.5 rounded-md border border-[#4620CA]/20 bg-[#F4EFFC] px-2 py-0.5 text-xs text-[#4620CA] ">
                  Beta
                </span>
              </div>
              <div className="mt-1.5 text-xs leading-relaxed text-slate-500">
                Assess a candidates response to the question above. Suggested
                answers are based on their similarity to the expected answers.
              </div>
            </div>
          </TableBase.Td>
          <TableBase.Td />
          <TableBase.Td className="w-full border-l !px-0" colSpan={3}>
            {question.suggested_answers?.map((answer, i) => {
              return (
                <CorrectAnswers
                  key={i}
                  answerIndex={i}
                  questionIndex={index}
                  value={answer || ''}
                  onRemoveAnswer={onRemoveAnswer}
                  isLoading={isLoading}
                  onChange={value =>
                    onAnswerChange(value, i, question.position - 1)
                  }
                />
              );
            })}
            <div className="mb-auto w-full px-3">
              <Button
                className="mt-3 w-full border-dashed border-[#558408] text-[#558408]"
                disabled={isLoading}
                variant="soft-sky-outline"
                onClick={handleAddCorrectAnswer}
              >
                <Lucide icon="Plus" className="mr-2 size-4 stroke-[1.3]" />
                Add Another Answer
              </Button>
            </div>
          </TableBase.Td>
        </TableBase.Tr>
      )}
    </>
  );
}

export { JobInterviewQuestion };
