import clsx from 'clsx';
import { Disclosure, Lucide } from '@kerplunkai/common-components';
import { ReactNode, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { CurrentSubscriptionSummary } from '@modules/organization/organizationSettings/currentSubscriptionSummary.component';
import { selectProducts } from '@store/selectors/stripe.selectors';
import { selectSelectedOrg, selectSelectedOrgId } from '@store/selectors';
import {
  useSubscribeToPlanMutation,
  useSubscriptionsQuery,
} from '@store/services';

function OrganizationSubscription() {
  const { enqueueSnackbar } = useSnackbar();

  const orgId = useSelector(selectSelectedOrgId);
  const products = useSelector(selectProducts(orgId as string));
  const org = useSelector(selectSelectedOrg);

  const [subscribeToPlan, { isLoading }] = useSubscribeToPlanMutation();
  const { isLoading: isGettingSubscription } = useSubscriptionsQuery(
    orgId as string,
  );

  const handleSelectPlan = useCallback(
    async (price_id: string) => {
      try {
        const response = await subscribeToPlan({
          orgId: orgId as string,
          price_id,
        }).unwrap();

        if (response?.url) {
          window.open(response.url, '_self');
        }
      } catch (e) {
        enqueueSnackbar({
          message: 'Unable to subsribe to plan. Please try again.',
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, subscribeToPlan, orgId],
  );

  return (
    <div className="relative col-span-12 flex flex-col gap-y-7 lg:col-span-9 xl:col-span-8">
      {org?.subscription.has_subscription ? (
        <CurrentSubscriptionSummary org={org} />
      ) : (
        <div className="box box--stacked flex flex-col">
          <Disclosure.Group variant="boxed" className="border-0">
            <Disclosure>
              <Disclosure.Button disabled>
                <div className="flex items-center border-b border-dashed border-slate-200/60 pb-5 text-base font-medium text-[#181D2C]">
                  Sign Up for Free
                </div>
              </Disclosure.Button>
              <Disclosure.Panel>
                <div className="text-sm text-slate-600">
                  For pricing, please visit our{' '}
                  <a
                    href="https://kerplunk.com/enterprise-pricing#pricing"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 underline"
                  >
                    pricing page
                  </a>
                  .
                </div>
              </Disclosure.Panel>
            </Disclosure>
          </Disclosure.Group>
        </div>
      )}

      {false && (
        <div className="box box--stacked flex flex-col">
          <Disclosure.Group variant="boxed" className="border-0">
            <Disclosure>
              <Disclosure.Button disabled>
                <div className="flex items-center border-b border-dashed border-slate-200/60 pb-5 text-base font-medium text-[#181D2C]">
                  Change Subscription
                </div>
              </Disclosure.Button>
              <Disclosure.Panel>
                <div className="grid grid-cols-12 gap-5">
                  {Object.keys(products || {}).reduce((acc, productsKey) => {
                    const product = (products || {})[productsKey];
                    const tier = product[0].tier_name;

                    acc.push(
                      <div
                        key={productsKey}
                        className="col-span-12 flex flex-col gap-5 rounded-lg border border-slate-200 p-5 md:col-span-4"
                        style={{
                          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.02)',
                        }}
                      >
                        <div className="text-center text-sm capitalize text-[#181D2C]">
                          {tier}
                        </div>
                        <div className="flex flex-col gap-5">
                          {product.map(productOption => (
                            <div
                              key={productOption.id}
                              className={clsx(
                                'flex flex-col gap-6 rounded-lg border border-slate-200 p-5',
                                false && 'border-[#A0D846] bg-[#A9F72A1A]',
                              )}
                              style={{
                                boxShadow:
                                  '0px 4px 4px 0px rgba(0, 0, 0, 0.02)',
                              }}
                            >
                              <div className="flex items-center justify-between">
                                <div>
                                  <div className="text-xs font-semibold capitalize text-slate-600">
                                    {productOption.tier_name}
                                  </div>
                                  <div className="text-base font-semibold">
                                    $
                                    {(
                                      productOption.estimated_price_cents / 100
                                    ).toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                    })}
                                    &nbsp;
                                    {productOption.nickname.split(' ')[1]}
                                  </div>
                                </div>
                                {false && (
                                  <Lucide
                                    icon="CheckCircle2"
                                    className="size-7"
                                  />
                                )}
                              </div>
                              <div className="flex justify-between">
                                <div className="text-sm font-normal text-slate-500">
                                  Interviews Processed per User
                                </div>
                                <div className="text-sm font-semibold text-[#181D2C]">
                                  {productOption.interview_count_per_user_per_month.toLocaleString()}
                                </div>
                              </div>
                              <button
                                className="w-full rounded-md border border-slate-400 px-3.5 py-[9px] text-sm font-semibold text-slate-500"
                                disabled={isLoading || isGettingSubscription}
                                style={{
                                  boxShadow:
                                    '0px 4px 4px 0px rgba(0, 0, 0, 0.02)',
                                }}
                                onClick={() =>
                                  handleSelectPlan(productOption.id)
                                }
                              >
                                Change Subscription
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>,
                    );

                    return acc;
                  }, [] as ReactNode[])}
                </div>
              </Disclosure.Panel>
            </Disclosure>
          </Disclosure.Group>
        </div>
      )}
    </div>
  );
}

export { OrganizationSubscription };
