import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { selectCurrentUser } from '@store/selectors';

function ProfileHeader() {
  const currentUser = useSelector(selectCurrentUser);

  return (
    <div className="box box--stacked flex flex-col p-1.5">
      <div className="relative h-60 w-full rounded-[0.6rem] bg-gradient-to-b from-theme-1/95 to-theme-2/95">
        <div
          // eslint-disable-next-line tailwindcss/enforces-negative-arbitrary-values
          className={clsx([
            'relative size-full overflow-hidden',
            "before:absolute before:inset-0 before:-mt-[50rem] before:bg-texture-white before:content-['']",
            "after:absolute after:inset-0 after:-mt-[50rem] after:bg-texture-white after:content-['']",
          ])}
        />
        <div className="absolute inset-x-0 top-0 mx-auto mt-36 size-32">
          <div className="box image-fit size-full overflow-hidden rounded-full border-[6px] border-white">
            <img alt="Profile Avatar" src={currentUser?.avatar_url} />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-y-3 rounded-[0.6rem] bg-slate-50 p-5 pt-12 sm:flex-row sm:items-end">
        <div className="mt-6 grow justify-center text-center text-xl">
          {currentUser?.first_name} {currentUser?.last_name}
        </div>
      </div>
    </div>
  );
}

export { ProfileHeader };
