/* eslint-disable no-underscore-dangle */
import { useEffect, useRef } from 'react';

import { Button, FormTextarea, Lucide } from '@kerplunkai/common-components';

interface JobInterviewAnswersProps {
  answerIndex: number;
  questionIndex: number;
  isLoading: boolean;
  value: string;
  onChange: (value: string) => void;
  onRemoveAnswer: (questionIndex: number, answerIndex: number) => void;
}

function CorrectAnswers({
  isLoading,
  value,
  answerIndex,
  questionIndex,
  onChange,
  onRemoveAnswer,
}: JobInterviewAnswersProps) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '38px';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [value]);

  return (
    <div className="flex w-full items-center border-b p-3">
      <FormTextarea
        ref={textareaRef}
        className="resize-none text-slate-500 "
        disabled={isLoading}
        name="suggested_answers"
        placeholder="Enter a suggested correct answer here"
        value={value}
        onChange={e => onChange(e.target.value)}
      />
      <Button
        className="ml-4"
        disabled={isLoading}
        variant="text"
        onClick={() => onRemoveAnswer(questionIndex, answerIndex)}
      >
        <Lucide
          icon="Trash2"
          className="size-5 stroke-slate-500 stroke-[1.3]"
        />
      </Button>
    </div>
  );
}

export { CorrectAnswers };
