/* eslint-disable consistent-return */
import clsx from 'clsx';
import { Breadcrumbs, Button, Lucide } from '@kerplunkai/common-components';
import { MouseEvent } from 'react';

import { OrganizationMenu, ProfileMenu } from '@modules/layout/header';
import { setActiveMobileMenu } from '@store/slices';
import { useAppDispatch } from '@store/hooks';
import { useMatches } from 'react-router-dom';

interface HeaderProps {
  topBarActive: boolean;
}

function Header({ topBarActive }: HeaderProps) {
  const dispatch = useAppDispatch();
  const matches = useMatches();

  return (
    <div className="fixed inset-x-0 top-0 mt-3.5 h-[65px] transition-[margin] duration-100 xl:ml-[275px] group-[.side-menu--collapsed]:xl:ml-[90px]">
      <div
        className={clsx([
          'top-bar group absolute inset-x-0 mx-5 h-full xl:left-3.5',
          "before:absolute before:inset-x-0 before:top-0 before:-mt-[15px] before:h-[20px] before:backdrop-blur before:content-['']",
          topBarActive && 'top-bar--active',
        ])}
      >
        <div
          className="
                box group-[.top-bar--active]:box container flex size-full items-center border-transparent shadow-none transition-[padding,background-color,border-color] duration-300 ease-in-out 
                group-[.top-bar--active]:border-transparent group-[.top-bar--active]:bg-transparent
                group-[.top-bar--active]:bg-gradient-to-r group-[.top-bar--active]:from-theme-1 group-[.top-bar--active]:to-theme-2 group-[.top-bar--active]:px-5
              "
          style={{ backgroundColor: 'transparent' }}
        >
          <div className="flex items-center gap-1 xl:hidden">
            <Button
              as="a"
              className="rounded-full p-2 text-white hover:bg-white/5"
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                dispatch(setActiveMobileMenu(true));
              }}
            >
              <Lucide icon="AlignJustify" className="size-[18px]" />
            </Button>
          </div>
          <div className="ml-4 hidden md:block xl:ml-0">
            <Breadcrumbs matches={matches} />
          </div>
          <div className="flex flex-1 items-center">
            <div className="ml-auto flex items-center gap-1" />
            <OrganizationMenu />
            <ProfileMenu />
          </div>
        </div>
      </div>
    </div>
  );
}

export { Header };
