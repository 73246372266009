import { ReactNode } from 'react';

interface JobSummaryGroupItemProps {
  label: string;
  value: string | ReactNode;
}

function JobSummaryGroupItem({ label, value }: JobSummaryGroupItemProps) {
  return (
    <div className="flex gap-1.5">
      <p className="text-xs text-slate-500">{label}</p>
      {typeof value === 'string' ? (
        <p className="overflow-auto text-ellipsis text-xs font-medium text-slate-600">
          {value}
        </p>
      ) : (
        value
      )}
    </div>
  );
}

export { JobSummaryGroupItem };
