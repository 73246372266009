import { Outlet } from 'react-router-dom';
import { useEffect } from 'react';

import { ProfileHeader } from '@modules/profile/profileHeader.component';
import { ProfileMenu } from '@modules/profile/profileMenu.component';
import { useLazyUserQuery } from '@store/services';

function ProfileLayout() {
  const [getUser] = useLazyUserQuery();

  // ensure we load user details from scratch
  useEffect(() => {
    getUser();
  }, [getUser]);

  return (
    <div className="grid grid-cols-12 gap-x-6 gap-y-10">
      <div className="col-span-12">
        <ProfileHeader />
      </div>
      <div className="col-span-12 mt-3.5 grid grid-cols-12 gap-x-6 gap-y-10">
        <div className="relative col-span-12 xl:col-span-3">
          <ProfileMenu />
        </div>
        <div className="col-span-12 flex flex-col gap-y-7 xl:col-span-9">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export { ProfileLayout };
