import { Lucide } from '@kerplunkai/common-components';

import { JobQuestion } from '@typings';

interface CompletedQuestionListProps {
  completedQuestions?: JobQuestion[];
}

export function CompletedQuestionsList({
  completedQuestions,
}: CompletedQuestionListProps) {
  return (
    completedQuestions &&
    completedQuestions.length > 0 && (
      <>
        <div className="text ml-2 flex flex-row text-sm font-semibold text-white">
          <hr className="border-1 my-8 h-px w-full bg-slate-400 dark:bg-gray-700" />
        </div>
        <div className="text ml-2 flex flex-row text-sm font-semibold text-white">
          <div className="flex grow self-center">
            <Lucide className="mr-2" icon="CheckCircle2" />
            Previous Questions
          </div>
        </div>
        {completedQuestions &&
          completedQuestions.map(q => {
            return (
              <div key={q.content} className=" flex grow pt-8 text-slate-300">
                <div className="self-center">
                  <Lucide
                    className="size-8 stroke-[1.5] text-lime-400"
                    icon="CheckCircle2"
                  />
                </div>
                <div className="ml-2 self-center text-slate-400">
                  {q.content}
                </div>
              </div>
            );
          })}
      </>
    )
  );
}
