import { createSelector } from '@reduxjs/toolkit';
import { organizationService } from '@store/services';

export const selectOrganizations = () =>
  createSelector(
    organizationService.endpoints.organizations.select(),
    queryState => queryState?.data?.results,
  );

export const selectOrganizationsDetails = () =>
  createSelector(
    organizationService.endpoints.organizationsDetails.select(),
    queryState => queryState?.data,
  );

export const selectOrganizationIndustries = () =>
  createSelector(
    organizationService.endpoints.organizationsDetails.select(),
    queryState => queryState?.data?.all_industries,
  );

export const selectOrganizationsLoading = () =>
  createSelector(
    organizationService.endpoints.organizations.select(),
    queryState => queryState?.isLoading,
  );
