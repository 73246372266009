import { ChangeEvent, useCallback, useMemo } from 'react';
import {
  FormControl,
  FormInput,
  FormInputControl,
  HireprosLitepicker,
} from '@kerplunkai/common-components';
import { useField } from 'formik';
import { useMask } from '@react-input/mask';

import { InterviewRequest } from '@typings';

interface InterviewDetailsFormProps {
  interview: InterviewRequest;
  isLoading: boolean;
  onChange: (name: string, value: string) => void;
}

function InterviewDetailsForm({
  interview,
  isLoading,
  onChange,
}: InterviewDetailsFormProps) {
  const is24Hr = (interview.interview_at_time || '').startsWith('2');

  const inputRef = useMask({
    mask: is24Hr ? 'Hh:Xx' : 'Hh:Xx Ss',
    replacement: {
      H: /[0-2]/,
      h: is24Hr ? /[0-4]/ : /\d/,
      X: /[0-5]/,
      x: /\d/,
      S: /[AaPp]/,
      s: /[Mm]/,
    },
  });

  const [, titleMeta] = useField('title');
  const [, dayStartMeta] = useField('interview_at_day');
  const [, timeStartMeta] = useField('interview_at_time');

  const meetingStartError = useMemo(() => {
    if (!dayStartMeta.error && !timeStartMeta.error) return undefined;

    return dayStartMeta.error && timeStartMeta.error
      ? 'Meeting Start is required'
      : dayStartMeta.error || timeStartMeta.error;
  }, [dayStartMeta, timeStartMeta]);

  const handleInputChange = useCallback(
    ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
      onChange(name, value);
    },
    [onChange],
  );

  return (
    <div className="mt-5">
      <FormInputControl
        className="mt-5"
        description="Enter a title for this meeting."
        errorText={titleMeta.error}
        helpText={`Maximum characters ${interview.title.length}/70`}
        inputProps={{ disabled: isLoading, onChange: handleInputChange }}
        isRequired
        label="Meeting Title"
        name="title"
        value={interview.title}
      />
      <FormInputControl
        className="mt-5"
        description="Enter a description for this meeting."
        inputProps={{ disabled: isLoading, onChange: handleInputChange }}
        label="Meeting Description"
        name="description"
        value={interview.description}
      />
      <FormControl
        className="mt-5"
        description="Enter a start date and time for this meeting."
        label="Meeting Start"
        isRequired
      >
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-3">
            <HireprosLitepicker
              value={interview.interview_at_day || ''}
              disabled={isLoading}
              onChange={e => onChange('interview_at_day', e.target.value)}
              options={{
                autoApply: false,
                singleMode: true,
                numberOfColumns: 1,
                numberOfMonths: 1,
                dropdowns: {
                  minYear: 2023,
                  maxYear: null,
                  months: true,
                  years: true,
                },
                format: 'MMM D, YYYY',
              }}
              className="rounded-lg text-slate-500"
            />
          </div>
          <div className="col-span-3">
            <FormInput
              ref={inputRef}
              className="text-slate-600"
              disabled={isLoading}
              name="interview_at_time"
              placeholder="HH:MM AM"
              value={interview.interview_at_time}
              onChange={handleInputChange}
            />
          </div>
        </div>
        {meetingStartError && (
          <div className="mt-4 text-xs text-danger">{meetingStartError}</div>
        )}
      </FormControl>
    </div>
  );
}

export { InterviewDetailsForm };
