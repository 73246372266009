/* eslint-disable react/no-array-index-key */
import { Disclosure, Lucide } from '@kerplunkai/common-components';
import { useSelector } from 'react-redux';

import { CodeMirrorBlock } from '@components';
import { InterviewJobListing } from '@modules/meetings/components';
import { InterviewRequest } from '@typings';
import { selectJobQCategoryTypes, selectSelectedOrgId } from '@store/selectors';

interface MeetingQuestionsProps {
  interview: InterviewRequest;
}

function MeetingQuestions({ interview }: MeetingQuestionsProps) {
  const orgId = useSelector(selectSelectedOrgId);
  const categoryTypes = useSelector(selectJobQCategoryTypes(orgId as string));

  return (
    <div className="box box--stacked flex flex-col">
      {interview.job_listing && (
        <div className="px-5">
          <InterviewJobListing jobListing={interview.job_listing} isPreview />
        </div>
      )}
      <div className="rounded-[0.6rem] border-dashed border-slate-200/60 p-5 dark:border-darkmode-400">
        <Disclosure.Group variant="boxed">
          <Disclosure className="rounded-[0.6rem]">
            <Disclosure.Button>
              <div className="flex items-center border-b border-dashed border-slate-200/60 pb-5 text-[0.94rem] font-medium text-[#181D2C] ">
                <Lucide
                  icon="ChevronDown"
                  className="mr-2 size-5 stroke-[1.3]"
                />
                Interview Questions&nbsp;✨
              </div>
            </Disclosure.Button>
            <Disclosure.Panel>
              {interview.job_listing?.interview_questions.map(
                (question, index) => (
                  <div
                    key={question.position}
                    className="block flex-col pt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center"
                  >
                    <div className="mb-2 inline-block sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                      <div className="text-left">
                        <div className="flex items-center">
                          <div className="font-medium text-slate-600">
                            Question&nbsp;{index + 1}
                          </div>
                          <div className="ml-2.5 rounded-md border border-sky-800/10 bg-sky-800/10 px-2 py-0.5 text-xs text-sky-800">
                            {(categoryTypes || {})[question.category]}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-3 w-full flex-1 font-medium text-slate-600 xl:mt-0">
                      {question.content}
                      {question.code_blocks
                        ?.filter(({ content, language }) => content && language)
                        .map((codeBlock, curIndex) => (
                          <div key={`code-block-${curIndex}`} className="mt-2">
                            <CodeMirrorBlock
                              block={codeBlock}
                              editable={false}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                ),
              )}
            </Disclosure.Panel>
          </Disclosure>
        </Disclosure.Group>
      </div>
    </div>
  );
}

export { MeetingQuestions };
