import { Row } from '@tanstack/react-table';
import {
  TanstackTableAction,
  TanstackTableActionColumn,
} from '@kerplunkai/common-components';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { OrgUser } from '@typings';
import { selectSelectedOrgId } from '@store/selectors';
import {
  useCancelOrgInviteMutation,
  useCreateOrgUserMutation,
  useRemoveOrgUserMutation,
} from '@store/services';

function OrgUsersActionColumn({ row }: { row: Row<OrgUser> }) {
  const { original } = row;

  const { enqueueSnackbar } = useSnackbar();

  const [cancelInviteRequest] = useCancelOrgInviteMutation();
  const [createOrgUser] = useCreateOrgUserMutation();
  const [removeOrgUser] = useRemoveOrgUserMutation();

  const orgId = useSelector(selectSelectedOrgId);

  const handleCancelInvite = useCallback(async () => {
    try {
      await cancelInviteRequest({
        orgId: orgId as string,
        userInviteId: original.id,
      }).unwrap();

      enqueueSnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        children: (
          <div className="flex flex-col gap-1">
            <p className="text-sm text-[#364154]">Invite Cancelled</p>
            <p className="text-sm text-[#677489]">
              You have successfully cancelled the invitation. The invitee will
              no longer have access.
            </p>
          </div>
        ),
        icon: 'BellRing',
        variant: 'toast-notification',
      });
    } catch (e) {
      enqueueSnackbar({
        message: 'Unable to cancel invite. Please try again.',
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, orgId, original.id, cancelInviteRequest]);

  const handleResendInvite = useCallback(async () => {
    try {
      await createOrgUser({
        orgId: orgId as string,
        email: original.email,
      }).unwrap();

      enqueueSnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        children: (
          <div className="flex flex-col gap-1">
            <p className="text-sm text-[#364154]">Invite Resent</p>
            <p className="text-sm text-[#677489]">
              The invitation has been successfully resent. The invitee will
              receive a new invitation email shortly.
            </p>
          </div>
        ),
        icon: 'BellRing',
        variant: 'toast-notification',
      });
    } catch (e) {
      enqueueSnackbar({
        message: 'Unable to resend invite. Please try again.',
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, orgId, original, createOrgUser]);

  const handleRemoveUser = useCallback(async () => {
    try {
      await removeOrgUser({
        orgId: orgId as string,
        userId: original.id,
      }).unwrap();

      enqueueSnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        children: (
          <div className="flex flex-col gap-1">
            <p className="text-sm text-[#364154]">User Removed Successfully</p>
            <p className="text-sm text-[#677489]">
              The user has been successfully removed from your organization.
            </p>
          </div>
        ),
        icon: 'BellRing',
        variant: 'toast-notification',
      });
    } catch (e) {
      enqueueSnackbar({
        message: 'Unable to resend invite. Please try again.',
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, orgId, original, removeOrgUser]);

  const actions: TanstackTableAction<OrgUser>[] = useMemo(() => {
    switch (original.status) {
      case 'invited':
        return [
          {
            icon: 'X',
            text: 'Cancel Invite',
            onClick: handleCancelInvite,
          },
          {
            icon: 'Send',
            text: 'Resend Invite',
            onClick: handleResendInvite,
          },
        ];

      case 'accepted':
        return [
          {
            icon: 'X',
            text: 'Remove User',
            onClick: handleRemoveUser,
          },
        ];

      default:
        return [];
    }
  }, [
    original.status,
    handleCancelInvite,
    handleRemoveUser,
    handleResendInvite,
  ]);

  return <TanstackTableActionColumn data={row} actions={actions} />;
}

export { OrgUsersActionColumn };
