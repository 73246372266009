import ReactDOM from 'react-dom/client';
import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react';
import { IntercomProvider } from 'react-use-intercom';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { ToastNotification } from '@kerplunkai/common-components';

import { CONFERENCE } from '@constants';
import { RouterContainer } from '@router/router.container';
import { setInitialRoute } from '@store/slices';
import { store } from '@store/store';

import '@assets/css/app.css';

const config = {
  apiKey: import.meta.env.VITE_HONEYBADGER_API_KEY,
  environment: import.meta.env.VITE_ENV,
};

const honeybadger = Honeybadger.configure(config);

if (
  window.location.pathname &&
  !window.location.pathname.startsWith(`/${CONFERENCE}`)
) {
  store.dispatch(
    setInitialRoute(`${window.location.pathname}${window.location.search}`),
  );
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <HoneybadgerErrorBoundary honeybadger={honeybadger}>
    <Provider store={store}>
      <SnackbarProvider
        autoHideDuration={5000}
        Components={{
          'toast-notification': ToastNotification,
        }}
      >
        <IntercomProvider appId="tz0ajqx1" autoBoot={false}>
          <RouterContainer />
        </IntercomProvider>
      </SnackbarProvider>
    </Provider>
  </HoneybadgerErrorBoundary>,
);
