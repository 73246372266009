import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { Button, FormControl } from '@kerplunkai/common-components';
import { JobRequest } from '@typings';
import { cloneDeep } from 'lodash';
import {
  selectJobExperienceLevels,
  selectJobSchedule,
  selectJobTypes,
} from '@store/selectors';

interface JobAddDetailsFormProps {
  isLoading: boolean;
  job: JobRequest;
  orgId: string;
  onChange: (name: string, value: number | boolean | string | string[]) => void;
}

function JobAddDetailsForm({
  isLoading,
  job,
  orgId,
  onChange,
}: JobAddDetailsFormProps) {
  const jobTypes = useSelector(selectJobTypes(orgId));
  const experienceLevels = useSelector(selectJobExperienceLevels(orgId));
  const schedule = useSelector(selectJobSchedule(orgId));

  const handleMultipleChoiceChange = useCallback(
    (name: string, value: string) => {
      const selections = cloneDeep(job[name] as string[]);

      if (selections) {
        const index = selections.findIndex(selection => selection === value);

        if (index === -1) {
          selections.push(value);
        } else {
          selections.splice(index, 1);
        }

        onChange(name, selections);
      }
    },
    [job, onChange],
  );

  return (
    <div className="mt-5">
      {jobTypes && (
        <FormControl
          description="Add additional details for your job posting that are relevant to the candidate."
          childrenClassName="gap-y-4"
          className="mt-5"
          label="Job Type"
        >
          {Object.keys(jobTypes).map(value => (
            <Button
              key={value}
              className="mb-2 whitespace-nowrap [&:not(:last-child)]:mr-2"
              disabled={isLoading}
              variant={value === job.job_type ? 'primary' : 'soft-sky-outline'}
              onClick={() => onChange('job_type', value)}
            >
              {jobTypes[value]}
            </Button>
          ))}
        </FormControl>
      )}
      {experienceLevels && (
        <FormControl className="mt-5" label="Experience Level">
          {Object.keys(experienceLevels).map(value => (
            <Button
              key={value}
              className="mb-2 whitespace-nowrap [&:not(:last-child)]:mr-2"
              disabled={isLoading}
              variant={
                job.experience_levels.indexOf(value) !== -1
                  ? 'primary'
                  : 'soft-sky-outline'
              }
              onClick={() =>
                handleMultipleChoiceChange('experience_levels', value)
              }
            >
              {experienceLevels[value]}
            </Button>
          ))}
        </FormControl>
      )}
      {schedule && (
        <FormControl
          childrenClassName="gap-y-4"
          className="mt-5"
          label="Schedule"
        >
          {Object.keys(schedule).map(value => (
            <Button
              key={value}
              className="mb-2 whitespace-nowrap [&:not(:last-child)]:mr-2"
              disabled={isLoading}
              variant={
                job.schedules.indexOf(value) !== -1
                  ? 'primary'
                  : 'soft-sky-outline'
              }
              onClick={() => handleMultipleChoiceChange('schedules', value)}
            >
              {schedule[value]}
            </Button>
          ))}
        </FormControl>
      )}
    </div>
  );
}

export { JobAddDetailsForm };
