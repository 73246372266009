/* eslint-disable react/no-array-index-key */
import { Disclosure, Lucide } from '@kerplunkai/common-components';
import { InterviewQuestionAnalysis, InterviewQuestionCategory } from '@typings';

import { CodeMirrorBlock } from '@components';
import {
  InterviewCategoryChip,
  InterviewQuestionTranscriptDetails,
  InterviewQuestionTranscriptNotes,
  InterviewScoringChip,
} from '@modules/interviewDetails/components';

interface InterviewTranscriptAnalysisProps {
  analysis: InterviewQuestionAnalysis;
  searchTerm?: string;
}

function InterviewTranscriptAnalysis({
  analysis,
  searchTerm,
}: InterviewTranscriptAnalysisProps) {
  return (
    <Disclosure className="rounded-[0.6rem]">
      <Disclosure.Button>
        <div className="border-b border-dashed border-slate-200/60 pb-5 text-[0.94rem] font-medium">
          <div className="flex items-start text-base md:items-center">
            <div className="mr-2 size-5">
              <Lucide icon="ChevronDown" className="stroke-[1.3]" />
            </div>
            {analysis.content}
          </div>
          {analysis.code_blocks
            ?.filter(({ content, language }) => content && language)
            .map((codeBlock, curIndex) => (
              <div key={`code-block-${curIndex}`} className="ml-[28px] mt-2">
                <CodeMirrorBlock block={codeBlock} editable={false} />
              </div>
            ))}
          <div className="ml-[28px] mt-2 flex items-center">
            <div className="text-xs font-normal">
              Question {analysis.position}
            </div>
            {analysis.interview_question.category && (
              <InterviewCategoryChip
                categoryName={
                  InterviewQuestionCategory[
                    analysis.interview_question.category
                  ]
                }
                className="rounded-sm"
              />
            )}
            {analysis.interview_question.score != null && (
              <InterviewScoringChip
                score={analysis.interview_question.score}
                className="rounded-sm"
              />
            )}
          </div>
        </div>
      </Disclosure.Button>
      <Disclosure.Panel className="text-sm font-normal leading-relaxed text-slate-600 dark:text-slate-500">
        <div className="rounded-md">
          <InterviewQuestionTranscriptNotes
            interviewQuestion={analysis.interview_question}
            searchTerm={searchTerm}
          />
          <InterviewQuestionTranscriptDetails
            transcripts={analysis.transcript}
            searchTerm={searchTerm}
          />
        </div>
      </Disclosure.Panel>
    </Disclosure>
  );
}

export { InterviewTranscriptAnalysis };
