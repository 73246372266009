import { Button, Lucide, Tippy } from '@kerplunkai/common-components';
import {
  IconInfoBox,
  SocialLinksWithLabel,
} from '@modules/interviewDetails/components';
import { Interview } from '@typings';

interface CandidateContactPanelProps {
  interview?: Interview;
  candidatePhone?: string;
  showSendMessageButton: boolean;
}

function CandidateContactPanel({
  interview,
  candidatePhone,
  showSendMessageButton,
}: CandidateContactPanelProps) {
  const handleSendMessage = () => {
    console.log('send message to candidate id: ', interview?.candidate_id);
  };

  return (
    <div className="box mb-5 p-5">
      <div className="text-base font-medium">{`Contact ${interview?.candidate_name}`}</div>
      <IconInfoBox
        iconClassName="stroke-sky-800"
        containerClasses="my-3"
        icon="Mail"
        primaryText="Email"
        secondaryText={interview?.candidate_email || '--'}
        onClick={() => {
          window.location.href = `mailto:${interview?.candidate_email}`;
        }}
      />
      {candidatePhone && (
        <IconInfoBox
          iconClassName="stroke-sky-800"
          containerClasses="my-3"
          icon="Smartphone"
          primaryText="Phone Number"
          secondaryText={candidatePhone}
          onClick={() => {
            window.location.href = `tel:${candidatePhone}`;
          }}
        />
      )}
      <div className="my-3 flex">
        <SocialLinksWithLabel interview={interview} />
      </div>
      {showSendMessageButton && (
        <Tippy as="div" content="Coming soon">
          <Button
            className="w-full"
            size="lg"
            variant="primary"
            onClick={handleSendMessage}
          >
            <Lucide className="stroke-[1.5]" icon="MessageCircle" />
            <div className="ml-2 text-sm">Send Message</div>
          </Button>
        </Tippy>
      )}
    </div>
  );
}

export { CandidateContactPanel };
