import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Organization } from '@typings';
// import { organizationService } from '@store/services';

type LayoutState = {
  activeMobileMenu: boolean;
  compactMenu: boolean;
  compactMenuOnHover: boolean;
  pageLoader: boolean;
  selectedOrg: Organization | null;
  initialRoute: string | null;
};

const getCompactMenu = () => {
  return localStorage.getItem('compactMenu') === 'true';
};

const slice = createSlice({
  name: 'layout',
  initialState: {
    activeMobileMenu: false,
    compactMenu:
      localStorage.getItem('compactMenu') === null ? true : getCompactMenu(),
    compactMenuOnHover: false,
    pageLoader: true,
  } as LayoutState,
  reducers: {
    setActiveMobileMenu: (state, action: PayloadAction<boolean>) => {
      state.activeMobileMenu = action.payload;
    },
    setCompactMenu: (state, action: PayloadAction<boolean>) => {
      localStorage.setItem('compactMenu', action.payload.toString());

      state.compactMenu = action.payload;
    },
    setCompactMenuOnHover: (state, action: PayloadAction<boolean>) => {
      state.compactMenuOnHover = action.payload;
    },
    setPageLoader: (state, action: PayloadAction<boolean>) => {
      state.pageLoader = action.payload;
    },
    setSelectedOrg: (state, action: PayloadAction<Organization>) => {
      if (action.payload)
        localStorage.setItem('selectedOrgId', action.payload.id);

      state.selectedOrg = action.payload;
    },
    setInitialRoute: (state, action: PayloadAction<string | null>) => {
      state.initialRoute = action.payload;
    },
  },
});

export const {
  setActiveMobileMenu,
  setCompactMenu,
  setCompactMenuOnHover,
  setPageLoader,
  setSelectedOrg,
  setInitialRoute,
} = slice.actions;

export default slice.reducer;
