import {
  PagedFilterSortRequest,
  PagedResponse,
  generateQueryParams,
} from '@kerplunkai/common-components';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { BASE_URL } from '@constants';
import {
  CreateInterviewRoomRequest,
  CreateInterviewRoomResponse,
  CreateQuestionInterviewRoomRequest,
  CreateQuestionInterviewRoomResponse,
  DeleteInterviewNoteRequest,
  Interview,
  InterviewNote,
  InterviewNoteRequest,
  InterviewNotesRequest,
  InterviewNotesResponse,
  InterviewRequest,
  InterviewShareUrlRequest,
  InterviewShareUrlResponse,
  OrgInterviewRequest,
  SubmitInterviewQuestionRequest,
  SubmitInterviewQuestionResponse,
  UpdateInterviewNoteRequest,
  UpdateInterviewRequest,
  UpdateInterviewResponse,
} from '@typings';
import { RootState } from '@store/store';

export const interviewService = createApi({
  reducerPath: 'interviewService',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}`,
    prepareHeaders: (headers, { getState }) => {
      const { token } = (getState() as RootState).auth;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ['InterviewNotes'],
  endpoints: builder => ({
    orgInterviews: builder.query<
      PagedResponse<Interview>,
      PagedFilterSortRequest
    >({
      query: ({ orgId, pagination, filters = '' }) => ({
        url: `/organizations/${orgId}/interviews${generateQueryParams({ pagination, filters })}`,
        method: 'GET',
      }),
    }),
    orgInterview: builder.query<Interview, OrgInterviewRequest>({
      query: ({ interviewId, organizationId, shareToken }) => ({
        url: shareToken
          ? `/interviews/access/${shareToken}`
          : `/organizations/${organizationId}/interviews/${interviewId}`,
        method: 'GET',
      }),
    }),
    interviewNotes: builder.query<
      InterviewNotesResponse,
      InterviewNotesRequest
    >({
      query: ({ interviewId, organizationId }) => ({
        url: `/organizations/${organizationId}/interviews/${interviewId}/notes`,
        method: 'GET',
      }),
      providesTags: ['InterviewNotes'],
    }),
    createInterviewNote: builder.mutation<InterviewNote, InterviewNoteRequest>({
      query: ({ organizationId, interviewId, text }) => ({
        url: `/organizations/${organizationId}/interviews/${interviewId}/notes`,
        method: 'POST',
        body: { note: { content: text } },
      }),
      invalidatesTags: ['InterviewNotes'],
    }),
    deleteInterviewNote: builder.mutation<void, DeleteInterviewNoteRequest>({
      query: ({ organizationId, interviewId, interviewNoteId }) => ({
        url: `/organizations/${organizationId}/interviews/${interviewId}/notes/${interviewNoteId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['InterviewNotes'],
    }),
    updateInterviewNote: builder.mutation<
      InterviewNote,
      UpdateInterviewNoteRequest
    >({
      query: ({ organizationId, interviewId, interviewNoteId, text }) => ({
        url: `/organizations/${organizationId}/interviews/${interviewId}/notes/${interviewNoteId}`,
        method: 'PUT',
        body: { note: { content: text } },
      }),
      invalidatesTags: ['InterviewNotes'],
    }),
    getShareInterviewUrl: builder.mutation<
      InterviewShareUrlResponse,
      InterviewShareUrlRequest
    >({
      query: ({ organizationId, interviewId, jobDescription, jobTitle }) => ({
        url: `/organizations/${organizationId}/interviews/${interviewId}/public`,
        method: 'POST',
        body: {
          job: {
            title: jobTitle,
            description: jobDescription,
          },
        },
      }),
    }),
    publicInterview: builder.query<
      Interview,
      { interviewId: string; shareToken: string }
    >({
      query: ({ interviewId, shareToken }) => ({
        url: `/viewer/${interviewId || shareToken}`,
        method: 'GET',
      }),
    }),
    createInterview: builder.mutation<Interview, InterviewRequest>({
      query: ({ organization_id, ...interview }) => ({
        url: `/organizations/${organization_id}/interviews`,
        method: 'POST',
        body: {
          interview,
        },
      }),
    }),
    updateInterview: builder.mutation<Interview, InterviewRequest>({
      query: ({ organization_id, ...interview }) => ({
        url: `/organizations/${organization_id}/interviews/${interview.id}`,
        method: 'PUT',
        body: {
          interview,
        },
      }),
    }),
    updateMeetingInterview: builder.mutation<
      UpdateInterviewResponse,
      UpdateInterviewRequest
    >({
      query: data => ({
        url: `users/${data.userId}/interviews/${data.interviewId}`,
        method: 'PUT',
        body: {
          interview: {
            status: data.status,
            rating: data.rating,
            feedback: data.feedback,
          },
        },
      }),
    }),
    submitQuestionResponse: builder.mutation<
      SubmitInterviewQuestionResponse,
      SubmitInterviewQuestionRequest
    >({
      query: data => ({
        url: `users/${data.userId}/interviews/${data.interviewId}/interview_questions/${data.questionId}/submit`,
        method: 'POST',
        body: {
          interview_question: {
            status: data.status,
          },
        },
      }),
    }),
    createInterviewMeetingRoom: builder.mutation<
      CreateInterviewRoomResponse,
      CreateInterviewRoomRequest
    >({
      query: data => ({
        url: `users/${data.userId}/interviews/${data.interviewId}/create_room`,
        method: 'POST',
      }),
    }),
    createQuestionMeetingRoom: builder.mutation<
      CreateQuestionInterviewRoomResponse,
      CreateQuestionInterviewRoomRequest
    >({
      query: data => ({
        url: `users/${data.userId}/interviews/${data.interviewId}/interview_questions/${data.questionId}/create_room`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useOrgInterviewQuery,
  useOrgInterviewsQuery,
  useLazyOrgInterviewsQuery,
  useInterviewNotesQuery,
  useCreateInterviewNoteMutation,
  useDeleteInterviewNoteMutation,
  useUpdateInterviewNoteMutation,
  useGetShareInterviewUrlMutation,
  usePublicInterviewQuery,
  useCreateInterviewMutation,
  useUpdateInterviewMutation,
  useLazyOrgInterviewQuery,
  useSubmitQuestionResponseMutation,
  useCreateInterviewMeetingRoomMutation,
  useCreateQuestionMeetingRoomMutation,
  useUpdateMeetingInterviewMutation,
} = interviewService;
