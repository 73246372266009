/* eslint-disable jsx-a11y/media-has-caption */
import ReactPlayer from 'react-player';
import clsx from 'clsx';
import {
  AudioWaveformIndicator,
  LoadingIcon,
  Lucide,
  useViewPort,
} from '@kerplunkai/common-components';
import { Participant } from '@videosdk.live/js-sdk';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { MediaControls } from '@modules/conference/components';
import { getMicStream } from '@utilities';
import {
  selectActiveCameraStream,
  selectActiveMicDevice,
  selectActiveMicStream,
  selectIsMicPaused,
} from '@store/selectors';
import { setInterviewMicStream } from '@store/slices';
import { useAppDispatch } from '@store/hooks';
import { useTensorFlowBlurredVideo } from '@modules/conference/hooks';

type ParticipantWindowProps = {
  isLoading?: boolean;
  show?: boolean;
  isInterviewPaused?: boolean;
  videoClassName?: string;
  isLocalParticipant?: boolean;
  participant?: Participant;
  enableWebcam?: () => void;
  disableWebcam?: () => void;
  muteMic?: () => void;
  unmuteMic?: () => void;
};

export function ParticipantWindow({
  isLoading = false,
  show = true,
  isInterviewPaused = false,
  videoClassName,
  isLocalParticipant = true,
  participant,
  enableWebcam,
  disableWebcam,
  muteMic,
  unmuteMic,
}: ParticipantWindowProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);

  const dispatch = useAppDispatch();
  const isMobile = useViewPort();

  const selectedMicDeviceId = useSelector(selectActiveMicDevice);

  const cameraStream = useSelector(selectActiveCameraStream);
  const micStream = useSelector(selectActiveMicStream);

  const isMicMuted = useSelector(selectIsMicPaused);

  // this initializes cameraStream with a blurred video track
  useTensorFlowBlurredVideo({ isInterviewPaused });

  useEffect(() => {
    const setupMic = async () => {
      if (
        selectedMicDeviceId &&
        !micStream &&
        !isMicMuted &&
        !isInterviewPaused &&
        isLocalParticipant
      ) {
        const stream = await getMicStream(selectedMicDeviceId);
        dispatch(setInterviewMicStream(stream));
      }
    };
    setupMic();
  }, [
    dispatch,
    isMicMuted,
    isInterviewPaused,
    micStream,
    selectedMicDeviceId,
    isLocalParticipant,
  ]);

  useEffect(() => {
    if (!isLocalParticipant && participant) {
      participant.on('stream-enabled', stream => {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(stream.track);

        if (stream.kind === 'video' && videoRef.current) {
          videoRef.current.srcObject = mediaStream;
        } else if (stream.kind === 'audio' && audioRef.current) {
          audioRef.current.srcObject = mediaStream;
        }
      });

      participant.on('stream-disabled', stream => {
        if (stream.kind === 'video' && videoRef.current) {
          videoRef.current.srcObject = null;
        } else if (stream.kind === 'audio' && audioRef.current) {
          audioRef.current.srcObject = null;
        }
      });

      participant.on('media-status-changed', data => {
        console.log('MEETING STATUS CHANGED', data);
      });
    }
  }, [isLocalParticipant, participant]);

  return (
    <div className={clsx([show ? 'block' : 'mb-0 hidden'])}>
      <div className="relative">
        {cameraStream && micStream && isLocalParticipant && (
          <ReactPlayer
            playsinline
            pip={false}
            light={false}
            controls={false}
            muted
            playing
            className={videoClassName}
            url={cameraStream || ''}
            height={
              // eslint-disable-next-line no-nested-ternary
              cameraStream ? (isMobile ? '189px' : '100%') : '0px'
            }
            width={isMobile ? '131px' : ''}
            onError={err => {
              console.log('participant video error: ', err);
            }}
            style={{
              borderRadius: 8,
              overflow: 'hidden',
              transform: 'scaleX(-1)',
              WebkitTransform: 'scaleX(-1)',
            }}
          />
        )}

        {!isLocalParticipant && (
          <>
            <video className="rounded-lg" ref={videoRef} autoPlay playsInline />
            <audio ref={audioRef} autoPlay />
          </>
        )}

        {isLoading && (
          <div className="absolute inset-0 m-auto w-12">
            <LoadingIcon icon="oval" color="white" />
          </div>
        )}

        {/* {!isLocalParticipant && audioRef.current?.srcObject === null && (
          <div className="b-2 r-2 absolute inset-0 w-12">
            <Lucide icon="MicOff" className="text-red-500" />
          </div>
        )} */}

        {(!cameraStream || !micStream) && isLocalParticipant && (
          <div className="relative rounded-lg border-DEFAULT border-solid border-[#e2e8f0] bg-primary/30">
            <div className="text flex h-[189px] flex-col justify-center text-center align-middle text-xs text-white md:h-64">
              {!isLoading ? (
                <>
                  <Lucide className="self-center" icon="VideoOff" />
                  Please enable your camera and microphone to continue.
                </>
              ) : (
                <div className="relative h-full w-[131px] md:h-full md:w-[288px]" />
              )}
            </div>
          </div>
        )}

        {isLocalParticipant && (
          <div className="absolute bottom-0 w-full">
            <MediaControls
              enableWebcam={enableWebcam}
              disableWebcam={disableWebcam}
              muteMic={muteMic}
              unmuteMic={unmuteMic}
            />
          </div>
        )}
      </div>

      {isLocalParticipant && <AudioWaveformIndicator micStream={micStream} />}
    </div>
  );
}
