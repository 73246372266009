import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import { Lucide } from '@kerplunkai/common-components';
import { useCallback } from 'react';

import { NAV_ROUTES } from '@constants';

function ProfileMenu() {
  const location = useLocation();

  const isMenuItemActive = useCallback(
    (navRoute: string) => location.pathname === navRoute,
    [location.pathname],
  );

  return (
    <div className="sticky top-[104px]">
      <div className="box box--stacked flex flex-col px-5 pb-6 pt-5">
        <Link
          to={NAV_ROUTES.PROFILE}
          className={clsx([
            'flex items-center py-3 first:-mt-3 last:-mb-3 hover:text-primary [&.active]:font-medium [&.active]:text-primary',
            { active: isMenuItemActive(NAV_ROUTES.PROFILE) },
          ])}
        >
          <Lucide icon="AppWindow" className="mr-3 size-4 stroke-[1.3]" />
          Profile Info
        </Link>
      </div>
    </div>
  );
}

export { ProfileMenu };
