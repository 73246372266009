import { createSlice } from '@reduxjs/toolkit';

import { jobService } from '@store/services';

type JobState = {
  isLoadingDescription: boolean;
};

const slice = createSlice({
  name: 'auth',
  initialState: {
    isLoadingDescription: false,
  } as JobState,
  extraReducers: builder => {
    builder.addMatcher(
      jobService.endpoints.generateJobDescription.matchPending,
      state => {
        state.isLoadingDescription = true;
      },
    );
    builder.addMatcher(
      jobService.endpoints.generateJobDescription.matchFulfilled,
      state => {
        state.isLoadingDescription = false;
      },
    );
    builder.addMatcher(
      jobService.endpoints.generateJobDescription.matchRejected,
      state => {
        state.isLoadingDescription = false;
      },
    );
  },
  reducers: {},
});

export default slice.reducer;
