/* eslint-disable @typescript-eslint/naming-convention */
import clsx from 'clsx';
import {
  Button,
  Lucide,
  RadarChart,
  TableBase,
} from '@kerplunkai/common-components';
import { ChartDataset } from 'chart.js';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useCallback, useState } from 'react';
import { Row } from '@tanstack/react-table';
import { saveAs } from 'file-saver';
import { useNavigate } from 'react-router-dom';

import { Interview } from '@typings';
import { InterviewVideoPlayer } from '@modules/interviewDetails/components';
import { NAV_ROUTES } from '@constants';
import {
  getAnalysisCommentary,
  getApplicationStatusColor,
  getApplicationStatusIcon,
  getRadarScoringDetails,
  mapBreakdownSummaryToRadar,
} from '@utilities';

interface InterviewSummaryProps {
  rowInterview: Row<Interview>;
  showPaywall?: boolean;
}

function InterviewSummary({
  rowInterview,
  showPaywall = false,
}: InterviewSummaryProps) {
  const navigate = useNavigate();

  const [videoOpen, setVideoOpen] = useState(false);
  const interview = rowInterview.original;

  const getRadarDataSets = useCallback(() => {
    const dataSets: ChartDataset<'radar'>[] = [];
    const radarScoringDetails = getRadarScoringDetails(interview);

    if (radarScoringDetails.resume) {
      dataSets.push({
        label: 'Resume',
        data: mapBreakdownSummaryToRadar(radarScoringDetails.resume),
        fill: true,
        borderWidth: 1,
        backgroundColor: 'rgba(7, 89, 133, 0.2)',
        borderColor: 'rgba(7, 89, 133, 0.6)',
        pointBackgroundColor: 'rgba(7, 89, 133, 0.6)',
        pointBorderColor: 'transparent',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(7, 89, 133, 0.6)',
      });
    }

    if (radarScoringDetails.interview) {
      dataSets.push({
        label: 'Interview',
        data: mapBreakdownSummaryToRadar(radarScoringDetails.interview),
        fill: true,
        borderWidth: 1,
        backgroundColor: 'rgba(85, 132, 8, .2)',
        borderColor: 'rgba(85, 132, 8, .6)',
        pointBackgroundColor: 'rgba(85, 132, 8, .6)',
        pointBorderColor: 'transparent',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(85, 132, 8, .6)',
      });
    }

    return dataSets;
  }, [interview]);

  const handleDownloadResume = useCallback(() => {
    const { resume } = interview;

    if (resume) saveAs(resume.url, resume.filename);
  }, [interview]);

  const {
    status,
    full_interview_video_url,
    resume,
    id,
    interview_question_analyses,
  } = interview;

  const analysisCommentary = getAnalysisCommentary(interview);
  const radarDatasets = getRadarDataSets();

  return (
    <>
      <Transition
        show={
          rowInterview.getIsExpanded() ||
          (showPaywall && rowInterview.index === 0)
        }
        as={Fragment}
        enter="transition ease duration-500 transform"
        enterFrom="opacity-0 -translate-y-12"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease duration-300 transform"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 -translate-y-12"
      >
        <TableBase.Tr>
          <TableBase.Td
            className="relative border-dashed bg-white"
            colSpan={rowInterview.getVisibleCells().length}
          >
            {showPaywall && rowInterview.index === 0 && (
              <>
                <div className="absolute inset-0 z-10 bg-[#E0E8EF]/35" />
                <div
                  className="absolute inset-x-0 top-1/3 z-20 m-auto flex min-h-[110px] w-full max-w-[735px] -translate-y-1/2 items-center justify-between gap-5 rounded-lg bg-white p-7"
                  style={{ boxShadow: '0px 4px 24px 0px #0000002E' }}
                >
                  <div className="flex flex-col gap-1">
                    <div className="text-base font-semibold text-[#181D2C]">
                      You’ve run out of AI Analysis
                    </div>
                    <div className="text-sm font-medium text-slate-400">
                      Gain access to unlimited AI analysis with Kerplunk Pro.
                    </div>
                  </div>
                  <button
                    className="flex gap-1.5 rounded-full bg-[#A9F72A] px-7 py-[15px] text-sm font-bold text-sky-800"
                    onClick={() => navigate(NAV_ROUTES.SUBSCRIPTION)}
                  >
                    Upgrade
                    <Lucide icon="ArrowRight" />
                  </button>
                </div>
              </>
            )}
            {!analysisCommentary && (
              <div
                className={clsx(
                  'flex items-center justify-center gap-2 py-12 text-sm',
                  `text-${getApplicationStatusColor(status)}`,
                )}
              >
                <Lucide
                  icon={getApplicationStatusIcon(status)}
                  className="size-3.5 stroke-[1.7]"
                />
                <div className="ml-1.5 whitespace-nowrap">
                  {status === 'started'
                    ? 'Interview is still in progress'
                    : 'Interview is still being processed'}
                </div>
              </div>
            )}
            {analysisCommentary && (
              <div
                className={clsx(
                  'grid grid-cols-12',
                  showPaywall && rowInterview.index === 0 ? 'blur-sm' : '',
                )}
              >
                <div className="col-span-12 px-6 lg:col-span-8">
                  <div className="grid grid-cols-12 whitespace-normal py-6">
                    <div className="col-span-12 text-base font-medium text-slate-600 sm:col-span-2">
                      Analysis
                    </div>
                    <div className="col-span-12 text-sm text-slate-500 sm:col-span-10">
                      {analysisCommentary.analysis}
                    </div>
                  </div>
                  <div className="grid grid-cols-12 whitespace-normal py-6">
                    <div className="col-span-12 text-base font-medium text-slate-600 sm:col-span-2">
                      Soft Skills
                    </div>
                    <div className="col-span-12 text-sm text-slate-500 sm:col-span-10">
                      {analysisCommentary.soft_skills}
                    </div>
                  </div>
                  <div className="grid grid-cols-12 whitespace-normal py-6">
                    <div className="col-span-12 text-base font-medium text-slate-600 sm:col-span-2">
                      Culture
                    </div>
                    <div className="col-span-12 text-sm text-slate-500 sm:col-span-10">
                      {analysisCommentary.culture_fit}
                    </div>
                  </div>
                  <div className="grid grid-cols-12 whitespace-normal pt-6">
                    <div className="col-span-12 text-base font-medium text-slate-600 sm:col-span-2">
                      Feedback
                    </div>
                    <div className="col-span-12 text-sm text-slate-500 sm:col-span-10">
                      {analysisCommentary.feedback}
                    </div>
                  </div>
                  <div className="grid grid-cols-12 py-6">
                    <div className="col-span-12 sm:col-span-2" />
                    <div className="col-span-12 flex gap-4 sm:col-span-10">
                      <Button
                        disabled={!full_interview_video_url}
                        className="flex gap-2.5 px-4 py-2"
                        variant="primary"
                        onClick={() => setVideoOpen(true)}
                      >
                        <Lucide icon="Play" />
                        Watch Interview
                      </Button>
                      <Button
                        className="flex gap-2.5 px-4 py-2"
                        onClick={() => navigate(`/interview/${id}`)}
                      >
                        <Lucide icon="Briefcase" />
                        View Details
                      </Button>
                      <Button
                        disabled={!resume}
                        className="flex gap-2.5 px-4 py-2"
                        onClick={handleDownloadResume}
                      >
                        <Lucide icon="GanttChartSquare" />
                        View Resume
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="col-span-12 flex flex-col items-center gap-2.5 lg:col-span-4">
                  <div className="flex gap-2.5 py-4 text-slate-600">
                    <p className="text-base font-medium">Overall</p>
                    <p className="text-base font-medium">
                      {interview ? interview.ai_score.toFixed(0) || '-' : '-'}
                      &nbsp;%
                    </p>
                  </div>
                  {radarDatasets && (
                    <div>
                      <RadarChart
                        labels={[
                          'X-Factor',
                          'Soft Skills',
                          'Resume Score',
                          'Culture Fit',
                          'Technical',
                        ]}
                        datasets={radarDatasets}
                      />
                      <div className="mt-5 flex flex-wrap items-center justify-center gap-x-5 gap-y-3">
                        <div className="flex items-center text-slate-500">
                          <div className="mr-2 size-2 rounded-full border border-sky-800/60 bg-sky-800/60" />{' '}
                          Resume
                        </div>
                        <div className="flex items-center text-slate-500">
                          <div className="mr-2 size-2 rounded-full border border-[#558408]/50 bg-[#558408]/50" />{' '}
                          Interview
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </TableBase.Td>
        </TableBase.Tr>
      </Transition>

      {/* VIDEO PLAYER MODAL */}
      <Transition appear show={videoOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setVideoOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-6xl overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="mt-2">
                    {full_interview_video_url && (
                      <InterviewVideoPlayer
                        videoUrl={full_interview_video_url}
                        interviewAnalyses={interview_question_analyses}
                      />
                    )}
                  </div>

                  <div className="row-reverse mt-4 flex justify-end">
                    <button
                      type="button"
                      className="relative min-w-20 rounded-md border border-slate-500 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => setVideoOpen(false)}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export { InterviewSummary };
