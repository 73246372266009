export const isFirefox = window.navigator.userAgent.includes('Firefox');

const hasCameraPermission = async () => {
  try {
    await navigator.mediaDevices.getUserMedia({ video: true });
    return true;
  } catch (error) {
    return false;
  }
};

const hasMicPermission = async () => {
  try {
    await navigator.mediaDevices.getUserMedia({ audio: true });
    return true;
  } catch (error) {
    return false;
  }
};

export const queryFirefoxPermissions = async () => {
  try {
    const result = await Promise.all([
      hasCameraPermission(),
      hasMicPermission(),
    ]);

    return { cameraPermission: result[0], micPermission: result[1] };
  } catch (error) {
    console.error('Error occurred querying firefox permissions:', error);
    return { cameraPermission: false, micPermission: false };
  }
};
