import clsx from 'clsx';
import spacetime from 'spacetime';
import {
  Button,
  DEFAULT_PAGE_INFO,
  FormSelect,
  Lucide,
  TableFilterPredicatesEnum,
  TableFilters,
  TableRefetchFn,
  TanstackTable,
  TanstackTableActionColumn,
  TanstackTableColumnDef,
  Tippy,
} from '@kerplunkai/common-components';

import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
} from 'react';

import {
  Row,
  getCoreRowModel,
  getExpandedRowModel,
} from '@tanstack/react-table';

import { useNavigate, useParams } from 'react-router-dom';

import { FILTER_ORG_ID, NEW_ROUTES } from '@constants';
import { Interview, InterviewStatus } from '@typings';
import { InterviewScoringHoverBars } from '@components';
import { InterviewSummary } from '@modules/interviews/interviewSummary.component';
import { darkGreen, medGreen } from '@theme';
import {
  getApplicationStatusColor,
  getApplicationStatusIcon,
  getIsReadyForReview,
  getScoringCategoryStyles,
  getScoringDetails,
} from '@utilities';
import { selectSelectedOrgId } from '@store/selectors';
import {
  useLazyGetJobInterviewsQuery,
  useLazyOrgInterviewsQuery,
} from '@store/services';
import { useSelector } from 'react-redux';

import defaultAvatar from '@assets/images/no-pic@2x.png';
import { PAYWALL_INTERVIEW_SUMMARY } from '@constants/interview';
import { cloneDeep } from 'lodash';
import { selectShowPaywall } from '@store/selectors/stripe.selectors';

import { SocialLinkIconButtons } from '@modules/interviews/components';

interface InterviewsTableProps {
  allowToPost?: boolean;
  allowUpload?: boolean;
  allowCopilot?: boolean;
  allCandidates?: boolean;
}

function InterviewsTable({
  allowToPost,
  allowUpload,
  allowCopilot,
  allCandidates,
}: InterviewsTableProps) {
  const navigate = useNavigate();
  const { jobId } = useParams<{ jobId: string }>();

  const handleViewInterviewDetails = useCallback(
    (interviewId: string) => {
      navigate(`/interview/${interviewId}`);
    },
    [navigate],
  );

  const tableColumns: TanstackTableColumnDef<Interview>[] = [
    {
      accessorKey: 'avatar',
      header: '',
      enableSorting: false,
      cell: ({ row }) => {
        return (
          <div className="flex justify-center">
            <div className={clsx('size-9 rounded-full')}>
              <img
                alt="avatar"
                className="size-full rounded-full"
                src={
                  row.original.candidate_avatar_url.includes('gravatar') // TODO: maybe don't send gravatars to org site at all?
                    ? defaultAvatar
                    : row.original.candidate_avatar_url
                }
              />
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: 'candidate_name',
      header: 'Candidate Name',
      cell: ({ getValue, row }) => {
        return (
          <div className="flex flex-col gap-1">
            <p className="text-xs text-slate-400">Candidate Name</p>
            <div className="flex items-center text-sm text-sky-800">
              <div>{getValue<string>()}</div>
              <SocialLinkIconButtons interview={row.original} />
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: ({ getValue, row }) => {
        const status = getValue<keyof typeof InterviewStatus>();
        const isReadyForReview = getIsReadyForReview(row.original);

        return status === 'scheduled' ? (
          <Button
            className={clsx(
              'bg-[#A9F72A]/10 p-2',
              `border-[${medGreen}] text-[${darkGreen}]`,
            )}
          >
            <Lucide className="mr-2" icon="ArrowUpRightSquare" />
            Start Meeting
          </Button>
        ) : (
          <div className="flex flex-col gap-1">
            <p className="text-xs text-slate-400">Status</p>
            <div
              className={clsx(
                'flex items-center gap-1 text-sm',
                `text-${
                  isReadyForReview
                    ? `[${darkGreen}]`
                    : getApplicationStatusColor(status)
                }`,
              )}
            >
              <Lucide
                icon={getApplicationStatusIcon(status)}
                className="size-3.5 stroke-[1.7]"
              />
              <div className="ml-1.5 whitespace-nowrap">
                {isReadyForReview
                  ? 'Ready for Review'
                  : InterviewStatus[status] || 'In Progress'}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: 'job_title',
      header: 'Job Title',
      enableSorting: false,
      cell: ({ getValue, row }) => (
        <div className="flex w-[190px] flex-col gap-1">
          <p className="one-line-ellipsis text-sm  font-medium">
            {getValue<string>()}
          </p>
          <p className="one-line-ellipsis text-xs text-slate-400">
            {row.original.organization}
          </p>
        </div>
      ),
      getClassNames: (row: Row<Interview>) =>
        row.getIsExpanded()
          ? 'border-b-0 bg-white py-4 first:rounded-lg last:rounded-lg'
          : 'border-dashed bg-white py-4 first:rounded-lg last:rounded-lg',
    },
    {
      accessorKey: 'category',
      header: 'Category',
      enableSorting: false,
      cell: ({ row }) => {
        const styles = getScoringCategoryStyles(row.original);

        return styles.labelText ? (
          <div
            className={`inline-flex items-center justify-center gap-0.5 rounded border px-2 py-0.5 text-xs ${styles.background} ${styles.border} ${styles.text}`}
          >
            {styles.labelText}
          </div>
        ) : (
          <Tippy content="The user has not uploaded a resume or completed a video interview.">
            <div className="inline-flex w-14 items-center justify-center gap-0.5 rounded border border-[#558408]/10 bg-[#558408]/10 px-2 py-0.5 text-xs text-[#558408]">
              -
            </div>
          </Tippy>
        );
      },
    },
    {
      accessorKey: 'ai_score',
      header: 'AI Score',
      cell: ({ row }) => {
        const scoringDetails = getScoringDetails(row.original);

        return (
          <div
            className={clsx(
              'flex flex-col gap-2.5',
              !scoringDetails && 'opacity-20',
            )}
          >
            <p className="text-xs text-slate-400">
              ✨&nbsp;AI Score&nbsp;(
              {scoringDetails ? row.original.ai_score.toFixed(0) : '-'}%)
            </p>
            <div className="flex h-2.5 w-full">
              <InterviewScoringHoverBars scoringDetails={scoringDetails} />
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: 'created_at',
      header: 'Date',
      cell: ({ getValue }) => {
        const dt = spacetime(getValue<Date>());

        return (
          <div className="flex flex-col gap-1">
            <p className="text-xs text-slate-400">Date </p>
            <p className="text-sm text-slate-600">
              {dt.format('{month} {date} {year}')}
            </p>
          </div>
        );
      },
    },
    {
      header: '',
      accessorKey: 'actions',
      enableSorting: false,
      cell: ({ row }) => {
        const isReadyForReview = getIsReadyForReview(row.original);
        return isReadyForReview ? (
          <TanstackTableActionColumn
            data={row}
            actions={[
              {
                icon: 'Briefcase',
                text: 'View Details',
                onClick: () => {
                  handleViewInterviewDetails(row.original.id);
                },
              },
            ]}
          />
        ) : null;
      },
    },
  ];

  const orgId = useSelector(selectSelectedOrgId);
  const showPaywall = useSelector(selectShowPaywall());

  const [
    getOrgInterviews,
    { data: orgInterviewsData, isFetching: isFetchingOrgInterviews },
  ] = useLazyOrgInterviewsQuery();
  const [
    getJobInterviews,
    { data: jobInterviewsData, isFetching: isFetchingJobInterviews },
  ] = useLazyGetJobInterviewsQuery();

  const handleRefetch: TableRefetchFn = useCallback(
    (pagination, { filters, searchTerm, sorting }) => {
      let filterQueryParms = '';

      if (searchTerm && searchTerm.length > 0)
        filterQueryParms = `q[full_name_and_title_${TableFilterPredicatesEnum.cont}]=${searchTerm}`;
      if (sorting)
        filterQueryParms += `${filterQueryParms !== '' ? '&' : ''}q[s]=${sorting}`;

      Object.keys(filters || {}).forEach(filter => {
        filterQueryParms += `${filterQueryParms !== '' ? '&' : ''}q[${filter}_${TableFilterPredicatesEnum.eq}]=${(filters || {})[filter]}`;
      });

      if (allCandidates) {
        filterQueryParms += `${filterQueryParms !== '' ? '&' : ''}allCandidates=true`;
      }

      if (!jobId)
        getOrgInterviews({
          orgId: orgId as string,
          pagination,
          filters: filterQueryParms,
        });
      else
        getJobInterviews({
          orgId: orgId as string,
          jobId: jobId as string,
          pagination,
          filters: filterQueryParms,
        });
    },
    [getOrgInterviews, getJobInterviews, jobId, orgId, allCandidates],
  );

  const interviewsData = useMemo(() => {
    const data = orgInterviewsData || jobInterviewsData;

    if (data && !showPaywall) return data;
    if (data && showPaywall) {
      const newData = cloneDeep(data);

      newData.results = [PAYWALL_INTERVIEW_SUMMARY, ...newData.results];

      return newData;
    }

    return undefined;
  }, [orgInterviewsData, jobInterviewsData, showPaywall]);
  const isFetching = useMemo(
    () => isFetchingJobInterviews || isFetchingOrgInterviews,
    [isFetchingJobInterviews, isFetchingOrgInterviews],
  );

  return (
    <TanstackTable
      actionsHeaderItems={{
        exportItem: {
          text: 'Export',
          onClick: () => ({}),
        },
        addItem: allowToPost
          ? {
              text: 'Post a Job',
              onClick: () => navigate(NEW_ROUTES.NEW_JOB),
            }
          : undefined,
        coPilotItem:
          allowCopilot && orgId === FILTER_ORG_ID
            ? {
                text: 'Create Co-Pilot Meeting',
                onClick: () =>
                  navigate(
                    `${NEW_ROUTES.NEW_MEETING}${jobId ? `?jobId=${jobId}` : ''}`,
                  ),
              }
            : undefined,
        uploadItem: allowUpload
          ? {
              text: 'Upload Resume',
              onClick: () => {},
            }
          : undefined,
      }}
      emptyText="No Interviews(s) Found"
      expansionRow={(row: Row<Interview>) => (
        <InterviewSummary rowInterview={row} showPaywall={showPaywall} />
      )}
      initialSorting={{
        initialField: 'custom_sort',
        initialOrder: 'DESC',
      }}
      rowTdClassName="max-sm:first:shadow-custom"
      headerClassName="max-sm:border-t-0 max-sm:mt-3 max-sm:bg-white"
      isFetching={isFetching}
      pageInfo={interviewsData ? interviewsData.meta : DEFAULT_PAGE_INFO}
      tableOptions={{
        data: interviewsData ? interviewsData.results : [],
        columns: tableColumns,
        getCoreRowModel: getCoreRowModel(),
        getRowId: row => row.id,
        getRowCanExpand: () => true,
        getExpandedRowModel: getExpandedRowModel(),
      }}
      onRefetch={handleRefetch}
      renderFilters={(
        tempFilters: TableFilters,
        setTempFilters: Dispatch<SetStateAction<TableFilters>>,
      ) => (
        <div>
          <div className="text-left text-slate-500">Status</div>
          <FormSelect
            className="mt-2 flex-1"
            value={tempFilters.status as string}
            onChange={({ target: { value } }: ChangeEvent<HTMLSelectElement>) =>
              setTempFilters({ ...tempFilters, status: value })
            }
          >
            <option value="">Status</option>
            <option value="started">In Progress</option>
            <option value="processing">Processing</option>
            <option value="completed">Ready for Review</option>
          </FormSelect>
        </div>
      )}
      searchPlaceholder="Search Candidates..."
      showSearch
    />
  );
}

export { InterviewsTable };
