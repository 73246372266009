/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from 'yup';
import {
  AvatarUpload,
  BlobImage,
  FormInput,
  FormLabel,
  FormModal,
  FormSelect,
  isValidFileType,
} from '@kerplunkai/common-components';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { FormikProps } from 'formik';
import { useSelector } from 'react-redux';

import { INITIAL_ORG, US_STATES } from '@constants';
import { Organization, OrganizationRequest } from '@typings';
import { getCompanySizeOptions } from '@utilities';
import {
  selectOrganizationsDetails,
  selectSelectedOrgId,
} from '@store/selectors';
import {
  useCreateOrgClientMutation,
  useLazyOrgClientsQuery,
} from '@store/services';

const PAGINATION_INFO = {
  pageIndex: 1,
  pageSize: 1000,
};
const FILE_SIZE = 5; // 1024 * 1024 * 5

interface ClientFormModalProps {
  open?: string;
  onAddSuccess: (client: Organization) => void;
  toggleOpen: Dispatch<SetStateAction<string | undefined>>;
}

function ClientFormModal({
  open,
  onAddSuccess,
  toggleOpen,
}: ClientFormModalProps) {
  const orgId = useSelector(selectSelectedOrgId);
  const orgDetails = useSelector(selectOrganizationsDetails());

  const [createClient, { isLoading: isCreatingClient }] =
    useCreateOrgClientMutation();
  const [getOrgClients, { isLoading: isGettingClients }] =
    useLazyOrgClientsQuery();

  const handleCreate = useCallback(
    (values: OrganizationRequest) => {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise<void>(async (resolve, reject) => {
        const result = await createClient({
          ...values,
          organizationId: orgId as string,
        }).unwrap();
        getOrgClients({
          orgId: orgId as string,
          pagination: PAGINATION_INFO,
          filters: '',
        });

        if (result instanceof Error) {
          reject();
        } else {
          onAddSuccess(result);
          resolve();
        }
      });
    },
    [createClient, getOrgClients, orgId, onAddSuccess],
  );

  return (
    <FormModal<OrganizationRequest>
      formikConfig={{
        initialValues: { ...INITIAL_ORG, name: open || '' },
        validateOnChange: false,
        validationSchema: Yup.object({
          name: Yup.string().required('Company Name is required'),
          size: Yup.string().required('Company Size is required'),
          industry_id: Yup.string().required('Industry is required'),
          city: Yup.string().required('City is required'),
          state: Yup.string().required('State is required'),
          logo: Yup.mixed()
            .required('Logo is required')
            .test('is-valid-type', 'Not a valid image type', value =>
              isValidFileType(
                (value && (value as BlobImage).name.toLowerCase()) as string,
                'image',
              ),
            )
            .test('fileSize', 'File size is too large', (value?: unknown) => {
              if (!value) return false;

              const { size } = (value as BlobImage).src;

              return size / 1024 ** 2 <= FILE_SIZE;
            }),
        }),
        onSubmit: () => {},
      }}
      isLoading={isCreatingClient || isGettingClients}
      open={Boolean(open)}
      title="Add New Client"
      snackbar={{
        success: {
          children: (
            <div className="flex flex-col gap-1">
              <p className="text-sm capitalize text-[#364154]">
                Client Created
              </p>
              <p className="text-sm text-[#677489]">
                The Client has been successfully created and added to the job.
              </p>
            </div>
          ),
        },
        error: { message: `Unable to create client. Please try again.` },
      }}
      successButton={{
        children: `Add Client`,
      }}
      onClose={() => toggleOpen(undefined)}
      onSubmit={handleCreate}
    >
      {({
        values,
        errors,
        handleChange,
        setFieldValue,
      }: FormikProps<OrganizationRequest>) => (
        <>
          <div className="flex flex-col gap-3.5">
            <FormLabel>
              <div className="flex">
                Company Logo
                <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-400 dark:bg-darkmode-300 dark:text-slate-400">
                  Required
                </div>
              </div>
              <div className="mt-1.5 text-left text-xs text-slate-500">
                Upload an image file of your company logo. Recommended formats
                are .png or .jpg for best quality.
              </div>
            </FormLabel>
            <div>
              <AvatarUpload
                img={(values.logo as any)?.src}
                imageUrl={values.logo_url}
                shouldCrop
                onChange={(avatarBlob: any | null) => {
                  setFieldValue('logo_url', '');
                  setFieldValue('logo', avatarBlob);
                }}
              />
              {errors.logo && (
                <div className="mt-2 text-xs text-danger">{errors.logo}</div>
              )}
            </div>
          </div>
          <div className="grid grid-cols-12 gap-x-6">
            <div className="col-span-12 flex flex-col gap-3.5 md:col-span-6">
              <FormLabel htmlFor="name">
                <div className="flex">
                  Company Name
                  <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-400 dark:bg-darkmode-300 dark:text-slate-400">
                    Required
                  </div>
                </div>
                <div className="mt-1.5 text-left text-xs text-slate-500">
                  Enter the name of your company as registered or commonly
                  known.
                </div>
              </FormLabel>
              <div>
                <FormInput
                  id="name"
                  disabled
                  error={Boolean(errors.name)}
                  name="name"
                  placeholder="Company Name"
                  value={values.name}
                  onChange={handleChange}
                />
                {Boolean(errors.email) && (
                  <div className="mt-2 text-left text-xs text-danger">
                    {errors.email}
                  </div>
                )}
              </div>
            </div>
            <div className="col-span-12 flex flex-col gap-3.5 md:col-span-6">
              <FormLabel htmlFor="size">
                <div className="flex">
                  Company Size
                  <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-400 dark:bg-darkmode-300 dark:text-slate-400">
                    Required
                  </div>
                </div>
                <div className="mt-1.5 text-left text-xs text-slate-500">
                  Select the range of the number of employees in the company.
                </div>
              </FormLabel>
              <div>
                <FormSelect
                  className="flex-1"
                  disabled={isCreatingClient || isGettingClients}
                  name="size"
                  value={values.size}
                  onChange={handleChange}
                >
                  <option value="">Select Company Size</option>
                  {getCompanySizeOptions(
                    orgDetails?.company_sizes,
                    values.size,
                  ).map(option => (
                    <option key={option.value} value={option.value}>
                      {option.name}
                    </option>
                  ))}
                </FormSelect>
                {Boolean(errors.size) && (
                  <div className="mt-2 text-left text-xs text-danger">
                    {errors.size}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-3.5">
            <FormLabel htmlFor="industry_id">
              <div className="flex">
                Industry
                <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-400 dark:bg-darkmode-300 dark:text-slate-400">
                  Required
                </div>
              </div>
              <div className="mt-1.5 text-left text-xs text-slate-500">
                Choose the industry that best describes the company&apos;s main
                business activity.
              </div>
            </FormLabel>
            <div>
              <FormSelect
                className="mt-2 flex-1"
                disabled={isCreatingClient || isGettingClients}
                name="industry_id"
                value={values.industry_id}
                onChange={handleChange}
              >
                <option value="">Select Industry</option>
                {(orgDetails?.all_industries || []).map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </FormSelect>
              {Boolean(errors.industry_id) && (
                <div className="mt-2 text-left text-xs text-danger">
                  {errors.industry_id}
                </div>
              )}
            </div>
          </div>
          <div className="grid grid-cols-12 gap-x-6">
            <div className="col-span-12 flex flex-col gap-3.5 md:col-span-6">
              <FormLabel htmlFor="state">
                <div className="flex">
                  State
                  <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-400 dark:bg-darkmode-300 dark:text-slate-400">
                    Required
                  </div>
                </div>
                <div className="mt-1.5 text-left text-xs text-slate-500">
                  Enter the name of the state the company is located.
                </div>
              </FormLabel>
              <div>
                <FormSelect
                  className="flex-1"
                  disabled={isCreatingClient || isGettingClients}
                  name="state"
                  value={values.state}
                  onChange={handleChange}
                >
                  <option value="">Select State</option>
                  {US_STATES.map(({ name, abbreviation }) => (
                    <option key={abbreviation} value={abbreviation}>
                      {name}
                    </option>
                  ))}
                </FormSelect>
                {Boolean(errors.state) && (
                  <div className="mt-2 text-left text-xs text-danger">
                    {errors.state}
                  </div>
                )}
              </div>
            </div>
            <div className="col-span-12 flex flex-col gap-3.5 md:col-span-6">
              <FormLabel htmlFor="city">
                <div className="flex">
                  City
                  <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-400 dark:bg-darkmode-300 dark:text-slate-400">
                    Required
                  </div>
                </div>
                <div className="mt-1.5 text-left text-xs text-slate-500">
                  Enter the name of the city the company is located.
                </div>
              </FormLabel>
              <div>
                <FormInput
                  className="col-span-3 text-slate-600"
                  disabled={isCreatingClient || isGettingClients}
                  error={Boolean(errors.city)}
                  name="city"
                  placeholder="City"
                  value={values.city}
                  onChange={handleChange}
                />
                {Boolean(errors.city) && (
                  <div className="mt-2 text-left text-xs text-danger">
                    {errors.city}
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </FormModal>
  );
}

export { ClientFormModal };
