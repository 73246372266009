import { PagedFilterSortRequest } from '@kerplunkai/common-components';
import { createSelector } from '@reduxjs/toolkit';

import { clientService } from '@store/services';

export const selectOrgClients = ({
  orgId,
  pagination,
  filters = '',
}: PagedFilterSortRequest) =>
  createSelector(
    clientService.endpoints.orgClients.select({
      orgId,
      pagination,
      filters,
    }),
    queryState => queryState?.data?.results,
  );

export const selectOrgClient = (orgId: string, orgClientId: string) =>
  createSelector(
    clientService.endpoints.orgClient.select({
      orgId,
      orgClientId,
    }),
    queryState => queryState.data,
  );
