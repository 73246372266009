export interface OrgUserRequest {
  orgId: string;
  email: string;
}

export interface RemoveOrgUserRequest {
  orgId: string;
  userId: string;
}

export interface CancelOrgInviteRequest {
  orgId: string;
  userInviteId: string;
}

export interface OrgUser {
  id: string;
  email: string;
  first_name: null;
  last_name: null;
  status: OrgUserStatus;
  invites: OrgInvite[];
}

interface OrgInvite {
  id: string;
  status: string;
  created_at: string;
  accepted_at: string;
  rejected_at: null;
  deleted_at: null;
  inviter: OrgUserInviter;
}

interface OrgUserInviter {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
}

export enum OrgUserStatus {
  invited = 'invited',
  accepted = 'accepted',
  rejected = 'rejected',
  removed = 'removed',
  owner = 'owner',
}

export interface UserRoleStyles {
  background: string;
  border: string;
  text: string;
  labelText?: string;
}
