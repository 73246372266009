import { createSelector } from '@reduxjs/toolkit';

import { selectSelectedOrg } from '@store/selectors';
import { stripeService } from '@store/services';

export const selectProducts = (orgId: string) =>
  createSelector(
    stripeService.endpoints.products.select(orgId),
    queryState => queryState?.data?.results,
  );

export const selectSubscription = (orgId: string) =>
  createSelector(
    stripeService.endpoints.subscriptions.select(orgId),
    queryState => queryState?.data,
  );

export const selectShowPaywall = () =>
  createSelector(
    selectSelectedOrg,
    queryState => !queryState?.subscription.active_subscription,
  );
