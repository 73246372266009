export const blueRibbon1 = '#153cf5';
export const blueRibbon2 = '#4a3aff'; // Primary
export const kleinBlue = '#0423b2';
export const blackPearl = '#05152e';
export const cloudBurst = '#1d2b3f';
export const riverBed = '#47505c';
export const osloGray = '#858c95';
export const ghost = '#ced3d9';
export const athensGray = '#e5e5ef';
export const whisper1 = '#f7f7fb';
export const whisper2 = '#eff0f6';
export const lightningYellow = '#ffc225';
export const lochmara = '#027dc6';
export const pictonBlue = '#3ab7ea';
export const portGore = '#211f54';
export const stormGray = '#6e7191';
export const jacksonsPurple = '#281ca5';
export const titanWhite = '#f2f1ff';
export const darkGreen = '#558408';
export const lightGreen = '#A9F72A';
export const medGreen = '#A0D846';

export const colors = {
  blueRibbon1,
  blueRibbon2,
  kleinBlue,
  blackPearl,
  cloudBurst,
  riverBed,
  osloGray,
  ghost,
  athensGray,
  whisper1,
  whisper2,
  lightningYellow,
  lochmara,
  pictonBlue,
  portGore,
  stormGray,
  jacksonsPurple,
  titanWhite,
  darkGreen,
  lightGreen,
  medGreen,
};
