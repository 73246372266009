/* eslint-disable @typescript-eslint/naming-convention */
import { Button, FormInput, Lucide } from '@kerplunkai/common-components';
import { InterviewQuestionAnalysis } from '@typings';
import { useState } from 'react';

import { downloadFile } from '@utilities';

import { InterviewTranscriptAnalyses } from '@modules/interviewDetails/components';

interface InterviewTranscriptPanelProps {
  analyses: InterviewQuestionAnalysis[];
  transcriptDownloadUrl?: string;
}

function InterviewTranscriptPanel({
  analyses,
  transcriptDownloadUrl,
}: InterviewTranscriptPanelProps) {
  const [searchTerm, setSearchTerm] = useState<string>('');

  return (
    <div className="box p-3 sm:p-5">
      <div className="flex flex-col md:flex-row">
        <div className="mb-3 flex grow text-base md:mb-0 md:text-lg">
          <div className="self-center">Interview Transcript ✨</div>
        </div>
        {transcriptDownloadUrl && (
          <div>
            <Button onClick={() => downloadFile(transcriptDownloadUrl)}>
              <Lucide className="mr-2" icon="Download" />
              <div className="font-light">Download Transcript & Notes</div>
            </Button>
          </div>
        )}
      </div>
      <div className="relative my-4">
        <Lucide
          icon="Search"
          className="absolute inset-y-0 left-0 z-10 my-auto ml-3 size-4 stroke-[1.3] text-slate-500"
        />
        <FormInput
          type="text"
          className="rounded-lg pl-9"
          placeholder="Search transcript..."
          value={searchTerm}
          onChange={evt => setSearchTerm(evt.target.value)}
        />
      </div>
      <InterviewTranscriptAnalyses
        analyses={analyses}
        searchTerm={searchTerm}
      />
    </div>
  );
}

export { InterviewTranscriptPanel };
