const LOGIN = 'login';
const SIGNUP = 'sign_up';
const FORGOT_PASSWORD = 'forgot-password';
const RESET_PASSWORD = 'reset_password';
const ACCEPT_INVITE = 'accept_invite';
const RECENT_INTERVIEWS = 'recent-interviews';
const INTERVIEW_DETAILS = 'interview';
const JOBS = 'jobs';
const SEARCH = 'search';
const SETTINGS = 'settings';
const CATEGORIES = 'categories';
const SUBCATEGORIES = 'subcategories';
const BENEFITS = 'benefits';
const ORGANIZATION_CREATE = 'organization';
const CLIENTS = 'clients';
const USERS = 'users';
const SUBSCRIPTION = 'subscription';
const PROFILE = 'profile';
const INTEGRATIONS = 'integrations';
const VIEWER = 'viewer';
const MEETINGS = 'meetings';
export const CONFERENCE = 'conference';

const JOB_PARAM = ':jobId';
const CATEGORY_PARAM = ':categoryId';
const SUBCATEGORY_PARAM = ':subcategoryId';
const BENEFIT_PARAM = ':benefitId';
const CLIENT_PARAM = ':clientId';
const INTERVIEW_PARAM = ':interviewId';
const INTERVIEW_SHARE_TOKEN_PARAM = ':shareToken';
const ASSOCIATE_SESSION = 'associate-stripe-session';

export const NAV_ROUTES = {
  BENEFIT: `/${BENEFITS}/${BENEFIT_PARAM}`,
  BENEFITS: `/${BENEFITS}`,
  CATEGORIES: `/${CATEGORIES}`,
  CATEGORY: `/${CATEGORIES}/${CATEGORY_PARAM}`,
  CLIENT: `/${CLIENTS}/${CLIENT_PARAM}`,
  CLIENTS: `/${CLIENTS}`,
  FORGOT_PASSWORD: `/${FORGOT_PASSWORD}`,
  JOB_APPLICATIONS: `${JOBS}/${JOB_PARAM}/applications`,
  JOB: `/${JOBS}/${JOB_PARAM}`,
  JOBS: `/${JOBS}`,
  LOGIN: `/${LOGIN}`,
  ORGANIZATION_CREATE: `/${ORGANIZATION_CREATE}`,
  RECENT_INTERVIEWS: `/${RECENT_INTERVIEWS}`,
  RESET_PASSWORD: `/${RESET_PASSWORD}`,
  INTERVIEW_SHARE_ACCESS: `/${INTERVIEW_DETAILS}/access/${INTERVIEW_SHARE_TOKEN_PARAM}`,
  INTERVIEW_DETAILS: `/${INTERVIEW_DETAILS}/${INTERVIEW_PARAM}`,
  SEARCH: `/${SEARCH}`,
  SETTINGS: `/${SETTINGS}`,
  SIGNUP: `/${SIGNUP}`,
  SUBCATEGORIES: `/${SUBCATEGORIES}`,
  SUBCATEGORY: `/${SUBCATEGORIES}/${SUBCATEGORY_PARAM}`,
  ORGANIZATION_USERS: `/${USERS}`,
  ACCEPT_INVITE: `/${ACCEPT_INVITE}`,
  SUBSCRIPTION: `/${SETTINGS}/${SUBSCRIPTION}`,
  PROFILE: `/${PROFILE}`,
  INTEGRATIONS: `/${INTEGRATIONS}`,
  VIEWER: `/${VIEWER}/${INTERVIEW_PARAM}`,
  ASSOCIATE_SESSION: `/${ASSOCIATE_SESSION}`,
  MEETINGS: `/${MEETINGS}`,
  MEETING: `/${MEETINGS}/${INTERVIEW_PARAM}`,
  CONFERENCE: `/${CONFERENCE}/${INTERVIEW_PARAM}`,
  CONFERENCE_SETUP: `/${CONFERENCE}/${INTERVIEW_PARAM}/setup`,
  CONFERENCE_QUESTIONS: `/${CONFERENCE}/${INTERVIEW_PARAM}/questions`,
  CONFERENCE_END: `/${CONFERENCE}/${INTERVIEW_PARAM}/end`,
};

export const NEW_ROUTES = {
  NEW_JOB: `/${JOBS}/new`,
  NEW_CATEGORY: `/${CATEGORIES}/new`,
  NEW_SUBCATEGORY: `/${SUBCATEGORIES}/new`,
  NEW_BENEFIT: `/${BENEFITS}/new`,
  NEW_CLIENT: `/${CLIENTS}/new`,
  NEW_MEETING: `/${MEETINGS}/new`,
};
