import { EmptyTranscript, InterviewQuestionTranscript } from '@typings';
import { HighlightableText } from '@modules/interviewDetails/components';
import { getFormattedTime } from '@utilities';

interface InterviewQuestionTranscriptDetailsProps {
  transcripts: InterviewQuestionTranscript[] | EmptyTranscript;
  searchTerm?: string;
}

function InterviewQuestionTranscriptDetails({
  transcripts,
  searchTerm,
}: InterviewQuestionTranscriptDetailsProps) {
  const isEmpty = !Array.isArray(transcripts);
  return (
    <div>
      {isEmpty ? (
        <div className="py-2">No transcript available</div>
      ) : (
        transcripts.map((transcript: InterviewQuestionTranscript) => {
          return (
            <div key={transcript.start_time} className="grid grid-cols-12">
              <div className="col-span-2 md:col-span-1">
                <div className="mb-2 mt-5 w-fit rounded-full border bg-slate-100 px-2 py-0.5 text-sm font-medium">
                  {getFormattedTime(transcript.start_time)}
                </div>
              </div>
              <div className="col-span-10 md:col-span-11">
                <div className="mb-2 ml-4 mt-5 px-2 py-0.5 text-sm font-medium">
                  <HighlightableText
                    text={transcript.text}
                    searchTerm={searchTerm}
                  />
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
}

export { InterviewQuestionTranscriptDetails };
