import {
  Button,
  Disclosure,
  Loading,
  Lucide,
} from '@kerplunkai/common-components';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { NAV_ROUTES } from '@constants';
import { selectSelectedOrgId } from '@store/selectors';
import {
  useGetShareInterviewUrlMutation,
  useInterviewNotesQuery,
  useOrgInterviewQuery,
} from '@store/services';

import { downloadFile } from '@utilities';

import {
  CandidateContactPanel,
  CandidateInfoPanel,
  IconInfoBox,
  InterviewAnalysisPanel,
  InterviewNotesPanel,
  InterviewShareLinkModal,
  InterviewSummaryPanel,
  InterviewTranscriptPanel,
  InterviewVideoPlayer,
  JobPostingDetailsPanel,
} from '@modules/interviewDetails/components';

import defaultAvatar from '@assets/images/no-pic@2x.png';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

function InterviewDetails() {
  const { interviewId, shareToken } = useParams<{
    interviewId: string;
    shareToken: string;
  }>();

  const navigate = useNavigate();

  const orgId = useSelector(selectSelectedOrgId);

  const [sharingUrl, setSharingUrl] = useState('');
  const [showShareLinkModal, setShowShareLinkModal] = useState(false);
  const [isTexTCopied, setIsTextCopied] = useState(false);
  const [getSharingUrl, { isLoading: isLoadingShareLink }] =
    useGetShareInterviewUrlMutation();

  const { enqueueSnackbar } = useSnackbar();

  const isSharedView = !!shareToken;

  const {
    data: interviewData,
    isLoading: isLoadingInterviews,
    isError: isInterviewError,
  } = useOrgInterviewQuery({
    organizationId: orgId as string,
    interviewId: interviewId as string,
    shareToken,
  });

  useEffect(() => {
    if (isInterviewError) {
      enqueueSnackbar({
        message: isSharedView
          ? 'Your link is expired or invalid. Please try again with a new link.'
          : 'Interview details not available at this time. Please try again later.',
        variant: 'info',
      });
      navigate(NAV_ROUTES.RECENT_INTERVIEWS);
    }
  }, [isSharedView, isInterviewError, navigate, enqueueSnackbar]);

  const { data: notesData, isLoading: isLoadingNotes } = useInterviewNotesQuery(
    {
      organizationId: orgId as string,
      interviewId: interviewId as string,
    },
  );

  const handleDownloadResume = () => {
    if (interviewData?.resume?.url) {
      downloadFile(interviewData.resume.url);
    }
  };

  const handleGenerateShareUrl = async () => {
    setShowShareLinkModal(true);
    if (interviewId && interviewData && orgId) {
      try {
        const { protocol, host } = window.location;
        const result = await getSharingUrl({
          organizationId: orgId,
          interviewId,
          jobDescription: interviewData?.job_listing.description,
          jobTitle: interviewData?.job_title,
        }).unwrap();
        const url = `${protocol}//${host}/interview/access/${result.token}`;
        setSharingUrl(url);
      } catch (e) {
        console.log('error generating link: ', e);
      }
    }
  };

  const handleClose = () => {
    setShowShareLinkModal(false);
    setIsTextCopied(false);
  };
  const resumeDetails = interviewData?.resume?.analyses[0]?.details;

  const handleDownloadPdf = () => {
    if (interviewData?.pdf_url) {
      downloadFile(interviewData.pdf_url);
    }
  };

  if (isLoadingNotes || isLoadingInterviews) return <Loading />;

  return (
    <>
      <div className="grid grid-cols-12 gap-x-6 gap-y-10">
        <div className="col-span-12">
          <div className="mb-6 flex flex-col md:h-10 md:flex-row md:items-center">
            <Button
              variant="text"
              onClick={() => navigate(NAV_ROUTES.RECENT_INTERVIEWS)}
              className="flex justify-start text-base font-medium group-[.mode--light]:text-white"
            >
              <Lucide
                icon="ChevronLeftCircle"
                className="mr-2 size-5 stroke-[1.3]"
              />
              <div className="flex">Return to Interview List</div>
            </Button>
          </div>

          <div className="flex items-center">
            <img
              alt="avatar"
              className="mr-2 !h-9 !w-9 self-center rounded-full md:mr-4"
              src={
                interviewData?.candidate_avatar_url.includes('gravatar')
                  ? defaultAvatar
                  : interviewData?.candidate_avatar_url
              }
            />
            <div className="flex grow flex-col">
              <div className="text-base font-medium group-[.mode--light]:text-white md:text-3xl">
                {`${interviewData?.candidate_name}`}
              </div>
              <div className="text-sm group-[.mode--light]:text-white md:text-base">
                {`Interviewing for ${interviewData?.job_title}`}
              </div>
            </div>
            {!isSharedView && (
              <div className="content-center">
                {interviewData?.pdf_url && (
                  <Button
                    className="mr-2 bg-white"
                    variant="secondary"
                    onClick={handleDownloadPdf}
                  >
                    <Lucide icon="FileDown" className="mr-2 size-4" />
                    Download PDF
                  </Button>
                )}
                <Button
                  className="bg-white"
                  variant="secondary"
                  onClick={handleGenerateShareUrl}
                >
                  <Lucide icon="Link" className="mr-2 size-4" />
                  Share
                </Button>
              </div>
            )}
          </div>

          <div className="mt-3 grid grid-cols-12 gap-x-3">
            {/* LEFT COLUMN */}
            <div className="order-2 col-span-12 md:order-1 md:col-span-8 ">
              <div className="box box--stacked mb-6 flex flex-col">
                <div className="rounded-[0.6rem] border-dashed border-slate-200/60 p-3 dark:border-darkmode-400 md:p-5">
                  {resumeDetails && (
                    <Disclosure.Group variant="boxed">
                      <Disclosure className="rounded-[0.6rem]">
                        <Disclosure.Button>
                          <div className="flex items-center border-b border-dashed border-slate-200/60 pb-5 text-[0.94rem] font-medium text-[#181D2C] ">
                            <Lucide
                              icon="ChevronDown"
                              className="mr-2 size-5 stroke-[1.3]"
                            />
                            <div className="flex grow">
                              {`About ${interviewData?.candidate_name}`}
                            </div>
                            {interviewData?.resume?.url && (
                              <Button
                                onClick={(
                                  evt: React.MouseEvent<HTMLButtonElement>,
                                ) => {
                                  evt.preventDefault();
                                  handleDownloadResume();
                                }}
                              >
                                <Lucide className="mr-2" icon="ScrollText" />
                                <div className="font-light">
                                  Download Résumé
                                </div>
                              </Button>
                            )}
                          </div>
                        </Disclosure.Button>

                        <Disclosure.Panel>
                          <div className="mx-4 flex justify-between">
                            <IconInfoBox
                              containerClasses="my-3"
                              icon="MapPinned"
                              primaryText="Location"
                              secondaryText={
                                resumeDetails.current_location || '--'
                              }
                            />
                            <IconInfoBox
                              containerClasses="my-3"
                              icon="User"
                              primaryText="Current Title"
                              secondaryText={
                                resumeDetails.current_title || '--'
                              }
                            />
                            <IconInfoBox
                              containerClasses="my-3"
                              icon="User"
                              primaryText="Experience Level"
                              secondaryText={
                                resumeDetails.experience_level || '--'
                              }
                            />
                          </div>
                        </Disclosure.Panel>
                      </Disclosure>
                    </Disclosure.Group>
                  )}

                  <div className="mt-3.5">
                    <div className="mb-3.5 text-base font-medium text-[#181D2C]">
                      Overview ✨
                    </div>
                    <InterviewAnalysisPanel interview={interviewData} />
                  </div>

                  <div className="mt-2">
                    <InterviewSummaryPanel
                      shortSummaryText={
                        interviewData?.detailed_commentary?.overview ||
                        'No summary available. Please check again later.'
                      }
                      longSummaryText=""
                      commentary={interviewData?.detailed_commentary}
                    />
                  </div>
                </div>
              </div>
              {notesData && (
                <div className="mt-2">
                  <InterviewNotesPanel
                    notes={notesData?.results}
                    orgId={orgId}
                    interviewId={interviewId}
                  />
                </div>
              )}

              {interviewData?.interview_question_analyses && (
                <div className="mt-2">
                  <InterviewTranscriptPanel
                    analyses={interviewData.interview_question_analyses}
                    transcriptDownloadUrl={
                      interviewData.full_interview_transcript_url
                    }
                  />
                </div>
              )}
            </div>

            {/* RIGHT COLUMN */}
            <div className="order-1 col-span-12 md:order-2 md:col-span-4">
              <div>
                {interviewData && (
                  <div className="relative w-full">
                    {interviewData.full_interview_video_url && (
                      <InterviewVideoPlayer
                        interviewAnalyses={
                          interviewData.interview_question_analyses
                        }
                        videoUrl={interviewData.full_interview_video_url}
                        boxStyling
                      />
                    )}
                  </div>
                )}
              </div>

              {resumeDetails && !isSharedView && (
                <CandidateInfoPanel
                  candidateName={interviewData?.candidate_name || 'Candidate'}
                  candidateLocation={resumeDetails?.current_location || '--'}
                  candidateTitle={resumeDetails?.current_title || '--'}
                  candidateExperienceLevel={
                    resumeDetails?.experience_level || '--'
                  }
                  resumeUrl={interviewData?.resume?.url}
                />
              )}

              {!isSharedView && (
                <CandidateContactPanel
                  interview={interviewData}
                  candidatePhone={resumeDetails?.phone_number}
                  showSendMessageButton
                />
              )}

              {interviewData?.job_listing && (
                <JobPostingDetailsPanel
                  orgLogoUrl={interviewData?.organization_logo_url}
                  orgName={interviewData?.organization}
                  jobListing={interviewData?.job_listing}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <InterviewShareLinkModal
        open={showShareLinkModal}
        sharingUrl={sharingUrl}
        summaryText={interviewData?.detailed_commentary?.overview}
        feedbackContent={interviewData?.pros_and_cons}
        isLoading={isLoadingShareLink}
        isTexTCopied={isTexTCopied}
        setIsTextCopied={setIsTextCopied}
        handleClose={handleClose}
      />
    </>
  );
}

export { InterviewDetails };
