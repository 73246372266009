import { useSelector } from 'react-redux';

import { selectJobCategories, selectJobSubcategories } from '@store/selectors';

export const useCategoryOptions = (orgId: string) => {
  const categories = useSelector(selectJobCategories(orgId));

  return (categories || []).map(({ id, name }) => ({
    name,
    value: id,
  }));
};

export const useSubcategoryOptions = (orgId: string) => {
  const subcategories = useSelector(selectJobSubcategories(orgId));

  return (subcategories || []).map(({ id, name }) => ({
    name,
    value: id,
  }));
};
