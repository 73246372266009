import {
  DoughnutChart,
  GraphCard,
  ReportLineChart,
  getColor,
} from '@kerplunkai/common-components';
import { useSelector } from 'react-redux';

import spacetime from 'spacetime';
import { ChartData, ChartDataItem } from '@typings/organization';
import { NewFeatureCard } from '@modules/dashboard/newFeatureCard.component';
import { selectSelectedOrgId } from '@store/selectors';
import { useStatisticsQuery } from '@store/services';

interface SummaryProps {
  dateRange: string;
}

function Summary({ dateRange }: SummaryProps) {
  const orgId = useSelector(selectSelectedOrgId);

  const { data: statistics } = useStatisticsQuery(
    {
      orgId: orgId as string,
      startDate: spacetime(dateRange.split('-')[0]).format(
        '{year}-{iso-month}-{date-pad}',
      ),
      endDate: spacetime(dateRange.split('-')[1]).format(
        '{year}-{iso-month}-{date-pad}',
      ),
    },
    { skip: !orgId || dateRange === '' },
  );

  const getChartData = (data: ChartData | undefined) => {
    if (!data) return { current: [], previous: [] };
    return {
      current: data.current_period.map((item: ChartDataItem) => item.count),
      previous: data.previous_period.map((item: ChartDataItem) => item.count),
    };
  };

  const interviewChartData = getChartData(statistics?.interviews_per_day);
  const jobApplicationChartData = getChartData(
    statistics?.job_applications_per_day,
  );

  return (
    <div className="mt-3.5 grid grid-cols-12 gap-5">
      <div className="col-span-12 flex flex-col md:col-span-6 2xl:col-span-3">
        <NewFeatureCard />
      </div>
      <div className="col-span-12 flex flex-col md:col-span-6 2xl:col-span-3">
        <GraphCard
          chart={
            <ReportLineChart
              className="relative z-10 -ml-1.5"
              height={100}
              borderColor={getColor('primary')}
              backgroundColor={getColor('primary', 0.3)}
              data={[interviewChartData.current, interviewChartData.previous]}
            />
          }
          icon={{
            innerClassName: 'bg-sky-800/10 border-sky-800/10',
            className: 'fill-sky-800/10',
            name: 'Database',
          }}
          legend={[
            {
              className: 'bg-sky-800/70',
              text: '30 days',
            },
            {
              className: 'bg-slate-300',
              text: 'Prior 30 days',
            },
          ]}
          showDots
          subtitle={`Across ${statistics?.job_listings_count || 0} opening(s)`}
          title={`${statistics?.interview_counts.current_period || 0} New interviews`}
        />
      </div>
      <div className="col-span-12 flex flex-col md:col-span-6 2xl:col-span-3">
        <GraphCard
          chart={
            <ReportLineChart
              className="relative z-10 -ml-1.5"
              height={100}
              borderColor={getColor('success')}
              backgroundColor={getColor('success', 0.3)}
              data={[
                jobApplicationChartData.current,
                jobApplicationChartData.previous,
              ]}
            />
          }
          icon={{
            innerClassName: 'bg-teal-700/10 border-teal-700/10',
            className: 'stroke-teal-700 text-success',
            name: 'Files',
          }}
          legend={[
            {
              className: 'bg-teal-700 ',
              text: 'Current',
            },
            {
              className: 'bg-slate-300',
              text: 'Previous',
            },
          ]}
          showDots
          subtitle={`${statistics?.job_applications_daily_change || 0} daily change`}
          title={`${statistics?.job_applications_count || 0} Job Applications`}
        />
      </div>
      <div className="col-span-12 flex flex-col md:col-span-6 2xl:col-span-3">
        <GraphCard
          chart={
            <div className="h-[100px]">
              <DoughnutChart
                chartData={[
                  statistics?.candidates_count || 0,
                  statistics?.resumes_count || 0,
                ]}
              />
            </div>
          }
          icon={{
            innerClassName: 'bg-sky-800/10 border-sky-800/10',
            className: 'stroke-sky-800',
            name: 'Zap',
          }}
          legend={[
            {
              className: 'bg-sky-800/70',
              text: 'Resumes',
            },
            {
              className: 'bg-rose-800',
              text: 'Candidates',
            },
          ]}
          subtitle={`${statistics?.candidates_count || 0} Candidates`}
          title={`${statistics?.resumes_count || 0} Resumes`}
        />
      </div>
    </div>
  );
}

export { Summary };
