interface InterviewCategoryChipProps {
  categoryName: string;
  className?: string;
}

function InterviewCategoryChip({
  categoryName,
  className,
}: InterviewCategoryChipProps) {
  return (
    <div
      className={`ml-2 w-fit content-center rounded-md border bg-[#0759851A] px-2 text-xs font-normal text-[#075985] ${className || ''}`}
    >
      {categoryName}
    </div>
  );
}

export { InterviewCategoryChip };
