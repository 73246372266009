import { Button, Lucide } from '@kerplunkai/common-components';
import { Dialog, Transition } from '@headlessui/react';
import {
  Dispatch,
  Fragment,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import {
  CreateIntegrationRequest,
  Integration as IntegrationType,
  OrgIntegration,
} from '@typings';
import { IntegrationFormWrapper } from '@modules/integrations/forms';
import { getAddIntegrationText } from '@utilities';
import { selectSelectedOrgId } from '@store/selectors';
import {
  useCreateIntegrationMutation,
  useDeleteIntegrationMutation,
  useUpdateIntegrationMutation,
} from '@store/services';

interface IntegrationModalProps {
  integrationType: IntegrationType | null;
  integrations: OrgIntegration[];
  open: boolean;
  toggleOpen: Dispatch<SetStateAction<boolean>>;
  onActionSuccess: (
    actionType: 'ADD' | 'UPDATE' | 'REMOVE',
    integration: OrgIntegration | null,
    integrationId?: string,
  ) => void;
}

function IntegrationModal({
  integrationType,
  integrations,
  open,
  toggleOpen,
  onActionSuccess,
}: IntegrationModalProps) {
  const orgId = useSelector(selectSelectedOrgId);

  const [createIntegration, { isLoading }] = useCreateIntegrationMutation();
  const [updateIntegration, { isLoading: isUpdating }] =
    useUpdateIntegrationMutation();
  const [deleteIntegration, { isLoading: isDeleting }] =
    useDeleteIntegrationMutation();

  const [curIntegrationType, setCurIntegrationType] = useState(integrationType);
  const [addNewOpen, setAddNewOpen] = useState(integrations.length === 0);

  useEffect(() => {
    if (integrationType) setCurIntegrationType(integrationType);
    else setTimeout(() => setCurIntegrationType(null), 300);
  }, [integrationType]);

  const handleCreateIntegration = useCallback(
    ({ parameters, ...values }: CreateIntegrationRequest) => {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise<void>(async (resolve, reject) => {
        const payload: CreateIntegrationRequest & {
          organizationId: string;
        } = {
          organizationId: orgId as string,
          ...values,
          type: `Integrations::${curIntegrationType?.type}`,
        };

        if (parameters) payload.parameters = parameters;

        const result = values.id
          ? await updateIntegration({ ...payload, integrationId: values.id })
          : await createIntegration(payload);

        if ('error' in result) {
          reject();
        } else {
          onActionSuccess(values.id ? 'UPDATE' : 'ADD', result.data, values.id);

          resolve();
        }
      });
    },
    [
      createIntegration,
      updateIntegration,
      orgId,
      curIntegrationType,
      onActionSuccess,
    ],
  );

  const handleDeleteIntegration = useCallback(
    (integrationId: string) => {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise<void>(async (resolve, reject) => {
        const result = await deleteIntegration({
          organizationId: orgId as string,
          integrationId,
        });

        if ('error' in result) {
          reject();
        } else {
          onActionSuccess('REMOVE', null, integrationId);

          resolve();
        }
      });
    },
    [deleteIntegration, orgId, onActionSuccess],
  );

  const allowMore = useMemo(() => {
    if (!curIntegrationType || curIntegrationType.maxNum === undefined)
      return true;

    return curIntegrationType.maxNum > integrations.length;
  }, [curIntegrationType, integrations]);

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => toggleOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[43rem] overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="div" className="grid grid-cols-12">
                  <div className="col-span-3" />
                  <h3 className="col-span-6 flex-1 text-center text-sm text-[#181D2C]">
                    {`${curIntegrationType?.name} Integration`}
                  </h3>
                  <div className="col-span-3 flex justify-end">
                    <Button
                      className="p-0 text-sm capitalize text-slate-400"
                      variant="text"
                      onClick={() => toggleOpen(false)}
                    >
                      <Lucide icon="X" />
                    </Button>
                  </div>
                </Dialog.Title>
                {curIntegrationType && (
                  <div className="mt-4 flex w-full flex-col">
                    <div className="my-3.5 flex flex-col gap-6">
                      {integrations.map(integration => (
                        <IntegrationFormWrapper
                          key={integration.id}
                          integration={integration}
                          integrationType={curIntegrationType}
                          isLoading={isLoading || isDeleting || isUpdating}
                          onClose={() => setAddNewOpen(false)}
                          onDelete={handleDeleteIntegration}
                          onSubmit={handleCreateIntegration}
                        />
                      ))}
                      {addNewOpen && allowMore && (
                        <IntegrationFormWrapper
                          integrationType={curIntegrationType}
                          isLoading={isLoading || isDeleting || isUpdating}
                          onClose={() => setAddNewOpen(false)}
                          onSubmit={handleCreateIntegration}
                        />
                      )}
                      {!addNewOpen && allowMore && (
                        <Button
                          className="flex gap-1.5 rounded-md border-dashed border-sky-800 text-sky-800"
                          disabled={isLoading || isDeleting}
                          onClick={() => setAddNewOpen(true)}
                        >
                          <Lucide icon="Plus" />
                          Add Another{' '}
                          {getAddIntegrationText(curIntegrationType)}
                        </Button>
                      )}
                    </div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export { IntegrationModal };
