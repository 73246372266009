import { Button } from '@kerplunkai/common-components';

function Terms() {
  return (
    <div className="mt-4 w-full text-center text-xs text-slate-500">
      <p className="xl:text-nowrap">
        By continuing, you agree to Kerplunk’s{' '}
        <Button
          as="a"
          href="https://www.kerplunk.com/terms"
          target="_blank"
          rel="noopener noreferrer"
          variant="text"
          className="mr-1 underline"
        >
          Terms of Service
        </Button>
        and
        <Button
          as="a"
          href="https://www.kerplunk.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
          variant="text"
          className="ml-1 underline"
        >
          Privacy Policy
        </Button>
        .
      </p>
    </div>
  );
}

export { Terms };
