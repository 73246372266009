import { Button, Lucide } from '@kerplunkai/common-components';
import { downloadFile } from '@utilities';

import { IconInfoBox } from '@modules/interviewDetails/components';

interface CandidateInfoPanelProps {
  candidateName: string;
  candidateLocation: string;
  candidateTitle: string;
  candidateExperienceLevel: string;
  resumeUrl?: string;
}

function CandidateInfoPanel({
  candidateName,
  candidateLocation,
  candidateTitle,
  candidateExperienceLevel,
  resumeUrl,
}: CandidateInfoPanelProps) {
  return (
    <div className="box mb-5 p-5">
      <div className="text-base font-medium">{candidateName}</div>
      <IconInfoBox
        iconClassName="stroke-sky-800"
        containerClasses="my-3"
        icon="MapPinned"
        primaryText="Location"
        secondaryText={candidateLocation}
      />
      <IconInfoBox
        iconClassName="stroke-sky-800"
        containerClasses="my-3"
        icon="SquareUser"
        primaryText="Current Title"
        secondaryText={candidateTitle}
      />
      <IconInfoBox
        iconClassName="stroke-sky-800"
        containerClasses="my-3"
        icon="SquareUser"
        primaryText="Experience Level"
        secondaryText={candidateExperienceLevel}
      />
      {resumeUrl && (
        <Button onClick={() => downloadFile(resumeUrl)}>
          <Lucide className="mr-2" icon="ScrollText" />
          <div className="font-light">Download Résumé</div>
        </Button>
      )}
    </div>
  );
}

export { CandidateInfoPanel };
