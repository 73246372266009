/* eslint-disable no-underscore-dangle */
import { FormCard } from '@kerplunkai/common-components';

import { InterviewRequest } from '@typings';

interface MeetingGuestsProps {
  interview: InterviewRequest;
}

function MeetingGuests({ interview }: MeetingGuestsProps) {
  return (
    <FormCard title="Meeting Guests">
      {interview.interview_attendees_attributes.map(attendee => {
        if (attendee._destroy) return null;

        return (
          <div
            key={attendee.email}
            className="pt-5 font-medium text-slate-600 first:mt-0 first:pt-0"
          >
            {attendee.email}
          </div>
        );
      })}
    </FormCard>
  );
}

export { MeetingGuests };
