/* eslint-disable @typescript-eslint/no-unused-vars */
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
  Button,
  FormControl,
  LoadingIcon,
  Lucide,
} from '@kerplunkai/common-components';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useCallback } from 'react';
import { useField } from 'formik';
import { useSnackbar } from 'notistack';

import { JobRequest } from '@typings';
import { useLazyGenerateJobDescriptionQuery } from '@store/services';

interface JobDescriptionFormProps {
  isLoading: boolean;
  job: JobRequest;
  onChange: (name: string, value: number | boolean | string | string[]) => void;
}

function JobDescriptionForm({
  isLoading,
  job,
  onChange,
}: JobDescriptionFormProps) {
  const [descriptionField, descriptionMeta] = useField('description');

  const { enqueueSnackbar } = useSnackbar();

  const [getJobDescription, { isFetching: isFetchingDescription }] =
    useLazyGenerateJobDescriptionQuery();

  const handleGenerateDescription = useCallback(async () => {
    try {
      const descriptionResponse = await getJobDescription(job).unwrap();

      onChange('description', descriptionResponse.description);
    } catch (err) {
      enqueueSnackbar({
        message: 'Error generating description. Please try again.',
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, job, getJobDescription, onChange]);

  return (
    <div className="mt-5">
      <FormControl
        className="mt-5 !items-start text-right"
        isRequired
        helpProps={{
          className: 'text-slate-400 flex justify-end',
        }}
        description="Craft a comprehensive description that highlights the unique features, benefits, and specifications of you open position."
        label="Job Description"
        helpText={`Maximum characters ${job.description.length}/10,000`}
        childrenClassName="xl:max-w-[calc(100%-219px)] xl:!w-0 "
        labelAddon={
          <div className="flex gap-x-5">
            <Button
              className="w-fit p-3"
              disabled={isLoading || isFetchingDescription}
              variant="primary"
              onClick={handleGenerateDescription}
            >
              <Lucide icon="Wand2" className="mr-2" />
              Generate Description
            </Button>
            {isFetchingDescription && (
              <div className="size-8 self-center">
                <LoadingIcon icon="oval" />
              </div>
            )}
          </div>
        }
      >
        <div>
          <div className={descriptionMeta.error ? 'border border-danger' : ''}>
            <CKEditor
              editor={ClassicEditor}
              data={job.description}
              disabled={isLoading || isFetchingDescription}
              onChange={(_, editor) => {
                onChange('description', editor.getData());
              }}
            />
          </div>
          {descriptionMeta.error && (
            <div className="mt-2 flex justify-start text-xs text-danger">
              {descriptionMeta.error}
            </div>
          )}
        </div>
      </FormControl>
    </div>
  );
}

export { JobDescriptionForm };
