import clsx from 'clsx';
import { Button, Lucide, icons } from '@kerplunkai/common-components';

interface IconInfoBoxProps {
  icon: keyof typeof icons;
  primaryText: string;
  secondaryText: string;
  iconClassName?: string;
  containerClasses?: string;
  onClick?: () => void;
}

function IconInfoBox({
  icon,
  primaryText,
  secondaryText,
  containerClasses,
  iconClassName,
  onClick,
}: IconInfoBoxProps) {
  return (
    <div className={clsx(['mr-3 flex', containerClasses])}>
      <Lucide
        className={clsx([
          'size-6 self-center stroke-blue-950 stroke-[1.5]',
          iconClassName,
        ])}
        icon={icon}
      />
      <div className="ml-3">
        <div className="text-xs font-light text-slate-500">{primaryText}</div>

        {onClick && (
          <Button variant="text" onClick={onClick}>
            {secondaryText}
          </Button>
        )}
        {!onClick && (
          <div className="text-sm font-semibold text-slate-600">
            {secondaryText}
          </div>
        )}
      </div>
    </div>
  );
}

export { IconInfoBox };
