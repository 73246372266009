/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-new */
import SimpleBar from 'simplebar';
import clsx from 'clsx';
import { Button, Lucide } from '@kerplunkai/common-components';
import { MouseEvent, createRef, useCallback, useEffect, useState } from 'react';
import { Transition } from 'react-transition-group';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  CLIENT_MENU_ITEMS,
  FILTER_ORG_ID,
  MENU,
  MENU_ACTIONS,
  NAV_ROUTES,
  NEW_ROUTES,
} from '@constants';
import { FormattedMenu, enter, leave, nestedMenu } from '@utilities';
import { SideMenuButton } from '@modules/layout/sideMenuButton.component';
import {
  selectActiveMobileMenu,
  selectCompactMenu,
  selectSelectedOrg,
} from '@store/selectors';
import {
  setActiveMobileMenu,
  setCompactMenu,
  setCompactMenuOnHover,
} from '@store/slices/layout.slice';
import { useAppDispatch } from '@store/hooks';

import logoFull from '@assets/images/logos/Kerplunk-Logo-blk.svg';

function SideMenu() {
  const scrollableRef = createRef<HTMLDivElement>();

  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const compactMenu = useSelector(selectCompactMenu);
  const activeMobileMenu = useSelector(selectActiveMobileMenu);
  const organization = useSelector(selectSelectedOrg);

  const [formattedMenu, setFormattedMenu] = useState<
    Array<FormattedMenu | string>
  >([]);
  const formattedActionMenu = nestedMenu(MENU_ACTIONS, location);

  const sideMenu = useCallback(() => {
    const fullMenu = [...MENU];

    if (organization?.industry.name === 'Recruiting')
      fullMenu.push(...CLIENT_MENU_ITEMS);

    return nestedMenu(fullMenu, location);
  }, [location, organization]);

  const compactLayout = () => {
    if (window.innerWidth <= 1600) {
      setCompactMenu(true);
    }
  };

  useEffect(() => {
    if (scrollableRef.current) {
      new SimpleBar(scrollableRef.current);
    }

    setFormattedMenu(sideMenu());
    compactLayout();

    window.onresize = () => {
      compactLayout();
    };
  }, [sideMenu]);

  const toggleCompactMenu = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      dispatch(setCompactMenu(!compactMenu));
    },
    [dispatch, compactMenu],
  );

  return (
    <>
      <div
        className={clsx([
          'fixed z-50 ml-[275px] size-10 items-center justify-center xl:hidden',
          { flex: activeMobileMenu },
          { hidden: !activeMobileMenu },
        ])}
      >
        <Button
          className="ml-5 mt-5"
          variant="text"
          onClick={() => dispatch(setActiveMobileMenu(false))}
        >
          <Lucide icon="X" className="size-8 text-white" />
        </Button>
      </div>
      <div
        className={clsx([
          'box relative z-20 flex h-full w-[275px] flex-col overflow-hidden rounded-none bg-white/[0.95] transition-[width] duration-300 xl:rounded-xl group-[.side-menu--collapsed.side-menu--on-hover]:xl:w-[275px] group-[.side-menu--collapsed]:xl:w-[91px] group-[.side-menu--collapsed.side-menu--on-hover]:xl:shadow-[6px_0_12px_-4px_#0000000f]',
        ])}
        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
        onMouseOver={() => dispatch(setCompactMenuOnHover(true))}
        onMouseLeave={() => dispatch(setCompactMenuOnHover(false))}
        // onFocus={() => dispatch(setCompactMenuOnHover(true))}
      >
        <div
          className={clsx([
            'relative z-10 hidden h-[65px] w-[275px] flex-none items-center overflow-hidden px-5 duration-300 xl:flex group-[.side-menu--collapsed.side-menu--on-hover]:xl:w-[275px] group-[.side-menu--collapsed]:xl:w-[91px]',
          ])}
        >
          <Button
            className="w-full max-w-[134px] justify-start overflow-hidden group-[.side-menu--collapsed]:xl:mx-2 group-[.side-menu--collapsed.side-menu--on-hover]:xl:ml-0"
            variant="text"
            onClick={() => navigate('/')}
          >
            <img className="min-w-32" alt="Logo" src={logoFull} />
          </Button>
          <Button
            className="ml-auto flex size-[20px] items-center justify-center rounded-full border border-slate-600/40 transition-[opacity,transform] hover:bg-slate-600/5 group-[.side-menu--collapsed:not(.side-menu--on-hover)]:hidden group-[.side-menu--collapsed]:xl:rotate-180 group-[.side-menu--collapsed.side-menu--on-hover]:xl:opacity-100 group-[.side-menu--collapsed]:xl:opacity-0"
            variant="text"
            onClick={toggleCompactMenu}
          >
            <Lucide icon="ArrowLeft" className="size-3.5 stroke-[1.3]" />
          </Button>
        </div>
        <div
          ref={scrollableRef}
          className={clsx([
            'no-scrollbar z-20 size-full overflow-y-auto overflow-x-hidden px-5 pb-3 pt-4 xl:pt-0',
            '[&_.simplebar-content]:p-0 [&_.simplebar-track.simplebar-vertical]:mr-0.5 [&_.simplebar-track.simplebar-vertical]:w-0 [&_.simplebar-track.simplebar-vertical_.simplebar-scrollbar]:before:bg-slate-400/30',
          ])}
        >
          <ul className="scrollable no-scrollbar">
            <div className="rounded-[.6rem] border border-slate-200">
              {formattedActionMenu
                .filter(menu => {
                  if (organization?.id !== FILTER_ORG_ID)
                    return typeof menu === 'string'
                      ? true
                      : menu.pathname !== NEW_ROUTES.NEW_MEETING;

                  return true;
                })
                .map((menu, menuKey) =>
                  typeof menu === 'string' ? (
                    <li
                      className="side-menu__divider"
                      key={`top-level-action-${menuKey}`}
                    >
                      {menu}
                    </li>
                  ) : (
                    <li key={`top-level-action-${menuKey}`}>
                      <SideMenuButton menu={menu} />
                    </li>
                  ),
                )}
            </div>
            {/* BEGIN: First Child */}
            {formattedMenu
              .filter(menu => {
                if (organization?.id !== FILTER_ORG_ID)
                  return typeof menu === 'string'
                    ? true
                    : menu.pathname !== NAV_ROUTES.MEETINGS;

                return true;
              })
              .map((menu, menuKey) =>
                typeof menu === 'string' ? (
                  <li
                    className="side-menu__divider"
                    key={`top-level-${menuKey}`}
                  >
                    {menu}
                  </li>
                ) : (
                  <li key={`top-level-${menuKey}`}>
                    <SideMenuButton menu={menu} />
                    {/* BEGIN: Second Child */}
                    {menu.subMenu && (
                      <Transition
                        in={menu.activeDropdown}
                        onEnter={enter}
                        onExit={leave}
                        timeout={300}
                      >
                        <ul
                          className={clsx([
                            '',
                            { block: menu.activeDropdown },
                            { hidden: !menu.activeDropdown },
                          ])}
                        >
                          {menu.subMenu.map((subMenu, subMenuKey) => (
                            <li key={subMenuKey}>
                              <SideMenuButton menu={subMenu} />
                              {/* BEGIN: Third Child */}
                              {subMenu.subMenu && (
                                <Transition
                                  in={subMenu.activeDropdown}
                                  onEnter={enter}
                                  onExit={leave}
                                  timeout={300}
                                >
                                  <ul
                                    className={clsx([
                                      '',
                                      {
                                        block: subMenu.activeDropdown,
                                      },
                                      { hidden: !subMenu.activeDropdown },
                                    ])}
                                  >
                                    {subMenu.subMenu.map(
                                      (lastSubMenu, lastSubMenuKey) => (
                                        <li key={lastSubMenuKey}>
                                          <SideMenuButton menu={lastSubMenu} />
                                        </li>
                                      ),
                                    )}
                                  </ul>
                                </Transition>
                              )}
                            </li>
                          ))}
                        </ul>
                      </Transition>
                    )}
                  </li>
                ),
              )}
          </ul>
        </div>
      </div>
    </>
  );
}

export { SideMenu };
