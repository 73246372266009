import { Button, Lucide } from '@kerplunkai/common-components';
import { Interview } from '@typings';

import tiktokIcon from '@assets/icons/tiktok_icon.svg';
import xTwitterIcon from '@assets/icons/x_twitter_icon.svg';

interface SocialLinkIconButtonsProps {
  interview?: Interview;
}

const IMG_ICON_SIZE_PX = 24;

const BUTTON_CLASSES = 'mb-3 mr-3 flex items-center rounded-full';
const LABEL_CLASSES = 'ml-2 flex text-sm font-semibold';

function SocialLinksWithLabel({ interview }: SocialLinkIconButtonsProps) {
  return (
    <div className="flex flex-wrap">
      {/* linkedin */}
      {interview?.candidate_linked_in_url && (
        <Button
          variant="text"
          className={BUTTON_CLASSES}
          onClick={() =>
            window.open(interview.candidate_linked_in_url, '_blank')
          }
        >
          <Lucide className="size-6 stroke-sky-800" icon="Linkedin" />
          <div className={LABEL_CLASSES}>LinkedIn</div>
        </Button>
      )}

      {/* twitter/x */}
      {interview?.candidate_twitter_x_url && (
        <Button
          variant="text"
          className={BUTTON_CLASSES}
          onClick={() =>
            window.open(interview.candidate_twitter_x_url, '_blank')
          }
        >
          <img
            src={xTwitterIcon}
            alt="X"
            width={IMG_ICON_SIZE_PX}
            height={IMG_ICON_SIZE_PX}
          />
          <div className={LABEL_CLASSES}>X (Twitter)</div>
        </Button>
      )}

      {/* instagram */}
      {interview?.candidate_instagram_url && (
        <Button
          variant="text"
          className={BUTTON_CLASSES}
          onClick={() =>
            window.open(interview.candidate_instagram_url, '_blank')
          }
        >
          <Lucide
            className="size-6 self-center stroke-sky-800"
            icon="Instagram"
          />
          <div className={LABEL_CLASSES}>Instagram</div>
        </Button>
      )}

      {/* facebook */}
      {interview?.candidate_facebook_url && (
        <Button
          variant="text"
          className={BUTTON_CLASSES}
          onClick={() =>
            window.open(interview.candidate_facebook_url, '_blank')
          }
        >
          <Lucide
            className="size-6 self-center stroke-sky-800"
            icon="Facebook"
          />
          <div className={LABEL_CLASSES}>Facebook</div>
        </Button>
      )}

      {/* tiktok */}
      {interview?.candidate_tiktok_url && (
        <Button
          variant="text"
          className={BUTTON_CLASSES}
          onClick={() => window.open(interview.candidate_tiktok_url, '_blank')}
        >
          <img
            src={tiktokIcon}
            alt="TikTok"
            width={IMG_ICON_SIZE_PX}
            height={IMG_ICON_SIZE_PX}
          />
          <div className={LABEL_CLASSES}>TikTok</div>
        </Button>
      )}

      {/* github */}
      {interview?.candidate_github_url && (
        <Button
          variant="text"
          className={BUTTON_CLASSES}
          onClick={() => window.open(interview.candidate_github_url, '_blank')}
        >
          <Lucide className="size-6 self-center stroke-sky-800" icon="Github" />
          <div className={LABEL_CLASSES}>GitHub</div>
        </Button>
      )}

      {/* portfolio */}
      {interview?.candidate_portfolio_url && (
        <Button
          variant="text"
          className={BUTTON_CLASSES}
          onClick={() =>
            window.open(interview.candidate_portfolio_url, '_blank')
          }
        >
          <Lucide className="size-6 self-center stroke-sky-800" icon="Link" />
          <div className={LABEL_CLASSES}>Portfolio</div>
        </Button>
      )}
    </div>
  );
}

export { SocialLinksWithLabel };
