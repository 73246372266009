import {
  Button,
  Disclosure,
  FormActions,
  FormTextarea,
  Lucide,
} from '@kerplunkai/common-components';
import { InterviewNote } from '@typings';
import {
  useCreateInterviewNoteMutation,
  useDeleteInterviewNoteMutation,
  useUpdateInterviewNoteMutation,
} from '@store/services';

import { useEffect, useMemo, useState } from 'react';

import spacetime from 'spacetime';
import { enqueueSnackbar } from 'notistack';

import { InterviewNotePanel } from '@modules/interviewDetails/components';

interface RecruiterNotesPanelProps {
  notes?: InterviewNote[];
  interviewId?: string;
  orgId?: string;
}

function InterviewNotesPanel({
  notes,
  interviewId,
  orgId,
}: RecruiterNotesPanelProps) {
  const [showAddNote, setShowAddNote] = useState(false);
  const [newNoteText, setNewNoteText] = useState('');

  const [createNote, { isError: createError, reset: resetCreate }] =
    useCreateInterviewNoteMutation();

  const [updateNote, { isError: updateError, reset: resetUpdate }] =
    useUpdateInterviewNoteMutation();

  const [deleteNote, { isError: deleteError, reset: resetDelete }] =
    useDeleteInterviewNoteMutation();

  useEffect(() => {
    if (createError || updateError || deleteError) {
      let message = 'An error occurred. Please try again later.';

      if (createError) {
        resetCreate();
        message =
          'There was a problem creating the note. Please try again later.';
      } else if (updateError) {
        resetUpdate();
        message =
          'There was a problem saving the note. Please try again later.';
      } else if (deleteError) {
        resetDelete();
        message =
          'There was a problem deleting the note. Please try again later.';
      }

      enqueueSnackbar({
        message,
        variant: 'error',
      });
    }
  }, [
    createError,
    updateError,
    deleteError,
    resetCreate,
    resetUpdate,
    resetDelete,
  ]);

  const handleSaveNote = () => {
    if (orgId && interviewId) {
      createNote({
        interviewId,
        organizationId: orgId,
        text: newNoteText,
      });

      setNewNoteText('');
      setShowAddNote(false);
    }
  };

  const handleCancelSaveNote = () => {
    setNewNoteText('');
    setShowAddNote(false);
  };

  const handleUpdateNote = async (id: string, text: string) => {
    if (orgId && interviewId) {
      updateNote({
        interviewId,
        interviewNoteId: id,
        organizationId: orgId,
        text,
      });
    }
  };

  const handleDeleteNote = async (id: string) => {
    if (orgId && interviewId) {
      deleteNote({
        interviewId,
        interviewNoteId: id,
        organizationId: orgId,
      });
    }
  };

  const sortedNotes = useMemo(() => {
    return notes?.slice().sort((a, b) => {
      const timeA = spacetime(a.updated_at).epoch;
      const timeB = spacetime(b.updated_at).epoch;
      return timeB - timeA;
    });
  }, [notes]);

  return (
    <Disclosure.Group variant="boxed" openByDefault className="box">
      <Disclosure>
        <Disclosure.Panel className="mt-0 flex w-full text-sm font-normal text-slate-600 dark:text-slate-500">
          <div className="w-full">
            {!showAddNote && (
              <>
                <div className="text-base font-medium">Notes</div>
                <div className="text-xs font-normal">
                  Enter any notes relevant about this candidate
                </div>

                <Button
                  className="mt-4 w-full border-dashed"
                  variant="soft-sky-outline"
                  onClick={() => setShowAddNote(true)}
                >
                  <Lucide icon="Plus" className="mr-2 size-4 stroke-[1.3]" />
                  Add Note
                </Button>
              </>
            )}

            {showAddNote && (
              <>
                <div className="border-b border-dashed border-slate-200/60 pb-5 text-base font-medium">
                  New Note
                </div>
                <div className="pt-5">
                  <FormTextarea
                    className="mb-4"
                    value={newNoteText}
                    name="note"
                    placeholder="Enter Note"
                    onChange={event => setNewNoteText(event.target.value)}
                    autoFocus
                  />
                  <FormActions
                    cancel={{
                      style: { width: '98px', height: '38px' },
                      icon: 'X',
                      text: 'Cancel',
                      onClick: handleCancelSaveNote,
                    }}
                    save={{
                      style: { width: '119px', height: '38px', padding: '8px' },
                      variant: 'primary',
                      disabled: newNoteText === '',
                      icon: 'PenLine',
                      text: 'Save Note',
                      onClick: handleSaveNote,
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </Disclosure.Panel>

        {/* list of existing notes */}
        <div className="mt-8">
          {sortedNotes?.map(note => {
            return (
              <InterviewNotePanel
                key={note.id}
                note={note}
                onDeleteNote={id => handleDeleteNote(id)}
                onSaveNote={(id, text) => handleUpdateNote(id, text)}
              />
            );
          })}
        </div>
      </Disclosure>
    </Disclosure.Group>
  );
}

export { InterviewNotesPanel };
