export interface OrgApplicationRequest {
  applicationId: string;
  organizationId: string;
}

export interface JobApplication {
  id: string;
  title: string;
  organization_id: string;
  job_id: string;
  candidate_name: string;
  candidate_id: string;
  status: 'in_progress' | 'processing' | 'ready_for_review';
  score: Score;
  created_at: Date;
  updated_at: Date;
  analysis_summary: AnalysisSummary;
  interview_url: string;
  resume_url: string;
  score_breakdown: ScoreBreakdown;
  [key: string]: string | Date | AnalysisSummary | ScoreBreakdown | Score;
}

export interface Score {
  cultural: number;
  technical: number;
  softskills: number;
  overall: number;
  x_factor: number;
  resume: number;
}

export interface AnalysisSummary {
  analysis: string;
  softskills: string;
  culture: string;
  feedback: string;
}

export interface ScoreBreakdown {
  interview: ScoringBreakdownSummary;
  resume: ScoringBreakdownSummary;
}

export interface ScoringBreakdownSummary {
  resume_score: number;
  x_factor_score: number;
  technical_score: number;
  culture_fit_score: number;
  soft_skills_score: number;
}

export enum ApplicationStatus {
  'started' = 'In Progress',
  'completed' = 'Processing',
  'analyzed' = 'Ready for Review',
}
