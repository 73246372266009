import spacetime from 'spacetime';
import { ColumnDef, getCoreRowModel } from '@tanstack/react-table';
import {
  DEFAULT_PAGE_INFO,
  ModalDialog,
  TableFilterPredicatesEnum,
  TableRefetchFn,
  TanstackTable,
  TanstackTableActionColumn,
} from '@kerplunkai/common-components';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { JobCategory } from '@typings';
import { NAV_ROUTES, NEW_ROUTES } from '@constants';
import { selectSelectedOrgId } from '@store/selectors';
import {
  useDeleteJobSubcategoryMutation,
  useLazyJobSubcategoriesQuery,
} from '@store/services';

const getColumns = (
  navigate: NavigateFunction,
  onDelete: Dispatch<SetStateAction<string[] | null>>,
): ColumnDef<JobCategory>[] => [
  {
    header: 'Name',
    accessorKey: 'name',
    cell: ({ getValue }) => <p>{getValue<string>()}</p>,
  },
  {
    accessorKey: 'created_at',
    header: 'Date',
    cell: ({ getValue }) => {
      const dt = spacetime(getValue<Date>());

      return (
        <div className="flex flex-col gap-1">
          <p className="text-xs text-slate-400">Date</p>
          <p className="text-sm text-slate-600">
            {dt.format('{month} {date}, {year}')}
          </p>
        </div>
      );
    },
  },
  {
    accessorKey: 'actions',
    header: () => <div className="flex justify-center">Action</div>,
    maxSize: 100,
    cell: ({ row }) => (
      <TanstackTableActionColumn
        data={row}
        actions={[
          {
            icon: 'CheckSquare',
            text: 'Edit',
            onClick: () =>
              navigate(`${NAV_ROUTES.SUBCATEGORIES}/${row.original.id}`),
          },
          {
            icon: 'Trash2',
            text: 'Delete',
            onClick: () => onDelete([row.original.id]),
          },
        ]}
      />
    ),
    enableSorting: false,
    enableColumnFilter: false,
  },
];

function SubcategoriesTable() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const orgId = useSelector(selectSelectedOrgId);

  const [getSubcategories, { data: subcategoriesData, isFetching }] =
    useLazyJobSubcategoriesQuery();
  const [deleteSubcategory, { isLoading: isDeleting }] =
    useDeleteJobSubcategoryMutation();

  const [itemsToDelete, setItemsToDelete] = useState<string[] | null>(null);

  const handleDeleteSubsubcategories = useCallback(
    (subcategoriesToDelete: string[]) => {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise<void>(async (resolve, reject) => {
        const results = await Promise.all(
          subcategoriesToDelete?.map(subcategoryToDelete =>
            deleteSubcategory({
              organizationId: orgId as string,
              jobsubcategoryId: subcategoryToDelete,
            }),
          ),
        );
        const validResults = results.filter(
          result => !(result instanceof Error),
        );

        if (validResults.length === subcategoriesToDelete.length) {
          enqueueSnackbar({
            message: 'Deleting subcategory(s) was successful',
            variant: 'success',
          });
          resolve();
        } else {
          enqueueSnackbar({
            message:
              'Unable to delete some or all subcategory(s). Please try again.',
            variant: 'error',
          });
          reject();
        }

        setItemsToDelete(null);
      });
    },
    [enqueueSnackbar, orgId, deleteSubcategory],
  );

  const handleRefetch: TableRefetchFn = useCallback(
    (pagination, { searchTerm, sorting }) => {
      const hasSearch = searchTerm && searchTerm.length > 0;
      let filterQueryParms = '';

      if (searchTerm && searchTerm.length > 0)
        filterQueryParms = `q[name_${TableFilterPredicatesEnum.cont}]=${searchTerm}`;
      if (sorting) filterQueryParms += `${hasSearch ? '&' : ''}q[s]=${sorting}`;

      getSubcategories({
        orgId: orgId as string,
        pagination,
        filters: filterQueryParms,
      });
    },
    [getSubcategories, orgId],
  );

  return (
    <>
      <TanstackTable
        actionsHeaderItems={{
          addItem: {
            text: 'Add a Subcategory',
            onClick: () => navigate(NEW_ROUTES.NEW_SUBCATEGORY),
          },
        }}
        emptyText="No Subcategory(s) Found"
        initialSorting={{
          initialField: 'name',
          initialOrder: 'ASC',
        }}
        isFetching={isFetching}
        pageInfo={
          subcategoriesData ? subcategoriesData.meta : DEFAULT_PAGE_INFO
        }
        searchPlaceholder="Search Subcategories..."
        selection
        selectionModalProps={{
          title: 'Delete Subcategory(s)',
          description: 'Do you really want to delete these subcategory(s)?',
          confirmText: 'Delete',
          cancelText: 'Cancel',
        }}
        showSearch
        tableOptions={{
          data: subcategoriesData ? subcategoriesData.results : [],
          columns: getColumns(navigate, setItemsToDelete),
          getCoreRowModel: getCoreRowModel(),
          getRowId: row => row.id,
        }}
        onDelete={handleDeleteSubsubcategories}
        onRefetch={handleRefetch}
      />
      <ModalDialog
        open={Boolean(itemsToDelete)}
        title="Delete Subcategory(s)"
        description="Do you really want to delete these subcategory(s)?"
        confirmText="Delete"
        cancelText="Cancel"
        isLoading={isDeleting}
        onConfirm={() =>
          handleDeleteSubsubcategories(itemsToDelete as string[])
        }
        onCancel={() => setItemsToDelete(null)}
      />
    </>
  );
}

export { SubcategoriesTable };
