import {
  Button,
  Disclosure,
  FormActions,
  FormTextarea,
  Lucide,
  ModalDialog,
} from '@kerplunkai/common-components';
import { InterviewNote } from '@typings';
import { useState } from 'react';

import defaultAvatar from '@assets/images/no-pic@2x.png';
import spacetime from 'spacetime';
import { selectCurrentUser } from '@store/selectors';
import { useSelector } from 'react-redux';

interface InterviewNotePanelProps {
  note: InterviewNote;
  onSaveNote: (id: string, text: string) => void;
  onDeleteNote: (id: string) => void;
}

function InterviewNotePanel({
  note,
  onSaveNote,
  onDeleteNote,
}: InterviewNotePanelProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [noteText, setNoteText] = useState(note.content);

  const currentUser = useSelector(selectCurrentUser);

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const handleCancelEdit = () => {
    setIsEditing(false);
    setNoteText(note.content);
  };

  const handleSaveNote = () => {
    setIsEditing(false);
    onSaveNote(note.id, noteText);
  };

  const getFormattedDateTime = () => {
    // goto(null) uses client tz
    const dt = spacetime(note.updated_at).goto(null);
    const minutes = dt.minute();
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return {
      day: dt.format('{month} {date}, {year}'),
      time: `${dt.hour12()}:${formattedMinutes} ${dt.ampm().toUpperCase()}`,
    };
  };

  const dateTime = getFormattedDateTime();
  const isOwnNote = note.creator.id === currentUser?.id;

  return (
    <>
      <Disclosure.Panel
        key={note.id}
        className="w-full text-sm font-normal text-slate-600 dark:text-slate-500"
      >
        <div className="grid grid-cols-12">
          {!isEditing && (
            <div className="order-2 col-span-12 mt-2 md:order-1 md:col-span-3 md:mt-0">
              {dateTime.day}
              <div className="text-xs font-normal text-[#64748B]">
                {dateTime.time}
              </div>
            </div>
          )}
          <div
            className={`${isEditing ? 'col-span-12' : 'order-1 col-span-12 md:order-2 md:col-span-9'}`}
          >
            {!isEditing && (
              <>
                <div className="mb-2 flex items-center">
                  <img
                    alt="avatar"
                    className="mr-1 size-8 rounded-full"
                    src={note.creator.avatar_url || defaultAvatar}
                  />
                  <div className="font-medium">{note.creator.display_name}</div>
                </div>
                <div>{note.content}</div>
              </>
            )}
            {isEditing && (
              <FormTextarea
                value={noteText}
                name="note"
                placeholder="Enter Note"
                onChange={event => setNoteText(event.target.value)}
              />
            )}
          </div>
        </div>
        <div className="mb-8 mt-4 flex w-full flex-col justify-end gap-3 md:flex-row">
          {!isEditing && isOwnNote && (
            <>
              <Button
                className="rounded-lg border-slate-300/80 bg-white/80"
                variant="outline-secondary"
                onClick={() => setIsEditing(true)}
              >
                <Lucide
                  icon="PencilLine"
                  className="mr-2 size-4 stroke-[1.3]"
                />
                Edit
              </Button>
              <Button
                variant="outline-secondary"
                onClick={() => setShowConfirmDialog(true)}
              >
                <Lucide icon="Trash2" className="mr-2 size-4 stroke-[1.3]" />
                Delete
              </Button>
            </>
          )}

          {isEditing && (
            <FormActions
              cancel={{
                style: { width: '98px', height: '38px' },
                icon: 'X',
                text: 'Cancel',
                onClick: handleCancelEdit,
              }}
              save={{
                style: { width: '119px', height: '38px', padding: '8px' },
                variant: 'primary',
                disabled: noteText === '' || noteText === note.content,
                icon: 'PenLine',
                text: 'Save Note',
                onClick: handleSaveNote,
              }}
            />
          )}
        </div>
      </Disclosure.Panel>

      <ModalDialog
        open={showConfirmDialog}
        onConfirm={() => {
          setShowConfirmDialog(false);
          onDeleteNote(note.id);
        }}
        onCancel={() => setShowConfirmDialog(false)}
        title="Delete Note"
        description="Do you really want to delete this note?"
        confirmText="Delete"
        cancelText="Cancel"
      />
    </>
  );
}

export { InterviewNotePanel };
