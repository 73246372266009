// tailwind.config.js
import tailwindConfig from '@kerplunkai/common-components/tailwind.config.js';

const config = {
  // Extend or override Tailwind configuration from the component library
  // For example, you can add new colors, fonts, or breakpoints here
  ...tailwindConfig,
  content: ['./src/**/*.{js,jsx,ts,tsx}'],
};

export default config;
