/* eslint-disable @typescript-eslint/naming-convention */
import { darkGreen } from '@theme';
import { icons } from '@kerplunkai/common-components';

import {
  Interview,
  InterviewScore,
  InterviewScoringDetails,
  ScoringBreakdownSummary,
  ScoringCategory,
  ScoringCategoryStyles,
} from '@typings';

export const getIsReadyForReview = ({
  status,
  commentary,
  score_breakdown,
}: Interview) =>
  Boolean(status === 'completed' && commentary && score_breakdown);

export const getIsResumeOnly = ({ resume, ...interview }: Interview) =>
  Boolean(
    !getIsReadyForReview(interview) &&
      resume &&
      resume.analyses.length > 0 &&
      resume.analyses[0].scores,
  );

export const getApplicationStatusColor = (status: string) => {
  switch (status) {
    case 'started':
      return 'yellow-600';
    case 'completed':
      return 'orange-700';

    default:
      return `[${darkGreen}]`;
  }
};

export const getApplicationStatusIcon = (
  status: string,
): keyof typeof icons => {
  switch (status) {
    case 'started':
      return 'Hourglass';

    case 'completed':
      return 'Clock';

    default:
      return 'CheckCircle';
  }
};

export const mapBreakdownSummaryToRadar = (
  breakdownSummary: ScoringBreakdownSummary,
): number[] => {
  const radarKeys: (keyof ScoringBreakdownSummary)[] = [
    'x_factor_score',
    'soft_skills_score',
    'resume_score',
    'culture_fit_score',
    'technical_score',
  ];

  return radarKeys.map(key => breakdownSummary[key]);
};

export const getScoringCategory = (score: number): ScoringCategory | null => {
  // Expert - Green, 90-100%
  // Advanced - Blue, 80-89.99%
  // Intermediate  - Yellow, 70-79.99%
  // Novice - Red, 0-69.99%

  if (score >= 90) {
    return ScoringCategory.expert;
  }

  if (score >= 80) {
    return ScoringCategory.advanced;
  }

  if (score >= 70) {
    return ScoringCategory.intermediate;
  }

  if (score < 70) {
    return ScoringCategory.novice;
  }

  return null; // Handle invalid input
};

export const getInterviewScoringDetails = (
  score: InterviewScore,
): InterviewScoringDetails => {
  const x_factor_score = score?.x_factor_score ?? 0;
  const culture_fit_score = score?.culture_fit_score ?? 0;
  const technical_score = score?.technical_score ?? 0;
  const soft_skills_score = score?.soft_skills_score ?? 0;
  const resume_score = score?.resume_score ?? 0;

  const sumOfScores =
    x_factor_score +
    culture_fit_score +
    technical_score +
    soft_skills_score +
    resume_score;

  return {
    percentXFactor: (x_factor_score / sumOfScores) * 100 || 0,
    percentCultural: (culture_fit_score / sumOfScores) * 100 || 0,
    percentTechnical: (technical_score / sumOfScores) * 100 || 0,
    percentSoftskills: (soft_skills_score / sumOfScores) * 100 || 0,
    percentResume: (resume_score / sumOfScores) * 100 || 0,
  };
};

export const getScoringDetails = (
  interview: Interview,
): InterviewScoringDetails | null => {
  const isResumeOnly = getIsResumeOnly(interview);

  // handle resume-only scoring
  if (isResumeOnly && interview.resume)
    return getInterviewScoringDetails(interview.resume.analyses[0].scores);

  // handle non-resume-only scoring
  if (interview.score_breakdown)
    return getInterviewScoringDetails(interview.score_breakdown.interview);

  return null;
};

export const getScoringStyles = (category?: ScoringCategory | null) => {
  switch (category) {
    case ScoringCategory.expert:
      return {
        background: 'bg-[#558408]/10',
        border: 'border-[#558408]/10',
        text: 'text-[#558408]',
        labelText: 'Expert',
      };

    case ScoringCategory.advanced:
      return {
        background: 'bg-sky-600/10',
        border: 'border-sky-600/10',
        text: 'text-sky-600',
        labelText: 'Advanced',
      };

    case ScoringCategory.intermediate:
      return {
        background: 'bg-[#B98C00]/10',
        border: 'border-[#B98C00]/10',
        text: 'text-[#B98C00]',
        labelText: 'Intermediate',
      };

    case ScoringCategory.novice:
      return {
        background: 'bg-orange-700/10',
        border: 'border-orange-700/10',
        text: 'text-orange-700',
        labelText: 'Novice',
      };

    default:
      return {
        background: 'bg-[#558408]/10',
        border: 'border-[#558408]/10',
        text: 'text-[#558408]',
      };
  }
};

export const getScoringCategoryStyles = (
  interview: Interview,
): ScoringCategoryStyles => {
  const isResumeOnly = getIsResumeOnly(interview);

  if (isResumeOnly)
    return {
      background: 'bg-[#181D2C]/10',
      border: 'border-[#181D2C]/10',
      text: 'text-[#181D2C]',
      labelText: 'Résumé Only',
    };

  if ('ai_score' in interview) {
    const category = getScoringCategory(interview.ai_score);
    if (category) return getScoringStyles(category);
  }

  // no overall score available
  return {
    background: 'bg-[#558408]/10',
    border: 'border-[#558408]/10',
    text: 'text-[#558408]',
  };
};

export const getRadarScoringDetails = (
  interview: Interview,
): {
  interview: ScoringBreakdownSummary | null;
  resume: ScoringBreakdownSummary | null;
} => {
  const radarScoringDetails: {
    interview: ScoringBreakdownSummary | null;
    resume: ScoringBreakdownSummary | null;
  } = {
    interview: null,
    resume: null,
  };

  // add interview scoring details
  if (interview?.score_breakdown?.interview) {
    radarScoringDetails.interview = interview.score_breakdown.interview;
  }

  // add resume scoring details
  if (interview?.resume?.analyses) {
    const { analyses } = interview.resume;
    if (analyses.length > 0 && analyses[0].scores) {
      radarScoringDetails.resume = analyses[0].scores;
    }
  }

  return radarScoringDetails;
};

export const getAnalysisCommentary = (interview: Interview) => {
  const isReadyForReview = getIsReadyForReview(interview);
  const isResumeOnly = getIsResumeOnly(interview);

  if (isReadyForReview) return interview.commentary;
  if (isResumeOnly) return interview.resume?.analyses[0].commentary;

  return null;
};
