import { Dispatch, SetStateAction } from 'react';

import { JobIntroVideoOptionsPanel } from '@modules/jobs/jobPreview/jobIntroVideoOptionsPanel.component';
import { JobPreviewQuestions } from '@modules/jobs/jobPreview/jobPreviewQuestions.component';
import { JobRequest } from '@typings';
import { JobSummary } from '@modules/jobs/jobPreview/jobSummary.component';

interface JobPreviewProps {
  job: JobRequest;
  orgId: string;
  onStepChange: Dispatch<SetStateAction<number>>;
}

function JobPreview({ job, orgId, onStepChange }: JobPreviewProps) {
  return (
    <div className="grid grid-cols-12">
      <div className="col-span-12 lg:col-span-8">
        <JobPreviewQuestions
          job={job}
          orgId={orgId}
          onStepChange={onStepChange}
        />
      </div>
      <div className="col-span-12 mt-10 lg:col-span-4 lg:mt-0 lg:pl-4">
        <JobIntroVideoOptionsPanel />
        <JobSummary job={job} orgId={orgId} onStepChange={onStepChange} />
      </div>
    </div>
  );
}

export { JobPreview };
