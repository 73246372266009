import { Button, Lucide } from '@kerplunkai/common-components';
import { useNavigate } from 'react-router-dom';

function InterviewEnd() {
  const navigate = useNavigate();

  return (
    <div className="flex size-full h-[calc(100vh-166px)] flex-col items-center justify-center gap-6 p-5">
      <div className="text-4xl text-white">
        The co-pilot interview has ended
      </div>
      <Button
        variant="text"
        onClick={() => navigate('/')}
        className="flex gap-1.5 rounded-full border-none bg-white/5 !px-7 !py-[15px] text-slate-50"
      >
        <Lucide icon="SkipForward" className="mr-2 text-white" />
        Return To Dashboard
      </Button>
    </div>
  );
}
export { InterviewEnd };
