import { ColumnDef, getCoreRowModel } from '@tanstack/react-table';
import {
  DEFAULT_PAGE_INFO,
  ModalDialog,
  TableFilterPredicatesEnum,
  TableRefetchFn,
  TanstackTable,
} from '@kerplunkai/common-components';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { OrgUser } from '@typings';
import { OrgUserFormModal } from '@modules/orgUser/orgUserFormModal.component';
import { OrgUsersActionColumn } from '@modules/orgUser/orgUsersActionColumn.component';
import { getRoleStyles } from '@utilities/orgUser';
import { selectSelectedOrgId } from '@store/selectors';
import {
  useLazyOrgUsersQuery,
  useRemoveOrgUserMutation,
} from '@store/services';

const getColumns = (): ColumnDef<OrgUser>[] => [
  {
    header: 'User Name',
    accessorKey: 'name',
    accessorFn: ({ first_name, last_name }) => {
      if (first_name && last_name) return `${first_name} ${last_name}`;

      return '-';
    },
    enableSorting: false,
  },
  {
    header: 'User Email',
    accessorKey: 'email',
  },
  {
    accessorKey: 'status',
    header: 'Role',
    enableSorting: false,
    cell: ({ row }) => {
      const styles = getRoleStyles(row.original);

      return (
        <div
          className={`inline-flex items-center justify-center gap-0.5 rounded border px-2 py-0.5 text-xs ${styles.background} ${styles.border} ${styles.text}`}
        >
          {styles.labelText}
        </div>
      );
    },
  },
  {
    accessorKey: 'actions',
    header: () => <div className="flex justify-center">Action</div>,
    maxSize: 50,
    cell: ({ row }) => <OrgUsersActionColumn row={row} />,
    enableSorting: false,
  },
];

function OrgUsersTable() {
  const { enqueueSnackbar } = useSnackbar();

  const orgId = useSelector(selectSelectedOrgId);

  const [getOrgUsers, { data: clientsData, isFetching }] =
    useLazyOrgUsersQuery();
  const [removeUser, { isLoading: isRemoving }] = useRemoveOrgUserMutation();

  const [itemsToRemove, setItemsToRemove] = useState<string[] | null>(null);
  const [addUserOpen, setAddUserOpen] = useState(false);

  const handleRemoveUsers = useCallback(
    (usersToRemove: string[]) => {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise<void>(async (resolve, reject) => {
        const results = await Promise.all(
          usersToRemove?.map(itemToRemove =>
            removeUser({
              orgId: orgId as string,
              userId: itemToRemove,
            }),
          ),
        );
        const validResults = results.filter(
          result => !(result instanceof Error),
        );

        if (validResults.length === usersToRemove.length) {
          enqueueSnackbar({
            message: 'Removing user(s) was successful',
            variant: 'success',
          });
          resolve();
        } else {
          enqueueSnackbar({
            message: 'Unable to remove some or all user(s). Please try again.',
            variant: 'error',
          });
          reject();
        }

        setItemsToRemove(null);
      });
    },
    [enqueueSnackbar, orgId, removeUser],
  );

  const handleRefetch: TableRefetchFn = useCallback(
    (pagination, { searchTerm, sorting }) => {
      const hasSearch = searchTerm && searchTerm.length > 0;
      let filterQueryParms = '';

      if (searchTerm && searchTerm.length > 0)
        filterQueryParms = `q[first_name_or_last_name_or_email_${TableFilterPredicatesEnum.cont}]=${searchTerm}`;
      if (sorting) filterQueryParms += `${hasSearch ? '&' : ''}q[s]=${sorting}`;

      getOrgUsers({
        orgId: orgId as string,
        pagination,
        filters: filterQueryParms,
      });
    },
    [getOrgUsers, orgId],
  );

  return (
    <>
      <TanstackTable
        actionsHeaderItems={{
          addItem: {
            icon: 'UserPlus',
            text: 'Add a User',
            onClick: () => setAddUserOpen(true),
          },
        }}
        emptyText="No User(s) Found"
        initialSorting={{
          initialField: 'name',
          initialOrder: 'ASC',
        }}
        isFetching={isFetching}
        pageInfo={clientsData ? clientsData.meta : DEFAULT_PAGE_INFO}
        searchPlaceholder="Search Users..."
        selection
        selectionModalProps={{
          title: 'Remove User(s)',
          description: 'Do you really want to remove these user(s)?',
          confirmText: 'Remove',
          cancelText: 'Cancel',
        }}
        showSearch
        tableOptions={{
          data: clientsData ? clientsData.results : [],
          columns: getColumns(),
          getCoreRowModel: getCoreRowModel(),
          getRowId: row => row.id,
        }}
        onDelete={handleRemoveUsers}
        onRefetch={handleRefetch}
      />
      <ModalDialog
        open={Boolean(itemsToRemove)}
        title="Remove User(s)"
        description="Do you really want to remove these user(s)?"
        confirmText="Remove"
        cancelText="Cancel"
        isLoading={isRemoving}
        onConfirm={() => handleRemoveUsers(itemsToRemove as string[])}
        onCancel={() => setItemsToRemove(null)}
      />
      <OrgUserFormModal open={addUserOpen} toggleOpen={setAddUserOpen} />
    </>
  );
}

export { OrgUsersTable };
