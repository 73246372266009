import { Button, Lucide } from '@kerplunkai/common-components';
import {
  pauseCameraStream,
  pauseMicStream,
  resumeCameraStream,
  resumeMicStream,
} from '@store/slices';
import { selectIsCameraPaused, selectIsMicPaused } from '@store/selectors';
import { useAppDispatch } from '@store/hooks';
import { useSelector } from 'react-redux';

interface MediaControlsProps {
  enableWebcam?: () => void;
  disableWebcam?: () => void;
  muteMic?: () => void;
  unmuteMic?: () => void;
}

export function MediaControls({
  enableWebcam,
  disableWebcam,
  muteMic,
  unmuteMic,
}: MediaControlsProps) {
  const isMicMuted = useSelector(selectIsMicPaused);
  const isCameraMuted = useSelector(selectIsCameraPaused);

  const dispatch = useAppDispatch();

  const toggleMic = () => {
    dispatch(isMicMuted ? resumeMicStream() : pauseMicStream());

    if (unmuteMic && muteMic) {
      if (isMicMuted) unmuteMic();
      else muteMic();
    }
  };

  const toggleCam = () => {
    dispatch(isCameraMuted ? resumeCameraStream() : pauseCameraStream());

    if (enableWebcam && disableWebcam) {
      if (isCameraMuted) enableWebcam();
      else disableWebcam();
    }
  };

  return (
    <div className="flex grow justify-center">
      <Button className="m-2" variant="text" onClick={toggleMic}>
        <div className="z-50 m-auto flex size-10 cursor-pointer items-center justify-center rounded-full bg-gray-100/50 text-white shadow-lg">
          <Lucide icon={!isMicMuted ? 'Mic' : 'MicOff'} />
        </div>
      </Button>
      <Button className="m-2" variant="text" onClick={toggleCam}>
        <div className="z-50 m-auto flex size-10 cursor-pointer items-center justify-center rounded-full bg-gray-100/50 text-white shadow-lg">
          <Lucide icon={!isCameraMuted ? 'Video' : 'VideoOff'} />
        </div>
      </Button>
    </div>
  );
}
