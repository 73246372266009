import * as Yup from 'yup';
import * as changeCase from 'change-case';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { FormInput, FormLabel, FormModal } from '@kerplunkai/common-components';
import { FormikProps } from 'formik';
import { useSelector } from 'react-redux';

import { selectSelectedOrgId } from '@store/selectors';
import {
  useCreateJobCategoryMutation,
  useCreateJobSubcategoryMutation,
  useLazyJobCategoriesQuery,
  useLazyJobSubcategoriesQuery,
} from '@store/services';

const PAGINATION_INFO = {
  pageIndex: 1,
  pageSize: 1000,
};

type AddCategory = {
  name?: string;
};

export type CategoryType = 'category' | 'subcategory';

interface JobCategoryFormModalProps {
  categoryType: CategoryType;
  open?: string;
  onAddSuccess: (categoryId: string) => void;
  toggleOpen: Dispatch<SetStateAction<boolean>>;
}

function JobCategoryFormModal({
  categoryType,
  open,
  onAddSuccess,
  toggleOpen,
}: JobCategoryFormModalProps) {
  const orgId = useSelector(selectSelectedOrgId);

  const [createJobCategory, { isLoading: isCreatingCategory }] =
    useCreateJobCategoryMutation();
  const [getJobCategories] = useLazyJobCategoriesQuery();
  const [createJobSubcategory, { isLoading: isCreatingSubcategory }] =
    useCreateJobSubcategoryMutation();
  const [getJobSubcategories] = useLazyJobSubcategoriesQuery();

  const handleCreate = useCallback(
    (values: AddCategory) => {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise<void>(async (resolve, reject) => {
        const payload = {
          organizationId: orgId as string,
          name: values.name as string,
        };

        let result;

        if (categoryType === 'category') {
          result = await createJobCategory(payload).unwrap();
          getJobCategories({
            orgId: orgId as string,
            pagination: PAGINATION_INFO,
            filters: '',
          });
        } else {
          result = await createJobSubcategory(payload).unwrap();
          getJobSubcategories({
            orgId: orgId as string,
            pagination: PAGINATION_INFO,
            filters: '',
          });
        }

        if (result instanceof Error) {
          reject();
        } else {
          onAddSuccess(result.id);
          resolve();
        }
      });
    },
    [
      orgId,
      createJobCategory,
      createJobSubcategory,
      getJobCategories,
      getJobSubcategories,
      categoryType,
      onAddSuccess,
    ],
  );

  return (
    <FormModal<AddCategory>
      formikConfig={{
        initialValues: { name: open },
        validateOnChange: false,
        validationSchema: Yup.object({
          name: Yup.string().required(
            `${changeCase.capitalCase(categoryType)} is required`,
          ),
        }),
        onSubmit: () => {},
      }}
      isLoading={isCreatingCategory || isCreatingSubcategory}
      open={Boolean(open)}
      title={`Add New ${changeCase.capitalCase(categoryType)}`}
      snackbar={{
        success: {
          children: (
            <div className="flex flex-col gap-1">
              <p className="text-sm capitalize text-[#364154]">
                {categoryType}&nbsp;Created
              </p>
              <p className="text-sm text-[#677489]">
                The&nbsp;{categoryType}&nbsp;has been successfully created and
                added to the job.
              </p>
            </div>
          ),
        },
        error: { message: `Unable to add ${categoryType}. Please try again.` },
      }}
      successButton={{
        children: `Add ${changeCase.capitalCase(categoryType)}`,
      }}
      onClose={() => toggleOpen(false)}
      onSubmit={handleCreate}
    >
      {({ values, errors, handleChange }: FormikProps<AddCategory>) => (
        <div className="flex flex-col gap-3.5">
          <FormLabel htmlFor="name">
            <div className="flex capitalize">
              {categoryType}&nbsp;Name
              <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-400 dark:bg-darkmode-300 dark:text-slate-400">
                Required
              </div>
            </div>
          </FormLabel>
          <div>
            <FormInput
              id="name"
              disabled
              error={Boolean(errors.name)}
              name="name"
              placeholder={changeCase.capitalCase(categoryType)}
              value={values.name}
              onChange={handleChange}
            />
            {Boolean(errors.name) && (
              <div className="mt-2 text-left text-xs text-danger">
                {errors.name}
              </div>
            )}
          </div>
        </div>
      )}
    </FormModal>
  );
}

export { JobCategoryFormModal };
