export interface Job {
  id: string;
  display_compensation: boolean;
  show_hiring_organization: boolean;
  location_type: string;
  max_pay: string;
  min_pay: string;
  pay_type: keyof typeof PayType;
  application_count: number;
  benefits: JobBenefit[];
  categories: JobCategory[];
  closed_at: Date | null;
  created_at: Date;
  created_by: CreatedBy;
  daily_change: number | null;
  description: string;
  experience_levels: Array<keyof typeof JobExperienceLevel>;
  hiring_organization: string;
  industry: JobIndustry;
  interview_questions: JobQuestion[];
  generate_coding_questions: boolean;
  job_location: string;
  job_type: keyof typeof JobType;
  organization: string;
  published_at: Date | null;
  salary: string | null;
  schedules: Array<keyof typeof JobSchedule>;
  shortcode: string;
  slug: string;
  started_at: Date | null;
  state: string;
  city: string;
  country: string;
  status: keyof typeof JobStatus;
  subcategories: JobCategory[];
  title: string;
  updated_at: Date;
  weekly_change: number | null;
  intro_video_url: string;
  [key: string]:
    | string
    | number
    | null
    | Date
    | JobCategory[]
    | boolean
    | string[]
    | JobQuestion[]
    | JobIndustry
    | CreatedBy;
}

export type JobListingDetail = Job & {
  min_pay: string;
  max_pay: string;
};

export interface JobCategory {
  id: string;
  name: string;
}

export interface JobBenefit {
  id: string;
  name: string;
}

export interface JobIndustry {
  id: string;
  name: string;
}

export interface OrgJobRequest {
  jobId: string;
  organizationId: string;
}

export interface JobRequest {
  id?: string;
  benefit_ids: string[];
  city?: string;
  description: string;
  display_compensation: boolean;
  show_hiring_organization: boolean;
  experience_levels: string[];
  interview_questions_attributes: JobQuestion[];
  industry_id?: string;
  job_category_ids: string[];
  job_location: string;
  job_subcategory_ids: string[];
  job_type: string;
  max_pay: string;
  min_pay: string;
  organization_id: string;
  hiring_organization_id?: string;
  pay_type: string;
  schedules: string[];
  created_by?: CreatedBy;
  state: string;
  country: string;
  status: keyof typeof JobStatus;
  title: string;
  location_type: string;
  generate_coding_questions: boolean;
  intro_video_url: string;
  [key: string]:
    | string
    | number
    | Date
    | null
    | boolean
    | string[]
    | undefined
    | { id?: string; text: string }[]
    | CreatedBy
    | JobQuestion[];
}

export interface JobCategoryRequest {
  id?: string;
  name: string;
  organizationId?: string;
}

export interface JobBenefitRequest {
  id?: string;
  name: string;
  organizationId?: string;
}

export type Industries = string[];
export type Application = {
  status: { [key: string]: string };
};
export type JobListing = {
  experience_level: { [key: string]: string };
  interview_question: { category: { [key: string]: string } };
  job_type: { [key: string]: string };
  location_type: { [key: string]: string };
  organization_benefits: { [key: string]: string };
  pay_type: { [key: string]: string };
  schedule: { [key: string]: string };
  status: { [key: string]: string };
};

export interface JobDetails {
  application: Application;
  industries: Industries;
  job_listing: JobListing;
}

export enum JobStatus {
  'draft' = 'Draft',
  'published' = 'Published',
  'closed' = 'Closed',
}

export enum PayType {
  'per_hour' = 'Per Hour',
  'salary' = 'Salary',
}

export type JobStatusString = 'draft' | 'published' | 'closed';

export enum JobType {
  'full_time' = 'Full-time',
  'part_time' = 'Part-time',
  'contract' = 'Contract',
  'temporary' = 'Temporary',
  'internship' = 'Internship',
  'volunteer' = 'Volunteer',
  'freelance' = 'Freelance',
}

export enum JobExperienceLevel {
  'no_experience' = 'No Experience',
  'one_to_three_years' = '1-3 years',
  'less_than_one_year' = 'Less than 1 year',
  'three_to_five_years' = '3-5 years',
  'five_to_ten_years' = '5-10 years',
  'ten_plus_years' = '10+ years',
}

export enum JobSchedule {
  'four_hour_shift' = '4 hour shift',
  'eight_hour_shift' = '8 hour shift',
  'ten_hour_shift' = '10 hour shift',
  'twelve_hour_shift' = '12 hour shift',
  'mon_fri' = 'Mon-Fri',
  'day_shift' = 'Day shift',
  'night_shift' = 'Night shift',
  'no_nights' = 'No nights',
  'overnight' = 'Overnight',
  'on_call' = 'On call',
  'weekends' = 'Weekends',
}

export enum JobBenefitName {
  'health_insurance' = 'Health insurance',
  'dental_insurance' = 'Dental insurance',
  'vision_insurance' = 'Vision insurance',
  'retirement_plan' = 'Retirement plan',
  'paid_time_off' = 'Paid time off',
  'gym_membership' = 'Gym membership',
  'tuition_reimbursement' = 'Tuition reimbursement',
  'employee_discount' = 'Employee discount',
  'stock_options' = 'Stock options',
  'performance_bonus' = 'Performance bonus',
}

export enum JobLocation {
  'in_person_precise' = 'In-Person (Precise)',
  'in_person_general' = 'In-Person (General)',
  'remote' = 'Remote',
  'hybrid' = 'Hybrid',
}

export interface JobQuestion {
  id?: string;
  content: string;
  code_blocks?: CodeBlock[];
  locked: boolean;
  position: number;
  generated_by_ai: boolean;
  category: string;
  suggested_answers?: string[];
  _destroy?: boolean;
  [key: string]: string | boolean | number | undefined | string[] | CodeBlock[];
}

export interface CodeBlock {
  content: string;
  language: string;
}

export interface CodeQuestion {
  content: string;
  code_blocks: CodeBlock[];
}

export interface GenQuestionsResponse {
  personal_questions: string[];
  technical_questions: (string | CodeQuestion)[];
  cultural_questions: string[];
  token_count: number;
  usage: ChatGPTUsage;
}

export interface ChatGPTUsage {
  prompt_tokens: number;
  completion_tokens: number;
  total_tokens: number;
}

export interface GenDescriptionResponse {
  description: string;
  token_count: 178;
  usage: ChatGPTUsage;
}

export interface CreatedBy {
  display_name: string | null;
}
