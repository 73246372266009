import {
  Button,
  Disclosure,
  FormTextField,
  Lucide,
  ModalDialog,
} from '@kerplunkai/common-components';

import { Dispatch, SetStateAction, useRef } from 'react';
import { FeedbackContent } from '@typings';
import { LoadingSpinner } from '@modules/interviewDetails/components';
import { copyContent } from '@utilities';
import { twMerge } from 'tailwind-merge';

import { useCopyToClipboard } from 'usehooks-ts';

interface InterviewShareLinkModalProps {
  open: boolean;
  summaryText?: string;
  feedbackContent?: FeedbackContent;
  handleClose: () => void;
  sharingUrl: string;
  isLoading?: boolean;
  isTexTCopied?: boolean;
  setIsTextCopied: Dispatch<SetStateAction<boolean>>;
}

function InterviewShareLinkModal({
  open,
  handleClose,
  sharingUrl,
  feedbackContent,
  summaryText,
  isLoading = false,
  isTexTCopied,
  setIsTextCopied,
}: InterviewShareLinkModalProps) {
  const [copiedText, copy] = useCopyToClipboard();
  const isLinkCopied = copiedText === sharingUrl;
  const contentRef = useRef<HTMLDivElement | null>(null);

  const handleCopy = () => {
    if (contentRef.current) {
      copyContent(contentRef.current, setIsTextCopied);
    }
  };

  return (
    <ModalDialog
      isLoading={isLoading}
      open={open}
      onCancel={handleClose}
      cancelText="Close"
      showCloseButton
      title="Share Interview"
      titleClass="text-center text-sm font-normal text-[#181D2C]"
      panelClass="max-w-2xl"
    >
      <div className="box my-4 p-4">
        <div className="flex">
          <Lucide
            icon="Eye"
            className="mr-4 size-5 self-center stroke-[#075985] stroke-[1.5]"
          />
          <div className="self-center">
            <div className="text-sm font-semibold text-gray-500">
              People with this link can view the interview up to 30 days.
            </div>
            <div className="text-xs font-medium text-[#64748B]">
              Viewers will be required to have a Kerplunk account.
            </div>
          </div>
        </div>
      </div>
      <div className="box my-4 p-4">
        <FormTextField
          placeholder="Link"
          label="Link"
          name="share-link"
          value={isLoading ? '' : sharingUrl}
          onChange={() => {}}
          inputClassName={twMerge([
            'text-ellipsis pr-8 ',
            isLinkCopied && `!bg-[#A9F72A1A]/10 text-[#558408]`,
          ])}
          inputContainerClass={twMerge([
            'relative',
            isLinkCopied && 'box-border border rounded-md border-[#A0D846]',
          ])}
          inputElem={
            <div className="absolute inset-y-0 right-2 m-auto">
              {isLoading ? (
                <LoadingSpinner className="size-4 h-full" />
              ) : (
                <Button
                  onClick={() => copy(sharingUrl)}
                  className="h-full"
                  variant="text"
                >
                  <Lucide
                    icon={isLinkCopied ? 'Check' : 'Copy'}
                    className={twMerge([
                      'size-6 stroke-[1.5] ',
                      isLinkCopied && `stroke-[#558408]`,
                    ])}
                  />
                </Button>
              )}
            </div>
          }
        />
      </div>
      <div className="box my-4 p-4">
        <Disclosure.Group openByDefault>
          <Disclosure className="rounded-[0.6rem]">
            <Disclosure.Button>
              <div className="flex border-b border-dashed border-slate-200/60 pb-5 text-[0.94rem] font-medium">
                <Lucide
                  icon="ChevronDown"
                  className="mr-2 size-5 stroke-[1.3]"
                />
                <div className="text-sm font-medium text-[#181D2C]">
                  Candidate Summary Pros & Cons
                </div>
              </div>
            </Disclosure.Button>
            <Disclosure.Panel className="text-sm">
              <div ref={contentRef}>
                <p className="mb-4 font-medium">{summaryText}</p>
                <ul className="pl-4">
                  <li className="mb-4 flex list-disc items-start">
                    <strong className="text-[#181D2C]">Pros: </strong>
                    <span className="ml-1 inline-block font-medium">
                      {feedbackContent?.pros}
                    </span>
                  </li>
                  <li className="flex list-disc items-start">
                    <strong className="text-[#181D2C]">Cons:</strong>
                    <span className="ml-1 inline-block font-medium">
                      {feedbackContent?.cons}
                    </span>
                  </li>
                </ul>
              </div>
              <div className="mt-3.5 flex w-full justify-end">
                <Button
                  variant="text"
                  className={twMerge([
                    'bg-white border border-slate-500 !py-[9px] !px-4 font-medium',
                    isTexTCopied &&
                      'box-border  text-[#558408] bg-[#A9F72A1A]/10 border-[#A0D846]',
                  ])}
                  onClick={handleCopy}
                >
                  <Lucide
                    icon={isTexTCopied ? 'Check' : 'Copy'}
                    className={twMerge([
                      'size-5 mr-1.5 stroke-[1.5]',
                      isTexTCopied && `stroke-[#558408]`,
                    ])}
                  />
                  {isTexTCopied ? 'Summary Copied' : 'Copy Summary'}
                </Button>
              </div>
            </Disclosure.Panel>
          </Disclosure>
        </Disclosure.Group>
      </div>
    </ModalDialog>
  );
}

export { InterviewShareLinkModal };
