import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@store/store';
import { interviewService } from '@store/services';

export const selectOrgInterview = (orgId: string, interviewId: string) =>
  createSelector(
    interviewService.endpoints.orgInterview.select({
      organizationId: orgId,
      interviewId,
    }),
    queryState => {
      if (!queryState?.data) return undefined;

      return queryState.data;
    },
  );

export const selectInterviewJobListingId = (state: RootState) =>
  state.interview.jobListingId;

export const selectInterviewJobApplicationId = (state: RootState) =>
  state.interview.jobApplicationId;

export const selectInterviewDetails = (state: RootState) =>
  state.interview.interviewData;

export const selectActiveMicStream = (state: RootState) =>
  state.interview.activeMicStream;

export const selectActiveCameraStream = (state: RootState) =>
  state.interview.activeCameraStream;

export const selectActiveMicDevice = (state: RootState) =>
  state.interview.selectedMicDeviceId;

export const selectActiveCameraDevice = (state: RootState) =>
  state.interview.selectedCameraDeviceId;

export const selectIsMicPaused = (state: RootState) =>
  state.interview.isMicPaused;

export const selectIsCameraPaused = (state: RootState) =>
  state.interview.isCameraPaused;

export const selectSelectedInterviewDocumentId = (state: RootState) =>
  state.interview.selectedInterviewDocumentId;

export const selectResumeInterviewId = (state: RootState) =>
  state.interview.resumeInterviewId;
