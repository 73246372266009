import { Loading } from '@kerplunkai/common-components';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { AVAILABLE_INTEGRATIONS, REQUEST_INTEGRATION } from '@constants';
import { Integration, OrgIntegration } from '@typings';
import { IntegrationCard } from '@modules/integrations/integrationCard.component';
import { IntegrationModal } from '@modules/integrations/integrationModal.component';
import { RequestIntegrationModal } from '@modules/integrations/requestIntegrationModal.component';
import { cloneDeep } from 'lodash';
import { filterIntegrationsByType } from '@utilities';
import { selectSelectedOrgId } from '@store/selectors';
import { useOrgIntegrationsQuery } from '@store/services';

function Integrations() {
  const orgId = useSelector(selectSelectedOrgId);

  const { data } = useOrgIntegrationsQuery({
    orgId: orgId as string,
  });

  const [integrationToCreate, setIntegrationToCreate] =
    useState<Integration | null>(null);
  const [integrations, setIntegrations] = useState<OrgIntegration[]>([]);

  useEffect(() => {
    if (data) setIntegrations(data.results);
  }, [data]);

  const handleActionSuccess = useCallback(
    (
      actionType: 'ADD' | 'UPDATE' | 'REMOVE',
      integration: OrgIntegration | null,
      integrationId?: string,
    ) => {
      const newIntegrations = cloneDeep(integrations);

      switch (actionType) {
        case 'REMOVE': {
          const integrationIndex = integrations.findIndex(
            ({ id }) => id === integrationId,
          );
          if (integrationIndex !== -1)
            newIntegrations.splice(integrationIndex, 1);
          break;
        }

        case 'UPDATE': {
          if (!integration) break;

          const integrationIndex = integrations.findIndex(
            ({ id }) => id === integration.id,
          );
          if (integrationIndex !== -1)
            newIntegrations[integrationIndex] = integration;
          break;
        }

        // ADD case
        default:
          if (!integration) break;

          newIntegrations.push(integration);
          break;
      }

      setIntegrations(newIntegrations);
    },
    [integrations],
  );

  if (!integrations) return <Loading />;

  return (
    <>
      <div className="grid grid-cols-12 gap-x-6 gap-y-10">
        <div className="col-span-12">
          <div className="mt-4 flex flex-col gap-y-3 md:mt-0 md:h-10 md:flex-row md:items-center">
            <div className="text-base font-medium group-[.mode--light]:text-white">
              Integrations
            </div>
          </div>
          <div className="mt-3.5 grid grid-cols-12 gap-6">
            {AVAILABLE_INTEGRATIONS.map(integration => (
              <IntegrationCard
                key={integration.name}
                integrationType={integration}
                integrations={filterIntegrationsByType(
                  integrations,
                  integration.type,
                )}
                onEnable={() => setIntegrationToCreate(integration)}
              />
            ))}
            <IntegrationCard
              integrationType={REQUEST_INTEGRATION}
              isRequest
              onEnable={() => setIntegrationToCreate(REQUEST_INTEGRATION)}
            />
          </div>
        </div>
      </div>
      {integrationToCreate && (
        <IntegrationModal
          integrationType={integrationToCreate}
          integrations={filterIntegrationsByType(
            integrations,
            integrationToCreate.type,
          )}
          open={integrationToCreate.type !== 'REQUEST_FOR'}
          toggleOpen={() => setIntegrationToCreate(null)}
          onActionSuccess={handleActionSuccess}
        />
      )}
      <RequestIntegrationModal
        integration={integrationToCreate}
        open={
          Boolean(integrationToCreate) &&
          integrationToCreate?.type === 'REQUEST_FOR'
        }
        toggleOpen={() => setIntegrationToCreate(null)}
      />
    </>
  );
}

export { Integrations };
