/* eslint-disable no-underscore-dangle */
import * as Yup from 'yup';
import { Formik, FormikErrors, FormikProps } from 'formik';
import { Loading, Stepper } from '@kerplunkai/common-components';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { FILTER_ORG_ID } from '@constants';
import { InterviewAttendee, InterviewRequest } from '@typings';
import { InterviewForm } from '@modules/meetings/meetingForms';
import { getInitialInterview } from '@utilities/interview';
import { selectSelectedOrgId } from '@store/selectors';
import { useLazyOrgInterviewQuery } from '@store/services';

function Meeting() {
  const meetingFormref = useRef<FormikProps<InterviewRequest>>(null);
  const { interviewId } = useParams<{ interviewId: string }>();

  const navigate = useNavigate();

  const orgId = useSelector(selectSelectedOrgId);

  const [getOrgInterivew, { isLoading, data: interview }] =
    useLazyOrgInterviewQuery();

  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (orgId !== FILTER_ORG_ID) {
      navigate('/');
      return;
    }

    if (orgId && interviewId)
      getOrgInterivew({ organizationId: orgId, interviewId });
  }, [navigate, interviewId, orgId, getOrgInterivew]);

  const handleStepChange = useCallback(async (stepNum: number) => {
    let validationErrors: FormikErrors<InterviewRequest> = {};

    if (meetingFormref.current)
      validationErrors = await meetingFormref.current.validateForm();

    if (isEmpty(validationErrors)) setActiveStep(stepNum);
  }, []);

  const validationSchema = Yup.object({
    title: Yup.string()
      .max(70, 'Must be 70 characters or less')
      .required('Meeting Title is required'),
    description: Yup.string(),
    interview_at_day: Yup.string().required('Interview Day is required'),
    interview_at_time: Yup.string().required('Interiew Start time is required'),
    interview_attendees_attributes: Yup.array()
      .of(
        Yup.object().shape({
          email: Yup.string(),
          _destroy: Yup.boolean(),
        }),
      )
      .compact((a: InterviewAttendee) => !a.email && !a._destroy)
      .min(1, 'At least one attendee is required to save'),
    job_listing_id: Yup.string().required('Job Listing is required'),
  });

  if (isLoading) return <Loading />;

  return (
    <div className="grid grid-cols-12 gap-x-6 gap-y-10">
      <div className="col-span-12">
        <div className="grid grid-cols-12">
          <div className="col-span-12">
            <Stepper
              activeStep={activeStep}
              steps={[
                {
                  text: 'Meeting Information',
                },
                {
                  text: 'Preview & Send',
                },
              ]}
              onStepChange={handleStepChange}
            />
          </div>
        </div>
        <div className="mt-3.5 grid grid-cols-12 gap-x-6 gap-y-7">
          <Formik
            innerRef={meetingFormref}
            initialValues={getInitialInterview(interview)}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={() => {}}
          >
            <InterviewForm
              activeStep={activeStep}
              isLastStep={activeStep === 1}
              validationSchema={validationSchema}
              onStepChange={setActiveStep}
            />
          </Formik>
        </div>
      </div>
    </div>
  );
}

export { Meeting };
