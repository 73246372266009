import {
  Button,
  FormControl,
  FormSelect,
  FormSwitch,
  Lucide,
  NumericDollarInput,
} from '@kerplunkai/common-components';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { JobBenefitDialogForm } from '@modules/jobs/jobForms/jobBenefitDialogForm.component';
import { JobRequest } from '@typings';
import { selectJobOrgBenefits, selectJobPayTypes } from '@store/selectors';

interface JobCompensationFormProps {
  isLoading: boolean;
  job: JobRequest;
  orgId: string;
  onChange: (name: string, value: string | boolean | string[]) => void;
}

function JobCompensationForm({
  isLoading,
  job,
  orgId,
  onChange,
}: JobCompensationFormProps) {
  const orgBenefits = useSelector(selectJobOrgBenefits(orgId));
  const payTypes = useSelector(selectJobPayTypes(orgId));

  const [showAddBenefitModal, setShowAddBenefitModal] = useState(false);

  const handleMultipleChoiceChange = useCallback(
    (name: string, value: string) => {
      const selections = [...(job[name] as string[])];

      if (selections) {
        const index = selections.findIndex(selection => selection === value);

        if (index === -1) {
          selections.push(value);
        } else {
          selections.splice(index, 1);
        }

        onChange(name, selections);
      }
    },
    [job, onChange],
  );

  return (
    <>
      <div className="mt-5">
        <FormControl
          className="mt-5 flex items-center"
          description="Sometimes states require compensation to be made publicly available. Please check your local regulations to comply with compensation awareness."
          label="Display Compensation"
        >
          <div className="flex items-center">
            <FormSwitch.Input
              checked={job.display_compensation}
              disabled={isLoading}
              type="checkbox"
              onChange={() =>
                onChange('display_compensation', !job.display_compensation)
              }
            />
            <p className="ml-2 text-slate-400">Show compensation</p>
          </div>
        </FormControl>
        {job.display_compensation && (
          <FormControl
            className="mt-5 "
            childrenClassName="sm:gird-cols-3 grid md:grid-cols-4 gap-3"
            description="Review the pay we estimated for your job and adjust as needed. Check your local minimum wage."
            label="Pay"
          >
            <FormSelect
              disabled={isLoading}
              className="focus:ring-0"
              name="pay_type"
              value={job.pay_type || ''}
              onChange={({ target: { name, value } }) => onChange(name, value)}
            >
              {payTypes &&
                Object.keys(payTypes).map(value => (
                  <option key={value} value={value}>
                    {payTypes[value]}
                  </option>
                ))}
            </FormSelect>
            <div className="col-span-3 flex flex-col items-start md:flex-row md:items-center ">
              <NumericDollarInput
                onValueChange={valueObject => {
                  const { value } = valueObject;
                  onChange('min_pay', value);
                }}
                value={Number(job.min_pay)}
                placeHolder="Minimum"
                thousandSeparator=","
                className="w-32 text-slate-500 placeholder:text-slate-400"
              />

              <div className="mx-4 font-normal text-slate-400">to</div>
              <NumericDollarInput
                onValueChange={valueObject => {
                  const { value } = valueObject;
                  onChange('max_pay', value);
                }}
                value={Number(job.max_pay)}
                placeHolder="Maximum"
                thousandSeparator=","
                className="w-32 text-slate-500 placeholder:text-slate-400"
              />
            </div>
          </FormControl>
        )}
        {orgBenefits && (
          <FormControl className="mt-5" label="Benefits">
            <div className="flex flex-wrap gap-y-4">
              {Object.keys(orgBenefits).map(value => (
                <Button
                  key={value}
                  className="[&:not(:last-child)]:mr-2"
                  disabled={isLoading}
                  variant={
                    job.benefit_ids.indexOf(value) !== -1
                      ? 'primary'
                      : 'soft-sky-outline'
                  }
                  onClick={() =>
                    handleMultipleChoiceChange('benefit_ids', value)
                  }
                >
                  {orgBenefits[value]}
                </Button>
              ))}
              <Button
                className="border-dashed [&:not(:last-child)]:mr-2"
                disabled={isLoading}
                variant="soft-sky-outline"
                onClick={() => setShowAddBenefitModal(true)}
              >
                <Lucide className="mr-1" icon="Plus" />
                Add Benefit
              </Button>
            </div>
          </FormControl>
        )}
      </div>
      <JobBenefitDialogForm
        open={showAddBenefitModal}
        orgId={orgId}
        selectBenefit={(value: string) =>
          handleMultipleChoiceChange('benefit_ids', value)
        }
        setOpen={setShowAddBenefitModal}
      />
    </>
  );
}

export { JobCompensationForm };
