import {
  PagedFilterSortRequest,
  PagedResponse,
  generateQueryParams,
} from '@kerplunkai/common-components';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { BASE_URL } from '@constants';
import { ClientRequest, Organization } from '@typings';
import { RootState } from '@store/store';

export const clientService = createApi({
  reducerPath: 'clientService',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/organizations/`,
    prepareHeaders: (headers, { getState }) => {
      const { token } = (getState() as RootState).auth;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ['OrgClient'],
  endpoints: builder => ({
    orgClients: builder.query<
      PagedResponse<Organization>,
      PagedFilterSortRequest
    >({
      query: ({ orgId, pagination, filters = '' }) => ({
        url: `${orgId}/clients${generateQueryParams({ pagination, filters })}`,
        method: 'GET',
      }),
      providesTags: result => {
        if (result) {
          return [
            ...result.results.map(({ id }) => ({
              type: 'OrgClient' as const,
              id,
            })),
            { type: 'OrgClient', id: 'LIST' },
          ];
        }

        return [{ type: 'OrgClient', id: 'LIST' }];
      },
    }),
    orgClient: builder.query<
      Organization,
      { orgId: string; orgClientId: string }
    >({
      query: ({ orgId, orgClientId }) => ({
        url: `${orgId}/clients/${orgClientId}`,
        method: 'GET',
      }),
      providesTags: (_result, _error, { orgClientId }) => [
        { type: 'OrgClient', id: orgClientId },
      ],
    }),
    createOrgClient: builder.mutation<Organization, ClientRequest>({
      query: ({ organizationId, ...client }) => {
        const bodyFormData = new FormData();

        Object.keys(client).forEach(key => {
          if (client[key] && key !== 'logo')
            bodyFormData.append(`client[${key}]`, client[key] as string | Blob);
        });
        if (client.logo)
          bodyFormData.append(
            'client[logo]',
            new File([client.logo.src], 'test'),
          );

        return {
          url: `${organizationId}/clients`,
          method: 'POST',
          body: bodyFormData,
        };
      },
      invalidatesTags: [{ type: 'OrgClient', id: 'LIST' }],
    }),
    updateOrgClient: builder.mutation<Organization, ClientRequest>({
      query: ({ organizationId, ...client }) => {
        const bodyFormData = new FormData();

        Object.keys(client).forEach(key => {
          if (client[key] && key !== 'logo')
            bodyFormData.append(`client[${key}]`, client[key] as string | Blob);
        });
        if (client.logo)
          bodyFormData.append(
            'client[logo]',
            new File([client.logo.src], 'test'),
          );

        return {
          url: `${organizationId}/clients/${client.id}`,
          method: 'PUT',
          body: bodyFormData,
        };
      },
      invalidatesTags: (_result, _error, { id }) => [{ type: 'OrgClient', id }],
    }),
    deleteOrgClient: builder.mutation<
      null,
      { organizationId: string; orgClientId: string }
    >({
      query: ({ organizationId, orgClientId }) => ({
        url: `${organizationId}/clients/${orgClientId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'OrgClient', id: 'LIST' }],
    }),
  }),
});

export const {
  useLazyOrgClientsQuery,
  useOrgClientsQuery,
  useLazyOrgClientQuery,
  useOrgClientQuery,
  useCreateOrgClientMutation,
  useUpdateOrgClientMutation,
  useDeleteOrgClientMutation,
} = clientService;
