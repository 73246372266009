import IVSBroadcastClient, {
  AmazonIVSBroadcastClient,
  BASIC_LANDSCAPE,
} from 'amazon-ivs-web-broadcast';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { IKerplunkMediaService } from '@typings';
import {
  selectActiveCameraStream,
  selectActiveMicStream,
} from '@store/selectors';

const IVS_CAM_STREAM_KEY = 'ivs-camera-stream';
const IVS_MIC_STREAM_KEY = 'ivs-microphone-stream';

const RECORD_DELAY_TIMEOUT_MS = 2000;

export const useAmazonIVSService = (): IKerplunkMediaService => {
  const [client, setClient] = useState<AmazonIVSBroadcastClient | null>(null);
  const [streamKey, setStreamKey] = useState<string | null>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isMeetingConnected, setIsMeetingConnected] = useState(false);

  const [isMicAdded, setIsMicAdded] = useState(false);
  const [isCameraAdded, setIsCameraAdded] = useState(false);

  const cameraStream = useSelector(selectActiveCameraStream);
  const micStream = useSelector(selectActiveMicStream);

  const getClient = (ingestUrl: string) =>
    IVSBroadcastClient.create({
      streamConfig: BASIC_LANDSCAPE,
      ingestEndpoint: `${ingestUrl}`,
    });

  // manage adding / removing streams to ivs client
  // streams can change during a session, so we need to react to them here
  useEffect(() => {
    if (client) {
      if (cameraStream && !isCameraAdded) {
        console.log('ivs adding video stream');
        client.addVideoInputDevice(cameraStream, IVS_CAM_STREAM_KEY, {
          index: 1,
        });
        setIsCameraAdded(true);
      }

      if (micStream && !isMicAdded) {
        console.log('ivs adding audio stream');
        client.addAudioInputDevice(micStream, IVS_MIC_STREAM_KEY);
        setIsMicAdded(true);
      }

      if (!cameraStream && isCameraAdded) {
        console.log('ivs removing video stream');
        client.removeVideoInputDevice(IVS_CAM_STREAM_KEY);
        setIsCameraAdded(false);
      }

      if (!micStream && isMicAdded) {
        console.log('ivs removing audio stream');
        client.removeAudioInputDevice(IVS_MIC_STREAM_KEY);
        setIsMicAdded(false);
      }
    }
  }, [cameraStream, client, isCameraAdded, isMicAdded, micStream]);

  useEffect(() => {
    if (client) {
      console.log('ivs registering event listeners');
      client?.emitter.on('activeStateChange', value =>
        console.log('ivs active state change:', value),
      );

      client?.emitter.on('connectionStateChange', state => {
        console.log('ivs connection state change:', state);
      });

      client?.emitter.on('clientError', error =>
        console.error('ivs client error:', error),
      );
    }
  }, [client]);

  const resetState = () => {
    setStreamKey(null);
    setIsMeetingConnected(false);
    setIsRecording(false);
    setClient(null);
    setIsCameraAdded(false);
    setIsMicAdded(false);
  };

  return useMemo(
    () => ({
      initClient: ({ ingest_endpoint, stream_key }) => {
        if (ingest_endpoint && stream_key) {
          if (!client) {
            setClient(getClient(ingest_endpoint));
            setIsMeetingConnected(true);
          }
          setStreamKey(stream_key);
        }
      },
      startRecording: () => {
        console.log('ivs starting recording...');
        if (streamKey) {
          client?.startBroadcast(streamKey).then(() => {
            setTimeout(() => {
              console.log('ivs now recording');
              setIsRecording(true);
            }, RECORD_DELAY_TIMEOUT_MS);
          });
        }
      },
      stopRecording: () => {
        console.log('ivs stopping recording...');
        client?.stopBroadcast();
        setIsRecording(false);
      },
      close: () => {
        console.log(
          'ivs stopping recording and ending meeting. goodbye and good luck with your interviews!',
        );
        client?.stopBroadcast();
        setTimeout(() => {
          client?.delete();
        }, 1000);
        resetState();
      },
      leave: () => {
        console.log(
          'ivs stopping recording and ending meeting. goodbye and good luck with your interviews!',
        );
        client?.stopBroadcast();
        setTimeout(() => {
          client?.delete();
        }, 1000);
        resetState();
      },
      isMeetingConnected,
      isRecording,
      participants: [],
    }),
    [client, isMeetingConnected, isRecording, streamKey],
  );
};
