import { Button, Lucide } from '@kerplunkai/common-components';
import { useMemo } from 'react';

import clsx from 'clsx';
import { Integration, OrgIntegration } from '@typings';

interface IntegrationCardProps {
  integrationType: Integration;
  integrations?: OrgIntegration[];
  isRequest?: boolean;
  onEnable?: () => void;
}

function IntegrationCard({
  integrationType,
  integrations,
  isRequest = false,
  onEnable,
}: IntegrationCardProps) {
  const activeIntegrations = useMemo(
    () => integrations?.filter(({ active }) => active) || [],
    [integrations],
  );
  const inactiveIntegrations = useMemo(
    () => integrations?.filter(({ active }) => !active) || [],
    [integrations],
  );

  const isIntegrationConnected = useMemo(
    () => activeIntegrations.length !== 0 || inactiveIntegrations.length !== 0,
    [activeIntegrations, inactiveIntegrations],
  );

  const showEnableIntegration =
    !isIntegrationConnected && !isRequest && onEnable;

  return (
    <div
      key={integrationType.name}
      className="box col-span-12 grid grid-cols-12 gap-6 p-6 md:col-span-6"
      style={
        isRequest
          ? { background: 'linear-gradient(180deg, #0C4A6E 0%, #172554 100%)' }
          : {}
      }
    >
      <div className="col-span-3 flex items-center justify-center gap-5">
        {integrationType.logo && (
          <img
            alt={`${integrationType.name} Logo`}
            src={integrationType.logo}
          />
        )}
        {integrationType.icon && (
          <Lucide
            className={integrationType.iconClassnames}
            icon={integrationType.icon}
          />
        )}
      </div>
      <div className="col-span-1 flex justify-center">
        <div className="h-full w-0 border border-slate-400 opacity-35" />
      </div>
      <div className="col-span-8 flex flex-col gap-3 p-5">
        <div className="flex flex-col gap-y-0.5">
          <div
            className={clsx(
              'text-sm font-semibold',
              isRequest ? 'text-white' : 'text-[#181D2C]',
            )}
          >
            {integrationType.name}
          </div>
          <div
            className={clsx(
              'text-xs',
              isRequest ? 'text-slate-400' : 'text-slate-600',
            )}
          >
            {integrationType.description}
          </div>
        </div>
        <div className="flex flex-1 items-end">
          {isIntegrationConnected && (
            <div className="flex gap-3">
              {activeIntegrations.length !== 0 && (
                <Button
                  disabled
                  variant="soft-success"
                  className="flex w-fit gap-1.5 border border-[#A9F72A]/100 bg-[#A9F72A1A] px-3.5 py-2.5 text-[#558408] !opacity-100"
                >
                  <Lucide icon="Check" />
                  <div className="text-sm font-semibold">
                    {activeIntegrations.length}
                  </div>
                </Button>
              )}
              {inactiveIntegrations.length !== 0 && (
                <Button
                  disabled
                  variant="soft-danger"
                  className="flex w-fit gap-1.5 border border-[#C2410C]/100 bg-[#C2410C1A] px-3.5 py-2.5 text-[#C2410C] !opacity-100"
                >
                  <Lucide icon="AlertTriangle" />
                  <div className="text-sm font-semibold">
                    {inactiveIntegrations.length}
                  </div>
                </Button>
              )}
              {onEnable && (
                <Button
                  variant="soft-secondary"
                  className="flex w-fit gap-1.5 px-3.5 py-2.5 text-slate-500"
                  onClick={onEnable}
                >
                  <Lucide icon="PenLine" />
                  <div className="text-sm font-semibold">Edit</div>
                </Button>
              )}
            </div>
          )}

          {showEnableIntegration && integrationType.isComingSoon && (
            <Button
              disabled
              className="flex w-fit gap-1.5 px-3.5 py-2.5"
              variant="soft-secondary"
              onClick={() => {}}
            >
              <div className="text-sm font-semibold">Coming Soon</div>
            </Button>
          )}
          {showEnableIntegration && !integrationType.isComingSoon && (
            <Button
              variant="primary"
              className="flex w-fit gap-1.5 px-3.5 py-2.5"
              onClick={onEnable}
            >
              <Lucide icon="PlugZap" />
              <div className="text-sm font-semibold">Enable Integration</div>
            </Button>
          )}
          {isRequest && (
            <Button
              variant="primary"
              className="flex w-fit gap-1.5 border-[#558408] bg-[#558408] px-3.5 py-2.5"
              onClick={onEnable}
            >
              <Lucide icon="PlugZap" />
              <div className="text-sm font-semibold">Request Integration</div>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export { IntegrationCard };
