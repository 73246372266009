import { FormCard } from '@kerplunkai/common-components';

import { JobInfoForm } from '@modules/organization/jobCreateSteps/jobInfoForm.component';

interface JobInfoStepProps {
  isLoading?: boolean;
}

function JobInfoStep({ isLoading = false }: JobInfoStepProps) {
  return (
    <>
      <FormCard title="Job Information">
        <JobInfoForm isLoading={isLoading} />
      </FormCard>

      {/* <FormCard title="Job Description">
        <JobDescriptionForm isLoading={isLoading} onChange={onChange} />
      </FormCard>

      <FormCard title="Additional Details">
        <JobAddDetailsForm
          isLoading={isLoading}
          orgId={orgId as string}
          onChange={onChange}
        />
      </FormCard>

      <FormCard title="Compensation">
        <JobCompensationForm
          isLoading={isLoading}
          orgId={orgId as string}
          onChange={onChange}
        />
      </FormCard> */}
    </>
  );
}

export { JobInfoStep };
