import {
  Button,
  FormInput,
  Lucide,
  TableBase,
} from '@kerplunkai/common-components';

import { InterviewAttendee } from '@typings';

interface InterviewAttendeeTableRowProps {
  index: number;
  isLoading: boolean;
  attendee: InterviewAttendee;
  onAttendeeChange: (value: string, name: string, index: number) => void;
  onRemoveAttendee: (index: number) => void;
}

function InterviewAttendeeTableRow({
  index,
  isLoading,
  attendee,
  onAttendeeChange,
  onRemoveAttendee,
}: InterviewAttendeeTableRowProps) {
  return (
    <TableBase.Tr>
      <TableBase.Td className="w-full min-w-72 !pl-3">
        <FormInput
          className="resize-none text-slate-500"
          disabled={isLoading}
          name="email"
          placeholder="Enter email address"
          value={attendee.email}
          onChange={({ target: { name, value } }) =>
            onAttendeeChange(value, name, index)
          }
        />
      </TableBase.Td>
      <TableBase.Td className="min-w-5 whitespace-nowrap text-slate-500">
        <Button
          disabled={isLoading}
          variant="text"
          onClick={() => onRemoveAttendee(index)}
        >
          <Lucide
            icon="Trash2"
            className="size-5 stroke-slate-500 stroke-[1.3]"
          />
        </Button>
      </TableBase.Td>
    </TableBase.Tr>
  );
}

export { InterviewAttendeeTableRow };
