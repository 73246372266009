import {
  PagedFilterSortRequest,
  PagedResponse,
  generateQueryParams,
} from '@kerplunkai/common-components';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { BASE_URL } from '@constants';
import { JobCategory, JobCategoryRequest } from '@typings';
import { RootState } from '@store/store';

export const categoryService = createApi({
  reducerPath: 'categoryService',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/organizations`,
    prepareHeaders: (headers, { getState }) => {
      const { token } = (getState() as RootState).auth;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ['JobCategory', 'JobSubcategory'],
  endpoints: builder => ({
    jobCategories: builder.query<
      PagedResponse<JobCategory>,
      PagedFilterSortRequest
    >({
      query: ({ orgId, pagination, filters = '' }) => ({
        url: `${orgId}/job_categories${generateQueryParams({ pagination, filters })}`,
        method: 'GET',
      }),
      providesTags: result => {
        if (result) {
          return [
            ...result.results.map(({ id }) => ({
              type: 'JobCategory' as const,
              id,
            })),
            { type: 'JobCategory', id: 'LIST' },
          ];
        }

        return [{ type: 'JobCategory', id: 'LIST' }];
      },
    }),
    jobCategory: builder.query<
      JobCategory,
      { organizationId: string; jobCategoryId: string }
    >({
      query: ({ organizationId, jobCategoryId }) => ({
        url: `${organizationId}/job_categories/${jobCategoryId}`,
        method: 'GET',
      }),
      providesTags: (_result, _error, { jobCategoryId }) => [
        { type: 'JobCategory', id: jobCategoryId },
      ],
    }),
    createJobCategory: builder.mutation<JobCategory, JobCategoryRequest>({
      query: ({ organizationId, name }) => ({
        url: `${organizationId}/job_categories`,
        method: 'POST',
        body: { job_category: { name } },
      }),
      invalidatesTags: [{ type: 'JobCategory', id: 'LIST' }],
    }),
    updateJobCategory: builder.mutation<JobCategory, JobCategoryRequest>({
      query: ({ organizationId, ...job_category }) => ({
        url: `${organizationId}/job_categories/${job_category.id}`,
        method: 'PUT',
        body: { job_category },
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: 'JobCategory', id },
      ],
    }),
    deleteJobCategory: builder.mutation<
      null,
      { organizationId: string; jobCategoryId: string }
    >({
      query: ({ organizationId, jobCategoryId }) => ({
        url: `${organizationId}/job_categories/${jobCategoryId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { jobCategoryId }) => [
        { type: 'JobCategory', id: jobCategoryId },
      ],
    }),
    jobSubcategories: builder.query<
      PagedResponse<JobCategory>,
      PagedFilterSortRequest
    >({
      query: ({ orgId, pagination, filters = '' }) => ({
        url: `${orgId}/job_subcategories${generateQueryParams({ pagination, filters })}`,
        method: 'GET',
      }),
      providesTags: result =>
        result
          ? [
              ...result.results.map(({ id }) => ({
                type: 'JobSubcategory' as const,
                id,
              })),
              'JobSubcategory',
            ]
          : ['JobSubcategory'],
    }),
    jobSubcategory: builder.query<
      JobCategory,
      { organizationId: string; jobSubcategoryId: string }
    >({
      query: ({ organizationId, jobSubcategoryId }) => ({
        url: `${organizationId}/job_subcategories/${jobSubcategoryId}`,
        method: 'GET',
      }),
      providesTags: (_result, _error, { jobSubcategoryId }) => [
        { type: 'JobSubcategory', id: jobSubcategoryId },
      ],
    }),
    createJobSubcategory: builder.mutation<JobCategory, JobCategoryRequest>({
      query: ({ organizationId, name }) => ({
        url: `${organizationId}/job_subcategories`,
        method: 'POST',
        body: { job_subcategory: { name } },
      }),
      invalidatesTags: [{ type: 'JobSubcategory', id: 'LIST' }],
    }),
    updateJobSubcategory: builder.mutation<JobCategory, JobCategoryRequest>({
      query: ({ organizationId, ...job_subcategory }) => ({
        url: `${organizationId}/job_subcategories/${job_subcategory.id}`,
        method: 'PUT',
        body: { job_subcategory },
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: 'JobSubcategory', id },
      ],
    }),
    deleteJobSubcategory: builder.mutation<
      null,
      { organizationId: string; jobsubcategoryId: string }
    >({
      query: ({ organizationId, jobsubcategoryId }) => ({
        url: `${organizationId}/job_subcategories/${jobsubcategoryId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { jobsubcategoryId }) => [
        { type: 'JobSubcategory', id: jobsubcategoryId },
      ],
    }),
  }),
});

export const {
  useLazyJobCategoriesQuery,
  useJobCategoriesQuery,
  useDeleteJobCategoryMutation,
  useCreateJobCategoryMutation,
  useJobCategoryQuery,
  useUpdateJobCategoryMutation,
  useLazyJobSubcategoriesQuery,
  useJobSubcategoriesQuery,
  useDeleteJobSubcategoryMutation,
  useCreateJobSubcategoryMutation,
  useJobSubcategoryQuery,
  useUpdateJobSubcategoryMutation,
} = categoryService;
