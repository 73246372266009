/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  FormInput,
  FormLabel,
  Lucide,
} from '@kerplunkai/common-components';
import { ChangeEvent, useCallback } from 'react';
import { cloneDeep } from 'lodash';

import { IntegrationFormProps } from '@typings';

export interface WebhookFormProps extends IntegrationFormProps {}

function WebhookForm({
  errors,
  integration,
  integrationType,
  isEdit = false,
  isLoading,
  isNew = false,
  values,
  onEdit,
  onChange,
  onDelete,
  onSetFieldValue,
}: WebhookFormProps) {
  const handleHeadersChange = useCallback(
    (
      { target: { name, value } }: ChangeEvent<HTMLInputElement>,
      index: number,
    ) => {
      if (!onSetFieldValue) return;

      const headers =
        cloneDeep(values.parameters?.webhook_config.headers) || [];

      headers[index][name] = value;

      onSetFieldValue('parameters', {
        webhook_config: { ...values.parameters?.webhook_config, headers },
      });
    },
    [values, onSetFieldValue],
  );

  const handleURLChange = useCallback(
    ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
      if (!onSetFieldValue) return;

      onSetFieldValue('parameters', {
        webhook_config: { ...values.parameters?.webhook_config, [name]: value },
      });
    },
    [values, onSetFieldValue],
  );

  const handleAddHeaderPair = useCallback(() => {
    if (!onSetFieldValue) return;

    onSetFieldValue('parameters', {
      webhook_config: {
        ...values.parameters?.webhook_config,
        headers: [
          ...(values.parameters?.webhook_config.headers || []),
          { key: '', value: '' },
        ],
      },
    });
  }, [values, onSetFieldValue]);

  const handleRemoveHeaderPair = useCallback(
    (index: number) => {
      if (!onSetFieldValue) return;

      const headers =
        cloneDeep(values.parameters?.webhook_config.headers) || [];

      headers.splice(index, 1);

      onSetFieldValue('parameters', {
        webhook_config: { ...values.parameters?.webhook_config, headers },
      });
    },
    [values, onSetFieldValue],
  );

  return (
    <div className="flex flex-col gap-6">
      {(isNew || isEdit) && (
        <>
          <div className="flex max-w-full flex-col gap-3.5">
            <FormLabel htmlFor="name">
              <div className="flex">
                Integration Name
                <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-400 dark:bg-darkmode-300 dark:text-slate-400">
                  Required
                </div>
              </div>
              <div className="mt-1.5 text-left text-xs text-slate-500">
                Input the name of your&nbsp;{integrationType?.name}
                &nbsp;integration
              </div>
            </FormLabel>
            <div>
              <FormInput
                id="name"
                disabled={isLoading}
                error={Boolean(errors.name)}
                name="name"
                placeholder="Integration Name"
                value={values.name}
                onChange={onChange}
              />
              {Boolean(errors.name) && (
                <div className="mt-2 text-left text-xs text-danger">
                  {errors.name}
                </div>
              )}
            </div>
          </div>
          <div className="flex max-w-full flex-col gap-3.5">
            <FormLabel htmlFor="name">
              <div className="flex">
                Webhook URL
                <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-400 dark:bg-darkmode-300 dark:text-slate-400">
                  Required
                </div>
              </div>
              <div className="mt-1.5 text-left text-xs text-slate-500">
                Input the url of your&nbsp;{integrationType?.name}
                &nbsp;integration
              </div>
            </FormLabel>
            <div>
              <FormInput
                id="webhook"
                disabled={isLoading}
                error={Boolean((errors.parameters as any)?.webhook_config.url)}
                name="url"
                placeholder="URL"
                value={values.parameters?.webhook_config.url}
                onChange={handleURLChange}
              />
              {Boolean((errors.parameters as any)?.webhook_config.url) && (
                <div className="mt-2 text-left text-xs text-danger">
                  {(errors.parameters as any)?.webhook_config.url}
                </div>
              )}
            </div>
          </div>
          <div className="flex max-w-full flex-col gap-3.5">
            <FormLabel>
              <div className="flex">Headers</div>
              <div className="mt-1.5 text-left text-xs text-slate-500">
                Input header key and values for your integration.
              </div>
            </FormLabel>
            {values.parameters?.webhook_config.headers.map((header, index) => (
              <div
                key={`header-${index}`}
                className="grid grid-cols-11 items-center gap-6"
              >
                <div className="col-span-5">
                  <FormInput
                    disabled={isLoading}
                    name="key"
                    placeholder="Key"
                    value={header.key}
                    onChange={e => handleHeadersChange(e, index)}
                  />
                </div>
                <div className="col-span-5">
                  <FormInput
                    disabled={isLoading}
                    name="value"
                    placeholder="Value"
                    value={header.value}
                    onChange={e => handleHeadersChange(e, index)}
                  />
                </div>
                <div className="col-span-1 flex justify-end">
                  <Button
                    disabled={isLoading}
                    variant="text"
                    onClick={() => handleRemoveHeaderPair(index)}
                  >
                    <Lucide
                      icon="Trash2"
                      className="size-5 stroke-slate-500 stroke-[1.3]"
                    />
                  </Button>
                </div>
              </div>
            ))}
            <Button
              className="flex gap-1.5 rounded-md border-dashed border-sky-800 text-sky-800"
              disabled={isLoading}
              onClick={handleAddHeaderPair}
            >
              <Lucide icon="Plus" />
              Add Header Key/Value Pair
            </Button>
          </div>
        </>
      )}
      {integration && !isEdit && (
        <div className="flex max-w-full flex-col gap-3.5">
          <div className="text-base font-medium">
            Integration Name: {integration.name}
          </div>
          <div className="text-base font-medium">
            Webhook URL: {integration.parameters?.webhook_config.url}
          </div>

          <div className="text-base font-medium">Headers</div>
          <div className="grid grid-cols-12">
            <div className="col-span-6 text-base font-medium">Key</div>
            <div className="col-span-6 text-base font-medium">Value</div>
          </div>
          {integration.parameters?.webhook_config.headers.map(
            ({ key, value }) => (
              <div key={`${key}-${value}`} className="grid grid-cols-12">
                <div className="col-span-6 font-medium">{key}</div>
                <div className="col-span-6 font-medium">{value}</div>
              </div>
            ),
          )}
          <div className="flex gap-6">
            <Button
              variant="soft-secondary"
              className="flex w-fit gap-1.5 px-3.5 py-2.5 text-slate-500"
              onClick={onEdit}
            >
              <Lucide icon="PenLine" />
              <div className="text-sm font-semibold">Edit</div>
            </Button>
            <Button
              className="border-orange-700 bg-orange-700/80 px-3 py-2 text-white"
              disabled={isLoading}
              variant="soft-sky-outline"
              onClick={onDelete}
            >
              <div className="flex gap-1.5 font-normal">
                <Lucide icon="X" />
                Disconnect
              </div>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export { WebhookForm };
