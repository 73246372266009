import { TokenOrg } from '@kerplunkai/common-components';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { BASE_URL } from '@constants';
import {
  LoginRequest,
  SetPasswordWithToken,
  SignupRequest,
  SignupWithTokenRequest,
  UserResponse,
} from '@typings';
import { RootState } from '@store/store';

export const authService = createApi({
  reducerPath: 'authService',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/`,
    prepareHeaders: (headers, { getState }) => {
      const { token } = (getState() as RootState).auth;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: builder => ({
    login: builder.mutation<UserResponse, LoginRequest>({
      query: credentials => ({
        url: 'login',
        method: 'POST',
        body: { user: credentials },
      }),
      transformResponse: (response: UserResponse, meta) => {
        const bearerToken = meta?.response?.headers.get('Authorization');
        const token = bearerToken?.split(' ')[1] as string;

        return {
          ...response,
          token,
        };
      },
    }),
    logout: builder.mutation<null, void>({
      query: () => ({
        url: 'logout',
        method: 'DELETE',
      }),
    }),
    signup: builder.mutation<UserResponse, SignupRequest>({
      query: credentials => ({
        url: 'signup',
        method: 'POST',
        body: { user: { ...credentials, origin_signup: 'organization_user' } },
      }),
      transformResponse: (response: UserResponse, meta) => {
        const bearerToken = meta?.response?.headers.get('Authorization');
        const token = bearerToken?.split(' ')[1] as string;

        return {
          ...response,
          token,
        };
      },
    }),
    forgotPassword: builder.mutation<void, string>({
      query: email => ({
        url: 'password',
        method: 'POST',
        body: {
          user: {
            email,
          },
        },
      }),
    }),
    setPassword: builder.mutation<void, SetPasswordWithToken>({
      query: setPassword => ({
        url: 'password',
        method: 'PUT',
        body: {
          user: setPassword,
        },
      }),
    }),
    checkToken: builder.mutation<TokenOrg[], string>({
      query: invitation_token => ({
        url: 'user/token_check',
        method: 'POST',
        body: {
          user: { invitation_token },
        },
      }),
    }),
    signUpWithToken: builder.mutation<UserResponse, SignupWithTokenRequest>({
      query: signUpWithToken => ({
        url: 'signup',
        method: 'POST',
        body: {
          user: signUpWithToken,
        },
      }),
      transformResponse: (response: UserResponse, meta) => {
        const bearerToken = meta?.response?.headers.get('Authorization');
        const token = bearerToken?.split(' ')[1] as string;

        return {
          ...response,
          token,
        };
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useSignupMutation,
  useLogoutMutation,
  useForgotPasswordMutation,
  useSetPasswordMutation,
  useCheckTokenMutation,
  useSignUpWithTokenMutation,
} = authService;
