import { Interview } from '@typings';

export const PAYWALL_INTERVIEW_SUMMARY: Interview = {
  id: '',
  job_application_id: '',
  status: 'completed',
  interview_type: '',
  rating: 0,
  feedback: '',
  created_at: '',
  updated_at: '',
  candidate_id: '',
  candidate_name: 'AI Exceeded',
  candidate_email: '',
  candidate_display_name: '',
  candidate_linked_in_url: '',
  candidate_avatar_url:
    'https://secure.gravatar.com/avatar/95bd0845b4542423b8a6f1bad1d8a50b.png?r=PG',
  organization: 'Big Sky Recruiting',
  organization_logo_url: '',
  show_hiring_organization: true,
  hiring_organization: 'Big Sky Recruiting',
  hiring_organization_logo_url: '',
  job_title: 'Test Recruiting Job',
  is_resume_only: false,
  interview_questions: [],
  ai_score: 75.0,
  pros_and_cons: {
    pros: '',
    cons: '',
  },
  resume: {
    id: '',
    file_type: '',
    uploaded_at: new Date(),
    filename: '',
    url: '',
    analyses: [
      {
        id: '',
        ai_score: 87.0,
        scores: {
          resume_score: 90,
          x_factor_score: 80,
          technical_score: 92,
          culture_fit_score: 85,
          soft_skills_score: 88,
          interview_score: 75,
        },
        commentary: {
          analysis:
            'Aliqua excepteur sunt ipsum aute magna laborum fugiat duis in qui est ut fugiat ea. Irure pariatur non elit cillum enim ad laboris consequat. Non commodo reprehenderit cupidatat labore cupidatat non amet culpa ad aliquip in. Nulla quis ullamco dolore excepteur in dolore ut minim aliquip. Ea anim non aliquip duis sunt laboris in esse ullamco sit sint. In magna non laborum excepteur consectetur mollit occaecat ipsum in magna ex laboris. Laboris ea nisi adipisicing sunt consequat.',
          feedback:
            'Laboris Lorem dolore consequat aliquip. Nulla id consectetur amet dolor commodo nulla eiusmod laborum exercitation do veniam. Adipisicing adipisicing in tempor consectetur sit consequat nulla fugiat aute aute esse consequat tempor dolor. Fugiat dolor sit et cupidatat aliquip culpa voluptate amet sunt occaecat ad. Ad mollit nostrud fugiat tempor qui pariatur aliquip sint. Enim incididunt pariatur tempor Lorem voluptate et consectetur.',
          culture_fit:
            'Do dolore qui ipsum proident ipsum aliquip. Culpa cillum cupidatat minim elit excepteur. Amet tempor eiusmod aute velit ad. Voluptate occaecat esse fugiat consequat eiusmod occaecat labore commodo do deserunt. Irure cillum ipsum dolore dolor voluptate ut.',
          soft_skills:
            'Magna duis ex ipsum incididunt aute ad amet veniam elit. Mollit cupidatat sunt occaecat laboris consequat ex. Nisi aliqua enim veniam magna Lorem id excepteur laboris ullamco dolore consequat. Eiusmod aute elit laboris eiusmod est fugiat cupidatat consequat sunt. Ipsum voluptate fugiat dolore exercitation enim minim enim aute elit minim. Ea esse mollit laboris ipsum do nostrud. Incididunt culpa non sint ipsum voluptate ex occaecat exercitation sit ad sint aute do.',
        },
        details: {
          phone_number: '',
          current_title: '',
          current_location: '',
          experience_level: '',
        },
      },
    ],
  },
  full_interview_video_url: '',
  full_interview_transcript_url: '',
  score_breakdown: {
    interview: {
      resume_score: 85,
      x_factor_score: 92,
      technical_score: 73,
      culture_fit_score: 57,
      soft_skills_score: 68,
      interview_score: 34,
    },
    resume: {
      resume_score: 85,
      x_factor_score: 92,
      technical_score: 73,
      culture_fit_score: 57,
      soft_skills_score: 68,
      interview_score: 34,
    },
  },
  commentary: {
    analysis:
      'Sint in fugiat et nostrud. Nisi sit exercitation minim labore amet quis qui occaecat sunt eiusmod ad dolore. Exercitation commodo culpa eiusmod enim ex duis cillum ad aliquip sunt ullamco do qui tempor. Enim qui sit fugiat do. Dolore eu duis mollit et esse cupidatat. Amet proident do sunt deserunt laborum consectetur labore labore officia cillum in exercitation deserunt. Excepteur eiusmod aute ea cillum cillum excepteur exercitation Lorem occaecat incididunt.',
    feedback:
      'Ad non sint sit ex culpa tempor in do velit nostrud dolore id irure. Mollit culpa adipisicing laborum ipsum excepteur nostrud sit et laborum ipsum amet. Ea est laboris in nisi nulla tempor commodo deserunt. Laboris non mollit cillum et magna id enim ad exercitation est qui. Tempor cupidatat deserunt nostrud mollit non ut id.',
    culture_fit:
      'Eiusmod cupidatat reprehenderit labore laborum in occaecat incididunt cillum. Quis qui excepteur aliqua consectetur minim culpa ex velit sit tempor. Ex ea cupidatat exercitation voluptate eiusmod dolore commodo ex laborum elit. Aliquip et ipsum laborum duis magna esse laborum et ex aliquip esse ea reprehenderit veniam.',
    soft_skills:
      'Ex fugiat ipsum tempor ex id ad. Proident fugiat ea ex cillum commodo tempor anim. Ipsum consectetur sint consectetur ullamco occaecat nostrud officia laborum Lorem minim labore. Amet deserunt proident exercitation nisi aliquip fugiat exercitation sint incididunt occaecat proident eiusmod. Sunt officia consequat pariatur non duis tempor excepteur ea. Aliqua veniam deserunt culpa dolore commodo irure quis.',
  },
  detailed_commentary: {
    overview:
      'Qui sit mollit ullamco deserunt minim voluptate aute commodo culpa mollit. Tempor officia Lorem in sint nulla enim id deserunt irure nisi ea consequat. Elit excepteur consequat proident elit culpa aliqua ipsum Lorem ad culpa amet exercitation nulla. Quis consequat aute in sint ullamco adipisicing sunt est do duis eu aliquip mollit.',
    adaptability:
      'Dolore proident anim id tempor irure do id reprehenderit eu ipsum adipisicing voluptate culpa. Anim excepteur eiusmod nostrud quis. Cupidatat officia adipisicing et excepteur reprehenderit duis sit velit voluptate. Anim commodo labore elit eiusmod fugiat voluptate non culpa veniam. Lorem in laborum ipsum mollit esse irure ipsum enim culpa duis. Occaecat excepteur incididunt qui amet dolore et esse.',
    communication:
      'Laboris sunt cillum ut non aliquip tempor Lorem. Occaecat ad ullamco dolore sint aute elit quis minim amet reprehenderit excepteur. Aliquip ea nulla pariatur est officia ex commodo amet in irure. Excepteur pariatur laboris deserunt mollit laboris nisi ut dolore eiusmod minim. Consectetur sit ad occaecat aliquip non ex.',
    conclusion:
      'Aliquip enim et quis pariatur sit nisi adipisicing velit minim. Consectetur voluptate sint exercitation commodo reprehenderit aliquip magna. Laborum cupidatat aliquip exercitation occaecat laboris tempor duis cillum occaecat eu. Dolor sit anim esse nulla amet ea duis do aute sunt.',
    continuous_improvement:
      'Sunt consectetur ipsum minim voluptate fugiat amet reprehenderit cupidatat exercitation ipsum consequat. Irure velit ut elit et. Ea sit quis commodo excepteur nulla magna velit non eiusmod elit. Cillum consectetur ea dolore nisi consectetur ipsum et duis enim esse tempor in. Ullamco deserunt officia dolore laboris tempor irure. Quis sunt amet ut dolor duis dolor culpa cillum. Incididunt deserunt aute mollit in pariatur excepteur enim cupidatat magna commodo est.',
    experience:
      'Voluptate mollit in culpa adipisicing quis eu pariatur. Nulla laboris occaecat sint culpa aliquip. Eu nisi laboris incididunt reprehenderit consectetur nostrud proident proident.',
    financial_impact:
      'Sunt ad esse excepteur reprehenderit mollit labore. Consequat dolor consequat consequat mollit reprehenderit mollit qui veniam sint occaecat officia aliqua amet. Duis eiusmod fugiat commodo laborum culpa magna laboris id deserunt minim elit. Deserunt dolor nisi excepteur nisi eiusmod do commodo labore enim aliquip consectetur ex cillum. Eu reprehenderit proident est eu sint deserunt minim sunt dolore nostrud pariatur ex. In non adipisicing consectetur deserunt nulla amet id laborum labore dolore duis id occaecat.',
    professionalism:
      'Irure exercitation aliqua aute culpa magna anim nostrud nostrud mollit consectetur. Aliqua elit incididunt excepteur minim labore. Non reprehenderit enim occaecat aute. Amet duis commodo dolore dolor occaecat. Ex dolor aute consequat mollit duis sit magna cillum enim qui in. Consequat aliquip commodo anim enim labore velit sint. Anim deserunt esse enim adipisicing laborum ea et ea enim duis.',
  },
  job_listing: {
    id: '',
    title: 'Test Recruiting Job',
    description: '',
    status: 'published',
    job_location: ', ',
    location_type: '',
    job_type: 'contract',
    salary: null,
    display_compensation: true,
    number_of_positions: null,
    pay_type: 'per_hour',
    max_pay: '0.0',
    min_pay: '0.0',
    slug: 'test-recruiting-job',
    shortcode: 'L0C2D0oESKy8Fg',
    published_at: new Date(),
    started_at: null,
    closed_at: null,
    created_at: new Date(),
    updated_at: new Date(),
    application_count: 2,
    weekly_change: null,
    daily_change: null,
    organization: 'Big Sky Recruiting',
    show_hiring_organization: true,
    hiring_organization: 'Big Sky Recruiting',
    full_address: 'United States of America',
    street_address: null,
    city: '',
    state: '',
    postal_code: null,
    country: '',
    generate_coding_questions: false,
    intro_video_url: '',
    created_by: {
      display_name: 'Kerpunk',
    },
    organization_industry: {
      name: 'Recruiting',
      id: '',
    },
    industry: {
      name: 'Healthcare',
      id: '',
    },
    experience_levels: ['three_to_five_years'],
    schedules: ['eight_hour_shift'],
    categories: [],
    subcategories: [],
    benefits: [],
    interview_questions: [
      {
        id: '',
        content:
          "How do you align your personal values with the company's mission?",
        suggested_answers: [],
        locked: false,
        position: 1,
        generated_by_ai: true,
        category: 'culture',
      },
    ],
  },
  notes: [],
};
