import Avatar from 'react-avatar';
import clsx from 'clsx';
import {
  Lucide,
  Menu,
  Modal,
  ModalDialog,
} from '@kerplunkai/common-components';
import { useLocation } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import logoFull from '@assets/images/logos/Kerplunk-Logo-wht.svg';
import { resetUser } from '@store/slices';
import { selectCurrentUser } from '@store/selectors';
import { useAppDispatch } from '@store/hooks';
import { useInterviewActionContext } from '@modules/conference/components';

interface InterviewHeaderProps {
  topBarActive: boolean;
}

function InterviewHeader({ topBarActive }: InterviewHeaderProps) {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { closeFunction: interviewCleanupFunction } =
    useInterviewActionContext();

  const [showConfirmLogoutDialog, setShowConfirmLogoutDialog] = useState(false);
  const [showExitModal, setShowExitModal] = useState(false);

  const currentUser = useSelector(selectCurrentUser);

  const handleLogout = () => {
    setShowConfirmLogoutDialog(false);
    dispatch(resetUser());
  };

  const handleEndInterview = () => {
    interviewCleanupFunction?.();
    // navigate(NAV_ROUTES.DASHBOARD);
  };

  const ExitInterviewButton = useMemo(() => {
    return (
      <Menu.Item onClick={() => setShowExitModal(true)}>
        <Lucide icon="DoorOpen" className="mr-2 size-4" />
        Exit Interview
      </Menu.Item>
    );
  }, []);

  const disableAvatarMenu = useMemo(() => {
    const disableMenuPaths = [
      '/interview/questions',
      '/interview/end_interview',
    ];
    return disableMenuPaths.some(path => path === location.pathname);
  }, [location]);

  return (
    <div className="fixed inset-x-0 top-0 mt-3.5 h-[65px] transition-[margin] duration-100  group-[.side-menu--collapsed]:xl:ml-[90px]">
      <div
        className={clsx([
          'top-bar group absolute inset-x-0 mx-5 h-full xl:left-3.5',
          topBarActive && 'top-bar--active',
        ])}
      >
        <div
          className="
                box group-[.top-bar--active]:box container flex size-full items-center border-transparent bg-transparent shadow-none transition-[padding,background-color,border-color] duration-300 ease-in-out 
                group-[.top-bar--active]:border-transparent group-[.top-bar--active]:bg-transparent
                group-[.top-bar--active]:bg-gradient-to-r group-[.top-bar--active]:from-theme-1 group-[.top-bar--active]:to-theme-2 group-[.top-bar--active]:px-5
              "
          style={{ backgroundColor: 'transparent' }} // TODO: figure out why the tailwind bg-transparent class is not applied correctly
        >
          <div className="flex items-center gap-1 xl:hidden" />

          <div className="flex flex-1 items-center">
            <img
              className="mr-2 h-[30px] w-[119px] self-center"
              src={logoFull}
              alt="logo"
            />
            <div className="ml-auto flex items-center gap-1" />
            <Menu className="ml-5">
              <div className="flex">
                <Menu.Button className="image-fit size-[36px] overflow-hidden rounded-full border-[3px] border-white/[0.15]">
                  <Avatar
                    src={
                      currentUser?.avatar_url || currentUser?.backup_avatar_url
                    }
                    size="100%"
                  />
                </Menu.Button>
                <div className="text ml-2 self-center text-sm text-white">
                  {currentUser?.first_name} {currentUser?.last_name}
                </div>
              </div>

              {!disableAvatarMenu && (
                <Menu.Items className="mt-1 w-56">
                  {ExitInterviewButton}
                </Menu.Items>
              )}
            </Menu>
          </div>
        </div>
      </div>
      <ModalDialog
        open={showConfirmLogoutDialog}
        onConfirm={handleLogout}
        onCancel={() => setShowConfirmLogoutDialog(false)}
        title="Log Out"
        description="Do you really want to log out? This will end your interview. You will have a chance to resume it later."
        confirmText="Log Out"
        cancelText="Cancel"
      />
      <Modal
        backgroundVariant="redGradient"
        open={showExitModal}
        iconName="PhoneMissed"
        title="Exit Interview?"
        confirmExitText="Exit Interview"
        resumeText="Resume Interview"
        description="Not feeling the interview right now? You can jump back into this interview later from your Kerplunk dashboard."
        onConfirm={handleEndInterview}
        onResume={() => setShowExitModal(false)}
      />
    </div>
  );
}

export { InterviewHeader };
