import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Loading } from '@kerplunkai/common-components';
import { SubcategoryForm } from '@modules/categories/subcategoryForm.component';
import { selectSelectedOrgId } from '@store/selectors';
import { useJobSubcategoryQuery } from '@store/services';

import { Tips } from '@components';

function Subcategory() {
  const { subcategoryId } = useParams<{ subcategoryId: string }>();

  const orgId = useSelector(selectSelectedOrgId);

  const { data, isLoading } = useJobSubcategoryQuery(
    {
      organizationId: orgId as string,
      jobSubcategoryId: subcategoryId as string,
    },
    { skip: subcategoryId === 'new' },
  );

  if (isLoading) return <Loading />;

  return (
    <div className="grid grid-cols-12 gap-x-6 gap-y-10">
      <div className="col-span-12">
        <div className="mt-4 flex flex-col gap-y-3 md:mt-0 md:h-10 md:flex-row md:items-center">
          <div className="text-base font-medium group-[.mode--light]:text-white">
            Create a Subcategory
          </div>
        </div>
        <div className="mt-3.5 grid grid-cols-12 gap-x-6 gap-y-7 lg:gap-y-10 xl:grid-cols-10">
          <SubcategoryForm subcategoryData={data} />
          <div className="relative order-first col-span-12 lg:order-last lg:col-span-3 xl:col-span-2">
            <Tips
              text={
                "Tips for Categories: A compelling job post attracts the best candidates. Ensure that your job title is specific, the description is clear and provides an insight into your company culture. Highlight the key requirements but keep the post concise to maintain the reader's interest."
              }
              subtitle="Job Categories"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export { Subcategory };
