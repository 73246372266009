import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import {
  FormActions,
  FormCard,
  FormInputControl,
} from '@kerplunkai/common-components';
import { JobCategory } from '@typings';
import { NAV_ROUTES } from '@constants';
import { selectSelectedOrgId } from '@store/selectors';
import {
  useCreateJobSubcategoryMutation,
  useUpdateJobSubcategoryMutation,
} from '@store/services';

function SubcategoryForm({
  subcategoryData,
}: {
  subcategoryData?: JobCategory;
}) {
  const navigate = useNavigate();
  const { subcategoryId } = useParams<{ subcategoryId: string }>();
  const { enqueueSnackbar } = useSnackbar();

  const [
    createJobSubcategory,
    { isLoading: isCreating, error: createError, isSuccess: isCreateSuccess },
  ] = useCreateJobSubcategoryMutation();
  const [
    updateJobSubcategory,
    { isLoading: isUpdating, error: updateError, isSuccess: isUpdateSuccess },
  ] = useUpdateJobSubcategoryMutation();

  const orgId = useSelector(selectSelectedOrgId);

  const [subcategory, setSubcategory] = useState(
    subcategoryData || { name: '' },
  );

  useEffect(() => {
    if (isCreateSuccess || isUpdateSuccess) navigate(NAV_ROUTES.SUBCATEGORIES);
  }, [navigate, isCreateSuccess, isUpdateSuccess]);

  useEffect(() => {
    if (createError || updateError) {
      enqueueSnackbar({
        message: `Unable to ${createError ? 'create' : 'update'} subcategory. Please try again.`,
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, createError, updateError]);

  const isLoading = useMemo(
    () => isCreating || isUpdating,
    [isCreating, isUpdating],
  );

  const isNew = useMemo(() => subcategoryId === 'new', [subcategoryId]);

  const handleCreateJobSubcategory = useCallback(() => {
    createJobSubcategory({
      ...subcategory,
      organizationId: orgId as string,
    });
  }, [subcategory, orgId, createJobSubcategory]);

  const handleUpdateJobSubcategory = useCallback(() => {
    updateJobSubcategory({
      ...subcategory,
      organizationId: orgId as string,
    });
  }, [subcategory, orgId, updateJobSubcategory]);

  const handleSubmitJob = useCallback(() => {
    if (!isNew) {
      handleUpdateJobSubcategory();
      return;
    }

    handleCreateJobSubcategory();
  }, [isNew, handleCreateJobSubcategory, handleUpdateJobSubcategory]);

  return (
    <div className="relative col-span-12 flex flex-col gap-y-7 lg:col-span-9 xl:col-span-8">
      <FormCard hideAccordion title="Subcategory Information">
        <div className="mt-5">
          <FormInputControl
            className="mt-5"
            description="Enter a name for this category."
            helpText={`Maximum characters ${subcategory.name.length}/70`}
            inputProps={{
              disabled: isLoading,
              onChange: ({ target: { name, value } }) => {
                if (value.length <= 70) {
                  setSubcategory(curSubcategory => ({
                    ...curSubcategory,
                    [name]: value,
                  }));
                }
              },
            }}
            isRequired
            label="Subcategory Name"
            name="name"
            value={subcategory.name}
          />
        </div>
      </FormCard>
      <FormActions
        cancel={{
          disabled: isLoading,
          icon: 'X',
          text: 'Cancel',
          onClick: () => navigate(NAV_ROUTES.SUBCATEGORIES),
        }}
        submit={{
          disabled: isLoading,
          icon: 'Send',
          text: isLoading ? 'Saving' : 'Save',
          onClick: handleSubmitJob,
        }}
      />
    </div>
  );
}

export { SubcategoryForm };
