/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
  FormCheckControl,
  FormControl,
  FormInput,
  FormInputControl,
  FormSelectControl,
  FormSwitch,
  TomSelect,
} from '@kerplunkai/common-components';
import { useField } from 'formik';
import { useSelector } from 'react-redux';

import { AddressAutocomplete } from '@components';
import {
  CategoryType,
  JobCategoryFormModal,
} from '@modules/jobs/jobForms/jobCategoryFormModal.component';
import { ClientFormModal } from '@modules/jobs/jobForms/clientFormModal.component';
import { JobRequest, Organization } from '@typings';
import { parseJSON } from '@utilities';
import {
  selectLocationTypes,
  selectOrgClients,
  selectSelectedOrg,
} from '@store/selectors';
import {
  useCategoryOptions,
  useIndustryOptions,
  useOrgClients,
  useSubcategoryOptions,
} from '@hooks';

interface JobInfoFormProps {
  job: JobRequest;
  isLoading: boolean;
  orgId: string;
  onChange: (name: string, value: number | boolean | string | string[]) => void;
}

const PAGINATION_INFO = {
  pageIndex: 1,
  pageSize: 1000,
};

function JobInfoForm({ job, isLoading, orgId, onChange }: JobInfoFormProps) {
  const [, titleMeta] = useField('title');
  const [, hiringOrgIdMeta] = useField('hiring_organization_id');
  const [, industryMeta] = useField('industry_id');

  const jobCategoryOptions = useCategoryOptions(orgId);
  const jobSubcategoryOptions = useSubcategoryOptions(orgId);
  const industries = useIndustryOptions();
  const clientOptions = useOrgClients({
    orgId: orgId as string,
    pagination: PAGINATION_INFO,
    filters: '',
  });
  const locationTypes = useSelector(selectLocationTypes(orgId));
  const clients = useSelector(
    selectOrgClients({
      orgId: orgId as string,
      pagination: PAGINATION_INFO,
      filters: '',
    }),
  );
  const org = useSelector(selectSelectedOrg);

  const [itemToBeAdded, setItemToBeAdded] = useState<string>();
  const [categoryType, setCategoryType] = useState<CategoryType>('category');
  const [clientToBeAdded, setClientToBeAdded] = useState<string>();

  const handleInputChange = useCallback(
    ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
      onChange(name, value);
    },
    [onChange],
  );

  const handleSingleSelectChange = useCallback(
    ({
      target: { name, value },
    }: {
      target: { name: string; value: string };
    }) => {
      if (name === 'hiring_organization_id') {
        const client = clients?.find(c => c.id === value);

        if (client) onChange('industry_id', client.industry.id);
        else if (org)
          onChange(
            'industry_id',
            org.industry.name === 'Recruiting' ? '' : org.industry.id,
          );
      }

      onChange(name, value);
    },
    [clients, org, onChange],
  );

  const orgItems = useMemo(() => {
    const items = [];

    if (org) items.push({ value: org.id, name: org.name });
    if (clientOptions) items.push(...clientOptions);

    return items;
  }, [org, clientOptions]);

  const industryOptions = useMemo(
    () =>
      industries.filter(
        industry =>
          industry.name !== 'Recruiting' || org?.industry.name === 'Recruiting',
      ),
    [industries, org],
  );

  return (
    <>
      <div className="mt-5">
        <FormInputControl
          className="mt-5"
          description="Enter a job title for this position."
          errorText={titleMeta.error}
          helpText={`Maximum characters ${job.title.length}/70`}
          inputProps={{ disabled: isLoading, onChange: handleInputChange }}
          isRequired
          label="Job Title"
          name="title"
          value={job.title}
        />
        {org?.industry.name === 'Recruiting' && (
          <>
            <FormControl
              className="mt-5"
              description="Which organization is hiring for this position?"
              isRequired
              label="Hiring Organization"
              name="hiring_organization_id"
            >
              <TomSelect
                className="w-full"
                disabled={isLoading}
                options={{
                  create: (item: string) => {
                    setClientToBeAdded(item);

                    return false;
                  },
                  placeholder: 'Hiring Organization',
                }}
                value={job.hiring_organization_id || ''}
                onChange={e =>
                  handleSingleSelectChange({
                    target: {
                      name: 'hiring_organization_id',
                      value: e.target.value,
                    },
                  })
                }
              >
                {orgItems.map(({ value, name }) => (
                  <option key={value} value={value}>
                    {name}
                  </option>
                ))}
              </TomSelect>
              {Boolean(hiringOrgIdMeta.error) && (
                <div className="mt-2 text-left text-xs text-danger">
                  {hiringOrgIdMeta.error}
                </div>
              )}
            </FormControl>
            <FormSelectControl
              className="mt-5"
              description="Select the industry that best represents your job. This helps candidates find your position more easily."
              emptyText="Select Industry..."
              errorText={industryMeta.error}
              isRequired
              label="Industry"
              name="industry_id"
              options={industryOptions}
              selectProps={{
                disabled: job.hiring_organization_id !== org?.id,
                onChange: handleSingleSelectChange,
              }}
              showEmptyOption
              value={job?.industry_id || ''}
            />
          </>
        )}
        <FormControl
          className="mt-5 flex"
          description="Turn this toggle on to display the hiring organization to the candidate."
          label="Display Organization"
        >
          <div className="flex items-center">
            <FormSwitch.Input
              checked={job.show_hiring_organization}
              disabled={isLoading}
              type="checkbox"
              onChange={() =>
                onChange(
                  'show_hiring_organization',
                  !job.show_hiring_organization,
                )
              }
            />
            <p className="ml-2 text-slate-400">Display Hiring Organization</p>
          </div>
        </FormControl>
        <FormControl
          className="mt-5"
          description="Select the primary category that best represents your job. This helps candidates find your position more easily."
          label="Category"
          name="job_category_ids"
        >
          <TomSelect
            className="w-full"
            disabled={isLoading}
            options={{
              create: (item: string) => {
                setCategoryType('category');
                setItemToBeAdded(item);

                return false;
              },
              placeholder: 'Category',
              itemClass: 'border-none',
              plugins: {
                remove_button: {
                  title: '',
                  className: 'hidden',
                },
              },
            }}
            value={job.job_category_ids}
            onChange={e => onChange('job_category_ids', e.target.value)}
          >
            <option value="">Category</option>
            {jobCategoryOptions.map(({ value, name }) => (
              <option key={value} value={value}>
                {name}
              </option>
            ))}
          </TomSelect>
        </FormControl>
        <FormControl
          className="mt-5"
          description="Choose a more specific subcategory that closely matches your opening. It provides further details about your job posting."
          label="Subcategory"
          name="job_subcategory_ids"
        >
          <TomSelect
            className="w-full"
            disabled={isLoading}
            options={{
              create: (item: string) => {
                setCategoryType('subcategory');
                setItemToBeAdded(item);

                return false;
              },
              placeholder: 'Subcategory',
            }}
            multiple
            value={job.job_subcategory_ids}
            onChange={e => onChange('job_subcategory_ids', e.target.value)}
          >
            {jobSubcategoryOptions.map(({ value, name }) => (
              <option key={value} value={value}>
                {name}
              </option>
            ))}
          </TomSelect>
        </FormControl>
        <FormCheckControl
          className="mt-5 text-slate-400"
          description="Which option best describes this job's location?"
          disabled={isLoading}
          innerClassName="flex flex-col mt-2 md:mt-0 md:flex-row"
          label="Location Type"
          name="location_type"
          items={Object.keys(locationTypes || {}).map(typeValue => ({
            label: (locationTypes || {})[typeValue],
            value: typeValue,
          }))}
          type="radio"
          value={job.location_type}
          onChange={handleInputChange}
        />
        <FormControl
          className="mt-5"
          description="Enter the name of the city and state the position is located."
          label="Job Location"
        >
          <div className="flex flex-col gap-4">
            <AddressAutocomplete
              curAddress={parseJSON(job.job_location)}
              disabled={isLoading || job.location_type === 'remote'}
              onSelectAddress={(place: google.maps.places.PlaceResult) => {
                onChange('city', '');
                onChange('state', '');
                onChange('country', '');

                place.address_components?.forEach(component => {
                  const componentType = component.types[0];

                  switch (componentType) {
                    case 'locality':
                    case 'postal_town':
                      onChange('city', component.long_name);
                      break;

                    case 'administrative_area_level_1': {
                      onChange('state', component.short_name);
                      break;
                    }

                    case 'country':
                      onChange('country', component.short_name);
                      break;

                    default:
                      break;
                  }
                });

                onChange('job_location', JSON.stringify(place));
              }}
            />
            <div className="grid grid-cols-12 gap-3">
              <FormInput
                containerClass="contents"
                className="col-span-4 text-slate-600"
                disabled
                name="city"
                placeholder="City"
                value={job.city}
              />
              <FormInput
                containerClass="contents"
                className="col-span-4 text-slate-600"
                disabled
                name="state"
                placeholder="State"
                value={job.state}
              />
              <FormInput
                className="col-span-4 text-slate-600"
                disabled
                containerClass="contents"
                name="country"
                placeholder="Country"
                value={job.country}
              />
            </div>
          </div>
        </FormControl>
      </div>
      {itemToBeAdded && (
        <JobCategoryFormModal
          categoryType={categoryType}
          open={itemToBeAdded}
          onAddSuccess={(categoryId: string) => {
            if (categoryType === 'category')
              onChange('job_category_ids', [
                ...job.job_category_ids,
                categoryId,
              ]);
            else
              onChange('job_subcategory_ids', [
                ...job.job_subcategory_ids,
                categoryId,
              ]);
          }}
          toggleOpen={() => setItemToBeAdded(undefined)}
        />
      )}
      {clientToBeAdded && (
        <ClientFormModal
          open={clientToBeAdded}
          toggleOpen={setClientToBeAdded}
          onAddSuccess={(client: Organization) => {
            onChange('hiring_organization_id', client.id);
            onChange('industry_id', client.industry.id);
          }}
        />
      )}
    </>
  );
}

export { JobInfoForm };
