import * as Yup from 'yup';
import {
  AvatarUpload,
  BlobImage,
  FormControl,
  FormInput,
  FormInputControl,
  FormSelectControl,
  isValidFileType,
} from '@kerplunkai/common-components';
import { Formik, FormikProps } from 'formik';
import { RefObject } from 'react';
import { useSelector } from 'react-redux';

import { AddressAutocomplete } from '@components';
import { INITIAL_ORG } from '@constants';
import { OrganizationRequest } from '@typings';
import { getCompanySizeOptions } from '@utilities';
import { selectOrganizationsDetails } from '@store/selectors';

const FILE_SIZE = 5; // 1024 * 1024 * 5

interface OrganizationDetailsFormProps {
  formRef: RefObject<FormikProps<OrganizationRequest>>;
  inputsDisabled?: { [index: string]: boolean };
  isLoading: boolean;
  isUpdate?: boolean;
}

function OrganizationDetailsForm({
  formRef,
  inputsDisabled,
  isLoading,
  isUpdate = false,
}: OrganizationDetailsFormProps) {
  const orgDetails = useSelector(selectOrganizationsDetails());

  const getValidationSchema = () => {
    return Yup.object().shape({
      name: Yup.string().required('Company Name is required'),
      size: Yup.string(),
      industry_id: Yup.string().required('Industry is required'),
      city: Yup.string(),
      state: Yup.string(),
      intro_video_url: Yup.string()
        .nullable()
        .matches(
          /^(https?:\/\/(?:www\.)?[^\s/$.?#].[^\s]*)$/,
          'Must be a valid URL that starts with https://',
        ),
      logo: isUpdate
        ? Yup.mixed().when('logo_url', (logo_url: string[]) => {
            // check value of logo_url; if we have one, then don't validate 'logo' as required
            if (logo_url && logo_url.length > 0 && logo_url[0]) {
              return Yup.mixed().notRequired();
            }
            return Yup.mixed();
          })
        : Yup.mixed()
            .test(
              'is-valid-type',
              'Not a valid image type',
              (value?: { name?: string }) => {
                if (!value || !value.name) return true;
                return isValidFileType(value.name.toLowerCase(), 'image');
              },
            )
            .test('fileSize', 'File size is too large', (value?: unknown) => {
              if (!value) return true;

              const { size } = (value as BlobImage).src;

              return size / 1024 ** 2 <= FILE_SIZE;
            }),
    });
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={INITIAL_ORG}
      validateOnChange={false}
      validationSchema={getValidationSchema()}
      onSubmit={() => {}}
    >
      {({ errors, values, handleChange, setFieldValue }) => {
        const companySizeOptions = getCompanySizeOptions(
          orgDetails?.company_sizes,
          values.size,
        );

        return (
          <>
            <FormControl
              className="mt-5"
              description="Upload an image file of your company logo. Recommended formats are .png or .jpg for best quality."
              label="Company Logo"
            >
              <AvatarUpload
                img={values.logo?.src}
                imageUrl={values.logo_url}
                shouldCrop
                onChange={(avatarBlob: BlobImage | null) => {
                  setFieldValue('logo_url', '');
                  setFieldValue('logo', avatarBlob);
                }}
              />
              {errors.logo && (
                <div className="mt-2 text-xs text-danger">{errors.logo}</div>
              )}
            </FormControl>
            <FormInputControl
              className="mt-5 text-slate-600 placeholder:text-slate-400"
              description="Enter the name of your company as registered or commonly known."
              errorText={errors.name}
              inputProps={{
                disabled: isLoading || inputsDisabled?.name,
                onChange: handleChange,
              }}
              isRequired
              label="Company Name"
              name="name"
              value={values.name}
            />
            <FormSelectControl
              className="mt-5 text-slate-600"
              description="Select the range of the number of employees in your company."
              emptyText="Company Size"
              label="Company Size"
              name="size"
              errorText={errors.size}
              options={companySizeOptions}
              selectProps={{
                disabled:
                  isLoading ||
                  !orgDetails?.company_sizes ||
                  inputsDisabled?.size,
                onChange: handleChange,
              }}
              showEmptyOption
              value={values.size || ''}
            />
            <FormSelectControl
              className="mt-5 text-slate-600"
              description="Choose the industry that best describes your company's main business activity."
              emptyText="Select Industry"
              isRequired
              label="Industry"
              name="industry_id"
              errorText={errors.industry_id}
              options={(orgDetails?.all_industries || []).map(
                ({ id, name }) => ({
                  name,
                  value: id,
                }),
              )}
              selectProps={{
                disabled:
                  isLoading ||
                  !orgDetails?.all_industries ||
                  inputsDisabled?.industry_id,
                onChange: handleChange,
              }}
              showEmptyOption
              value={values.industry_id}
            />
            {isUpdate && (
              <FormInputControl
                className="mt-5 text-slate-600 placeholder:text-slate-400"
                description="Optionally provide a custom video URL to use for your company's interviews."
                errorText={errors.intro_video_url}
                inputProps={{
                  disabled: isLoading || inputsDisabled?.intro_video_url,
                  onChange: handleChange,
                  placeholder: 'https://www.youtube.com/watch?v=id',
                }}
                label="Interview Intro Video"
                name="intro_video_url"
                value={values.intro_video_url || ''}
              />
            )}
            <FormControl
              className="mt-5 text-slate-600"
              description="Enter the location of the company to populate city, state and country."
              label="Company Location"
            >
              <div className="flex flex-col gap-4">
                <AddressAutocomplete
                  disabled={isLoading}
                  onSelectAddress={(place: google.maps.places.PlaceResult) => {
                    setFieldValue('city', '');
                    setFieldValue('state', '');
                    setFieldValue('country', '');

                    place.address_components?.forEach(component => {
                      const componentType = component.types[0];

                      switch (componentType) {
                        case 'locality':
                        case 'postal_town':
                          setFieldValue('city', component.long_name);
                          break;

                        case 'administrative_area_level_1': {
                          setFieldValue('state', component.short_name);
                          break;
                        }

                        case 'country':
                          setFieldValue('country', component.short_name);
                          break;

                        default:
                          break;
                      }
                    });
                  }}
                />

                <div className="grid grid-cols-12 gap-3">
                  <FormInput
                    containerClass="contents"
                    className="col-span-4 text-slate-600"
                    disabled
                    error={Boolean(errors.city)}
                    name="city"
                    placeholder="City"
                    value={values.city}
                    onChange={handleChange}
                  />
                  <FormInput
                    containerClass="contents"
                    className="col-span-4 text-slate-600"
                    disabled
                    error={Boolean(errors.state)}
                    name="state"
                    placeholder="State"
                    value={values.state}
                    onChange={handleChange}
                  />
                  <FormInput
                    containerClass="contents"
                    className="col-span-4 text-slate-600"
                    disabled
                    error={Boolean(errors.country)}
                    name="country"
                    placeholder="Country"
                    value={values.country}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </FormControl>
          </>
        );
      }}
    </Formik>
  );
}

export { OrganizationDetailsForm };
