import clsx from 'clsx';
import spacetime from 'spacetime';
import {
  Button,
  DEFAULT_PAGE_INFO,
  FormSelect,
  Lucide,
  TableFilterPredicatesEnum,
  TableFilters,
  TableRefetchFn,
  TanstackTable,
  TanstackTableAction,
  TanstackTableActionColumn,
} from '@kerplunkai/common-components';
import { ChangeEvent, Dispatch, SetStateAction, useCallback } from 'react';
import { ColumnDef, getCoreRowModel } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { CONFERENCE, NAV_ROUTES, NEW_ROUTES } from '@constants';
import { Interview, InterviewStatus } from '@typings';
import { darkGreen, medGreen } from '@theme';
import {
  getApplicationStatusColor,
  getApplicationStatusIcon,
  getIsReadyForReview,
} from '@utilities';
import { mapToStatusInterviewRequest } from '@utilities/interview';
import { selectSelectedOrgId } from '@store/selectors';
import {
  useLazyOrgInterviewsQuery,
  useUpdateInterviewMutation,
} from '@store/services';

interface InterviewsTableProps {
  allowToPost?: boolean;
}

function InterviewsTable({ allowToPost }: InterviewsTableProps) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [updateInterview] = useUpdateInterviewMutation();
  const [getOrgInterviews, { data: orgInterviewsData, isFetching }] =
    useLazyOrgInterviewsQuery();

  const orgId = useSelector(selectSelectedOrgId);

  const handleViewInterviewDetails = useCallback(
    (interviewId: string) => {
      navigate(`/meetings/${interviewId}`);
    },
    [navigate],
  );

  const handleUpdateInterviewStatus = useCallback(
    async (interviewToUpdate: Interview) => {
      try {
        await updateInterview({
          ...mapToStatusInterviewRequest(interviewToUpdate),
          organization_id: orgId as string,
          status: 'cancelled',
        }).unwrap();

        enqueueSnackbar({
          message: 'Cancelling interivew was successful',
          variant: 'success',
        });
      } catch (e) {
        enqueueSnackbar({
          message: 'Unable to cancel interview. Please try again.',
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, orgId, updateInterview],
  );

  const tableColumns: ColumnDef<Interview>[] = [
    {
      accessorKey: 'title',
      header: 'Meeting Name',
      cell: ({ getValue }) => {
        return (
          <div className="flex flex-col gap-1">
            <p className="text-xs text-slate-400">Meeting Name</p>
            <div className="flex items-center text-sm text-sky-800">
              <div>{getValue<string>()}</div>
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: ({ getValue, row }) => {
        const status = getValue<keyof typeof InterviewStatus>();
        const isReadyForReview = getIsReadyForReview(row.original);

        return status === 'scheduled' ? (
          <Button
            className={clsx(
              'bg-[#A9F72A]/10 p-2',
              `border-[${medGreen}] text-[${darkGreen}]`,
            )}
            onClick={() => navigate(`/${CONFERENCE}/${row.original.id}/setup`)}
          >
            <Lucide className="mr-2" icon="ArrowUpRightSquare" />
            Start Meeting
          </Button>
        ) : (
          <div className="flex flex-col gap-1">
            <p className="text-xs text-slate-400">Status</p>
            <div
              className={clsx(
                'flex items-center gap-1 text-sm',
                `text-${
                  isReadyForReview
                    ? `[${darkGreen}]`
                    : getApplicationStatusColor(status)
                }`,
              )}
            >
              <Lucide
                icon={getApplicationStatusIcon(status)}
                className="size-3.5 stroke-[1.7]"
              />
              <div className="ml-1.5 whitespace-nowrap">
                {isReadyForReview
                  ? 'Ready for Review'
                  : InterviewStatus[status] || 'In Progress'}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: 'interview_at_day',
      header: 'Date',
      cell: ({ row }) => {
        const dt = spacetime(row.original.interview_at as string);

        return (
          <div className="flex flex-col gap-1">
            <p className="text-xs text-slate-400">Date</p>
            <p className="text-sm text-slate-600">
              {dt.format('{month} {date}, {year}')}
            </p>
          </div>
        );
      },
    },
    {
      accessorKey: 'interview_at_time',
      header: 'Time',
      cell: ({ row }) => {
        const dt = spacetime(row.original.interview_at as string);

        return (
          <div className="flex flex-col gap-1">
            <p className="text-xs text-slate-400">Time</p>
            <p className="text-sm text-slate-600">{dt.format('time')}</p>
          </div>
        );
      },
    },
    {
      accessorKey: 'actions',
      header: '',
      maxSize: 50,
      cell: ({ row }) => {
        const isReadyForReview = getIsReadyForReview(row.original);

        const actions: TanstackTableAction<Interview>[] = [
          {
            icon: 'CheckSquare',
            text: 'Edit',
            onClick: () =>
              navigate(`${NAV_ROUTES.MEETINGS}/${row.original.id}`),
          },
          {
            icon: 'XSquare',
            text: 'Cancel',
            onClick: () => handleUpdateInterviewStatus(row.original),
          },
        ];

        if (isReadyForReview)
          actions.unshift({
            icon: 'Briefcase',
            text: 'View Details',
            onClick: () => {
              handleViewInterviewDetails(row.original.id);
            },
          });

        return <TanstackTableActionColumn data={row} actions={actions} />;
      },
      enableSorting: false,
    },
  ];

  const handleRefetch: TableRefetchFn = useCallback(
    (pagination, { filters, searchTerm, sorting }) => {
      let filterQueryParms = '';

      if (searchTerm && searchTerm.length > 0)
        filterQueryParms = `q[title_${TableFilterPredicatesEnum.cont}]=${searchTerm}`;
      if (sorting)
        filterQueryParms += `${filterQueryParms !== '' ? '&' : ''}q[s]=${sorting}`;

      Object.keys(filters || {}).forEach(filter => {
        filterQueryParms += `${filterQueryParms !== '' ? '&' : ''}q[${filter}_${TableFilterPredicatesEnum.eq}]=${(filters || {})[filter]}`;
      });

      getOrgInterviews({
        orgId: orgId as string,
        pagination,
        filters: `${filterQueryParms}&q[interview_type_${TableFilterPredicatesEnum.eq}]=copilot`,
      });
    },
    [getOrgInterviews, orgId],
  );

  return (
    <TanstackTable
      actionsHeaderItems={{
        addItem: allowToPost
          ? {
              text: 'New Meeting',
              onClick: () => navigate(NEW_ROUTES.NEW_MEETING),
            }
          : undefined,
      }}
      emptyText="No Meetings(s) Found"
      initialSorting={{
        initialField: 'custom_sort',
        initialOrder: 'DESC',
      }}
      rowTdClassName="max-sm:first:shadow-custom"
      headerClassName="max-sm:border-t-0 max-sm:mt-3 max-sm:bg-white"
      isFetching={isFetching}
      pageInfo={orgInterviewsData ? orgInterviewsData.meta : DEFAULT_PAGE_INFO}
      searchPlaceholder="Search Meetings..."
      showSearch
      tableOptions={{
        data: orgInterviewsData ? orgInterviewsData.results : [],
        columns: tableColumns,
        getCoreRowModel: getCoreRowModel(),
        getRowId: row => row.id,
      }}
      onRefetch={handleRefetch}
      renderFilters={(
        tempFilters: TableFilters,
        setTempFilters: Dispatch<SetStateAction<TableFilters>>,
      ) => (
        <div>
          <div className="text-left text-slate-500">Status</div>
          <FormSelect
            className="mt-2 flex-1"
            value={tempFilters.status as string}
            onChange={({ target: { value } }: ChangeEvent<HTMLSelectElement>) =>
              setTempFilters({ ...tempFilters, status: value })
            }
          >
            <option value="">Status</option>
            <option value="scheduled">Scheduled</option>
            <option value="started">In Progress</option>
            <option value="processing">Processing</option>
            <option value="completed">Ready for Review</option>
          </FormSelect>
        </div>
      )}
    />
  );
}

export { InterviewsTable };
