import clsx from 'clsx';
import { Outlet, useNavigate } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { FILTER_ORG_ID, NAV_ROUTES } from '@constants';
import { InterviewActionContextProvider } from '@modules/conference/components';
import { InterviewHeader } from '@modules/conference/interviewLayout/interviewHeader.component';
import {
  resetInterviewState,
  setPageLoader,
  setSelectedOrg,
  stopInterviewStreams,
} from '@store/slices';
import {
  selectCurrentUser,
  selectPageLoader,
  selectSelectedOrgId,
} from '@store/selectors';
import { useAppDispatch } from '@store/hooks';
import {
  useLazyGetJobDetailsQuery,
  useLazyOrganizationsDetailsQuery,
  useLazyOrganizationsQuery,
} from '@store/services';

function InterviewLayout() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [getOrganizations] = useLazyOrganizationsQuery();
  const [getJobDetails] = useLazyGetJobDetailsQuery();
  const [getOrganizationDetails] = useLazyOrganizationsDetailsQuery();

  const pageLoader = useSelector(selectPageLoader);
  const currentUser = useSelector(selectCurrentUser);
  const selectedOrgId = useSelector(selectSelectedOrgId);

  useEffect(() => {
    return () => {
      // stop media stream when unmounting interview layout, if any known streams exist
      dispatch(stopInterviewStreams());
      dispatch(resetInterviewState());
    };
    // important this is empty; run cleanup only once per unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedOrgId !== FILTER_ORG_ID) navigate('/');

    if (selectedOrgId) {
      getJobDetails(selectedOrgId);

      dispatch(setPageLoader(false));
    }
  }, [dispatch, navigate, getJobDetails, selectedOrgId]);

  useEffect(() => {
    if (currentUser) {
      (async () => {
        try {
          const prevSelectedOrgId = localStorage.getItem('selectedOrgId');
          const { results } = await getOrganizations().unwrap();

          getOrganizationDetails();

          if (results.length === 0) {
            navigate(NAV_ROUTES.ORGANIZATION_CREATE);
            dispatch(setPageLoader(false));
            return;
          }

          const prevSelectedOrg = results.find(
            ({ id }) => id === prevSelectedOrgId,
          );

          dispatch(setSelectedOrg(prevSelectedOrg || results[0]));
        } catch (e) {
          console.log('ERROR', e);
        }
      })();
    }
  }, [
    dispatch,
    navigate,
    currentUser,
    getJobDetails,
    getOrganizations,
    getOrganizationDetails,
  ]);

  return (
    <InterviewActionContextProvider>
      <Transition
        show={!pageLoader}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className={clsx([
            'echo background group relative min-h-screen bg-gradient-to-b from-slate-200/70 to-slate-50',
            "before:fixed before:top-0 before:h-full before:w-screen before:bg-gradient-to-t before:from-theme-1/80 before:to-theme-2 before:transition-[opacity,height] before:duration-300 before:ease-in-out before:content-[''] [&.background--hidden]:before:opacity-0",
            "after:fixed after:top-0 after:h-full after:w-screen after:bg-texture-white after:bg-contain after:bg-fixed after:bg-[center_-13rem] after:bg-no-repeat after:transition-[opacity,height] after:duration-300 after:ease-in-out after:content-[''] [&.background--hidden]:after:opacity-0",
            false && 'background--hidden',
          ])}
        >
          <div
            className={clsx([
              'side-menu group fixed inset-y-0 left-0 z-50 shadow-xl transition-[margin,padding] duration-300 xl:ml-0 xl:py-3.5 xl:pl-3.5 xl:shadow-none',
              "after:fixed after:inset-0 after:bg-black/80 after:content-[''] after:xl:hidden",
              { 'side-menu--collapsed': false },
              { 'side-menu--on-hover': false },
              { 'ml-0 after:block': false },
              { '-ml-[275px] after:hidden': true },
            ])}
          >
            <InterviewHeader topBarActive={false} />
          </div>
          <div
            className={clsx([
              'mode group relative z-10 pb-16 pt-[54px] transition-[margin,width] duration-100 xl:pl-3.5',
              { 'mode--light': true },
            ])}
          >
            <div className="mt-8 px-3.5 sm:px-5">
              <div className="container">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </Transition>
      <Transition
        show={pageLoader}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <svg
          className="fixed inset-0 m-auto size-10 animate-spin text-theme-1"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      </Transition>
      {/* for displaying confetti */}
      <canvas className="absolute top-0 size-full" id="confetti-canvas" />
    </InterviewActionContextProvider>
  );
}

export { InterviewLayout };
