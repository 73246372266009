import { CAMERA_PERM_NAME, MIC_PERM_NAME } from '@constants';

export async function getDevices() {
  // The following line prevents issues on Safari/FF WRT to device selects
  // and ensures the device labels are not blank
  // await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
  const devices = await navigator.mediaDevices.enumerateDevices();
  const videoDevices = devices.filter(d => d.kind === 'videoinput');
  if (!videoDevices.length) {
    console.log('No video devices found.');
  }
  const audioDevices = devices.filter(d => d.kind === 'audioinput');
  if (!audioDevices.length) {
    console.log('No audio devices found.');
  }

  return { videoDevices, audioDevices };
}

type MediaPermissions = {
  hasAudioPermission: boolean;
  hasVideoPermission: boolean;
};

export async function checkMediaPermissions(): Promise<MediaPermissions> {
  const permissions: MediaPermissions = {
    hasAudioPermission: false,
    hasVideoPermission: false,
  };

  try {
    const videoPermission = await navigator.permissions.query({
      name: 'camera' as PermissionName,
    });

    const audioPermission = await navigator.permissions.query({
      name: 'microphone' as PermissionName,
    });

    permissions.hasAudioPermission = audioPermission.state === 'granted';
    permissions.hasVideoPermission = videoPermission.state === 'granted';
  } catch (error) {
    console.error('Error checking media permissions:', error);
  }

  return permissions;
}

export async function getCameraStream(deviceId: string) {
  const videoConstraints = {
    audio: false,
    video: {
      deviceId,
    },
  };
  const stream = await navigator.mediaDevices.getUserMedia(videoConstraints);
  return stream;
}

export async function requestMediaPermissions() {
  try {
    // Request access to camera, then mic
    const camStream = await navigator.mediaDevices.getUserMedia({
      video: true,
    });
    camStream.getTracks().forEach(track => track.stop());

    const micStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });
    micStream.getTracks().forEach(track => track.stop());

    return true; // Permission granted
  } catch (error) {
    console.error('Error accessing media devices:', error);
    return false; // Permission denied or error occurred
  }
}

export async function getMicStream(deviceId: string) {
  const audioConstraints = {
    video: false,
    audio: {
      deviceId,
    },
    // deviceId: deviceId ? { exact: deviceId } : null,
  } as MediaStreamConstraints;

  const stream = await navigator.mediaDevices.getUserMedia(audioConstraints);
  return stream;
}

export const getPermissionIcon = (
  permName: typeof CAMERA_PERM_NAME | typeof MIC_PERM_NAME,
) => {
  switch (permName) {
    case CAMERA_PERM_NAME:
      return 'Video';

    case MIC_PERM_NAME:
      return 'Mic';

    default:
      return 'Video';
  }
};

export const getPermissionTitleText = (
  permName: typeof CAMERA_PERM_NAME | typeof MIC_PERM_NAME,
) => {
  switch (permName) {
    case CAMERA_PERM_NAME:
      return 'Camera';

    case MIC_PERM_NAME:
      return 'Microphone';

    default:
      return 'Device';
  }
};
