import spacetime from 'spacetime';
import { ColumnDef, getCoreRowModel } from '@tanstack/react-table';
import {
  DEFAULT_PAGE_INFO,
  ModalDialog,
  TableFilterPredicatesEnum,
  TableRefetchFn,
  TanstackTable,
  TanstackTableActionColumn,
} from '@kerplunkai/common-components';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { JobBenefit } from '@typings';
import { NAV_ROUTES, NEW_ROUTES } from '@constants';
import { selectSelectedOrgId } from '@store/selectors';
import {
  useDeleteJobBenefitMutation,
  useLazyJobBenefitsQuery,
} from '@store/services';

const getColumns = (
  navigate: NavigateFunction,
  onDelete: Dispatch<SetStateAction<string[] | null>>,
): ColumnDef<JobBenefit>[] => [
  {
    header: 'Name',
    accessorKey: 'name',
    cell: ({ getValue }) => <p>{getValue<string>()}</p>,
  },
  {
    accessorKey: 'created_at',
    header: 'Date',
    cell: ({ getValue }) => {
      const dt = spacetime(getValue<Date>());

      return (
        <div className="flex flex-col gap-1">
          <p className="text-xs text-slate-400">Date</p>
          <p className="text-sm text-slate-600">
            {dt.format('{month} {date}, {year}')}
          </p>
        </div>
      );
    },
  },
  {
    accessorKey: 'actions',
    header: () => <div className="flex justify-center">Action</div>,
    maxSize: 100,
    cell: ({ row }) => (
      <TanstackTableActionColumn
        data={row}
        actions={[
          {
            icon: 'CheckSquare',
            text: 'Edit',
            onClick: () =>
              navigate(`${NAV_ROUTES.BENEFITS}/${row.original.id}`),
          },
          {
            icon: 'Trash2',
            text: 'Delete',
            onClick: () => onDelete([row.original.id]),
          },
        ]}
      />
    ),
    enableSorting: false,
    enableColumnFilter: false,
  },
];

function BenefitsTable() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const orgId = useSelector(selectSelectedOrgId);

  const [getBenefits, { data: benefitsData, isFetching }] =
    useLazyJobBenefitsQuery();
  const [deleteBenefit, { isLoading: isDeleting }] =
    useDeleteJobBenefitMutation();

  const [itemsToDelete, setItemsToDelete] = useState<string[] | null>(null);

  const handleDeleteBenefits = useCallback(
    (benefitsToDelete: string[]) => {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise<void>(async (resolve, reject) => {
        const results = await Promise.all(
          benefitsToDelete?.map(benefitToDelete =>
            deleteBenefit({
              organizationId: orgId as string,
              jobBenefitId: benefitToDelete,
            }),
          ),
        );
        const validResults = results.filter(
          result => !(result instanceof Error),
        );

        if (validResults.length === benefitsToDelete.length) {
          enqueueSnackbar({
            message: 'Deleting benefit(s) was successful',
            variant: 'success',
          });
          resolve();
        } else {
          enqueueSnackbar({
            message:
              'Unable to delete some or all benefit(s). Please try again.',
            variant: 'error',
          });
          reject();
        }

        setItemsToDelete(null);
      });
    },
    [enqueueSnackbar, orgId, deleteBenefit],
  );

  const handleRefetch: TableRefetchFn = useCallback(
    (pagination, { searchTerm, sorting }) => {
      const hasSearch = searchTerm && searchTerm.length > 0;
      let filterQueryParms = '';

      if (searchTerm && searchTerm.length > 0)
        filterQueryParms = `q[name_${TableFilterPredicatesEnum.cont}]=${searchTerm}`;
      if (sorting) filterQueryParms += `${hasSearch ? '&' : ''}q[s]=${sorting}`;

      getBenefits({
        orgId: orgId as string,
        pagination,
        filters: filterQueryParms,
      });
    },
    [getBenefits, orgId],
  );

  return (
    <>
      <TanstackTable
        actionsHeaderItems={{
          addItem: {
            text: 'Add a Benefit',
            onClick: () => navigate(NEW_ROUTES.NEW_BENEFIT),
          },
        }}
        emptyText="No Benefit(s) Found"
        initialSorting={{
          initialField: 'name',
          initialOrder: 'ASC',
        }}
        isFetching={isFetching}
        pageInfo={benefitsData ? benefitsData.meta : DEFAULT_PAGE_INFO}
        searchPlaceholder="Search Benefits..."
        selection
        selectionModalProps={{
          title: 'Delete Benefits(s)',
          description: 'Do you really want to delete these benefits(s)?',
          confirmText: 'Delete',
          cancelText: 'Cancel',
        }}
        showSearch
        tableOptions={{
          data: benefitsData ? benefitsData.results : [],
          columns: getColumns(navigate, setItemsToDelete),
          getCoreRowModel: getCoreRowModel(),
          getRowId: row => row.id,
        }}
        onDelete={handleDeleteBenefits}
        onRefetch={handleRefetch}
      />
      <ModalDialog
        open={Boolean(itemsToDelete)}
        title="Delete Benefit(s)"
        description="Do you really want to delete these benefit(s)?"
        confirmText="Delete"
        cancelText="Cancel"
        isLoading={isDeleting}
        onConfirm={() => handleDeleteBenefits(itemsToDelete as string[])}
        onCancel={() => setItemsToDelete(null)}
      />
    </>
  );
}

export { BenefitsTable };
