import { JobQuestion } from '@typings';

interface TextQuestionTemplateProps {
  question: JobQuestion;
}

function TextQuestionTemplate({ question }: TextQuestionTemplateProps) {
  return (
    <div className="block">
      <div className="ml-2 pt-1 text-start text-base font-semibold text-white xs:text-center sm:text-xl">
        {question.content || ''}
      </div>
    </div>
  );
}

export { TextQuestionTemplate };
