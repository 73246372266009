import { Breadcrumb } from '@kerplunkai/common-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { NAV_ROUTES } from '@constants';
import { selectOrgJob, selectSelectedOrgId } from '@store/selectors';
import { useMemo } from 'react';

interface JobBreadcrumbProps {
  jobId: string;
  key?: number;
}

function JobBreadcrumb({ jobId, key, ...props }: JobBreadcrumbProps) {
  const orgId = useSelector(selectSelectedOrgId);
  const job = useSelector(selectOrgJob(orgId as string, jobId));

  const to = useMemo(() => {
    if (jobId === 'new') return `${NAV_ROUTES.JOBS}/${jobId}`;

    return `${NAV_ROUTES.JOBS}/${job?.id}`;
  }, [jobId, job]);

  const linkText = useMemo(() => {
    if (jobId === 'new') return 'Post a Job';

    return job?.title;
  }, [jobId, job]);

  if (jobId !== 'new' && !job) return null;

  return (
    <Breadcrumb.Link forwardedKey={key} {...props}>
      <Link to={to}>{linkText}</Link>
    </Breadcrumb.Link>
  );
}

export { JobBreadcrumb };
