import { ClientsTable } from '@modules/clients/clientsTable.component';

function Clients() {
  return (
    <div className="grid grid-cols-12 gap-x-6 gap-y-10">
      <div className="col-span-12">
        <div className="mb-3 flex flex-col gap-y-3 md:h-10 md:flex-row md:items-center">
          <div className="text-base font-medium group-[.mode--light]:text-white">
            All Clients
          </div>
        </div>
        <div className="box box--stacked flex flex-col">
          <ClientsTable />
        </div>
      </div>
    </div>
  );
}

export { Clients };
