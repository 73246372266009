import clsx from 'clsx';
import { MouseEvent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Lucide } from '@kerplunkai/common-components';
import { FormattedMenu, linkTo } from '@utilities';

interface SideMenuButtonProps {
  menu: FormattedMenu;
}

function SideMenuButton({ menu }: SideMenuButtonProps) {
  const navigate = useNavigate();

  const handleNavigate = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      linkTo(menu, navigate);
    },
    [navigate, menu],
  );

  return (
    <Button
      as="a"
      className={clsx([
        'side-menu__link justify-normal',
        { 'side-menu__link--active': menu.active },
        {
          'side-menu__link--active-dropdown': menu.activeDropdown,
        },
      ])}
      variant="text"
      onClick={handleNavigate}
    >
      <Lucide icon={menu.icon} className="side-menu__link__icon" />
      <div className="side-menu__link__title">{menu.title}</div>
      {menu.badge && <div className="side-menu__link__badge">{menu.badge}</div>}
      {menu.subMenu && (
        <Lucide icon="ChevronDown" className="side-menu__link__chevron" />
      )}
    </Button>
  );
}

export { SideMenuButton };
