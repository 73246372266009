import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { User } from '@typings';
import { authService, userService } from '@store/services';

type AuthState = {
  user: User | undefined;
  token: string | undefined;
  inviteToken: string | undefined;
};

const getAuthToken = (): string | null => localStorage.getItem('token');

const slice = createSlice({
  name: 'auth',
  initialState: {
    user: undefined,
    token: getAuthToken() ? getAuthToken() : undefined,
  } as AuthState,
  extraReducers: builder => {
    builder.addMatcher(
      authService.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.token = payload.token;
        state.user = payload.user;
      },
    );
    builder.addMatcher(
      authService.endpoints.signup.matchFulfilled,
      (state, { payload }) => {
        state.token = payload.token;
        state.user = payload.user;
      },
    );
    builder.addMatcher(
      authService.endpoints.signUpWithToken.matchFulfilled,
      (state, { payload }) => {
        state.token = payload.token;
        state.user = payload.user;
      },
    );
    builder.addMatcher(authService.endpoints.logout.matchFulfilled, state => {
      state.token = undefined;
      state.user = undefined;
    });
    builder.addMatcher(
      userService.endpoints.user.matchFulfilled,
      (state, { payload }) => {
        state.user = payload;
      },
    );
  },
  reducers: {
    resetUser: state => {
      localStorage.removeItem('token');

      state.user = undefined;
      state.token = undefined;
    },
    setAuthToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setInviteToken: (state, action: PayloadAction<string | undefined>) => {
      state.inviteToken = action.payload;
    },
  },
});

export const { resetUser, setAuthToken, setInviteToken } = slice.actions;

export default slice.reducer;
