/* eslint-disable import/no-extraneous-dependencies */
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindColors from 'tailwindcss/colors';
import tailwindConfig from 'tailwind-config';
import { flatten } from 'flat';
import { toRGB } from '@kerplunkai/common-components';
// TODO: get colors as input param
const twConfig = resolveConfig(tailwindConfig);
const twColors = twConfig.theme?.colors;

type DefaultColors = typeof tailwindColors;

/** Extended colors */
interface Colors extends DefaultColors {
  theme: {
    1: string;
    2: string;
  };
  primary: string;
  secondary: string;
  success: string;
  info: string;
  warning: string;
  pending: string;
  danger: string;
  light: string;
  dark: string;
  darkmode: {
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  };
}

/** Get a value from Tailwind colors by flatten index, if not available the value will be taken from the CSS variable with (--color-) prefix. */
const getColor = (colorKey: DotNestedKeys<Colors>, opacity: number = 1) => {
  const flattenColors = flatten<
    typeof twColors,
    {
      [key: string]: string;
    }
  >(twColors);

  if (flattenColors[colorKey].search('var') === -1) {
    return `rgb(${toRGB(flattenColors[colorKey])} / ${opacity})`;
  }
  const cssVariableName = `--color-${
    flattenColors[colorKey].split('--color-')[1].split(')')[0]
  }`;
  return `rgb(${getComputedStyle(document.body).getPropertyValue(
    cssVariableName,
  )} / ${opacity})`;
};

export { getColor };
