import { Disclosure } from '@kerplunkai/common-components';
import { useSelector } from 'react-redux';

import spacetime from 'spacetime';
import { Organization } from '@typings';
import { selectSelectedOrgId } from '@store/selectors';
import { selectSubscription } from '@store/selectors/stripe.selectors';

interface CurrentSubscriptionSummaryProps {
  org: Organization;
}

function CurrentSubscriptionSummary({ org }: CurrentSubscriptionSummaryProps) {
  const orgId = useSelector(selectSelectedOrgId);
  const subscription = useSelector(selectSubscription(orgId as string));

  return (
    <div className="box box--stacked flex flex-col">
      <Disclosure.Group variant="boxed" className="border-0">
        <Disclosure>
          <Disclosure.Button disabled>
            <div className="flex items-center border-b border-dashed border-slate-200/60 pb-5 text-base font-medium text-[#181D2C]">
              Current Subscription
            </div>
          </Disclosure.Button>
          <Disclosure.Panel className="grid grid-cols-12 gap-6">
            <div className="col-span-12 flex flex-col gap-6 rounded-lg border border-slate-200 p-5 md:col-span-4">
              <div className="flex justify-between">
                <div className="text-sm font-semibold text-slate-600">
                  Tier:
                </div>
                <div className="text-sm capitalize text-slate-600">
                  {org.subscription.tier}
                </div>
              </div>
              <div className="flex justify-between">
                <div className="text-sm font-semibold text-slate-600">
                  Allowance Per Month:
                </div>
                <div className="text-sm capitalize text-slate-600">
                  {org.subscription.interview_allowance_per_month}
                </div>
              </div>
              <div className="flex justify-between">
                <div className="text-sm font-semibold text-slate-600">
                  Allowance Remaining:
                </div>
                <div className="text-sm capitalize text-slate-600">
                  {org.subscription.interview_allowance_remaining}
                </div>
              </div>
              <div className="flex justify-between">
                <div className="text-sm font-semibold text-slate-600">
                  Allowance Exceeded:
                </div>
                <div className="text-sm capitalize text-slate-600">
                  {org.subscription.interview_allowance_exceeded ? 'Yes' : 'No'}
                </div>
              </div>
              {subscription && (
                <button
                  className="w-full rounded-md border border-slate-400 px-3.5 py-[9px] text-sm font-semibold text-slate-500"
                  style={{
                    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.02)',
                  }}
                  onClick={() => window.open(subscription.url, '_self')}
                >
                  Manage Subscription
                </button>
              )}
            </div>
            {subscription && (
              <div className="col-span-12 flex flex-col justify-between gap-6 rounded-lg border border-slate-200 p-5 md:col-span-4">
                <div className="flex flex-1 flex-col gap-6">
                  <div className="flex justify-between">
                    <div className="text-sm font-semibold text-slate-600">
                      Price:
                    </div>
                    <div className="text-sm capitalize text-slate-600">
                      {subscription.status === 'trialing' ? (
                        <>
                          Free
                          <span className="ml-2 line-through">
                            ${(subscription.price_per_month / 100).toFixed(2)}
                          </span>
                        </>
                      ) : (
                        `$${(subscription.price_per_month / 100).toFixed(2)}`
                      )}
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="text-sm font-semibold text-slate-600">
                      Billing Period:
                    </div>
                    <div className="text-sm capitalize text-slate-600">
                      {subscription.billing_period}
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="text-sm font-semibold text-slate-600">
                      {subscription.status === 'trialing'
                        ? 'Trial End Date:'
                        : 'Renewal Date:'}
                    </div>
                    <div className="text-sm capitalize text-slate-600">
                      {subscription.next_renewal_date
                        ? spacetime(subscription.next_renewal_date).format(
                            '{month} {date}, {year}',
                          )
                        : '-'}
                    </div>
                  </div>
                </div>
                {subscription && (
                  <button
                    className="w-full rounded-md border border-slate-400 px-3.5 py-[9px] text-sm font-semibold text-slate-500"
                    style={{
                      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.02)',
                    }}
                    onClick={() => window.open(subscription.url, '_self')}
                  >
                    Manage Billing
                  </button>
                )}
              </div>
            )}
          </Disclosure.Panel>
        </Disclosure>
      </Disclosure.Group>
    </div>
  );
}

export { CurrentSubscriptionSummary };
