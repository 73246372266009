import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import {
  FormActions,
  FormCard,
  FormInputControl,
} from '@kerplunkai/common-components';
import { JobBenefit } from '@typings';
import { NAV_ROUTES } from '@constants';
import { selectSelectedOrgId } from '@store/selectors';
import {
  useCreateJobBenefitMutation,
  useUpdateJobBenefitMutation,
} from '@store/services';

function BenefitForm({ benefitData }: { benefitData?: JobBenefit }) {
  const navigate = useNavigate();
  const { benefitId } = useParams<{ benefitId: string }>();
  const { enqueueSnackbar } = useSnackbar();

  const [
    createJobBenefit,
    { isLoading: isCreating, error: createError, isSuccess: isCreateSuccess },
  ] = useCreateJobBenefitMutation();
  const [
    updateJobBenefit,
    { isLoading: isUpdating, error: updateError, isSuccess: isUpdateSuccess },
  ] = useUpdateJobBenefitMutation();

  const orgId = useSelector(selectSelectedOrgId);

  const [benefit, setBenefit] = useState(benefitData || { name: '' });

  useEffect(() => {
    if (isCreateSuccess || isUpdateSuccess) navigate(NAV_ROUTES.BENEFITS);
  }, [navigate, isCreateSuccess, isUpdateSuccess]);

  useEffect(() => {
    if (createError || updateError) {
      enqueueSnackbar({
        message: (
          ((createError || updateError) as FetchBaseQueryError)?.data as {
            messages: string[];
          }
        )?.messages[0],
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, createError, updateError]);

  const isLoading = useMemo(
    () => isCreating || isUpdating,
    [isCreating, isUpdating],
  );

  const isNew = useMemo(() => benefitId === 'new', [benefitId]);

  const handleCreateJobBenefit = useCallback(() => {
    createJobBenefit({
      ...benefit,
      organizationId: orgId as string,
    });
  }, [benefit, orgId, createJobBenefit]);

  const handleUpdateJobBenefit = useCallback(() => {
    updateJobBenefit({
      ...benefit,
      organizationId: orgId as string,
    });
  }, [benefit, orgId, updateJobBenefit]);

  const handleSubmitJob = useCallback(() => {
    if (!isNew) {
      handleUpdateJobBenefit();
      return;
    }

    handleCreateJobBenefit();
  }, [isNew, handleCreateJobBenefit, handleUpdateJobBenefit]);

  return (
    <div className="relative col-span-12 flex flex-col gap-y-7 lg:col-span-9 xl:col-span-8">
      <FormCard hideAccordion title="Benefit Information">
        <div className="mt-5">
          <FormInputControl
            className="mt-5"
            description="Enter a name for this benefit."
            helpText={`Maximum characters ${benefit.name.length}/70`}
            inputProps={{
              disabled: isLoading,
              onChange: ({ target: { name, value } }) => {
                if (value.length <= 70) {
                  setBenefit(curBenefit => ({
                    ...curBenefit,
                    [name]: value,
                  }));
                }
              },
            }}
            isRequired
            label="Benefit Name"
            name="name"
            value={benefit.name}
          />
        </div>
      </FormCard>
      <FormActions
        cancel={{
          disabled: isLoading,
          icon: 'X',
          text: 'Cancel',
          onClick: () => navigate(NAV_ROUTES.BENEFITS),
        }}
        submit={{
          disabled: isLoading,
          icon: 'Send',
          text: isLoading ? 'Saving' : 'Save',
          onClick: handleSubmitJob,
        }}
      />
    </div>
  );
}

export { BenefitForm };
