import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { CONFERENCE } from '@constants';
import {
  MediaPermissions,
  ParticipantWindow,
} from '@modules/conference/components';
import { MeetingGuests } from '@modules/meetings/meetingPreview/meetingGuests.component';
import { MeetingQuestions } from '@modules/meetings/meetingPreview/meetingQuestions.component';
import { getInitialInterview } from '@utilities/interview';
import { selectSelectedOrgId } from '@store/selectors';
import { useOrgInterviewQuery } from '@store/services';

function InterviewSetup() {
  const navigate = useNavigate();
  const { interviewId } = useParams<{ interviewId: string }>();

  const orgId = useSelector(selectSelectedOrgId);

  const { data: interview } = useOrgInterviewQuery({
    interviewId: interviewId as string,
    organizationId: orgId as string,
  });

  const [showPreviewFrame, setShowPreviewFrame] = useState(false);

  const handleShowPreview = () => {
    setShowPreviewFrame(true);
  };

  const handleStartInterview = useCallback(() => {
    if (!interview) return;

    const initialQuestionIndex = interview.interview_questions.findIndex(
      iq => iq.id === interview.current_interview_question_id,
    );

    navigate(`/${CONFERENCE}/${interview?.id}/questions`, {
      state: {
        initialQuestionIndex:
          initialQuestionIndex < 0 ? 0 : initialQuestionIndex,
      },
    });
  }, [navigate, interview]);

  if (!interview) return null;

  return (
    <div className="grid h-full grid-cols-12 gap-x-6 gap-y-5 sm:gap-y-10">
      <div className="col-span-12 flex flex-col gap-y-7 md:col-span-8">
        <MeetingGuests interview={getInitialInterview(interview)} />
        <MeetingQuestions interview={getInitialInterview(interview)} />
      </div>

      <div className="col-span-12 md:col-span-4 ">
        <ParticipantWindow show={showPreviewFrame} />
        <MediaPermissions
          onStartInterview={handleStartInterview}
          onShowPreview={handleShowPreview}
          isLoading={false}
          startButtonText="Join Co-Pilot Interview"
        />
      </div>
    </div>
  );
}

export { InterviewSetup };
