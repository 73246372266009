import { Menu } from '@typings';
import { NAV_ROUTES, NEW_ROUTES } from '@constants';

export const MENU_ACTIONS: Array<Menu | string> = [
  {
    icon: 'PenLine',
    pathname: NEW_ROUTES.NEW_JOB,
    title: 'Post a Job',
  },
  {
    icon: 'Sparkle',
    pathname: NEW_ROUTES.NEW_MEETING,
    title: 'Co-Pilot Meeting',
  },
];

export const MENU: Array<Menu | string> = [
  'FEATURES',
  {
    icon: 'Box',
    pathname: '/',
    title: 'Dashboard',
  },
  {
    icon: 'GanttChartSquare',
    pathname: NAV_ROUTES.JOBS,
    title: 'All Jobs',
  },
  {
    icon: 'Clock',
    pathname: NAV_ROUTES.RECENT_INTERVIEWS,
    title: 'Recent Interviews',
  },
  {
    icon: 'CalendarDays',
    pathname: NAV_ROUTES.MEETINGS,
    title: 'Meetings',
  },
  // {
  //   icon: 'Search',
  //   pathname: '/dashboard-overview-5',
  //   title: 'Candidate Search',
  // },
  // 'USER MANAGEMENT',
  // {
  //   icon: 'GanttChartSquare',
  //   pathname: '/users',
  //   title: 'Users',
  // },
  // {
  //   icon: 'CakeSlice',
  //   pathname: '/departments',
  //   title: 'Departments',
  // },
  // {
  //   icon: 'PackagePlus',
  //   pathname: '/add-user',
  //   title: 'Add User',
  // },
  'JOB DETAILS',
  {
    icon: 'BookMarked',
    pathname: NAV_ROUTES.CATEGORIES,
    title: 'Categories',
  },
  {
    icon: 'Album',
    pathname: NAV_ROUTES.SUBCATEGORIES,
    title: 'Subcategories',
  },
  {
    icon: 'Gift',
    pathname: NAV_ROUTES.BENEFITS,
    title: 'Benefits',
  },
  // {
  //   icon: 'PercentSquare',
  //   pathname: '/billing',
  //   title: 'Billing',
  // },
  // {
  //   icon: 'DatabaseZap',
  //   pathname: '/invoice',
  //   title: 'Invoice',
  // },
  'ORGANIZATION',
  {
    icon: 'Users',
    pathname: NAV_ROUTES.ORGANIZATION_USERS,
    title: 'Users',
  },
  {
    icon: 'Blocks',
    pathname: NAV_ROUTES.INTEGRATIONS,
    title: 'Integrations',
  },
];

export const CLIENT_MENU_ITEMS: Array<Menu | string> = [
  {
    icon: 'Briefcase',
    pathname: NAV_ROUTES.CLIENTS,
    title: 'Clients',
  },
];
