import { categoryService } from '@store/services';
import { createSelector } from '@reduxjs/toolkit';

export const selectJobCategories = (orgId: string) =>
  createSelector(
    categoryService.endpoints.jobCategories.select({
      orgId,
      pagination: {
        pageSize: 1000,
        pageIndex: 1,
      },
      filters: '',
    }),
    queryState => queryState?.data?.results,
  );

export const selectJobSubcategories = (orgId: string) =>
  createSelector(
    categoryService.endpoints.jobSubcategories.select({
      orgId,
      pagination: {
        pageSize: 1000,
        pageIndex: 1,
      },
      filters: '',
    }),
    queryState => queryState?.data?.results,
  );

export const selectOrgCategory = (orgId: string, jobCategoryId: string) =>
  createSelector(
    categoryService.endpoints.jobCategory.select({
      organizationId: orgId,
      jobCategoryId,
    }),
    queryState => queryState.data,
  );

export const selectOrgSubCategory = (orgId: string, jobSubcategoryId: string) =>
  createSelector(
    categoryService.endpoints.jobSubcategory.select({
      organizationId: orgId,
      jobSubcategoryId,
    }),
    queryState => queryState.data,
  );
