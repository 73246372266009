/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Alert,
  Button,
  DismissButton,
  FormCheckControl,
  FormControl,
  FormInput,
  FormInputControl,
  FormSelect,
  FormSelectControl,
  Lucide,
  TomSelect,
} from '@kerplunkai/common-components';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

import { JobRequest } from '@typings';
import { US_STATES } from '@constants';
import {
  selectLocationTypes,
  selectOrganizations,
  selectSelectedOrgId,
} from '@store/selectors';
import { useCategoryOptions, useSubcategoryOptions } from '@hooks';

interface JobInfoFormProps {
  isLoading: boolean;
}

function JobInfoForm({ isLoading }: JobInfoFormProps) {
  const { errors, values, handleChange } = useFormikContext<JobRequest>();

  const orgId = useSelector(selectSelectedOrgId);
  const locationTypes = useSelector(selectLocationTypes(orgId as string));
  const organizations = useSelector(selectOrganizations());

  const jobCategoryOptions = useCategoryOptions(orgId as string);
  const jobSubcategoryOptions = useSubcategoryOptions(orgId as string);

  return (
    <div className="mt-5">
      <Alert
        variant="outline-warning"
        className="mb-2 flex items-center border-warning/30 bg-warning/5 px-4"
      >
        {({ dismiss }) => (
          <>
            <div className="flex flex-col">
              <div className="flex gap-x-2.5">
                <h5 className="text-sm font-medium text-slate-600">
                  Not sure where to start
                </h5>
                <Lucide
                  icon="Lightbulb"
                  className="mr-3 size-4 stroke-[1.3] 2xl:mr-2"
                />
              </div>
            </div>
            <div className="mr-5 leading-relaxed">
              <Button
                as="a"
                className="ml-1 font-medium decoration-warning/50"
                variant="text"
              >
                Learn More
              </Button>
              <DismissButton
                type="button"
                className="btn-close inset-y-0"
                onClick={dismiss}
                aria-label="Close"
              >
                <Lucide icon="X" className="size-4" />
              </DismissButton>
            </div>
          </>
        )}
      </Alert>
      <FormInputControl
        className="mt-5"
        description="Enter a job title for this position."
        errorText={errors.title}
        helpText={`Maximum characters ${values.title.length}/70`}
        inputProps={{ disabled: isLoading, onChange: handleChange }}
        isRequired
        label="Job Title"
        name="title"
        value={values.title}
      />
      <FormSelectControl
        className="mt-5"
        description="Which organization is hiring for this position"
        emptyText="Job Organization"
        isRequired
        label="Job Organization"
        name="organization_id"
        options={(organizations || [])?.map(({ id, name }) => ({
          name,
          value: id,
        }))}
        selectProps={{
          disabled: isLoading || (organizations || []).length === 1,
          onChange: handleChange,
        }}
        showEmptyOption
        value={values.organization_id}
      />
      <FormSelectControl
        className="mt-5"
        description="Select the primary category that best represents your values. This helps candidates find you position more easily."
        emptyText="Category"
        isRequired
        label="Category"
        name="job_category_ids"
        options={jobCategoryOptions}
        selectProps={{
          disabled: isLoading,
          onChange: handleChange,
        }}
        showEmptyOption
        value={values?.job_category_ids[0] || ''}
      />
      <FormControl
        className="mt-5"
        description="Choose a more specific subcategory that closely matches you opening. It provides further detaisl about your job posting."
        isRequired
        label="Subcategory"
        name="job_subcategory_ids"
      >
        <TomSelect
          className="w-full"
          disabled={isLoading}
          options={{
            placeholder: 'Subcategory',
          }}
          multiple
          value={values.job_subcategory_ids}
          onChange={handleChange}
        >
          {jobSubcategoryOptions.map(({ value, name }) => (
            <option key={value} value={value}>
              {name}
            </option>
          ))}
        </TomSelect>
      </FormControl>
      <FormCheckControl
        className="mt-5"
        description="What option best describes this jobs location?"
        disabled={isLoading}
        innerClassName="flex flex-col md:flex-row bg-rose-500"
        isRequired
        label="Location Type"
        name="job_location"
        items={Object.keys(locationTypes || {}).map(typeValue => ({
          label: (locationTypes || {})[typeValue],
          value: typeValue,
        }))}
        type="radio"
        value={values.job_location}
        onChange={handleChange}
      />
      <FormControl
        className="mt-5"
        childrenClassName="grid grid-cols-4 gap-3"
        description="Enter the name of the city and state the position is located."
        isRequired
        label="Job Location"
      >
        <FormSelect
          disabled={isLoading}
          name="state"
          value={values.state || ''}
          onChange={handleChange}
        >
          <option value="">Select State</option>
          {US_STATES.map(({ name, abbreviation }) => (
            <option key={abbreviation} value={abbreviation}>
              {name}
            </option>
          ))}
        </FormSelect>
        <FormInput
          className="col-span-3"
          disabled={isLoading}
          name="city"
          placeholder="City"
          value={values.city || ''}
          onChange={handleChange}
        />
      </FormControl>
    </div>
  );
}

export { JobInfoForm };
