import * as Yup from 'yup';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  FormInput,
  FormLabel,
  FormModal,
  Lucide,
} from '@kerplunkai/common-components';
import { FormikProps } from 'formik';
// import { useSelector } from 'react-redux';

import { Integration, RequestIntegrationRequest } from '@typings';
// import { selectSelectedOrgId } from '@store/selectors';
// import { useCreateIntegrationMutation } from '@store/services';

const INITIAL_VALUES: RequestIntegrationRequest = {
  name: '',
  comments: '',
};

interface CreateKeyIntegrationModalProps {
  integration: Integration | null;
  open: boolean;
  toggleOpen: Dispatch<SetStateAction<boolean>>;
}

function RequestIntegrationModal({
  integration,
  open,
  toggleOpen,
}: CreateKeyIntegrationModalProps) {
  // const orgId = useSelector(selectSelectedOrgId);

  // const [createIntegration, { isLoading }] = useCreateIntegrationMutation();

  const [integrationType, setIntegrationType] = useState(integration);

  useEffect(() => {
    if (integration) setIntegrationType(integration);
    else setTimeout(() => setIntegrationType(null), 300);
  }, [integration]);

  const handleCreateIntegrations = useCallback(() => {
    // (values: RequestIntegrationRequest) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async resolve => {
      // const result = await createIntegration({
      //   organizationId: orgId as string,
      //   ...values,
      // });

      // if (result instanceof Error) {
      //   reject();
      // } else {
      resolve();
      // }
    });
  }, []);

  return (
    <FormModal<RequestIntegrationRequest>
      formikConfig={{
        initialValues: INITIAL_VALUES,
        validateOnChange: false,
        validationSchema: Yup.object({
          name: Yup.string().required('Integration Name is required'),
          api_key: Yup.string().required('Api Key is required'),
        }),
        onSubmit: () => {},
      }}
      isLoading={false}
      open={open}
      title={`${integrationType?.name}`}
      snackbar={{
        success: {
          children: (
            <div className="flex flex-col gap-1">
              <p className="text-sm text-[#364154]">
                {integrationType?.name}&nbps;Integration&nbsp;Created
              </p>
              <p className="text-sm text-[#677489]">
                The integration has been successfully created.
              </p>
            </div>
          ),
        },
        error: { message: 'Unable to create integration. Please try again.' },
      }}
      cancelButton={{
        className: 'border-slate-200 text-slate-500 py-2 px-3',
        children: (
          <div className="flex gap-1.5 font-normal">
            <Lucide icon="X" />
            Cancel
          </div>
        ),
      }}
      successButton={{
        className: 'py-2 px-3',
        children: (
          <div className="flex gap-1.5 font-normal">
            <Lucide icon="Plus" />
            Submit Request
          </div>
        ),
      }}
      onClose={() => toggleOpen(false)}
      onSubmit={handleCreateIntegrations}
    >
      {({
        values,
        errors,
        handleChange,
      }: FormikProps<RequestIntegrationRequest>) => (
        <>
          <div className="flex w-[30rem] max-w-full flex-col gap-3.5">
            <FormLabel htmlFor="name">
              <div className="flex">
                Integration Name
                <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-400 dark:bg-darkmode-300 dark:text-slate-400">
                  Required
                </div>
              </div>
            </FormLabel>
            <div>
              <FormInput
                id="name"
                error={Boolean(errors.name)}
                name="name"
                placeholder="Integration Name"
                value={values.name}
                onChange={handleChange}
              />
              {Boolean(errors.name) && (
                <div className="mt-2 text-left text-xs text-danger">
                  {errors.name}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-3.5">
            <FormLabel htmlFor="api_key">
              <div className="mt-1.5 text-left text-xs text-slate-500">
                Briefly tell us about this integration and why you are
                requesting it.
              </div>
            </FormLabel>
            <div>
              <FormInput
                id="comments"
                error={Boolean(errors.comments)}
                name="comments"
                placeholder="e.g. This integration would save my company so much time and money."
                value={values.comments}
                onChange={handleChange}
              />
              {Boolean(errors.comments) && (
                <div className="mt-2 text-left text-xs text-danger">
                  {errors.comments}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </FormModal>
  );
}

export { RequestIntegrationModal };
