import clsx from 'clsx';
import spacetime from 'spacetime';
import {
  Button,
  FormInput,
  Lucide,
  TanstackTable,
  Tippy,
} from '@kerplunkai/common-components';
import { ColumnDef, getCoreRowModel } from '@tanstack/react-table';
import { useCopyToClipboard } from 'usehooks-ts';
import { useMemo } from 'react';

import { Job, JobStatus } from '@typings';
import { darkGreen, lightGreen, medGreen } from '@theme';
import { getJobStatusColor } from '@utilities/jobs';

interface InterviewJobListingProps {
  isPreview?: boolean;
  jobListing: Job;
}

function InterviewJobListing({
  isPreview = false,
  jobListing,
}: InterviewJobListingProps) {
  const [copiedText, copy] = useCopyToClipboard();

  const columns: ColumnDef<Job>[] = useMemo(() => {
    const cols: ColumnDef<Job>[] = [
      {
        accessorKey: 'status',
        cell: ({ getValue }) => (
          <div
            className={clsx([
              'flex items-center',
              `text-${getJobStatusColor(getValue<string>())}`,
            ])}
          >
            <Lucide icon="Database" className="size-3.5 stroke-[1.7]" />
            <div className="ml-1.5 whitespace-nowrap">
              {JobStatus[getValue<keyof typeof JobStatus>()]}
            </div>
          </div>
        ),
      },
      {
        accessorKey: 'title',
        cell: ({ getValue, row }) => (
          <div>
            <p className="whitespace-normal font-medium text-slate-600">
              {getValue<string>()}
            </p>
            <p className="text-xs text-slate-400">
              {row.original.hiring_organization}
            </p>
          </div>
        ),
      },
    ];

    if (!isPreview)
      cols.push({
        accessorKey: 'slug',
        minSize: 224,
        cell: ({ getValue }) => {
          const slug = getValue<string>();
          const isCopied =
            `${import.meta.env.VITE_CANDIDATE_URL}/job/${slug}` === copiedText;

          return (
            <div className="flex">
              <FormInput
                className={clsx(
                  'mr-2 flex-1 border-none !bg-white bg-none text-slate-500 shadow-none',
                  isCopied && ` disabled:text-[${darkGreen}]`,
                )}
                disabled
                placeholder="everyone@gmail.com"
                type="text"
                value={`/${slug}`}
              />
              <Tippy
                as={Button}
                className={clsx(
                  'mr-2 border-slate-200 bg-slate-50 p-1.5',
                  isCopied &&
                    `disabled:border-[${medGreen}] disabled:bg-[${lightGreen}]/10 disabled:text-[${darkGreen}]`,
                )}
                disabled={isCopied}
                content="Copy job link"
                variant="outline-secondary"
                onClick={() =>
                  copy(`${import.meta.env.VITE_CANDIDATE_URL}/job/${slug}`)
                }
              >
                <Lucide
                  icon={isCopied ? 'Check' : 'Copy'}
                  className={clsx(
                    'size-6 stroke-[1.5] ',
                    isCopied ? `stroke-[${darkGreen}]` : 'stroke-slate-500',
                  )}
                />
              </Tippy>
            </div>
          );
        },
      });

    return [
      ...cols,
      {
        accessorKey: 'application_count',
        minSize: 225,
        cell: ({ row }) => {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const { application_count } = row.original;

          return (
            <div>
              <div className="whitespace-nowrap">
                <div className="flex items-center justify-center">
                  <div className="text-slate-400">{application_count}</div>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        accessorKey: 'published_at',
        cell: ({ getValue }) => (
          <div className="flex flex-col gap-1">
            <p className="text-xs text-slate-400">Date Published</p>
            <p className="text-sm text-slate-600">
              {getValue<string>()
                ? spacetime(getValue<string>())?.format('{date} {month} {year}')
                : '-'}
            </p>
          </div>
        ),
      },
      {
        accessorKey: 'created_by',
        cell: ({ row }) => (
          <div className="flex flex-col gap-1">
            <p className="text-xs text-slate-400">Created by</p>
            <p className="text-sm text-slate-600">
              {row.original.created_by.display_name}
            </p>
          </div>
        ),
      },
    ];
  }, [copiedText, copy, isPreview]);

  return (
    <div className="mt-8 rounded-lg border border-slate-200">
      <TanstackTable
        isFetching={false}
        showHeader={false}
        showPagination={false}
        tableOptions={{
          data: [jobListing],
          columns,
          getCoreRowModel: getCoreRowModel(),
          getRowId: row => row.id,
          enableSorting: false,
        }}
        pageInfo={{
          current_page: 1,
          next_page: 1,
          prev_page: 1,
          total_count: 1,
          total_pages: 1,
        }}
      />
    </div>
  );
}

export { InterviewJobListing };
