import { BASE_URL } from '@constants';
import {
  PagedFilterSortRequest,
  PagedResponse,
  generateQueryParams,
} from '@kerplunkai/common-components';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  CancelOrgInviteRequest,
  OrgUser,
  OrgUserRequest,
  RemoveOrgUserRequest,
  User,
} from '@typings';
import { RootState } from '@store/store';

export const orgUserService = createApi({
  reducerPath: 'orgUserService',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/organizations`,
    prepareHeaders: (headers, { getState }) => {
      const { token } = (getState() as RootState).auth;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ['User'],
  endpoints: builder => ({
    orgUsers: builder.query<PagedResponse<OrgUser>, PagedFilterSortRequest>({
      query: ({ orgId, pagination, filters = '' }) => ({
        url: `${orgId}/users${generateQueryParams({ pagination, filters })}`,
        method: 'GET',
      }),
      providesTags: result =>
        result
          ? [
              ...result.results.map(({ id }) => ({
                type: 'User' as const,
                id,
              })),
              { type: 'User', id: 'LIST' },
            ]
          : [{ type: 'User', id: 'LIST' }],
    }),
    createOrgUser: builder.mutation<OrgUser, OrgUserRequest>({
      query: ({ orgId, email }) => ({
        url: `${orgId}/users`,
        method: 'POST',
        body: {
          email,
        },
      }),
      invalidatesTags: [{ type: 'User', id: 'LIST' }],
    }),
    removeOrgUser: builder.mutation<User, RemoveOrgUserRequest>({
      query: ({ orgId, userId }) => ({
        url: `${orgId}/users/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'User', id: 'LIST' }],
    }),
    cancelOrgInvite: builder.mutation<User, CancelOrgInviteRequest>({
      query: ({ orgId, userInviteId }) => ({
        url: `${orgId}/users/${userInviteId}/remove`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'User', id: 'LIST' }],
    }),
  }),
});

export const {
  useLazyOrgUsersQuery,
  useCreateOrgUserMutation,
  useRemoveOrgUserMutation,
  useCancelOrgInviteMutation,
} = orgUserService;
