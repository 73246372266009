import { Loading } from '@kerplunkai/common-components';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ClientForm } from '@modules/clients/clientForm.component';
import { selectSelectedOrgId } from '@store/selectors';
import {
  useOrgClientQuery,
  useOrganizationsDetailsQuery,
} from '@store/services';

import { Tips } from '@components';

function Client() {
  const { clientId } = useParams<{ clientId: string }>();

  const organizationId = useSelector(selectSelectedOrgId);

  const { data, isLoading } = useOrgClientQuery(
    {
      orgId: organizationId as string,
      orgClientId: clientId as string,
    },
    { skip: clientId === 'new' },
  );
  useOrganizationsDetailsQuery();

  if (isLoading) return <Loading />;

  return (
    <div className="grid grid-cols-12 gap-x-6 gap-y-10">
      <div className="col-span-12">
        <div className="mt-4 flex flex-col gap-y-3 md:mt-0 md:h-10 md:flex-row md:items-center">
          <div className="text-base font-medium group-[.mode--light]:text-white">
            {`${clientId === 'new' ? 'Add' : 'Edit'} Client`}
          </div>
        </div>
        <div className="mt-3.5 grid grid-cols-12 gap-x-6 gap-y-7 lg:gap-y-10 xl:grid-cols-10">
          <ClientForm clientData={data} />
          <div className="relative order-first col-span-12 lg:order-last lg:col-span-3 xl:col-span-2">
            <Tips
              text={
                "Tips for Clients: A compelling job post attracts the best candidates. Ensure that your job title is specific, the description is clear and provides an insight into your company culture. Highlight the key requirements but keep the post concise to maintain the reader's interest."
              }
              subtitle="Job Clients"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export { Client };
