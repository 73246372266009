import TinySlider from 'tiny-slider-react';

import { Button, Lucide } from '@kerplunkai/common-components';

function NewFeatureCard() {
  return (
    <div className="box box--stacked col-span-12 p-1 md:col-span-6 2xl:col-span-3">
      <div className="-mx-1 h-[244px] overflow-hidden [&_.tns-nav]:bottom-auto [&_.tns-nav]:ml-5 [&_.tns-nav]:mt-5 [&_.tns-nav]:w-auto [&_.tns-nav_button.tns-nav-active]:w-5 [&_.tns-nav_button.tns-nav-active]:bg-white/70 [&_.tns-nav_button]:mx-0.5 [&_.tns-nav_button]:size-2 [&_.tns-nav_button]:bg-white/40">
        <TinySlider
          settings={{
            slideBy: 'page',
            mouseDrag: true,
            autoplay: true,
            controls: false,
            nav: true,
            speed: 500,
            mode: 'gallery',
          }}
        >
          <div className="px-1">
            <div className="relative flex size-full flex-col overflow-hidden rounded-lg bg-gradient-to-t from-sky-800 to-rose-900 p-5">
              <Lucide
                icon="Medal"
                className="absolute right-0 top-0 -mr-5 -mt-5 size-36 rotate-[-10deg] fill-white/[0.03] stroke-[0.3] text-white/20"
              />
              <div className="mb-9 mt-12">
                <div className="text-2xl font-medium leading-snug text-white">
                  Welcome to
                  <br />
                  Kerplunk!
                </div>
                <div className="mt-1.5 text-lg text-white/70">😎</div>
              </div>
              <Button
                as="a"
                href="https://app.kerplunk.com/jobs/new"
                className="flex items-center justify-start font-medium text-white"
                variant="text"
              >
                Get Started
                <Lucide icon="MoveRight" className="ml-1.5 size-4" />
              </Button>
            </div>
          </div>
          <div className="px-1">
            <div className="relative flex size-full flex-col overflow-hidden rounded-lg bg-gradient-to-b from-theme-2/90 to-theme-1/90 p-5">
              <Lucide
                icon="Database"
                className="absolute right-0 top-0 -mr-5 -mt-5 size-36 rotate-[-10deg] fill-white/[0.03] stroke-[0.3] text-white/20"
              />
              <div className="mb-9 mt-12">
                <div className="text-2xl font-medium leading-snug text-white">
                  Stay ahead
                  <br />
                  with upgrades
                </div>
                <div className="mt-1.5 text-lg text-white/70">
                  New features and updates!
                </div>
              </div>
              <Button
                as="a"
                className="flex items-center justify-start font-medium text-white"
                variant="text"
              >
                Discover now
                <Lucide icon="ArrowRight" className="ml-1.5 size-4" />
              </Button>
            </div>
          </div>
          <div className="px-1">
            <div className="relative flex size-full flex-col overflow-hidden rounded-lg bg-gradient-to-b from-theme-2/90 to-theme-1/90 p-5">
              <Lucide
                icon="Gauge"
                className="absolute right-0 top-0 -mr-5 -mt-5 size-36 rotate-[-10deg] fill-white/[0.03] stroke-[0.3] text-white/20"
              />
              <div className="mb-9 mt-12">
                <div className="text-2xl font-medium leading-snug text-white">
                  Supercharge
                  <br />
                  your workflow
                </div>
                <div className="mt-1.5 text-lg text-white/70">
                  Boost performance!
                </div>
              </div>
              <Button
                as="a"
                className="flex items-center justify-start font-medium text-white"
                variant="text"
              >
                Get started
                <Lucide icon="ArrowRight" className="ml-1.5 size-4" />
              </Button>
            </div>
          </div>
        </TinySlider>
      </div>
    </div>
  );
}

export { NewFeatureCard };
