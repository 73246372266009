import { Lucide, icons } from '@kerplunkai/common-components';

interface TipsProps {
  text: string;
  icon?: keyof typeof icons;
  title?: string;
  subtitle?: string;
}

function Tips({
  text,
  icon = 'Lightbulb',
  title = 'Tips',
  subtitle,
}: TipsProps) {
  return (
    <div className="sticky">
      <div className="relative rounded-[0.6rem] border border-warning/[0.15] bg-white p-5 dark:border-0 dark:bg-darkmode-600">
        <Lucide
          icon={icon}
          className="absolute right-0 top-0 mr-3 mt-5 size-12 text-warning/80"
        />
        <h2 className="text-lg font-medium">{title}</h2>
        {subtitle && <div className="mt-4 font-medium">{subtitle}</div>}
        <div className="mt-2 text-xs leading-relaxed text-slate-600/90 dark:text-slate-500">
          <div>{text}</div>
        </div>
      </div>
    </div>
  );
}

export { Tips };
