import { FormCard } from '@kerplunkai/common-components';
import { FormikProps } from 'formik';
import { RefObject } from 'react';

import { CreateWelcomeCard } from '@modules/organization/organizationCreateStep/createWelcomeCard.component';
import { OrganizationDetailsForm } from '@modules/organization/organizationCreateStep/organizationDetailsForm.component';
import { OrganizationRequest } from '@typings';

interface OrganizationCreateStepProps {
  formRef: RefObject<FormikProps<OrganizationRequest>>;
  isLoading: boolean;
}

function OrganizationCreateStep({
  formRef,
  isLoading,
}: OrganizationCreateStepProps) {
  return (
    <div className="mt-3.5 grid grid-cols-12 gap-x-6 gap-y-7 lg:gap-y-10">
      <div className="col-span-12 flex flex-col gap-y-7 md:col-span-3">
        <CreateWelcomeCard />
      </div>
      <div className="col-span-12 flex flex-col gap-y-7 md:col-span-9">
        <div className="box box--stacked">
          <FormCard hideAccordion title="Company Information">
            <OrganizationDetailsForm formRef={formRef} isLoading={isLoading} />
          </FormCard>
        </div>
      </div>
    </div>
  );
}

export { OrganizationCreateStep };
