import { convert } from 'html-to-text';

import { JobListingDetail } from '@typings';
import { formatLocation } from '@utilities';
import {
  getJobExperienceLevel,
  getJobPay,
  getJobSchedule,
  getJobType,
} from '@utilities/jobs';

interface JobPostingDetailsPanelProps {
  orgLogoUrl?: string;
  orgName: string;
  jobListing: JobListingDetail;
}

function JobPostingDetailsPanel({
  orgLogoUrl,
  orgName,
  jobListing,
}: JobPostingDetailsPanelProps) {
  const getDataDisplayElement = (title: string, detail: string | string[]) => {
    return (
      <div className="flex">
        <div className="mb-2 mr-2 text-xs font-light text-slate-400">
          {title}
        </div>
        <div>
          {Array.isArray(detail) ? (
            detail.map(text => (
              <div
                key={text}
                className="mb-2 text-xs font-medium text-slate-500"
              >
                {text}
              </div>
            ))
          ) : (
            <div className="mb-2 text-xs font-medium text-slate-500">
              {detail}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="box mb-5 p-5">
      <div className="flex">
        {orgLogoUrl && (
          <img
            className="size-11 self-center rounded-full"
            src={orgLogoUrl}
            alt={orgName}
          />
        )}
        <div className="mx-2 self-center">
          <div className="text-base font-medium">{orgName}</div>
          <div className="text-xs font-normal">
            {formatLocation({
              city: jobListing.city,
              country: jobListing.country,
              state: jobListing.state,
            })}
          </div>
        </div>
      </div>
      <div className="mt-4 rounded-t-lg border-2 border-b-0 border-dashed p-4">
        <div className="mb-2 font-medium text-sky-800">Job Information</div>
        {getDataDisplayElement('Job Title', jobListing.title)}
        {getDataDisplayElement('Job Organization', jobListing.organization)}
        {getDataDisplayElement(
          'Category',
          jobListing.categories.map(j => j.name),
        )}
        {getDataDisplayElement(
          'Subcategory',
          jobListing.subcategories.map(s => s.name),
        )}
      </div>
      <div className="border-2 border-b-0 border-dashed p-4">
        <div className="mb-2 font-medium text-sky-800">Job Description</div>
        <div className="mb-2 mr-2 text-xs font-light text-slate-400">
          {jobListing.title}
        </div>
        <div className="mb-2 text-xs font-medium text-slate-500">
          {convert(jobListing.description)}
        </div>
      </div>
      <div className="border-2 border-b-0 border-dashed p-4">
        <div className="mb-2 font-medium text-sky-800">Additional Details</div>
        {getDataDisplayElement('Job Type', getJobType(jobListing.job_type))}
        {getDataDisplayElement(
          'Experience Level',
          jobListing.experience_levels.map(e => getJobExperienceLevel(e)),
        )}
        {getDataDisplayElement(
          'Schedule',
          jobListing.schedules.map(j => getJobSchedule(j)),
        )}
      </div>
      <div className="rounded-b-lg border-2 border-dashed p-4">
        <div className="mb-2 font-medium text-sky-800">Compensation</div>
        {getDataDisplayElement(
          'Display Compensation',
          jobListing.display_compensation ? 'Yes' : 'No',
        )}
        {getDataDisplayElement(
          'Pay',
          getJobPay(
            jobListing.pay_type,
            jobListing.salary || undefined,
            jobListing.min_pay,
            jobListing.max_pay,
          ),
        )}
        {getDataDisplayElement(
          'Benefits',
          jobListing.benefits.map(b => b.name),
        )}
      </div>
    </div>
  );
}

export { JobPostingDetailsPanel };
