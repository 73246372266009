import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { FormActions, FormCard } from '@kerplunkai/common-components';
import { FormikProps } from 'formik';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { NAV_ROUTES } from '@constants';
import { Organization, OrganizationRequest } from '@typings';
import { OrganizationDetailsForm } from '@modules/organization/organizationCreateStep/organizationDetailsForm.component';
import { mapOrganizationToRequest } from '@utilities';
import { selectSelectedOrgId } from '@store/selectors';
import {
  useCreateOrgClientMutation,
  useUpdateOrgClientMutation,
} from '@store/services';

function ClientForm({ clientData }: { clientData?: Organization }) {
  const createOrganizationRef = useRef<FormikProps<OrganizationRequest>>(null);

  const navigate = useNavigate();
  const { clientId } = useParams<{ clientId: string }>();
  const { enqueueSnackbar } = useSnackbar();

  const orgId = useSelector(selectSelectedOrgId);

  const [createClient, { error: createError, isSuccess: isCreateSuccess }] =
    useCreateOrgClientMutation();
  const [updateClient, { error: updateError, isSuccess: isUpdateSuccess }] =
    useUpdateOrgClientMutation();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isCreateSuccess || isUpdateSuccess) navigate(NAV_ROUTES.CLIENTS);
  }, [navigate, isCreateSuccess, isUpdateSuccess]);

  useEffect(() => {
    if (clientData)
      createOrganizationRef.current?.setValues(
        mapOrganizationToRequest(clientData),
      );
  }, [clientData]);

  useEffect(() => {
    if (createError || updateError) {
      enqueueSnackbar({
        message: `${
          createError ? 'Creating' : 'Updating'
        } client failed. Please try again.`,
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, createError, updateError]);

  const isNew = useMemo(() => clientId === 'new', [clientId]);

  const handleSubmitClient = useCallback(async () => {
    const validation = await createOrganizationRef.current?.validateForm();
    const values = createOrganizationRef.current?.values;

    if (
      (validation &&
        !Object.keys(validation).every(
          key =>
            validation && (validation as { [key: string]: string })[key] === '',
        )) ||
      !values
    )
      return;

    try {
      setIsLoading(true);

      if (isNew)
        await createClient({
          organizationId: orgId as string,
          ...values,
        }).unwrap();
      else
        await updateClient({
          organizationId: orgId as string,
          ...values,
        }).unwrap();

      navigate(NAV_ROUTES.CLIENTS);
    } catch (e) {
      let message = `Unable to ${
        isNew ? 'create' : 'update'
      } client. Please try again.`;
      const errorData = (e as FetchBaseQueryError)?.data as {
        [key: string]: string[];
      };

      if (errorData.name) {
        message =
          'Company has already signed up. Please contact your administrator to be added.';
      }

      enqueueSnackbar({
        message,
        variant: 'error',
      });
    }

    setIsLoading(false);
  }, [enqueueSnackbar, navigate, isNew, orgId, createClient, updateClient]);

  return (
    <div className="relative col-span-12 flex flex-col gap-y-7 lg:col-span-9 xl:col-span-8">
      <FormCard hideAccordion title="Client Information">
        <OrganizationDetailsForm
          formRef={createOrganizationRef}
          isLoading={isLoading}
          isUpdate={!isNew}
        />
      </FormCard>
      <FormActions
        cancel={{
          disabled: isLoading,
          icon: 'X',
          text: 'Cancel',
          onClick: () => navigate(NAV_ROUTES.CLIENTS),
        }}
        submit={{
          disabled: isLoading,
          icon: 'Send',
          text: isLoading ? 'Saving' : 'Save',
          onClick: handleSubmitClient,
        }}
      />
    </div>
  );
}

export { ClientForm };
