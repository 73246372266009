import {
  Button,
  FormControl,
  TableFilterPredicatesEnum,
  Tippy,
  TomSelect,
} from '@kerplunkai/common-components';
import { useEffect, useMemo } from 'react';
import { useField } from 'formik';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { InterviewJobListing } from '@modules/meetings/components';
import { InterviewRequest, Job } from '@typings';
import { selectSelectedOrgId } from '@store/selectors';
import { useLazyGetOrgJobQuery, useLazyGetOrgJobsQuery } from '@store/services';

interface InterviewTypeFormProps {
  isLoading: boolean;
  interview: InterviewRequest;
  onChange: (name: string, value: string | Job | undefined) => void;
}

function InterviewTypeForm({
  isLoading,
  interview,
  onChange,
}: InterviewTypeFormProps) {
  const [searchParams] = useSearchParams();

  const [, jobListingMeta] = useField('job_listing_id');

  const orgId = useSelector(selectSelectedOrgId);

  const [getJobs, { isFetching: isFetchingJobs, data: jobs }] =
    useLazyGetOrgJobsQuery();
  const [getJob, { isFetching: isFetchingJob }] = useLazyGetOrgJobQuery();

  useEffect(() => {
    if (jobs && interview.job_listing_id) {
      const jobListing = jobs.results.find(
        listing => listing.id === interview.job_listing_id,
      );

      onChange('job_listing', jobListing);
    }
  }, [jobs, interview.job_listing_id, onChange]);

  useEffect(() => {
    const jobId = searchParams.get('jobId');

    if (jobId) {
      (async () => {
        try {
          const job = await getJob({
            organizationId: orgId as string,
            jobId,
          }).unwrap();

          onChange('job_listing_id', job.id);
          onChange('job_listing', job);
        } catch (e) {
          console.log('ERROR FETCHING JOB');
        }
      })();
    }
  }, [searchParams, getJob, orgId, onChange]);

  const jobListings = useMemo(() => {
    if (!jobs) {
      return !interview.job_listing ? [] : [interview.job_listing as Job];
    }

    return jobs.results;
  }, [jobs, interview.job_listing]);

  return (
    <div className="mt-5">
      <FormControl
        description="Select your meeting type."
        childrenClassName="gap-y-4"
        className="mt-5"
        label="Meeting Type"
      >
        <Button
          className="mb-2 whitespace-nowrap [&:not(:last-child)]:mr-2"
          disabled={isLoading || isFetchingJobs}
          variant={
            interview.interview_type === 'copilot'
              ? 'primary'
              : 'soft-sky-outline'
          }
          onClick={() => onChange('interview_type', 'copilot')}
        >
          Co-Pilot Interview
        </Button>
        <Tippy content="Coming soon">
          <Button
            className="mb-2 whitespace-nowrap [&:not(:last-child)]:mr-2"
            disabled
            variant={
              interview.interview_type === 'copilot'
                ? 'primary'
                : 'soft-sky-outline'
            }
            onClick={() => onChange('interview_type', 'copilot')}
          >
            Note Taker
          </Button>
        </Tippy>
      </FormControl>

      <FormControl
        className="mt-5"
        description="Select a job from your existing list of openings. This will allow Kerplunk to analyze candidate fit based on that specific role."
        label="Select Job"
        isRequired
        name=""
      >
        <TomSelect
          className="w-full"
          disabled={isLoading || isFetchingJobs || isFetchingJob}
          options={{
            load: (query: string, callback: () => void) => {
              getJobs({
                orgId: orgId as string,
                pagination: { pageIndex: 1, pageSize: 1000 },
                filters: `q[title_${TableFilterPredicatesEnum.cont}]=${query}&q[s]=title DESC`,
              });

              callback();
            },
            placeholder: 'Select Job Posting',
            itemClass: 'border-none',
            plugins: {
              remove_button: {
                title: '',
                className: 'hidden',
              },
            },
          }}
          value={interview.job_listing_id}
          onChange={e => onChange('job_listing_id', e.target.value)}
        >
          <option value="">Job Listing</option>
          {jobListings.map(({ id, title }) => (
            <option key={id} value={id}>
              {title}
            </option>
          ))}
        </TomSelect>
        {jobListingMeta.error && !jobListingMeta.value && (
          <div className="mt-4 text-xs text-danger">{jobListingMeta.error}</div>
        )}
      </FormControl>
      {interview.job_listing && (
        <InterviewJobListing jobListing={interview.job_listing} />
      )}
    </div>
  );
}

export { InterviewTypeForm };
