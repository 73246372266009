import { Organization, OrganizationRequest } from '@typings';

export const mapOrganizationToRequest = ({
  industry,
  created_at,
  updated_at,
  logo_url,
  subscription,
  ...client
}: Organization): OrganizationRequest => ({
  ...client,
  industry_id: industry.id,
  logo_url,
});

export const getCompanySizeOptions = (
  unfilteredOptions?: {
    [key: string]: string;
  },
  currentSelectionKey?: string,
) => {
  if (!unfilteredOptions) return [];

  // allow these keys to be selected
  const allowedSelectionKeys = ['1-10', '11-50', '51-250', '250+'];

  const filteredOptions = Object.keys(unfilteredOptions)
    .map(key => ({
      name: unfilteredOptions[key],
      value: key,
    }))
    .filter(option => {
      return allowedSelectionKeys.some(key => key === option.value);
    });

  const isCurrentSelectionAllowed = filteredOptions.some(
    option => option.value === currentSelectionKey,
  );

  // backwards-compatibility: allow an existing selection to be unshifted onto the options
  // even when not in the allowed list
  if (currentSelectionKey && !isCurrentSelectionAllowed) {
    filteredOptions.unshift({
      name: unfilteredOptions[currentSelectionKey],
      value: currentSelectionKey,
    });
  }

  return filteredOptions;
};
