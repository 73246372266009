import { Button, Lucide } from '@kerplunkai/common-components';
import { Interview } from '@typings';

import tiktokIcon from '@assets/icons/tiktok_icon.svg';
import xTwitterIcon from '@assets/icons/x_twitter_icon.svg';

interface SocialLinkIconButtonsProps {
  interview: Interview;
}

function SocialLinkIconButtons({ interview }: SocialLinkIconButtonsProps) {
  const hasASocialLink =
    interview.candidate_linked_in_url ||
    interview.candidate_twitter_x_url ||
    interview.candidate_facebook_url ||
    interview.candidate_instagram_url ||
    interview.candidate_portfolio_url ||
    interview.candidate_tiktok_url ||
    interview.candidate_github_url;

  return (
    <div className="flex items-center">
      {/* divider */}
      {hasASocialLink && <div className="mx-3 h-5 w-px bg-slate-400" />}

      {/* linkedin */}
      {interview.candidate_linked_in_url && (
        <Button
          variant="text"
          className="mr-2 rounded-full py-2"
          onClick={() =>
            window.open(interview.candidate_linked_in_url, '_blank')
          }
        >
          <Lucide className="size-4" icon="Linkedin" />
        </Button>
      )}
      {/* twitter/x */}
      {interview.candidate_twitter_x_url && (
        <Button
          variant="text"
          className="mr-2 rounded-full py-2"
          onClick={() =>
            window.open(interview.candidate_twitter_x_url, '_blank')
          }
        >
          <img src={xTwitterIcon} alt="X" className="min-h-4 min-w-4" />
        </Button>
      )}
      {/* instagram */}
      {interview.candidate_instagram_url && (
        <Button
          variant="text"
          className="mr-2 rounded-full py-2"
          onClick={() =>
            window.open(interview.candidate_instagram_url, '_blank')
          }
        >
          <Lucide className="size-4" icon="Instagram" />
        </Button>
      )}
      {/* facebook */}
      {interview.candidate_facebook_url && (
        <Button
          variant="text"
          className="mr-2 rounded-full py-2"
          onClick={() =>
            window.open(interview.candidate_facebook_url, '_blank')
          }
        >
          <Lucide className="size-4" icon="Facebook" />
        </Button>
      )}
      {/* tiktok */}
      {interview.candidate_tiktok_url && (
        <Button
          variant="text"
          className="mr-2 rounded-full py-2"
          onClick={() => window.open(interview.candidate_tiktok_url, '_blank')}
        >
          <img src={tiktokIcon} alt="TikTok" className="min-h-4 min-w-4" />
        </Button>
      )}
      {/* github */}
      {interview.candidate_github_url && (
        <Button
          variant="text"
          className="mr-2 rounded-full py-2"
          onClick={() => window.open(interview.candidate_github_url, '_blank')}
        >
          <Lucide className="size-4" icon="Github" />
        </Button>
      )}
      {/* portfolio */}
      {interview.candidate_portfolio_url && (
        <Button
          variant="text"
          className="rounded-full py-2"
          onClick={() =>
            window.open(interview.candidate_portfolio_url, '_blank')
          }
        >
          <Lucide className="size-4 stroke-sky-800" icon="Link" />
        </Button>
      )}
    </div>
  );
}

export { SocialLinkIconButtons };
