import { BASE_URL } from '@constants';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { RootState } from '@store/store';
import { User } from '@typings';
import { UserUpdateRequest } from '@typings/user';

export const userService = createApi({
  reducerPath: 'userService',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/`,
    prepareHeaders: (headers, { getState }) => {
      const { token } = (getState() as RootState).auth;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ['User'],
  endpoints: builder => ({
    user: builder.query<User, void>({
      query: () => ({
        url: 'user',
        method: 'GET',
      }),
      providesTags: ['User'],
    }),
    acceptInvite: builder.mutation<User, string>({
      query: invitation_token => ({
        url: 'user/accept_invite',
        method: 'POST',
        body: {
          user: { invitation_token },
        },
      }),
    }),
    updateUser: builder.mutation<User, UserUpdateRequest>({
      query: data => {
        const bodyFormData = new FormData();
        if (data.avatar) bodyFormData.append('user[avatar]', data.avatar.src);
        if (data.first_name)
          bodyFormData.append('user[first_name]', data.first_name);
        if (data.last_name)
          bodyFormData.append('user[last_name]', data.last_name);

        return {
          url: '/user',
          method: 'PUT',
          body: bodyFormData,
        };
      },
      invalidatesTags: ['User'],
    }),
  }),
});

export const {
  useUserQuery,
  useLazyUserQuery,
  useAcceptInviteMutation,
  useUpdateUserMutation,
} = userService;
