import { ReactNode } from 'react';

interface JobSummaryGroupProps {
  borderRadius?: string;
  borderWidth?: string;
  children: ReactNode;
  title: string;
}

function JobSummaryGroup({
  borderRadius,
  borderWidth,
  children,
  title,
}: JobSummaryGroupProps) {
  return (
    <div
      style={{
        padding: '17px 20px 17px 20px',
        borderRadius: borderRadius || 0,
        borderWidth: borderWidth || '1px',
        borderColor: '#CBD5E1',
        borderStyle: 'dashed',
      }}
    >
      <div className="flex flex-col gap-1.5">
        <div className="text-sm font-medium text-sky-800">{title}</div>
        {children}
      </div>
    </div>
  );
}

export { JobSummaryGroup };
