/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  FormActions,
  Loading,
  StepType,
  // Stepper,
} from '@kerplunkai/common-components';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { FormikProps } from 'formik';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { JobCreateSteps } from '@modules/organization/jobCreateSteps';
import { OrganizationCreateStep } from '@modules/organization/organizationCreateStep';
import { OrganizationRequest } from '@typings';
import { selectOrganizations, selectSelectedOrgId } from '@store/selectors';
import { useAppDispatch } from '@store/hooks';
import {
  useCreateOrganizationMutation,
  useLazyOrganizationsQuery,
  useOrganizationsDetailsQuery,
} from '@store/services';

import logo from '@assets/images/logos/Kerplunk-Logo-wht.svg';
import { setSelectedOrg } from '@store/slices';

function OrganizationCreate() {
  const createOrganizationRef = useRef<FormikProps<OrganizationRequest>>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const selectedOrg = useSelector(selectSelectedOrgId);
  const organizations = useSelector(selectOrganizations());

  useOrganizationsDetailsQuery();
  const [createOrganization] = useCreateOrganizationMutation();
  const [getOrganizations] = useLazyOrganizationsQuery();

  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (selectedOrg) navigate('/');
  }, [navigate, selectedOrg]);

  const handleCreateOrg = useCallback(async () => {
    const validation = await createOrganizationRef.current?.validateForm();
    const values = createOrganizationRef.current?.values;

    if (
      (validation &&
        !Object.keys(validation).every(
          key =>
            validation && (validation as { [key: string]: string })[key] === '',
        )) ||
      !values
    )
      return;

    try {
      setIsLoading(true);

      const sessionId = localStorage.getItem('stripe_session_id');
      if (sessionId) {
        values.stripe_session_id = sessionId;
        localStorage.removeItem('stripe_session_id');
      }

      const organization = await createOrganization(values).unwrap();
      await getOrganizations();

      dispatch(setSelectedOrg(organization));

      navigate('/');

      // setActiveStep(activeStep + 1);
    } catch (e) {
      let message = 'Unable to create organization. Please try again.';
      const errorData = (e as FetchBaseQueryError)?.data as {
        [key: string]: string[];
      };

      if (errorData.name) {
        message =
          'Company has already signed up. Please contact your administrator to be added.';
      }

      enqueueSnackbar({
        message,
        variant: 'error',
      });
    }

    setIsLoading(false);
  }, [
    dispatch,
    navigate,
    enqueueSnackbar,
    createOrganization,
    getOrganizations,
  ]);

  const handleCreateJob = useCallback(async () => {
    const isValid = createOrganizationRef.current?.isValid;

    try {
      setIsLoading(true);

      // create job here
      setIsLoading(false);
    } catch (e) {
      enqueueSnackbar({
        message: 'Unable to create job. Please try again.',
        variant: 'error',
      });
    }
  }, [createOrganizationRef, enqueueSnackbar]);

  const handleStepChange = useCallback(
    (nextStep: number) => {
      if (activeStep === 0 && !selectedOrg) return;

      setActiveStep(nextStep);
    },
    [activeStep, selectedOrg],
  );

  const renderedActiveStep = useMemo(() => {
    return activeStep === 0 ? (
      <OrganizationCreateStep
        formRef={createOrganizationRef}
        isLoading={isLoading}
      />
    ) : (
      <JobCreateSteps
        activeStep={activeStep}
        //  isLoading={isLoading}
      />
    );
  }, [activeStep, isLoading]);

  const renderedSteps = useMemo(() => {
    const steps: StepType[] = [
      {
        text: 'Company Information',
      },
      {
        text: 'Job Information',
      },
      {
        text: '✨AI Questions',
      },
      {
        text: 'Preview & Publish',
      },
    ];

    if (selectedOrg)
      steps.push({
        text: '',
        render: step => (
          <Button
            className="w-[100px] px-3.5 py-[11px] text-white"
            variant="outline-secondary"
            onClick={() => navigate('/')}
          >
            Skip
          </Button>
        ),
      });

    return steps;
  }, [navigate, selectedOrg]);

  if (organizations === undefined) return <Loading />;

  return (
    <>
      <div className="mb-10 flex justify-center">
        <img alt="Logo" src={logo} />
      </div>
      <div className="grid grid-cols-12 gap-x-6 gap-y-10">
        <div className="col-span-12">
          {/* <Stepper
          activeStep={activeStep}
          steps={renderedSteps}
          onStepChange={handleStepChange}
        /> */}
          <div className="flex flex-col gap-y-7">
            {renderedActiveStep}
            <FormActions
              cancel={
                activeStep !== 0
                  ? {
                      disabled: isLoading,
                      icon: 'ArrowLeft',
                      text: 'Back',
                      onClick: () => setActiveStep(activeStep - 1),
                    }
                  : undefined
              }
              submit={{
                disabled: isLoading,
                text: 'Submit',
                icon: 'Send',
                onClick:
                  activeStep === 0
                    ? () => handleCreateOrg()
                    : () => handleCreateJob(),
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export { OrganizationCreate };
