import { OrganizationRequest } from '@typings';

export const INITIAL_ORG: OrganizationRequest = {
  name: '',
  industry_id: '',
  country: '',
  city: '',
  state: '',
  size: '',
  logo: undefined,
  intro_video_url: '',
};
