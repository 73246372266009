import { CAMERA_PERM_NAME, MIC_PERM_NAME } from '@constants';
import {
  MediaPermissionPreviewStep,
  MediaPermissionSwitcher,
} from '@modules/conference/components';
import {
  checkMediaPermissions,
  getDevices,
  isFirefox,
  queryFirefoxPermissions,
  requestMediaPermissions,
} from '@utilities';
import {
  selectActiveCameraDevice,
  selectActiveCameraStream,
  selectActiveMicDevice,
  selectActiveMicStream,
  selectIsCameraPaused,
  selectIsMicPaused,
} from '@store/selectors';
import {
  setSelectedCameraDeviceId,
  setSelectedMicDeviceId,
  stopCameraStream,
  stopMicStream,
} from '@store/slices';
import { useAppDispatch } from '@store/hooks';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

type MediaPermissionsProps = {
  onStartInterview?: () => void;
  onShowPreview?: () => void;
  isLoading?: boolean;
  startButtonText?: string;
};

export function MediaPermissions({
  onStartInterview,
  onShowPreview,
  isLoading,
  startButtonText = 'Start Interview',
}: MediaPermissionsProps) {
  const dispatch = useAppDispatch();

  const [hasAudioPermission, setHasAudioPermission] = useState(false);
  const [hasVideoPermission, setHasVideoPermission] = useState(false);

  // start showing camera perm
  const [shownPermissionName, setShownPermissionName] = useState<
    typeof CAMERA_PERM_NAME | typeof MIC_PERM_NAME | null
  >(CAMERA_PERM_NAME);

  const [availableCameraDevices, setAvailableCameraDevices] =
    useState<MediaDeviceInfo[]>();
  const [availableMicDevices, setAvailableMicDevices] =
    useState<MediaDeviceInfo[]>();

  const reduxMicDeviceId = useSelector(selectActiveMicDevice);
  const reduxCameraDeviceId = useSelector(selectActiveCameraDevice);

  const isMicMuted = useSelector(selectIsMicPaused);
  const isCameraMuted = useSelector(selectIsCameraPaused);

  const cameraStream = useSelector(selectActiveCameraStream);
  const micStream = useSelector(selectActiveMicStream);

  const [isDisableCamSwitcher, setIsDisableCamSwitcher] = useState(false);
  const [isDisableMicSwitcher, setIsDisableMicSwitcher] = useState(false);

  useEffect(() => {
    requestMediaPermissions();
  }, []);

  const queryPermissions = useCallback(() => {
    if (isFirefox) {
      queryFirefoxPermissions()
        .then(result => {
          setHasAudioPermission(result.micPermission);
          setHasVideoPermission(result.cameraPermission);
        })
        .catch(error => {
          console.error('Unexpected firefox permission query result :', error);
        });
      return;
    }

    // non-firefox permission check
    checkMediaPermissions().then(
      ({ hasAudioPermission: hasAudio, hasVideoPermission: hasVideo }) => {
        setHasAudioPermission(hasAudio);
        setHasVideoPermission(hasVideo);
      },
    );
  }, []);

  // manage loading state of device switcher inputs
  useEffect(() => {
    setIsDisableCamSwitcher(!cameraStream);
    setIsDisableMicSwitcher(!micStream);
  }, [cameraStream, micStream]);

  useEffect(() => {
    // initialize available devices
    if (hasAudioPermission) {
      // we have all device permissions we need; get the list of devices now
      getDevices().then(({ audioDevices }) => {
        const micDevices = audioDevices.filter(d => d.kind === 'audioinput');
        setAvailableMicDevices(micDevices);
        if (!reduxMicDeviceId && !isMicMuted) {
          // initialize default
          if (micDevices && micDevices[0]) {
            dispatch(setSelectedMicDeviceId(micDevices[0].deviceId));
          }
        }
      });
    }

    if (hasVideoPermission) {
      getDevices().then(({ videoDevices }) => {
        const camDevices = videoDevices.filter(d => d.kind === 'videoinput');
        setAvailableCameraDevices(camDevices);
        if (!reduxCameraDeviceId && !isCameraMuted) {
          // initialize default
          if (camDevices && camDevices[0]) {
            dispatch(setSelectedCameraDeviceId(camDevices[0].deviceId));
          }
        }
      });
    }
  }, [
    dispatch,
    hasAudioPermission,
    hasVideoPermission,
    isCameraMuted,
    isMicMuted,
    reduxCameraDeviceId,
    reduxMicDeviceId,
  ]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      queryPermissions();
    }, 1000);

    return () => clearInterval(intervalId);
  }, [queryPermissions]);

  const handleSelectMic = useCallback(
    (deviceId: string) => {
      dispatch(stopMicStream());
      dispatch(setSelectedMicDeviceId(deviceId));
    },
    [dispatch],
  );

  const handleSelectCam = useCallback(
    (deviceId: string) => {
      dispatch(stopCameraStream());
      dispatch(setSelectedCameraDeviceId(deviceId));
    },
    [dispatch],
  );

  const advanceToNextStep = () => {
    if (shownPermissionName === CAMERA_PERM_NAME) {
      setShownPermissionName(MIC_PERM_NAME);
    }

    if (shownPermissionName === MIC_PERM_NAME) {
      // show 'ready to start' state
      // permissions are ready to go
      setShownPermissionName(null);

      // start showing video preview
      onShowPreview?.();
    }
  };

  // final step
  if (shownPermissionName === null) {
    return (
      <MediaPermissionPreviewStep
        hasVideoPermission={hasVideoPermission}
        hasAudioPermission={hasAudioPermission}
        handleSelectCam={handleSelectCam}
        handleSelectMic={handleSelectMic}
        isDisableCamSwitcher={isDisableCamSwitcher}
        isDisableMicSwitcher={isDisableMicSwitcher}
        availableCameraDevices={availableCameraDevices}
        availableMicDevices={availableMicDevices}
        handleStartInterview={() => onStartInterview?.()}
        isLoading={isLoading}
        startButtonText={startButtonText}
      />
    );
  }

  // permission switcher
  return (
    <MediaPermissionSwitcher
      shownPermissionName={shownPermissionName}
      hasVideoPermission={hasVideoPermission}
      hasAudioPermission={hasAudioPermission}
      handleSelectCam={handleSelectCam}
      handleSelectMic={handleSelectMic}
      isDisableCamSwitcher={isDisableCamSwitcher}
      isDisableMicSwitcher={isDisableMicSwitcher}
      availableCameraDevices={availableCameraDevices}
      availableMicDevices={availableMicDevices}
      advanceToNextStep={advanceToNextStep}
    />
  );
}
