import { Autocomplete, LoadScript } from '@react-google-maps/api';
import { FormInput } from '@kerplunkai/common-components';
import { useCallback, useEffect, useRef, useState } from 'react';

const libraries: 'places'[] = ['places'];

interface AddressAutocompleteProps {
  curAddress?: google.maps.places.PlaceResult;
  disabled: boolean;

  onSelectAddress: (place: google.maps.places.PlaceResult) => void;
}

function AddressAutocomplete({
  curAddress,
  disabled,
  onSelectAddress,
}: AddressAutocompleteProps) {
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [address, setAddress] = useState('');

  useEffect(() => {
    if (curAddress) setAddress(curAddress.formatted_address || '');
  }, [curAddress]);

  useEffect(() => {
    if (autocompleteRef.current) {
      google.maps.event.clearInstanceListeners(autocompleteRef.current);
    }
  }, []);

  const handlePlaceSelect = useCallback(() => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();

      setAddress(place.formatted_address || '');
      onSelectAddress(place);
    }
  }, [onSelectAddress]);

  const handleLoad = useCallback(
    (autocomplete: google.maps.places.Autocomplete) => {
      autocompleteRef.current = autocomplete;
      autocompleteRef.current.addListener('place_changed', handlePlaceSelect);
    },
    [handlePlaceSelect],
  );

  return (
    <LoadScript
      googleMapsApiKey={import.meta.env.VITE_GOOGLE_API_KEY}
      libraries={libraries}
    >
      <Autocomplete onLoad={handleLoad}>
        <FormInput
          ref={inputRef}
          className="text-slate-600"
          disabled={disabled}
          name="address"
          placeholder="Enter Address"
          value={address}
          onChange={e => setAddress(e.target.value)}
        />
      </Autocomplete>
    </LoadScript>
  );
}

export { AddressAutocomplete };
