import { Breadcrumb } from '@kerplunkai/common-components';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { NAV_ROUTES } from '@constants';
import { selectOrgBenefit, selectSelectedOrgId } from '@store/selectors';

interface BenefitBreadcrumbProps {
  benefitId: string;
  key?: number;
}

function BenefitBreadcrumb({
  benefitId,
  key,
  ...props
}: BenefitBreadcrumbProps) {
  const orgId = useSelector(selectSelectedOrgId);
  const benefit = useSelector(selectOrgBenefit(orgId as string, benefitId));

  const to = useMemo(() => {
    if (benefitId === 'new') return `${NAV_ROUTES.BENEFITS}/${benefitId}`;

    return `${NAV_ROUTES.BENEFITS}/${benefit?.id}`;
  }, [benefitId, benefit]);

  const linkText = useMemo(() => {
    if (benefitId === 'new') return 'Add a Benefit';

    return benefit?.name;
  }, [benefitId, benefit]);

  if (benefitId !== 'new' && !benefit) return null;

  return (
    <Breadcrumb.Link forwardedKey={key} {...props}>
      <Link to={to}>{linkText}</Link>
    </Breadcrumb.Link>
  );
}

export { BenefitBreadcrumb };
