import clsx from 'clsx';
import spacetime from 'spacetime';
import {
  Button,
  DEFAULT_PAGE_INFO,
  FormInput,
  FormSelect,
  Lucide,
  ModalDialog,
  TableFilterPredicatesEnum,
  TableFilters,
  TableRefetchFn,
  TanstackTable,
  TanstackTableActionColumn,
  Tippy,
} from '@kerplunkai/common-components';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { ColumnDef, getCoreRowModel } from '@tanstack/react-table';
import { Link, useNavigate } from 'react-router-dom';
import { useCopyToClipboard } from 'usehooks-ts';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { FILTER_ORG_ID, NAV_ROUTES, NEW_ROUTES } from '@constants';
import { Job, JobStatus } from '@typings';
import { darkGreen, lightGreen, medGreen } from '@theme';
import { getJobStatusColor, mapJobToJobForm } from '@utilities/jobs';
import { selectSelectedOrgId } from '@store/selectors';
import {
  useDeleteJobMutation,
  useDuplicateJobMutation,
  useLazyGetOrgJobsQuery,
  useUpdateJobMutation,
} from '@store/services';

function JobsTable() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [copiedText, copy] = useCopyToClipboard();

  const [updateJob] = useUpdateJobMutation();
  const [deleteJob, { isLoading: isDeleting }] = useDeleteJobMutation();
  const [duplicateJob] = useDuplicateJobMutation();

  const orgId = useSelector(selectSelectedOrgId);

  const [itemsToDelete, setItemsToDelete] = useState<string[] | null>(null);

  const [getJobs, { data: jobsData, isFetching }] = useLazyGetOrgJobsQuery();

  const handleUpdateJobStatus = useCallback(
    async (jobToUpdate: Job, status: keyof typeof JobStatus) => {
      try {
        await updateJob({
          ...mapJobToJobForm(jobToUpdate),
          organization_id: orgId as string,
          status,
        }).unwrap();

        enqueueSnackbar({
          message: 'Updating job was successful',
          variant: 'success',
        });
      } catch (e) {
        enqueueSnackbar({
          message: 'Unable to update job. Please try again.',
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, orgId, updateJob],
  );

  const handleDuplicateJob = useCallback(
    async (jobToDuplicate: Job) => {
      try {
        const result = await duplicateJob({
          organizationId: orgId as string,
          jobId: jobToDuplicate.id,
        }).unwrap();

        enqueueSnackbar({
          message: 'Job duplicated successfully',
          variant: 'success',
        });

        navigate(`${NAV_ROUTES.JOBS}/${result.id}`);
      } catch (error) {
        enqueueSnackbar({
          message: 'Failed to duplicate job. Please try again.',
          variant: 'error',
        });
      }
    },
    [duplicateJob, enqueueSnackbar, navigate, orgId],
  );

  const columns: ColumnDef<Job>[] = useMemo(
    () => [
      {
        header: 'Status',
        accessorKey: 'status',
        cell: ({ getValue }) => (
          <div
            className={clsx([
              'flex items-center',
              `text-${getJobStatusColor(getValue<string>())}`,
            ])}
          >
            <Lucide icon="Database" className="size-3.5 stroke-[1.7]" />
            <div className="ml-1.5 whitespace-nowrap">
              {JobStatus[getValue<keyof typeof JobStatus>()]}
            </div>
          </div>
        ),
      },
      {
        header: 'Job Name',
        accessorKey: 'title',
        cell: ({ getValue, row }) => (
          <Link to={`${row.original.id}/applications`}>
            <p className="whitespace-normal font-medium text-slate-600">
              {getValue<string>()}
            </p>
            <p className="text-xs text-slate-400">
              {row.original.hiring_organization}
            </p>
          </Link>
        ),
      },
      {
        header: 'Copy Link to Job Posting',
        accessorKey: 'slug',
        minSize: 224,
        className: 'px-0',
        cell: ({ getValue }) => {
          const slug = getValue<string>();
          const isCopied =
            `${import.meta.env.VITE_CANDIDATE_URL}/job/${slug}` === copiedText;

          return (
            <div className="flex">
              <FormInput
                className={clsx(
                  'mr-2 flex-1 border-none !bg-white bg-none text-slate-500 shadow-none',
                  isCopied && ` disabled:text-[${darkGreen}]`,
                )}
                disabled
                placeholder="everyone@gmail.com"
                type="text"
                value={`/${slug}`}
              />
              <Tippy
                as={Button}
                className={clsx(
                  'mr-2 border-slate-200 bg-slate-50 p-1.5',
                  isCopied &&
                    `disabled:border-[${medGreen}] disabled:bg-[${lightGreen}]/10 disabled:text-[${darkGreen}]`,
                )}
                disabled={isCopied}
                content="Copy job link"
                variant="outline-secondary"
                onClick={() =>
                  copy(`${import.meta.env.VITE_CANDIDATE_URL}/job/${slug}`)
                }
              >
                <Lucide
                  icon={isCopied ? 'Check' : 'Copy'}
                  className={clsx(
                    'size-6 stroke-[1.5] ',
                    isCopied ? `stroke-[${darkGreen}]` : 'stroke-slate-500',
                  )}
                />
              </Tippy>
            </div>
          );
        },
      },
      {
        header: () => (
          <div className="inline-flex justify-center">Total Applicants</div>
        ),
        accessorKey: 'application_count',
        minSize: 225,
        cell: ({ row }) => {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const { application_count } = row.original;

          return (
            <Link to={`${row.original.id}/applications`}>
              <div className="whitespace-nowrap">
                <div className="flex items-center justify-center">
                  <div className="text-slate-400">{application_count}</div>
                </div>
              </div>
            </Link>
          );
        },
      },
      {
        header: 'Date Published',
        accessorKey: 'published_at',
        cell: ({ getValue }) => (
          <div className="flex flex-col gap-1">
            <p className="text-xs text-slate-400">Date Published</p>
            <p className="text-sm text-slate-600">
              {getValue<string>()
                ? spacetime(getValue<string>())?.format('{date} {month} {year}')
                : '-'}
            </p>
          </div>
        ),
      },
      {
        header: 'Created by',
        accessorKey: 'created_by',
        cell: ({ row }) => (
          <div className="flex flex-col gap-1">
            <p className="text-xs text-slate-400">Created by</p>
            <p className="text-sm text-slate-600">
              {row.original.created_by.display_name}
            </p>
          </div>
        ),
      },
      {
        accessorKey: 'actions',
        header: '',
        maxSize: 50,
        cell: ({ row }) => (
          <TanstackTableActionColumn
            data={row}
            actions={[
              {
                icon: 'CheckSquare',
                text: 'Edit',
                onClick: () =>
                  navigate(`${NAV_ROUTES.JOBS}/${row.original.id}`),
              },
              {
                icon: 'Copy',
                text: 'Duplicate',
                onClick: () => handleDuplicateJob(row.original),
              },
              {
                icon: 'PencilRuler',
                text: 'Mark Draft',
                onClick: () => handleUpdateJobStatus(row.original, 'draft'),
              },
              {
                icon: 'XSquare',
                text: 'Mark Closed',
                onClick: () => handleUpdateJobStatus(row.original, 'closed'),
              },
              {
                icon: 'Trash2',
                text: 'Delete',
                onClick: () => setItemsToDelete([row.original.id]),
              },
            ]}
          />
        ),
        enableSorting: false,
      },
    ],
    [navigate, copiedText, copy, handleUpdateJobStatus, handleDuplicateJob],
  );

  const handleRefetch: TableRefetchFn = useCallback(
    (pagination, { filters, searchTerm, sorting }) => {
      let filterQueryParms = '';

      if (searchTerm && searchTerm.length > 0)
        filterQueryParms += `q[title_or_hiring_organization_name_${TableFilterPredicatesEnum.cont}]=${searchTerm}`;
      if (sorting)
        filterQueryParms += `${filterQueryParms.length ? '&' : ''}q[s]=${sorting}`;

      Object.keys(filters || {}).forEach(filter => {
        filterQueryParms += `${filterQueryParms !== '' ? '&' : ''}q[${filter}_${TableFilterPredicatesEnum.eq}]=${(filters || {})[filter]}`;
      });

      getJobs({
        orgId: orgId as string,
        pagination,
        filters: filterQueryParms,
      });
    },
    [getJobs, orgId],
  );

  const handleDeleteJobs = useCallback(
    (jobsToDelete: string[]) => {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise<void>(async (resolve, reject) => {
        const results = await Promise.all(
          jobsToDelete?.map(jobToDelete =>
            deleteJob({
              organizationId: orgId as string,
              jobId: jobToDelete,
            }),
          ),
        );
        const validResults = results.filter(
          result => !(result instanceof Error),
        );

        if (validResults.length === jobsToDelete.length) {
          enqueueSnackbar({
            message: 'Deleting job(s) was successful',
            variant: 'success',
          });
          resolve();
        } else {
          enqueueSnackbar({
            message: 'Unable to delete some or all job(s). Please try again.',
            variant: 'error',
          });
          reject();
        }

        setItemsToDelete(null);
      });
    },
    [enqueueSnackbar, orgId, deleteJob],
  );

  return (
    <>
      <TanstackTable
        headerClassName="whitespace-no-wrap lg:whitespace-normal"
        actionsHeaderItems={{
          addItem: {
            text: 'Post a Job',
            onClick: () => navigate(NEW_ROUTES.NEW_JOB),
          },
          exportItem: {
            text: 'Export',
            onClick: () => ({}),
          },
          ...(orgId === FILTER_ORG_ID
            ? {
                coPilotItem: {
                  text: 'Create Co-Pilot Meeting',
                  onClick: () => navigate(NEW_ROUTES.NEW_MEETING),
                },
              }
            : {}),
        }}
        emptyText="No Job(s) Found"
        initialSorting={{
          initialField: 'created_at',
          initialOrder: 'DESC',
        }}
        isFetching={isFetching}
        pageInfo={jobsData ? jobsData.meta : DEFAULT_PAGE_INFO}
        searchPlaceholder="Search Jobs..."
        showSearch
        selection
        selectionModalProps={{
          title: 'Delete Job(s)',
          description: 'Do you really want to delete these job(s)?',
          confirmText: 'Delete',
          cancelText: 'Cancel',
        }}
        tableOptions={{
          data: jobsData ? jobsData.results : [],
          columns,
          getCoreRowModel: getCoreRowModel(),
          getRowId: row => row.id,
        }}
        onDelete={handleDeleteJobs}
        onRefetch={handleRefetch}
        renderFilters={(
          tempFilters: TableFilters,
          setTempFilters: Dispatch<SetStateAction<TableFilters>>,
        ) => (
          <div>
            <div className="text-left text-slate-500">Status</div>
            <FormSelect
              className="mt-2 flex-1"
              value={tempFilters.status}
              onChange={({
                target: { value },
              }: ChangeEvent<HTMLSelectElement>) =>
                setTempFilters({ ...tempFilters, status: value })
              }
            >
              <option value="">Status</option>
              <option value="published">Published</option>
              <option value="draft">Draft</option>
              <option value="closed">Closed</option>
            </FormSelect>
          </div>
        )}
      />
      <ModalDialog
        open={Boolean(itemsToDelete)}
        title="Delete Job"
        description="Do you really want to delete these job?"
        confirmText="Delete"
        cancelText="Cancel"
        isLoading={isDeleting}
        onConfirm={() => handleDeleteJobs(itemsToDelete as string[])}
        onCancel={() => setItemsToDelete(null)}
      />
    </>
  );
}

export { JobsTable };
