import clsx from 'clsx';
import { ForgotPasswordForm } from '@kerplunkai/common-components';

import { useForgotPasswordMutation } from '@store/services';

import logo from '@assets/images/logos/Kerplunk-Logo-blk.svg';

function ForgotPassword() {
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  return (
    <div className="fixed inset-0 h-screen w-screen">
      <div
        className={clsx([
          'h-full',
          "before:absolute before:inset-y-0 before:left-0 before:w-screen before:bg-gradient-to-b before:from-theme-1 before:to-theme-2 before:content-[''] before:lg:-ml-10 before:lg:w-[800%]",
          "after:absolute after:inset-y-0 after:left-0 after:w-screen after:bg-texture-white after:bg-fixed after:bg-center after:bg-no-repeat after:content-[''] after:lg:w-[800%] after:lg:bg-[25rem_-25rem]",
        ])}
      >
        <div className="relative z-10 flex h-screen items-center justify-center">
          <ForgotPasswordForm
            isLoading={isLoading}
            logo={logo}
            sendForgotPassword={forgotPassword}
          />
        </div>
      </div>
    </div>
  );
}

export { ForgotPassword };
