/* eslint-disable react-hooks/rules-of-hooks */
import { IKerplunkMediaService } from '@typings';

import {
  useAmazonIVSService,
  useVideoSDKJSService,
} from '@modules/conference/hooks';

const defaultReturn: IKerplunkMediaService = {
  addAudioStream: () => {},
  addVideoStream: () => {},
  close: () => {},
  initClient: () => {},
  isRecording: false,
  removeAudioStream: () => {},
  removeVideoStream: () => {},
  startRecording: () => {},
  stopRecording: () => {},
  isMeetingConnected: false,
  participants: [],
};

export const useKerplunkMediaService = (
  serviceFlag?: 'aws_ivs' | 'videosdk', // Make the serviceFlag parameter optional
): IKerplunkMediaService => {
  if (serviceFlag === 'aws_ivs') {
    return useAmazonIVSService();
  }

  if (serviceFlag === 'videosdk') {
    return useVideoSDKJSService();
  }

  // Handle the case when serviceFlag is undefined or invalid
  console.error(
    'Invalid service flag or no service flag provided. Returning default media client.',
  );
  return defaultReturn;
};
