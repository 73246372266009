import { benefitService } from '@store/services';
import { createSelector } from '@reduxjs/toolkit';

export const selectOrgBenefit = (orgId: string, jobBenefitId: string) =>
  createSelector(
    benefitService.endpoints.jobBenefit.select({
      organizationId: orgId,
      jobBenefitId,
    }),
    queryState => queryState.data,
  );
