import { createSelector } from '@reduxjs/toolkit';

import { Job } from '@typings';
import { RootState } from '@store/store';
import { jobService } from '@store/services';

export const selectLocationTypes = (orgId: string) =>
  createSelector(
    jobService.endpoints.getJobDetails.select(orgId),
    queryState => queryState?.data?.job_listing.location_type,
  );

export const selectJobTypes = (orgId: string) =>
  createSelector(
    jobService.endpoints.getJobDetails.select(orgId),
    queryState => queryState?.data?.job_listing.job_type,
  );

export const selectJobExperienceLevels = (orgId: string) =>
  createSelector(
    jobService.endpoints.getJobDetails.select(orgId),
    queryState => queryState?.data?.job_listing.experience_level,
  );

export const selectJobSchedule = (orgId: string) =>
  createSelector(
    jobService.endpoints.getJobDetails.select(orgId),
    queryState => queryState?.data?.job_listing.schedule,
  );

export const selectJobOrgBenefits = (orgId: string) =>
  createSelector(
    jobService.endpoints.getJobDetails.select(orgId),
    queryState => queryState?.data?.job_listing.organization_benefits,
  );

export const selectJobPayTypes = (orgId: string) =>
  createSelector(
    jobService.endpoints.getJobDetails.select(orgId),
    queryState => queryState?.data?.job_listing.pay_type,
  );

export const selectJobQCategoryTypes = (orgId: string) =>
  createSelector(
    jobService.endpoints.getJobDetails.select(orgId),
    queryState => queryState?.data?.job_listing.interview_question.category,
  );

export const selectJobDetails = (orgId: string) =>
  createSelector(
    jobService.endpoints.getJobDetails.select(orgId),
    queryState => queryState?.data,
  );

export const selectOrgJob = (orgId: string, jobId: string) =>
  createSelector(
    jobService.endpoints.getOrgJob.select({
      organizationId: orgId,
      jobId,
    }),
    queryState => {
      if (!queryState?.data) return undefined;

      const job = { ...queryState?.data };

      if (job && job.interview_questions) {
        const interviewQuestions = [...job.interview_questions];

        job.interview_questions = interviewQuestions.sort(
          (a, b) => a.position - b.position,
        );
      }

      return job as Job;
    },
  );

export const selectJobDesriptionLoading = (state: RootState) =>
  state.job.isLoadingDescription;
