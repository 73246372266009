import {
  FormSelect,
  HireprosLitepicker,
  Lucide,
} from '@kerplunkai/common-components';
import { useSelector } from 'react-redux';
import { useState } from 'react';

import { DashboardInterviewsTable } from '@modules/dashboard/dashboardInterviewsTable.component';
import { QuickLinks } from '@modules/dashboard/quickLinks.component';
import { Summary } from '@modules/dashboard/summary.component';
import { selectCurrentUser } from '@store/selectors';

function Dashboard() {
  const currentUser = useSelector(selectCurrentUser);

  const getDefaultDateRange = () => {
    const today = new Date();
    const lastMonth = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      today.getDate(),
    );
    return `${lastMonth.toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' })} - ${today.toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' })}`;
  };

  const [summaryDateRange, setSummaryDateRange] = useState<string>(
    getDefaultDateRange(),
  );
  const [interviewDateRange, setInterviewDateRange] = useState<string>('');

  return (
    <div className="grid grid-cols-12 gap-x-6 ">
      <div className="col-span-12">
        <div className="flex flex-col gap-y-3 md:h-10 md:flex-row md:items-center">
          <div className="text-base font-medium group-[.mode--light]:text-white">
            Welcome back, {currentUser?.first_name}!
          </div>
          <div className="flex flex-col gap-x-3 gap-y-2 sm:flex-row md:ml-auto">
            <div className="relative">
              <Lucide
                icon="CalendarCheck2"
                className="absolute inset-y-0 left-0 z-10 my-auto ml-3 size-4 stroke-slate-500 stroke-[1.3]"
              />
              <FormSelect className="rounded-lg pl-9 text-slate-500 sm:w-44">
                <option value="custom-date">Custom Date</option>
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
                <option value="yearly">Yearly</option>
              </FormSelect>
            </div>

            <div className="relative">
              <Lucide
                icon="Calendar"
                className="absolute inset-y-0 left-0 z-10 my-auto ml-3 size-4 stroke-slate-500 stroke-[1.3]"
              />
              <HireprosLitepicker
                value={summaryDateRange}
                onChange={e => {
                  setSummaryDateRange(e.target.value);
                }}
                options={{
                  autoApply: false,
                  singleMode: false,
                  numberOfColumns: 2,
                  numberOfMonths: 2,
                  showWeekNumbers: true,
                  dropdowns: {
                    minYear: 1990,
                    maxYear: null,
                    months: true,
                    years: true,
                  },
                  format: 'MMM DD, YYYY',
                }}
                className="rounded-lg pl-9 text-slate-500 sm:w-64"
              />
            </div>
          </div>
        </div>
        <Summary dateRange={summaryDateRange} />
      </div>
      <div className="col-span-12 mt-9">
        <QuickLinks />
      </div>

      <div className="col-span-12 grid grid-cols-12">
        <div className="col-span-12 mt-12 flex flex-col">
          <div className="w-full">
            <div className="mb-[14px] flex flex-col md:flex-row md:items-center">
              <div className="text-base font-medium text-slate-600">
                Recent Interviews
              </div>
              <div className="flex flex-col gap-x-3 gap-y-2 sm:flex-row md:ml-auto">
                <div className="relative">
                  <Lucide
                    icon="CalendarCheck2"
                    className="absolute inset-y-0 left-0 z-10 my-auto ml-3 size-4 stroke-slate-500 stroke-[1.3]"
                  />
                  <FormSelect className="rounded-lg pl-9 text-slate-500 sm:w-44">
                    <option value="custom-date">Custom Date</option>
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                  </FormSelect>
                </div>
                <div className="relative">
                  <Lucide
                    icon="Calendar"
                    className="absolute inset-y-0 left-0 z-10 my-auto ml-3 size-4 stroke-slate-500 stroke-[1.3]"
                  />
                  <HireprosLitepicker
                    value={interviewDateRange}
                    onChange={e => {
                      setInterviewDateRange(e.target.value);
                    }}
                    options={{
                      autoApply: false,
                      singleMode: false,
                      numberOfColumns: 2,
                      numberOfMonths: 2,
                      showWeekNumbers: true,
                      dropdowns: {
                        minYear: 1990,
                        maxYear: null,
                        months: true,
                        years: true,
                      },
                      format: 'MMM DD, YYYY',
                    }}
                    className="rounded-lg pl-9 text-slate-500 sm:w-64"
                  />
                </div>
              </div>
            </div>

            <div className="min-w-full border-none bg-transparent shadow-none">
              <DashboardInterviewsTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { Dashboard };
