import * as Yup from 'yup';

import { Integration, WebhookHeaders } from '@typings';
import { WebhookForm, ZapierForm } from '@modules/integrations/forms';

import AshbyLogo from '@assets/images/integrations/Ashby.svg';
// import CalendlyLogo from '@assets/images/integrations/Calendly.svg';
import GreenhouseLogo from '@assets/images/integrations/Greenhouse.svg';
// import LeverLogo from '@assets/images/integrations/Lever.svg';
import ADPLogo from '@assets/images/integrations/ADP.svg';
import Plug from '@assets/images/Plug.svg';
import ZapierLogo from '@assets/images/integrations/Zapier.svg';

export const AVAILABLE_INTEGRATIONS: Integration[] = [
  // {
  //   logo: CalendlyLogo,
  //   name: 'Calendly',
  //   description:
  //     'Seamlessly schedule and manage interview follow ups with Calendly.',
  //   type: 'API_KEY',
  // },
  // {
  //   logo: LeverLogo,
  //   name: 'Lever',
  //   description:
  //     'Enhance your hiring workflow with Lever, making talent acquisition and management more efficient and effective.',
  //   type: 'O_AUTH',
  // },
  {
    logo: ZapierLogo,
    name: 'Zapier',
    description:
      'Supercharge your hiring process by connecting with Zapier, automating actions across 7,000+ web apps. Set triggers when interviews are completed, top candidates are identified, new jobs are posted, and so much more.',
    maxNum: 1,
    type: 'Zapier',
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
    }),
    integrationForm: ZapierForm,
  },
  {
    icon: 'Webhook',
    iconClassnames: 'w-full h-full max-w-[5rem]',
    name: 'Webhook',
    defaultValues: {
      webhook_config: {
        url: '',
        headers: [],
      },
    },
    description:
      'Unlock the power of real-time data flow in your hiring process. This flexible integration allows you to create custom connections, triggering instant updates and actions across your entire HR tech stack.',
    type: 'Webhook',
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      parameters: Yup.object().shape({
        webhook_config: Yup.object().shape({
          url: Yup.string()
            .required('Webhook URL is required')
            .matches(
              /^(https?:\/\/(?:www\.)?[^\s/$.?#].[^\s]*)?$|^$/,
              'Must be a valid URL',
            ),
          headers: Yup.array().of(Yup.object<WebhookHeaders>()),
        }),
      }),
    }),
    integrationForm: WebhookForm,
  },
  {
    logo: AshbyLogo,
    name: 'Ashby',
    description:
      "Blend Kerplunk's robust hiring features with Ashby's analytical prowess. This integration could allow you to sync candidate data seamlessly, providing deeper insights into your recruitment funnel and enhancing your decision-making process.",
    type: 'Ashby',
    isComingSoon: true,
  },
  {
    logo: ADPLogo,
    name: 'ADP',
    description:
      "Bridge Kerplunk's hiring capabilities with ADP's comprehensive HR suite. Streamline the transition from candidate to employee, automatically feeding new hire information from Kerplunk into ADP's payroll and benefits systems.",
    type: 'ADP',
    isComingSoon: true,
  },
  {
    logo: GreenhouseLogo,
    name: 'Greenhouse',
    description:
      'Amplify your recruitment efforts by connecting Kerplunk with Greenhouse. Leverage the strengths of both platforms, allowing for synchronized job postings, shared candidate profiles, and unified reporting.',
    type: 'Greenhouse',
    isComingSoon: true,
  },
];

export const REQUEST_INTEGRATION: Integration = {
  logo: Plug,
  name: 'Need an Integration?',
  description:
    'Easily connect our platform with your favorite tools! Click the button below to request a new integration.',
  type: 'REQUEST_FOR',
  validationSchema: Yup.object({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Breid description is required'),
  }),
  integrationForm: ZapierForm,
};
