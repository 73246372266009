import '@tensorflow/tfjs-backend-webgl';
import * as bodyPix from '@tensorflow-models/body-pix';
import * as tf from '@tensorflow/tfjs';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { getCameraStream } from '@utilities';
import {
  selectActiveCameraDevice,
  selectActiveCameraStream,
  selectIsCameraPaused,
} from '@store/selectors';
import { setInterviewCameraStream } from '@store/slices';

interface UseTensorFlowBlurredVideoProps {
  isInterviewPaused: boolean;
}

// 1-20
const BG_BLUR_AMOUNT = 6;

// 1-20
const BG_EDGE_BLUR_AMOUNT = 12;

export const useTensorFlowBlurredVideo = ({
  isInterviewPaused,
}: UseTensorFlowBlurredVideoProps) => {
  const cameraStream = useSelector(selectActiveCameraStream);
  const selectedCameraDeviceId = useSelector(selectActiveCameraDevice);
  const isCameraMuted = useSelector(selectIsCameraPaused);

  const [tfModel, setTfModel] = useState<bodyPix.BodyPix | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const initializeBackend = async () => {
      await tf.setBackend('webgl');
      await tf.ready();
    };

    (async () => {
      if (!tfModel) {
        await initializeBackend();
        const loadedModel = await bodyPix.load();
        setTfModel(loadedModel);
      }
    })();
  }, [tfModel]);

  useEffect(() => {
    const setupCamera = async () => {
      if (
        selectedCameraDeviceId &&
        !cameraStream &&
        !isCameraMuted &&
        !isInterviewPaused &&
        tfModel
      ) {
        const compositeStream = new MediaStream();
        const rawCameraStream = await getCameraStream(selectedCameraDeviceId);

        const video = document.createElement('video');
        video.srcObject = rawCameraStream;

        const canvasElement = document.createElement('canvas');

        video.onloadedmetadata = async () => {
          video.play();
          canvasElement.width = video.videoWidth;
          canvasElement.height = video.videoHeight;

          video.width = video.videoWidth;
          video.height = video.videoHeight;

          const ctx = canvasElement.getContext('2d');

          if (ctx && tfModel) {
            const processVideo = async () => {
              if (!canvasElement) return;

              const segmentation = await tfModel.segmentPerson(video);
              const backgroundBlurAmount = BG_BLUR_AMOUNT;
              const edgeBlurAmount = BG_EDGE_BLUR_AMOUNT;
              const flipHorizontal = false;

              bodyPix.drawBokehEffect(
                canvasElement,
                video,
                segmentation,
                backgroundBlurAmount,
                edgeBlurAmount,
                flipHorizontal,
              );

              requestAnimationFrame(processVideo);
            };

            requestAnimationFrame(processVideo);
          }

          const blurredStream = canvasElement.captureStream();
          const blurredVideoTrack = blurredStream.getVideoTracks()[0];
          const rawVideoTrack = rawCameraStream.getVideoTracks()[0];

          // blurred stream must be first in order for react player to properly display
          compositeStream.addTrack(blurredVideoTrack);
          compositeStream.addTrack(rawVideoTrack);

          dispatch(setInterviewCameraStream(compositeStream));
        };
      }
    };

    setupCamera();
  }, [
    cameraStream,
    dispatch,
    isCameraMuted,
    isInterviewPaused,
    selectedCameraDeviceId,
    tfModel,
  ]);
};
