import { PagedResponse } from '@kerplunkai/common-components';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { BASE_URL } from '@constants';
import {
  OrgStatistics,
  OrgStatisticsRequest,
  Organization,
  OrganizationDetails,
  OrganizationRequest,
} from '@typings';
import { RootState } from '@store/store';

export const organizationService = createApi({
  reducerPath: 'organizationService',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/`,
    prepareHeaders: (headers, { getState }) => {
      const { token } = (getState() as RootState).auth;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ['Org'],
  endpoints: builder => ({
    organizations: builder.query<PagedResponse<Organization>, void>({
      query: () => ({
        url: 'organizations',
        method: 'GET',
      }),
      providesTags: result =>
        result
          ? [
              ...result.results.map(({ id }) => ({ type: 'Org' as const, id })),
              'Org',
            ]
          : ['Org'],
    }),
    createOrganization: builder.mutation<Organization, OrganizationRequest>({
      query: organization => {
        const bodyFormData = new FormData();

        Object.keys(organization).forEach(key => {
          if (organization[key] && key !== 'logo')
            bodyFormData.append(
              `organization[${key}]`,
              organization[key] as string | Blob,
            );
        });
        if (organization.logo)
          bodyFormData.append(
            'organization[logo]',
            new File([organization.logo.src], 'test'),
          );

        if (organization.stripe_session_id)
          bodyFormData.append(
            'organization[stripe_session_id]',
            organization.stripe_session_id,
          );

        return {
          url: 'organizations',
          method: 'POST',
          body: bodyFormData,
        };
      },
    }),
    updateOrganization: builder.mutation<Organization, OrganizationRequest>({
      query: ({ organizationId, ...orgData }) => {
        const bodyFormData = new FormData();

        Object.keys(orgData).forEach(key => {
          if (orgData[key] && key !== 'logo')
            bodyFormData.append(
              `organization[${key}]`,
              orgData[key] as string | Blob,
            );
        });

        if (orgData.logo)
          bodyFormData.append(
            'organization[logo]',
            new File([orgData.logo.src], 'test'),
          );

        bodyFormData.append(
          'organization[intro_video_url]',
          orgData.intro_video_url || '',
        );

        return {
          url: `organizations/${organizationId}`,
          method: 'PUT',
          body: bodyFormData,
        };
      },
      invalidatesTags: (_result, _error, { id }) => [{ type: 'Org', id }],
    }),
    organizationsDetails: builder.query<OrganizationDetails, void>({
      query: () => ({
        url: 'organizations/details',
        method: 'GET',
      }),
    }),
    statistics: builder.query<OrgStatistics, OrgStatisticsRequest>({
      query: ({ orgId, startDate, endDate }) => ({
        url: `organizations/${orgId}/statistics?start_date=${startDate}&end_date=${endDate}`,
      }),
    }),
    associateSession: builder.mutation<
      Organization,
      { organizationId: string; stripeSessionId: string }
    >({
      query: ({ organizationId, stripeSessionId }) => ({
        url: `organizations/${organizationId}`,
        method: 'PUT',
        body: { organization: { stripe_session_id: stripeSessionId } },
      }),
      invalidatesTags: (_result, _error, { organizationId }) => [
        { type: 'Org', id: organizationId },
      ],
    }),
  }),
});

export const {
  useOrganizationsQuery,
  useUpdateOrganizationMutation,
  useCreateOrganizationMutation,
  useLazyOrganizationsQuery,
  useOrganizationsDetailsQuery,
  useLazyOrganizationsDetailsQuery,
  useStatisticsQuery,
  useAssociateSessionMutation,
} = organizationService;
