import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { BASE_URL } from '@constants';
import { CreateIntegrationRequest, OrgIntegration } from '@typings';
import { PagedResponse } from '@kerplunkai/common-components';
import { RootState } from '@store/store';

export const integrationService = createApi({
  reducerPath: 'integrationService',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/organizations`,
    prepareHeaders: (headers, { getState }) => {
      const { token } = (getState() as RootState).auth;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ['Integration'],
  endpoints: builder => ({
    orgIntegrations: builder.query<
      PagedResponse<OrgIntegration>,
      { orgId: string }
    >({
      query: ({ orgId }) => ({
        url: `${orgId}/integrations`,
        method: 'GET',
      }),
      providesTags: result => {
        if (result) {
          return [
            ...result.results.map(({ id }) => ({
              type: 'Integration' as const,
              id,
            })),
            { type: 'Integration', id: 'LIST' },
          ];
        }

        return [{ type: 'Integration', id: 'LIST' }];
      },
    }),
    createIntegration: builder.mutation<
      OrgIntegration,
      CreateIntegrationRequest & { organizationId: string }
    >({
      query: ({ organizationId, ...integration }) => ({
        url: `${organizationId}/integrations`,
        method: 'POST',
        body: { integration },
      }),
    }),
    updateIntegration: builder.mutation<
      OrgIntegration,
      CreateIntegrationRequest & {
        organizationId: string;
        integrationId: string;
      }
    >({
      query: ({ organizationId, integrationId, ...integration }) => ({
        url: `${organizationId}/integrations/${integrationId}`,
        method: 'PUT',
        body: { integration },
      }),
      // invalidatesTags: (_result, _error, { id }) => [
      //   { type: 'JobBenefit', id },
      // ],
    }),
    deleteIntegration: builder.mutation<
      null,
      { organizationId: string; integrationId: string }
    >({
      query: ({ organizationId, integrationId }) => ({
        url: `${organizationId}/integrations/${integrationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { integrationId }) => [
        { type: 'Integration', id: integrationId },
      ],
    }),
  }),
});

export const {
  useOrgIntegrationsQuery,
  useCreateIntegrationMutation,
  useDeleteIntegrationMutation,
  useUpdateIntegrationMutation,
} = integrationService;
