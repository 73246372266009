import {
  Button,
  FormSelect,
  LoadingIcon,
  Lucide,
} from '@kerplunkai/common-components';
import { useSelector } from 'react-redux';

import { CAMERA_PERM_NAME, MIC_PERM_NAME } from '@constants';
import {
  getColor,
  getPermissionIcon,
  getPermissionTitleText,
} from '@utilities';
import {
  selectActiveCameraDevice,
  selectActiveMicDevice,
} from '@store/selectors';

interface MediaPermissionSwitcherProps {
  shownPermissionName: typeof MIC_PERM_NAME | typeof CAMERA_PERM_NAME;
  hasVideoPermission?: boolean;
  hasAudioPermission?: boolean;
  handleSelectCam: (deviceId: string) => void;
  handleSelectMic: (deviceId: string) => void;
  isDisableCamSwitcher?: boolean;
  isDisableMicSwitcher?: boolean;
  availableCameraDevices?: MediaDeviceInfo[];
  availableMicDevices?: MediaDeviceInfo[];
  advanceToNextStep: () => void;
}

export function MediaPermissionSwitcher({
  shownPermissionName,
  hasVideoPermission,
  hasAudioPermission,
  handleSelectCam,
  handleSelectMic,
  isDisableCamSwitcher,
  isDisableMicSwitcher,
  availableCameraDevices,
  availableMicDevices,
  advanceToNextStep,
}: MediaPermissionSwitcherProps) {
  const reduxMicDeviceId = useSelector(selectActiveMicDevice);
  const reduxCameraDeviceId = useSelector(selectActiveCameraDevice);

  return (
    <div className="flex justify-center">
      <div className="box box--stacked grow p-3.5 sm:p-5">
        <div className="w-full rounded-[9.6px] border-2 border-solid border-slate-200 p-3.5 pt-4">
          <Lucide
            className="mx-auto size-[42px] text-sky-800"
            icon={getPermissionIcon(shownPermissionName)}
          />
          <div className="text text-center text-2xl text-[#181D2C]">
            {getPermissionTitleText(shownPermissionName)} on
          </div>
          <div className="mx-auto self-center py-5">
            <div className="mx-2 flex">
              {shownPermissionName === CAMERA_PERM_NAME &&
                hasVideoPermission && (
                  <div className="flex grow">
                    <FormSelect
                      className="rounded-full bg-slate-500/20 text-slate-500"
                      value={reduxCameraDeviceId || ''}
                      onChange={e => handleSelectCam(e.target.value)}
                      disabled={isDisableCamSwitcher}
                    >
                      {availableCameraDevices?.map(
                        ({ deviceId: id, label: name }) => (
                          <option key={id} value={id}>
                            {name}
                          </option>
                        ),
                      )}
                    </FormSelect>
                    {isDisableCamSwitcher && (
                      <LoadingIcon
                        icon="oval"
                        className="mx-2 size-6 self-center"
                        color={getColor('primary')}
                      />
                    )}
                  </div>
                )}

              {shownPermissionName === MIC_PERM_NAME && hasAudioPermission && (
                <div className="flex grow">
                  <FormSelect
                    className="rounded-full bg-slate-500/20 text-slate-500"
                    // disabled={isLoading}
                    value={reduxMicDeviceId || ''}
                    onChange={e => handleSelectMic(e.target.value)}
                    disabled={isDisableMicSwitcher}
                  >
                    {availableMicDevices?.map(
                      ({ deviceId: id, label: name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ),
                    )}
                  </FormSelect>
                  {isDisableMicSwitcher && (
                    <LoadingIcon
                      icon="oval"
                      className="mx-2 size-6 self-center"
                      color={getColor('primary')}
                    />
                  )}
                </div>
              )}
            </div>

            {shownPermissionName === MIC_PERM_NAME && !hasAudioPermission && (
              <div className="text px-8 pt-1 text-center text-base">
                Please enable microphone access in your browser to continue.
              </div>
            )}

            {shownPermissionName === CAMERA_PERM_NAME &&
              !hasVideoPermission && (
                <div className="text px-8 pt-1 text-center text-base">
                  Please enable camera access in your browser to continue.
                </div>
              )}
          </div>
          <div className="mx-5 border border-dashed sm:border-2" />

          <div className="mx-auto self-center pt-3">
            <div className="text py-2 text-center text-sm font-medium sm:text-base">
              {shownPermissionName === CAMERA_PERM_NAME
                ? 'You will be prompted to turn on your camera for this one-way interview.'
                : 'Be sure your microphone is on so your answers can be recorded.'}
            </div>
            <Button
              className="m-auto my-4 flex px-7 py-[15px] text-sm"
              variant="medprosPrimary"
              size="lg"
              rounded
              onClick={advanceToNextStep}
              disabled={
                (shownPermissionName === CAMERA_PERM_NAME &&
                  !hasVideoPermission) ||
                (shownPermissionName === MIC_PERM_NAME && !hasAudioPermission)
              }
            >
              Got it <Lucide className="ml-2" icon="ArrowRight" />
            </Button>
            <div className="mt-2 flex w-full items-center justify-center">
              <div
                className={`rounded-full ${
                  shownPermissionName === CAMERA_PERM_NAME
                    ? 'size-2.5 bg-gradient-to-b from-[#0260C6] to-[#3AB7EA]'
                    : 'size-2 bg-slate-400 '
                }`}
              />
              <div
                className={`ml-1.5 rounded-full ${
                  shownPermissionName === MIC_PERM_NAME
                    ? 'size-2.5 bg-gradient-to-b from-[#0260C6] to-[#3AB7EA]'
                    : 'size-2 bg-slate-400'
                }`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
