import { Element } from 'react-scroll';
import {
  // Alert,
  //  Button,
  FormCard,
  // Lucide
} from '@kerplunkai/common-components';

import { JobAddDetailsForm } from '@modules/jobs/jobForms/jobAddDetailsForm.component';
import { JobCompensationForm } from '@modules/jobs/jobForms/jobCompensationForm.component';
import { JobDescriptionForm } from '@modules/jobs/jobForms/jobDescriptionForm.component';
import { JobInfoForm } from '@modules/jobs/jobForms/jobInfoForm.component';
import { JobRequest } from '@typings';

interface JobInfoStepProps {
  isLoading: boolean;
  job: JobRequest;
  orgId: string;
  onChange: (name: string, value: number | boolean | string | string[]) => void;
}

function JobInfoStep({ isLoading, job, orgId, onChange }: JobInfoStepProps) {
  return (
    <>
      <Element name="jobInformation" id="jobInformation">
        <div className="w-full rounded-lg bg-white p-5 shadow ">
          {/* temporrary hidden */}
          {/* <Alert
            variant="outline-warning"
            className="mb-4 flex items-center rounded-lg border border-yellow-500 border-opacity-10 bg-yellow-500 bg-opacity-10 px-4"
          >
            <div className="mr-5 leading-relaxed">
              <div className="flex flex-col  md:flex-row md:items-start md:gap-x-[56px]">
                <div className="mb-4 flex flex-col">
                  <div className="mb-3 flex items-center">
                    <h4 className="mr-1 text-sm font-medium text-slate-600">
                      Not sure where to start?
                    </h4>
                    <Lucide
                      icon="Lightbulb"
                      className="mr-6 h-6 w-6 stroke-yellow-500 "
                    />
                  </div>
                  <p className="w-full text-xs font-normal text-slate-500 opacity-80 md:w-56">
                    Use our custom AI to create a job tailored to your company.
                    It&apos;s not permanent, so no worries!
                  </p>
                </div>
                <Button
                  className="w-fit rounded-md px-4 py-2.5 "
                  variant="primary"
                >
                  <Lucide icon="Wand2" className="mr-2" />
                  Generate Job Information
                </Button>
              </div>
            </div>
          </Alert> */}
          <FormCard
            title="Job Information"
            className="!p-0"
            classes="!border-0 !shadow-none"
          >
            <JobInfoForm
              isLoading={isLoading}
              job={job}
              orgId={orgId as string}
              onChange={onChange}
            />
          </FormCard>
        </div>
      </Element>
      <Element name="jobDescription" id="jobDescription">
        <FormCard title="Job Description">
          <JobDescriptionForm
            isLoading={isLoading}
            job={job}
            onChange={onChange}
          />
        </FormCard>
      </Element>
      <Element name="additionalDetails" id="additionalDetails">
        <FormCard title="Additional Details">
          <JobAddDetailsForm
            isLoading={isLoading}
            job={job}
            orgId={orgId as string}
            onChange={onChange}
          />
        </FormCard>
      </Element>
      <Element name="compensation" id="compensation">
        <FormCard title="Compensation">
          <JobCompensationForm
            isLoading={isLoading}
            job={job}
            orgId={orgId as string}
            onChange={onChange}
          />
        </FormCard>
      </Element>
    </>
  );
}

export { JobInfoStep };
