import clsx from 'clsx';
import { Lucide, Menu } from '@kerplunkai/common-components';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { NAV_ROUTES } from '@constants';
import { Organization } from '@typings';
import { selectOrganizations, selectSelectedOrg } from '@store/selectors';
import { setSelectedOrg } from '@store/slices';
import { useAppDispatch } from '@store/hooks';

function OrganizationMenu() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const organizations = useSelector(selectOrganizations());
  const selectedOrg = useSelector(selectSelectedOrg);

  const sortedOrgs = useMemo((): Organization[] => {
    if (!organizations) return [];

    return [...organizations].sort((orgA, orgB) =>
      orgA.name.localeCompare(orgB.name),
    );
  }, [organizations]);

  if (sortedOrgs.length === 0 || !selectedOrg) return null;

  return (
    <Menu>
      <Menu.Button className="flex min-w-48 items-center justify-between gap-2 rounded-md !bg-slate-100/[0.16] px-[12px] py-[9px] text-white hover:!bg-slate-100/[0.28] hui-open:!bg-white hui-open:text-[#181D2C] md:min-w-64">
        <div className="flex items-center">
          <div className="image-fit size-6 overflow-hidden rounded-full border-[3px] border-white/[0.15]">
            <img alt="Avatar" src={selectedOrg.logo_url} />
          </div>
          <div className="ml-2 text-sm">{selectedOrg.name}</div>
        </div>
        <Lucide
          className="text-slate-400 hui-open:rotate-180"
          icon="ChevronDown"
        />
      </Menu.Button>
      <Menu.Items className="mt-1 min-w-64">
        <Menu.Item onClick={() => navigate(NAV_ROUTES.SETTINGS)}>
          <Lucide icon="Settings" className="mr-2 size-4" />
          Organization Settings
        </Menu.Item>
        <Menu.Divider />
        {sortedOrgs.map(org => (
          <Menu.Item
            key={org.id}
            className={clsx(
              'flex justify-between',
              org.id === selectedOrg.id && 'bg-[#F2F5F8]',
            )}
            onClick={() => dispatch(setSelectedOrg(org))}
          >
            <div className="flex items-center">
              <div className="image-fit size-6 overflow-hidden rounded-full border-[3px] border-white/[0.15]">
                <img alt="Avatar" src={org.logo_url} />
              </div>
              <div className="ml-2 text-sm">{org.name}</div>
            </div>
            {selectedOrg.id === org.id && (
              <Lucide className="text-[#181D2C]" icon="Check" />
            )}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  );
}

export { OrganizationMenu };
