import React, {
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

interface InterviewActionContextProviderProps {
  children: ReactNode;
}

interface InterviewActions {
  closeFunction: (() => void) | null;
  setCloseFunction: (fn: (() => void) | null) => void;
}

const InterviewActionContext = createContext<InterviewActions>({
  closeFunction: null,
  setCloseFunction: () => {},
});

export function InterviewActionContextProvider({
  children,
}: InterviewActionContextProviderProps) {
  const [closeFunction, setCloseFunction] = useState<(() => void) | null>(null);

  const value = useMemo(
    () => ({ closeFunction, setCloseFunction }),
    [closeFunction, setCloseFunction],
  );
  return (
    <InterviewActionContext.Provider value={value}>
      {children}
    </InterviewActionContext.Provider>
  );
}

export const useInterviewActionContext = () => {
  const context = useContext(InterviewActionContext);
  if (!context) {
    throw new Error(
      'useInterviewActionContext must be used within a InterviewActionContextProvider',
    );
  }
  return context;
};
