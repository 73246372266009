import clsx from 'clsx';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { Header } from '@modules/layout/header.component';
import { NAV_ROUTES } from '@constants';
import { SideMenu } from '@modules/layout/sideMenu.component';
import {
  resetUser,
  setInitialRoute,
  setPageLoader,
  setSelectedOrg,
} from '@store/slices';
import {
  selectActiveMobileMenu,
  selectCompactMenu,
  selectCompactMenuOnHover,
  selectCurrentUser,
  selectInitialRoute,
  selectPageLoader,
  selectSelectedOrgId,
} from '@store/selectors';
import { useAppDispatch } from '@store/hooks';
import {
  useLazyGetJobDetailsQuery,
  useLazyOrganizationsDetailsQuery,
  useLazyOrganizationsQuery,
  useLazyProductsQuery,
  useLazySubscriptionsQuery,
  useLazyUserQuery,
} from '@store/services';

function MainLayout() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [getUser, { isError: isUserError }] = useLazyUserQuery();

  const [getOrganizations, { isError: isOrgError }] =
    useLazyOrganizationsQuery();
  const [getJobDetails] = useLazyGetJobDetailsQuery();
  const [getOrganizationDetails] = useLazyOrganizationsDetailsQuery();
  const [getProducts] = useLazyProductsQuery();
  const [getSubscriptions] = useLazySubscriptionsQuery();

  const pageLoader = useSelector(selectPageLoader);
  const compactMenu = useSelector(selectCompactMenu);
  const compactMenuOnHover = useSelector(selectCompactMenuOnHover);
  const activeMobileMenu = useSelector(selectActiveMobileMenu);
  const currentUser = useSelector(selectCurrentUser);
  const selectedOrgId = useSelector(selectSelectedOrgId);
  const initialRoute = useSelector(selectInitialRoute);

  const [topBarActive, setTopBarActive] = useState(false);

  const location = useLocation();

  // on certain routes, we may want to disable the top bar scroll effects
  // define those routes here
  const disableTopBarEffects = useMemo(() => {
    const pathsToDisable = ['/organization'];
    return pathsToDisable.some(path => path === location.pathname);
  }, [location.pathname]);

  window.onscroll = () => {
    if (
      !disableTopBarEffects &&
      (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0)
    ) {
      setTopBarActive(true);
    } else {
      setTopBarActive(false);
    }
  };

  useEffect(() => {
    if (currentUser && initialRoute) {
      navigate(initialRoute);
      dispatch(setInitialRoute(null));
    }
  }, [dispatch, navigate, currentUser, initialRoute]);

  useEffect(() => {
    if (!currentUser) {
      getUser();
    }
  }, [currentUser, getUser]);

  useEffect(() => {
    if (isUserError || isOrgError) {
      dispatch(resetUser());
      dispatch(setPageLoader(false));
    }
  }, [dispatch, isOrgError, isUserError]);

  useEffect(() => {
    if (selectedOrgId) {
      getJobDetails(selectedOrgId);
      getProducts(selectedOrgId);
      getSubscriptions(selectedOrgId);

      dispatch(setPageLoader(false));
    }
  }, [dispatch, getJobDetails, getProducts, getSubscriptions, selectedOrgId]);

  useEffect(() => {
    if (currentUser) {
      (async () => {
        try {
          const prevSelectedOrgId = localStorage.getItem('selectedOrgId');
          const { results } = await getOrganizations().unwrap();

          getOrganizationDetails();

          if (results.length === 0) {
            dispatch(setPageLoader(false));
            navigate(NAV_ROUTES.ORGANIZATION_CREATE);
            return;
          }

          const prevSelectedOrg = results.find(
            ({ id }) => id === prevSelectedOrgId,
          );

          dispatch(setSelectedOrg(prevSelectedOrg || results[0]));
        } catch (e) {
          console.log('ERROR', e);
        }
      })();
    }
  }, [
    dispatch,
    navigate,
    currentUser,
    getOrganizations,
    getOrganizationDetails,
  ]);

  useEffect(() => {
    const stripeSessionId = localStorage.getItem('stripe_session_id');
    if (stripeSessionId) {
      navigate('/associate-stripe-session');
    }
  }, [navigate]);

  return (
    <>
      <Transition
        show={!pageLoader}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className={clsx([
            'echo background group relative min-h-screen bg-gradient-to-b from-slate-200/70 to-slate-50',
            "before:fixed before:top-0 before:h-[370px] before:w-screen before:bg-gradient-to-t before:from-theme-1/80 before:to-theme-2 before:transition-[opacity,height] before:duration-300 before:ease-in-out before:content-[''] [&.background--hidden]:before:opacity-0",
            "after:fixed after:top-0 after:h-[370px] after:w-screen after:bg-texture-white after:bg-contain after:bg-fixed after:bg-[center_-13rem] after:bg-no-repeat after:transition-[opacity,height] after:duration-300 after:ease-in-out after:content-[''] [&.background--hidden]:after:opacity-0",
            topBarActive && 'background--hidden',
          ])}
        >
          {selectedOrgId && (
            <div
              className={clsx([
                'side-menu group fixed inset-y-0 left-0 z-50 shadow-xl transition-[margin,padding] duration-300 xl:ml-0 xl:py-3.5 xl:shadow-none',
                "after:fixed after:inset-0 after:bg-black/80 after:content-[''] after:xl:hidden",
                { 'side-menu--collapsed': compactMenu },
                { 'side-menu--on-hover': compactMenuOnHover },
                { 'ml-0 after:block': activeMobileMenu },
                { '-ml-[275px] after:hidden': !activeMobileMenu },
                { 'xl:pl-3.5': selectedOrgId },
              ])}
            >
              <SideMenu />
              <Header topBarActive={topBarActive} />
            </div>
          )}
          <div
            className={clsx([
              'mode group relative z-10 pb-16 pt-[54px] transition-[margin,width] duration-100',
              { 'xl:ml-[275px]': !compactMenu && selectedOrgId },
              { 'xl:ml-[91px]': compactMenu && selectedOrgId },
              { 'mode--light': !topBarActive },
              { 'xl:pl-3.5': selectedOrgId },
            ])}
          >
            <div className="mt-16 px-5">
              <div className="container">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </Transition>
      <Transition
        show={pageLoader}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <svg
          className="fixed inset-0 m-auto size-10 animate-spin text-theme-1"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      </Transition>
    </>
  );
}

export { MainLayout };
