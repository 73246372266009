import { LanguageSupport } from '@codemirror/language';
import { languages } from '@codemirror/language-data';

export async function getCodeMirrorLanguageSupport(
  language: string,
): Promise<LanguageSupport | null> {
  const languageInfo = languages.find(
    lang => lang.alias.includes(language) || lang.name === language,
  );
  if (languageInfo) {
    return languageInfo.load();
  }
  console.warn(`No language support found for: ${language}`);
  return null;
}
