import {
  CreateIntegrationRequest,
  Integration,
  OrgIntegration,
} from '@typings';

const INITIAL_INTEGRATION_VALUES = {
  name: '',
  type: '',
  active: true,
};

export const getAddIntegrationText = (integration: Integration | null) => {
  if (!integration) return '';

  switch (integration.type) {
    case 'REQUEST_FOR':
      return 'test';

    default:
      return `${integration.name} Integration Token`;
  }
};

export const mapIntegrationToForm = (
  integrationType: Integration,
  integration?: OrgIntegration,
): CreateIntegrationRequest => {
  if (!integration)
    return {
      ...INITIAL_INTEGRATION_VALUES,
      parameters: integrationType.defaultValues
        ? integrationType.defaultValues
        : undefined,
    };

  switch (integrationType.type) {
    default:
      return {
        id: integration.id,
        name: integration.name,
        type: integration.type,
        active: true,
        parameters: integration.parameters || undefined,
      };
  }
};

export const filterIntegrationsByType = (
  integrations: OrgIntegration[],
  type: string,
): OrgIntegration[] => {
  return integrations.filter(
    integration => integration.type.split('::')[1] === type,
  );
};
