import CodeMirror, {
  EditorView,
  Extension,
  ReactCodeMirrorRef,
} from '@uiw/react-codemirror';
import { oneDark } from '@codemirror/theme-one-dark';
import { useEffect, useMemo, useRef, useState } from 'react';

import { CodeBlock } from '@typings';
import { getCodeMirrorLanguageSupport } from '@utilities';

interface CodeMirrorBlockProps {
  block: CodeBlock;
}

function CodeMirrorBlock({ block }: CodeMirrorBlockProps) {
  const codeMirrorRef = useRef<ReactCodeMirrorRef>(null);
  const [extensions, setExtensions] = useState<Extension[]>([]);

  const readOnlyDarkTheme: Extension = useMemo(
    () =>
      EditorView.theme(
        {
          '&': {
            backgroundColor: '#000 !important',
            flexGrow: 1,
            borderRadius: '8px',
            padding: '12px',
            fontSize: '14px',
            textAlign: 'left',
            cursor: 'default',
          },
          '.cm-gutters': {
            display: 'none',
          },
          '.cm-selectionBackground': {
            display: 'none !important',
          },
          '.cm-selectionLayer': {
            backgroundColor: 'transparent !important',
          },
          '.cm-activeLine': {
            backgroundColor: 'transparent !important',
          },
          '.cm-cursor': {
            backgroundColor: 'transparent !important',
          },
          '.cm-cursorLayer': {
            display: 'none !important',
          },
        },
        { dark: true },
      ),
    [],
  );

  useEffect(() => {
    const loadExtensions = async () => {
      const languageSupport = await getCodeMirrorLanguageSupport(
        block.language,
      );

      setExtensions([
        oneDark,
        languageSupport || [],
        readOnlyDarkTheme,
        EditorView.domEventHandlers({
          mousedown: event => {
            event.preventDefault();
            return true;
          },
          contextmenu: event => {
            event.preventDefault();
            return true;
          },
        }),
        EditorView.lineWrapping,
      ]);
    };
    loadExtensions();
  }, [block.language, readOnlyDarkTheme]);

  return (
    <CodeMirror
      ref={codeMirrorRef}
      value={block.content}
      extensions={extensions}
      readOnly
    />
  );
}

export { CodeMirrorBlock };

// optional: specific customizable highlighting colors
// import { HighlightStyle, syntaxHighlighting } from '@codemirror/language';
// import { tags } from '@lezer/highlight';
// and add: syntaxHighlighting(customHighlightStyle) to extensions
// const customHighlightStyle = HighlightStyle.define([
//   { tag: tags.keyword, color: '#C678DD' },
//   { tag: tags.blockComment, color: '#5C6370' },
//   { tag: tags.lineComment, color: '#5C6370' },
//   { tag: tags.bracket, color: '#ABB2BF' },
//   { tag: tags.function(tags.variableName), color: '#61AFEF' },
//   { tag: tags.string, color: '#98C379' },
//   { tag: tags.number, color: '#D19A66' },
//   { tag: tags.operator, color: '#56B6C2' },
//   { tag: tags.variableName, color: '#E06C75' },
//   { tag: tags.typeName, color: '#E5C07B' },
//   { tag: tags.propertyName, color: '#61AFEF' },
//   { tag: tags.className, color: '#E5C07B' },
//   { tag: tags.attributeName, color: '#98C379' },
// ]);
