/* eslint-disable tailwindcss/no-custom-classname */
import {
  Disclosure,
  FormInput,
  FormSwitch,
  Lucide,
} from '@kerplunkai/common-components';
import { JobRequest } from '@typings';
import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';

import { useSelector } from 'react-redux';

import { selectSelectedOrg } from '@store/selectors';

function JobIntroVideoOptionsPanel() {
  const { errors, setFieldValue, values, validateField } =
    useFormikContext<JobRequest>();

  const orgDetails = useSelector(selectSelectedOrg);

  const [isUseDefaultVideo, setIsUseDefaultVideo] = useState(true);
  const [isInitialized, setIsInitialized] = useState(false);

  const [initialVideoUrl, setInitialVideoUrl] = useState(
    orgDetails?.intro_video_url,
  );

  useEffect(() => {
    if (values.intro_video_url) {
      setIsUseDefaultVideo(false);
      setInitialVideoUrl(values.intro_video_url);
    }
    setIsInitialized(true);
  }, [values.intro_video_url]);

  const handleToggleDefaultVideo = async () => {
    if (!isUseDefaultVideo) {
      await setFieldValue('intro_video_url', '');
    } else {
      await setFieldValue('intro_video_url', initialVideoUrl);
    }

    validateField('intro_video_url');
    setIsUseDefaultVideo(!isUseDefaultVideo);
  };

  const handleFormFieldChange = async (value: string) => {
    await setFieldValue('intro_video_url', value);
    validateField('intro_video_url');
  };

  if (!isInitialized) return null;

  return (
    <div className="box mb-4 border-none">
      <Disclosure.Group variant="boxed">
        <Disclosure className="rounded-[0.6rem] border-slate-200">
          <Disclosure.Button>
            <div className="flex items-center border-b border-dashed border-slate-200/60 pb-5 text-[0.94rem] font-medium text-[#181D2C]">
              <Lucide icon="ChevronDown" className="mr-2 size-5 stroke-[1.3]" />
              Introduction Video
            </div>
          </Disclosure.Button>
          <Disclosure.Panel>
            <div className="text mb-2 font-medium">Default or Custom</div>
            <div className="mb-4 text-xs font-normal text-slate-500">
              Applicants will see a welcome video before their interview begins.
              Choose between Kerplunk&apos;s default video or enter a custom
              video URL for a personalized welcome.
            </div>
            <>
              <div className="flex items-center">
                <FormSwitch.Input
                  checked={isUseDefaultVideo}
                  type="checkbox"
                  onChange={handleToggleDefaultVideo}
                />
                <div className="ml-5 text-sm font-medium text-slate-400">
                  Use Kerplunk Default Video
                </div>
              </div>
              {!isUseDefaultVideo && (
                <div className="mt-4">
                  <div className="mb-2 flex">
                    <div className="text font-medium">Custom Video</div>{' '}
                    <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-400 dark:bg-darkmode-300 dark:text-slate-400">
                      Required
                    </div>
                  </div>
                  <div className="mb-6 text-xs font-normal text-slate-500">
                    Personalize your applicant experience by pasting the URL to
                    your welcome video. Youtube works best!
                  </div>
                  <FormInput
                    value={values.intro_video_url || ''}
                    onChange={evt => handleFormFieldChange(evt.target.value)}
                  />
                  {errors.intro_video_url && (
                    <div className="my-2 text-left text-xs text-danger">
                      {errors.intro_video_url}
                    </div>
                  )}
                  <div className="mt-2 text-sm font-normal text-slate-500">
                    Ensure the URL starts with &quot;http://&quot; or
                    &quot;https://&quot;.
                  </div>
                </div>
              )}
            </>
          </Disclosure.Panel>
        </Disclosure>
      </Disclosure.Group>
    </div>
  );
}

export { JobIntroVideoOptionsPanel };
