import * as Yup from 'yup';
import { Formik } from 'formik';

import { INITIAL_JOB } from '@constants';
import { JobInfoStep } from '@modules/organization/jobCreateSteps/jobInfoStep.component';

interface JobCreateStepsProps {
  activeStep: number;
  // isLoading: boolean;
}

function JobCreateSteps({ activeStep }: JobCreateStepsProps) {
  return (
    <Formik
      initialValues={INITIAL_JOB}
      validationSchema={Yup.object({
        title: Yup.string()
          .max(70, 'Must be 70 characters or less')
          .required('Job Title is required'),
      })}
      onSubmit={() => {}}
    >
      {activeStep === 1 && <JobInfoStep />}
    </Formik>
  );
}

export { JobCreateSteps };
