import { useSelector } from 'react-redux';

import { selectOrganizationIndustries } from '@store/selectors';

export const useIndustryOptions = () => {
  const industries = useSelector(selectOrganizationIndustries());

  return (industries || []).map(({ id, name }) => ({
    name,
    value: id,
  }));
};
