export const parseJSON = (strToParse: string) => {
  try {
    return JSON.parse(strToParse);
  } catch (e) {
    return false;
  }
};

export const formatLocation = ({
  city,
  country,
  state,
}: {
  city?: string;
  country?: string;
  state?: string;
}) => {
  let str = '';

  if (city) str = city;
  if (state) str += str ? `, ${state}` : state;
  if (country) str += str ? `, ${country}` : country;

  return str;
};
