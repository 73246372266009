import {
  PagedFilterSortRequest,
  PagedResponse,
  generateQueryParams,
} from '@kerplunkai/common-components';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { BASE_URL } from '@constants';
import {
  GenDescriptionResponse,
  GenQuestionsResponse,
  Interview,
  Job,
  JobDetails,
  JobRequest,
  OrgJobRequest,
} from '@typings';
import { RootState } from '@store/store';

type JobIntPageFilterSortRequest = PagedFilterSortRequest & {
  jobId: string;
};

export const jobService = createApi({
  reducerPath: 'jobService',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/organizations`,
    prepareHeaders: (headers, { getState }) => {
      const { token } = (getState() as RootState).auth;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ['Job'],
  endpoints: builder => ({
    getOrgJobs: builder.query<PagedResponse<Job>, PagedFilterSortRequest>({
      query: ({ orgId, pagination, filters = '' }) => ({
        url: `${orgId}/job_listings${generateQueryParams({ pagination, filters })}`,
        method: 'GET',
      }),
      providesTags: result =>
        result
          ? [
              ...result.results.map(({ id }) => ({ type: 'Job' as const, id })),
              'Job',
            ]
          : ['Job'],
    }),
    getOrgJob: builder.query<Job, OrgJobRequest>({
      query: ({ jobId, organizationId }) => ({
        url: `${organizationId}/job_listings/${jobId}`,
        method: 'GET',
      }),
    }),
    createJob: builder.mutation<Job, JobRequest>({
      query: job => ({
        url: `${job.organization_id}/job_listings`,
        method: 'POST',
        body: { job },
      }),
      invalidatesTags: ['Job'],
    }),
    updateJob: builder.mutation<Job, JobRequest>({
      query: job => ({
        url: `${job.organization_id}/job_listings/${job.id}`,
        method: 'PUT',
        body: { job },
      }),
      invalidatesTags: ['Job'],
    }),
    deleteJob: builder.mutation<void, OrgJobRequest>({
      query: ({ organizationId, jobId }) => ({
        url: `${organizationId}/job_listings/${jobId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Job'],
    }),
    getJobDetails: builder.query<JobDetails, string>({
      query: organizationId => ({
        url: `${organizationId}/job_details`,
      }),
    }),
    generateDraftJobQuestions: builder.mutation<
      GenQuestionsResponse,
      OrgJobRequest
    >({
      query: ({ organizationId, jobId }) => ({
        url: `${organizationId}/job_listings/${jobId}/questions`,
        method: 'PUT',
      }),
    }),
    generatePublishJobQuestions: builder.mutation<
      GenQuestionsResponse,
      JobRequest
    >({
      query: job => ({
        url: `${job.organization_id}/job_listings/questions`,
        method: 'POST',
        body: { job },
      }),
    }),
    getJobInterviews: builder.query<
      PagedResponse<Interview>,
      JobIntPageFilterSortRequest
    >({
      query: ({ orgId, jobId, pagination, filters = '' }) => ({
        url: `${orgId}/job_listings/${jobId}/interviews${generateQueryParams({ pagination, filters })}`,
        method: 'GET',
      }),
    }),
    generateJobDescription: builder.query<GenDescriptionResponse, JobRequest>({
      query: job => ({
        url: `${job.organization_id}/job_listings/description`,
        method: 'POST',
        body: { job },
      }),
    }),
    duplicateJob: builder.mutation<Job, OrgJobRequest>({
      query: ({ organizationId, jobId }) => ({
        url: `${organizationId}/job_listings/${jobId}/duplicate`,
        method: 'POST',
      }),
      invalidatesTags: ['Job'],
    }),
  }),
});

export const {
  useGetOrgJobsQuery,
  useLazyGetOrgJobsQuery,
  useCreateJobMutation,
  useLazyGetJobDetailsQuery,
  useLazyGetOrgJobQuery,
  useUpdateJobMutation,
  useGenerateDraftJobQuestionsMutation,
  useGeneratePublishJobQuestionsMutation,
  useDeleteJobMutation,
  useGetJobInterviewsQuery,
  useLazyGetJobInterviewsQuery,
  useLazyGenerateJobDescriptionQuery,
  useDuplicateJobMutation,
} = jobService;
