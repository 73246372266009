import { Breadcrumb } from '@kerplunkai/common-components';
import { Link, Navigate, Outlet, RouteObject } from 'react-router-dom';

import {
  AcceptInviation,
  ForgotPassword,
  Login,
  ResetPassword,
  SignUp,
} from '@modules/auth';
import { AssociateStripeSession } from '@modules/stripe/associateSession.component';
import { Benefit, Benefits } from '@modules/benefits';
import {
  BenefitBreadcrumb,
  CategoryBreadcrumb,
  ClientBreadcrumb,
  JobBreadcrumb,
  SubcategoryBreadcrumb,
} from '@components';
import {
  Categories,
  Category,
  Subcategories,
  Subcategory,
} from '@modules/categories';
import { Client, Clients, ClientsLayout } from '@modules/clients';
import { Dashboard } from '@modules/dashboard/dashboard.container';
import { Integrations } from '@modules/integrations';
import {
  InterviewDetails,
  InterviewViewerDetails,
} from '@modules/interviewDetails';
import {
  InterviewEnd,
  InterviewLayout,
  InterviewQuestions,
  InterviewSetup,
} from '@modules/conference';
import { Interviews } from '@modules/interviews';
import { JobApplications } from '@modules/applications';
import { JobDetails, Jobs, JobsLayout } from '@modules/jobs';
import { MainLayout } from '@modules/layout/mainLayout.container';
import { Meeting, Meetings } from '@modules/meetings';
import { MeetingBreadcrumb } from '@components/breadcrumbs/meetingBreadcrumb.component';
import { NAV_ROUTES } from '@constants';
import { OrgUsers } from '@modules/orgUser';
import {
  OrganizationCreate,
  OrganizationSettingsLayout,
} from '@modules/organization';
import {
  OrganizationDetails,
  OrganizationSubscription,
} from '@modules/organization/organizationSettings';
import { ProfileInfo, ProfileLayout } from '@modules/profile';

// routes if user is unauthenticated
const publicRoutes: RouteObject[] = [
  {
    path: NAV_ROUTES.LOGIN,
    element: <Login />,
  },
  {
    path: NAV_ROUTES.SIGNUP,
    element: <SignUp />,
  },
  {
    path: NAV_ROUTES.FORGOT_PASSWORD,
    element: <ForgotPassword />,
  },
  {
    path: NAV_ROUTES.RESET_PASSWORD,
    element: <ResetPassword />,
  },
  {
    path: NAV_ROUTES.VIEWER,
    element: <InterviewViewerDetails />,
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      // unauthenticated pages that use the main layout can be routed here above the '*' match, if desired
      {
        path: '*',
        element: <Navigate to={NAV_ROUTES.LOGIN} />,
      },
    ],
  },
  {
    path: NAV_ROUTES.INTERVIEW_SHARE_ACCESS,
    element: <InterviewViewerDetails />,
  },
];

const privateRoutes: RouteObject[] = [
  {
    path: '/',
    element: <MainLayout />,
    handle: {
      crumb: () => (
        <Breadcrumb.Link>
          <Link to="/"> Home</Link>
        </Breadcrumb.Link>
      ),
    },
    children: [
      {
        path: '/',
        element: <Dashboard />,
      },
      {
        path: NAV_ROUTES.JOBS,
        element: <JobsLayout />,
        handle: {
          crumb: () => (
            <Breadcrumb.Link>
              <Link to={NAV_ROUTES.JOBS}>All Jobs</Link>
            </Breadcrumb.Link>
          ),
        },
        children: [
          {
            index: true,
            element: <Jobs />,
          },
          {
            path: NAV_ROUTES.JOB,
            element: <Outlet />,
            loader: ({ params }) => {
              return params.jobId ? params.jobId : '';
            },
            handle: {
              crumb: (jobId: string) => <JobBreadcrumb jobId={jobId} />,
            },
            children: [
              {
                index: true,
                element: <JobDetails />,
              },
              {
                path: 'applications',
                element: <JobApplications />,
                loader: ({ params }) => {
                  return params.jobId ? params.jobId : '';
                },
                handle: {
                  crumb: (jobId: string) => (
                    <Breadcrumb.Link>
                      <Link to={`/jobs/${jobId}/applications`}>
                        Applications
                      </Link>
                    </Breadcrumb.Link>
                  ),
                },
              },
            ],
          },
        ],
      },
      {
        path: NAV_ROUTES.RECENT_INTERVIEWS,
        element: <Interviews />,
        handle: {
          crumb: () => (
            <Breadcrumb.Link>
              <Link to={`${NAV_ROUTES.RECENT_INTERVIEWS}`}>
                Recent Interviews
              </Link>
            </Breadcrumb.Link>
          ),
        },
      },
      {
        path: NAV_ROUTES.INTEGRATIONS,
        element: <Integrations />,
        handle: {
          crumb: () => (
            <Breadcrumb.Link>
              <Link to={`${NAV_ROUTES.INTEGRATIONS}`}>Integrations</Link>
            </Breadcrumb.Link>
          ),
        },
      },
      {
        path: NAV_ROUTES.CATEGORIES,
        element: <Outlet />,
        handle: {
          crumb: () => (
            <Breadcrumb.Link>
              <Link to={`${NAV_ROUTES.CATEGORIES}`}>All Categories</Link>
            </Breadcrumb.Link>
          ),
        },
        children: [
          {
            index: true,
            element: <Categories />,
          },
          {
            path: NAV_ROUTES.CATEGORY,
            element: <Category />,
            loader: ({ params }) => {
              return params.categoryId ? params.categoryId : '';
            },
            handle: {
              crumb: (categoryId: string) => (
                <CategoryBreadcrumb categoryId={categoryId} />
              ),
            },
          },
        ],
      },
      {
        path: NAV_ROUTES.SUBCATEGORIES,
        element: <Outlet />,
        handle: {
          crumb: () => (
            <Breadcrumb.Link>
              <Link to={`${NAV_ROUTES.SUBCATEGORIES}`}>All Subcategories</Link>
            </Breadcrumb.Link>
          ),
        },
        children: [
          {
            index: true,
            element: <Subcategories />,
          },
          {
            path: NAV_ROUTES.SUBCATEGORY,
            element: <Subcategory />,
            loader: ({ params }) => {
              return params.subcategoryId ? params.subcategoryId : '';
            },
            handle: {
              crumb: (subcategoryId: string) => (
                <SubcategoryBreadcrumb subcategoryId={subcategoryId} />
              ),
            },
          },
        ],
      },
      {
        path: NAV_ROUTES.INTERVIEW_DETAILS,
        element: <InterviewDetails />,
      },
      {
        path: NAV_ROUTES.BENEFITS,
        element: <Outlet />,
        handle: {
          crumb: () => (
            <Breadcrumb.Link>
              <Link to={`${NAV_ROUTES.BENEFITS}`}>All Benefits</Link>
            </Breadcrumb.Link>
          ),
        },
        children: [
          {
            index: true,
            element: <Benefits />,
          },
          {
            path: NAV_ROUTES.BENEFIT,
            element: <Benefit />,
            loader: ({ params }) => {
              return params.benefitId ? params.benefitId : '';
            },
            handle: {
              crumb: (benefitId: string) => (
                <BenefitBreadcrumb benefitId={benefitId} />
              ),
            },
          },
        ],
      },
      {
        path: NAV_ROUTES.ORGANIZATION_CREATE,
        element: <OrganizationCreate />,
      },
      {
        path: NAV_ROUTES.CLIENTS,
        element: <ClientsLayout />,
        handle: {
          crumb: () => (
            <Breadcrumb.Link>
              <Link to={`${NAV_ROUTES.CLIENTS}`}>All Clients</Link>
            </Breadcrumb.Link>
          ),
        },
        children: [
          {
            path: '',
            element: <Clients />,
          },
          {
            path: NAV_ROUTES.CLIENT,
            element: <Client />,
            loader: ({ params }) => {
              return params.clientId ? params.clientId : '';
            },
            handle: {
              crumb: (clientId: string) => (
                <ClientBreadcrumb clientId={clientId} />
              ),
            },
          },
        ],
      },
      {
        path: NAV_ROUTES.ORGANIZATION_USERS,
        element: <OrgUsers />,
        handle: {
          crumb: () => (
            <Breadcrumb.Link>
              <Link to={`${NAV_ROUTES.ORGANIZATION_USERS}`}>Users</Link>
            </Breadcrumb.Link>
          ),
        },
      },
      {
        path: NAV_ROUTES.ACCEPT_INVITE,
        element: <AcceptInviation />,
      },
      {
        path: NAV_ROUTES.SETTINGS,
        element: <OrganizationSettingsLayout />,
        handle: {
          crumb: () => (
            <Breadcrumb.Link>
              <Link to={`${NAV_ROUTES.SETTINGS}`}>Organization Settings</Link>
            </Breadcrumb.Link>
          ),
        },
        children: [
          {
            path: '',
            element: <OrganizationDetails />,
          },
          {
            path: NAV_ROUTES.SUBSCRIPTION,
            element: <OrganizationSubscription />,
            handle: {
              crumb: () => (
                <Breadcrumb.Link>
                  <Link to={`${NAV_ROUTES.SUBSCRIPTION}`}>Subscription</Link>
                </Breadcrumb.Link>
              ),
            },
          },
        ],
      },
      {
        path: NAV_ROUTES.PROFILE,
        element: <ProfileLayout />,
        handle: {
          crumb: () => (
            <Breadcrumb.Link>
              <Link to={`${NAV_ROUTES.PROFILE}`}>Profile Info</Link>
            </Breadcrumb.Link>
          ),
        },
        children: [
          {
            path: '',
            element: <ProfileInfo />,
          },
        ],
      },
      {
        path: NAV_ROUTES.ASSOCIATE_SESSION,
        element: <AssociateStripeSession />,
      },
      {
        path: NAV_ROUTES.MEETINGS,
        element: <Outlet />,
        handle: {
          crumb: () => (
            <Breadcrumb.Link>
              <Link to={`${NAV_ROUTES.MEETINGS}`}>All Meetings</Link>
            </Breadcrumb.Link>
          ),
        },
        children: [
          {
            index: true,
            element: <Meetings />,
          },
          {
            path: NAV_ROUTES.MEETING,
            element: <Meeting />,
            loader: ({ params }) => {
              return params.interviewId ? params.interviewId : '';
            },
            handle: {
              crumb: (interviewId: string) => (
                <MeetingBreadcrumb interviewId={interviewId} />
              ),
            },
          },
        ],
      },
      {
        path: NAV_ROUTES.SEARCH,
        element: <Interviews title="Candidate Search" allCandidates />,
        handle: {
          crumb: () => (
            <Breadcrumb.Link>
              <Link to={`${NAV_ROUTES.RECENT_INTERVIEWS}`}>
                Candidate Search
              </Link>
            </Breadcrumb.Link>
          ),
        },
      },
    ],
  },
  {
    path: '/conference',
    element: <InterviewLayout />,
    children: [
      {
        path: NAV_ROUTES.CONFERENCE_SETUP,
        element: <InterviewSetup />,
      },
      {
        path: NAV_ROUTES.CONFERENCE_QUESTIONS,
        element: <InterviewQuestions />,
      },
      {
        path: NAV_ROUTES.CONFERENCE_END,
        element: <InterviewEnd />,
      },
    ],
  },
  // send user to main layout if they hit a bad route
  {
    path: '*',
    element: <Navigate to="/" />,
  },
];

const getRoutes = (isAuthenticated: boolean): RouteObject[] => {
  return isAuthenticated ? privateRoutes : publicRoutes;
};

export { getRoutes };
