import CodeMirror, {
  EditorView,
  Extension,
  ReactCodeMirrorRef,
} from '@uiw/react-codemirror';
import { oneDark } from '@codemirror/theme-one-dark';
import { useEffect, useMemo, useRef, useState } from 'react';

import { CodeBlock } from '@typings';
import { getCodeMirrorLanguageSupport } from '@utilities';

interface CodeMirrorBlockProps {
  block: CodeBlock;
  editable?: boolean;

  onChange?: (value: string) => void;
}

function CodeMirrorBlock({
  block,
  editable = true,
  onChange,
}: CodeMirrorBlockProps) {
  const codeMirrorRef = useRef<ReactCodeMirrorRef>(null);
  const [extensions, setExtensions] = useState<Extension[]>([
    EditorView.lineWrapping,
  ]);

  const readOnlyDarkTheme: Extension = useMemo(
    () =>
      EditorView.theme(
        {
          '&': {
            backgroundColor: '#000 !important',
            flexGrow: 1,
            borderRadius: '8px',
            padding: '12px',
            fontSize: '14px',
            textAlign: 'left',
            cursor: 'default',
          },
          '.cm-gutters': {
            display: 'none',
          },
          ...(!editable
            ? {
                '.cm-selectionBackground': {
                  display: 'none !important',
                },
                '.cm-selectionLayer': {
                  backgroundColor: 'transparent !important',
                },
                '.cm-activeLine': {
                  backgroundColor: 'transparent !important',
                },
                '.cm-cursor': {
                  backgroundColor: 'transparent !important',
                },
                '.cm-cursorLayer': {
                  display: 'none !important',
                },
              }
            : {}),
        },
        { dark: true },
      ),
    [editable],
  );

  useEffect(() => {
    const loadExtensions = async () => {
      const languageSupport = await getCodeMirrorLanguageSupport(
        block.language,
      );

      setExtensions([
        EditorView.lineWrapping,
        oneDark,
        languageSupport || [],
        readOnlyDarkTheme,
      ]);
    };

    loadExtensions();
  }, [block.language, readOnlyDarkTheme]);

  return (
    <CodeMirror
      ref={codeMirrorRef}
      extensions={extensions}
      theme="dark"
      editable={editable}
      value={block.content}
      onChange={onChange}
    />
  );
}

export { CodeMirrorBlock };
