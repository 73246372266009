/* eslint-disable jsx-a11y/label-has-associated-control */
import * as Yup from 'yup';
import {
  AvatarUpload,
  BlobImage,
  FormActions,
  FormCard,
  FormControl,
  FormInput,
  FormInputControl,
  isValidFileType,
} from '@kerplunkai/common-components';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { UserUpdateRequest } from '@typings';
import { selectCurrentUser } from '@store/selectors';
import { useUpdateUserMutation } from '@store/services';

const FILE_SIZE = 5; // 1024 * 1024 * 5

function ProfileInfo() {
  const { enqueueSnackbar } = useSnackbar();

  const [
    updateUser,
    { error: userUpdateError, isSuccess: userUpdateSuccess, isLoading },
  ] = useUpdateUserMutation();

  const currentUser = useSelector(selectCurrentUser);

  const initialFormValues = useMemo(() => {
    return {
      first_name: currentUser?.first_name,
      last_name: currentUser?.last_name,
      avatar: undefined,
      avatar_url: currentUser?.avatar_url,
    };
  }, [
    currentUser?.first_name,
    currentUser?.last_name,
    currentUser?.avatar_url,
  ]);

  Yup.mixed()
    .test(
      'is-valid-type',
      'Not a valid image type',
      (value?: { name?: string }) => {
        if (!value || !value.name) return false;
        return isValidFileType(value.name.toLowerCase(), 'image');
      },
    )
    .test('fileSize', 'File size is too large', (value?: unknown) => {
      if (!value) return false;

      const { size } = (value as BlobImage).src;

      return size / 1024 ** 2 <= FILE_SIZE;
    });

  const {
    setFieldValue,
    validateForm,
    submitForm,
    errors: formErrors,
    isValid,
    dirty,
    values,
  } = useFormik({
    enableReinitialize: true,
    initialValues: initialFormValues,
    validationSchema: Yup.object({
      first_name: Yup.string()
        .max(35, 'Must be 35 characters or less')
        .required('First Name is required'),
      last_name: Yup.string()
        .max(35, 'Must be 35 characters or less')
        .required('Last Name is required'),
      avatar: Yup.mixed()
        .when('avatar_url', {
          is: (avatar_url: string) => Boolean(avatar_url),
          then: schema => schema,
        })
        .test('is-valid-type', 'Not a valid image type', (value?: unknown) => {
          if (!value || !(value as BlobImage).name) return false;

          return isValidFileType(
            (value as BlobImage).name.toLowerCase(),
            'image',
          );
        })
        .test('fileSize', 'File size is too large', (value?: unknown) => {
          if (!value) return false;

          const { size } = (value as BlobImage).src;
          return size / 1024 ** 2 <= FILE_SIZE;
        }),
    }),
    onSubmit: (userInfo: UserUpdateRequest) => {
      updateUser(userInfo);
    },
  });

  useEffect(() => {
    if (userUpdateError) {
      enqueueSnackbar({
        message:
          'There was a problem updating your profile. Please try again later.',
        variant: 'error',
      });
    }

    if (userUpdateSuccess) {
      enqueueSnackbar({
        message: 'Profile updated successfully!',
        variant: 'success',
      });
    }
  }, [enqueueSnackbar, userUpdateError, userUpdateSuccess]);

  const handleFieldChange = useCallback(
    (name: string, value: string) => {
      setFieldValue(name, value);
    },
    [setFieldValue],
  );

  const handleSave = useCallback(() => {
    const validateAndSave = async () => {
      const errors = await validateForm();
      if (!isEmpty(errors)) return;
      submitForm();
    };
    validateAndSave();
  }, [validateForm, submitForm]);

  return (
    <div className="relative col-span-12 flex flex-col gap-y-7 lg:col-span-9 xl:col-span-8">
      <FormCard className="border-0 p-0" hideAccordion title="Profile Info">
        <FormControl
          className="mt-5"
          description="Upload an image file for your avatar. Recommended formats are .png or .jpg for best quality."
          label="Avatar"
        >
          <AvatarUpload
            img={values.avatar?.src}
            imageUrl={currentUser?.avatar_url}
            showRemoveButton={false}
            shouldCrop
            onChange={(avatarBlob: BlobImage | null) => {
              setFieldValue('avatar_url', '');
              setFieldValue('avatar', avatarBlob);
            }}
          />
          {formErrors.avatar && (
            <div className="mt-2 text-xs text-danger">{formErrors.avatar}</div>
          )}
        </FormControl>
        <FormControl
          className="mt-5"
          isRequired
          childrenClassName="grid grid-cols-4 gap-3"
          description="Enter your full legal name as it appears on your official
          identification."
          label="Full Name"
        >
          <div className="col-span-2">
            <FormInput
              name="first_name"
              type="text"
              placeholder="First Name"
              value={values.first_name}
              onChange={e => handleFieldChange('first_name', e.target.value)}
              error={Boolean(formErrors?.first_name)}
            />
            {formErrors.first_name && (
              <div className="absolute mt-2 text-xs text-danger">
                {formErrors.first_name}
              </div>
            )}
          </div>
          <div className="col-span-2">
            <FormInput
              name="last_name"
              type="text"
              placeholder="Last Name"
              value={values.last_name}
              onChange={e => handleFieldChange('last_name', e.target.value)}
              error={Boolean(formErrors?.last_name)}
            />
            {formErrors.last_name && (
              <div className="absolute mt-2 text-xs text-danger">
                {formErrors.last_name}
              </div>
            )}
          </div>
        </FormControl>
        <FormInputControl
          className="mt-5"
          description="This is the e-mail address associated with your account."
          inputProps={{ readOnly: true }}
          label="Email"
          name="email"
          value={currentUser?.email || ''}
        />
      </FormCard>
      <FormActions
        submit={{
          disabled: isLoading || !isValid || !dirty,
          icon: 'Save',
          text: isLoading ? 'Saving' : 'Save',
          onClick: handleSave,
        }}
      />
    </div>
  );
}

export { ProfileInfo };
