/* eslint-disable @typescript-eslint/naming-convention */
import clsx from 'clsx';
import spacetime from 'spacetime';
import {
  DEFAULT_PAGE_INFO,
  Lucide,
  TableFilterPredicatesEnum,
  TableRefetchFn,
  TanstackTable,
  TanstackTableColumnDef,
  Tippy,
} from '@kerplunkai/common-components';
import {
  Row,
  getCoreRowModel,
  getExpandedRowModel,
} from '@tanstack/react-table';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { ApplicationStatus, Interview } from '@typings';
import { InterviewScoringHoverBars } from '@components';
import { InterviewSummary } from '@modules/interviews';
import {
  getApplicationStatusColor,
  getApplicationStatusIcon,
  getIsReadyForReview,
  getScoringCategoryStyles,
  getScoringDetails,
} from '@utilities';
import { selectSelectedOrgId } from '@store/selectors';
import { useLazyOrgInterviewsQuery } from '@store/services';

import defaultAvatar from '@assets/images/no-pic@2x.png';
import { SocialLinkIconButtons } from '@modules/interviews/components';

function DashboardInterviewsTable() {
  const tableColumns: TanstackTableColumnDef<Interview>[] = [
    {
      accessorKey: 'avatar',
      header: 'avatar',
      cell: ({ row }) => (
        <div className="m-auto flex w-10 flex-col gap-1">
          <img
            alt="avatar"
            className="!h-9 !w-9 rounded-full"
            src={
              row.original.candidate_avatar_url.includes('gravatar') // TODO: maybe don't send gravatars to org site at all?
                ? defaultAvatar
                : row.original.candidate_avatar_url
            }
          />
        </div>
      ),
      getClassNames: (row: Row<Interview>) =>
        row.getIsExpanded()
          ? 'border-b-0 bg-white py-4 first:rounded-tl-lg first:rounded-bl-lg'
          : 'border-dashed bg-white py-4 first:rounded-tl-lg first:rounded-bl-lg',
    },
    {
      accessorKey: 'candidate_name',
      header: 'Candidate Name',
      cell: ({ getValue, row }) => {
        return (
          <div className="flex flex-col gap-1">
            <p className="text-xs text-slate-400">Candidate Name</p>
            <div className="flex items-center text-sm text-sky-800">
              <div>{getValue<string>()}</div>
              <SocialLinkIconButtons interview={row.original} />
            </div>
          </div>
        );
      },
      getClassNames: (row: Row<Interview>) =>
        row.getIsExpanded()
          ? 'border-b-0 bg-white py-4 first:rounded-lg last:rounded-lg'
          : 'border-dashed bg-white py-4 first:rounded-lg last:rounded-lg',
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: ({ getValue, row }) => {
        const status = getValue<keyof typeof ApplicationStatus>();
        const isReadyForReview = getIsReadyForReview(row.original);

        return (
          <div className="flex flex-col gap-1">
            <p className="text-xs text-slate-400">Status</p>
            <div
              className={clsx(
                'flex items-center gap-1 text-sm',
                `text-${
                  isReadyForReview
                    ? '[#558408]'
                    : getApplicationStatusColor(status)
                }`,
              )}
            >
              <Lucide
                icon={getApplicationStatusIcon(status)}
                className="size-3.5 stroke-[1.7]"
              />
              <div className="ml-1.5 whitespace-nowrap">
                {isReadyForReview
                  ? 'Ready for Review'
                  : ApplicationStatus[status] || 'In Progress'}
              </div>
            </div>
          </div>
        );
      },
      getClassNames: (row: Row<Interview>) =>
        row.getIsExpanded()
          ? 'border-b-0 bg-white py-4 first:rounded-lg last:rounded-lg'
          : 'border-dashed bg-white py-4 first:rounded-lg last:rounded-lg',
    },
    {
      accessorKey: 'job_title',
      header: 'Job Title',
      cell: ({ getValue, row }) => (
        <div className="flex w-[190px] flex-col gap-1">
          <p className="one-line-ellipsis text-sm  font-medium">
            {getValue<string>()}
          </p>
          <p className="one-line-ellipsis text-xs text-slate-400">
            {row.original.organization}
          </p>
        </div>
      ),
      getClassNames: (row: Row<Interview>) =>
        row.getIsExpanded()
          ? 'border-b-0 bg-white py-4 first:rounded-lg last:rounded-lg'
          : 'border-dashed bg-white py-4 first:rounded-lg last:rounded-lg',
    },
    {
      accessorKey: 'category',
      header: 'Category',
      cell: ({ row }) => {
        const styles = getScoringCategoryStyles(row.original);

        return styles.labelText ? (
          <div
            className={`inline-flex items-center justify-center gap-0.5 rounded border px-2 py-0.5 text-xs ${styles.background} ${styles.border} ${styles.text}`}
          >
            {styles.labelText}
          </div>
        ) : (
          <Tippy content="The user has not uploaded a resume or completed a video interview.">
            <div className="inline-flex w-14 items-center justify-center gap-0.5 rounded border border-[#558408]/10 bg-[#558408]/10 px-2 py-0.5 text-xs text-[#558408]">
              -
            </div>
          </Tippy>
        );
      },
      getClassNames: (row: Row<Interview>) =>
        row.getIsExpanded()
          ? 'border-b-0 bg-white py-4 first:rounded-lg last:rounded-lg'
          : 'border-dashed bg-white py-4 first:rounded-lg last:rounded-lg',
    },
    {
      header: 'AI Score',
      cell: ({ row }) => {
        const scoringDetails = getScoringDetails(row.original);
        return (
          <div
            className={clsx(
              'flex flex-col gap-2.5 ',
              !scoringDetails && 'opacity-20',
            )}
          >
            <p className="text-xs text-slate-400">
              ✨&nbsp;AI Score&nbsp;(
              {scoringDetails ? row.original.ai_score.toFixed(0) : '-'}%)
            </p>
            <div className="flex h-2.5 w-full">
              <InterviewScoringHoverBars scoringDetails={scoringDetails} />
            </div>
          </div>
        );
      },
      getClassNames: (row: Row<Interview>) =>
        row.getIsExpanded()
          ? 'border-b-0 bg-white py-4 first:rounded-lg last:rounded-lg'
          : 'border-dashed bg-white py-4 first:rounded-lg last:rounded-lg',
    },
    {
      accessorKey: 'created_at',
      header: 'Date',
      cell: ({ getValue }) => {
        const dt = spacetime(getValue<Date>());
        return (
          <div className="flex flex-col gap-1">
            <p className="text-xs text-slate-400">Date</p>
            <p className="text-sm text-slate-600">
              {dt.format('{month} {date}, {year}')}
            </p>
          </div>
        );
      },
      getClassNames: (row: Row<Interview>) =>
        row.getIsExpanded()
          ? 'border-b-0 bg-white py-4 rounded-tr-lg rounded-br-lg'
          : 'border-dashed bg-white py-4 rounded-tr-lg rounded-br-lg',
    },
  ];
  const orgId = useSelector(selectSelectedOrgId);

  const [getInterviews, { data: interviewsData, isFetching }] =
    useLazyOrgInterviewsQuery();

  const handleRefetch: TableRefetchFn = useCallback(
    async (pagination, { filters, sorting }) => {
      let filterQueryParms = '';

      if (sorting === 'id ASC')
        filterQueryParms = 'q[s][]=status%20asc&q[s][]=created_at%20desc';
      else filterQueryParms = `q[s]=${sorting}`;

      Object.keys(filters || {}).forEach(filter => {
        filterQueryParms += `${filterQueryParms !== '' ? '&' : ''}q[${filter}_${TableFilterPredicatesEnum.eq}]=${(filters || {})[filter]}`;
      });

      await getInterviews({
        orgId: orgId as string,
        pagination,
        filters: filterQueryParms,
      });
    },
    [getInterviews, orgId],
  );

  return (
    <TanstackTable
      emptyText="No Interviews(s) Found"
      expansionRow={(row: Row<Interview>) => (
        <InterviewSummary rowInterview={row} />
      )}
      isFetching={isFetching}
      pageInfo={interviewsData ? interviewsData.meta : DEFAULT_PAGE_INFO}
      showHeader={false}
      tableBaseClassName="border-separate border-spacing-y-[10px]"
      tableOptions={{
        data: interviewsData ? interviewsData.results : [],
        columns: tableColumns,
        getCoreRowModel: getCoreRowModel(),
        getRowId: row => row.id,
        getRowCanExpand: () => true,
        getExpandedRowModel: getExpandedRowModel(),
      }}
      onRefetch={handleRefetch}
    />
  );
}

export { DashboardInterviewsTable };
