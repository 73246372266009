import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { FILTER_ORG_ID } from '@constants';
import { InterviewsTable } from '@modules/meetings/components/interviewsTable.component';
import { selectSelectedOrgId } from '@store/selectors';

function Meetings() {
  const navigate = useNavigate();

  const selectedOrgId = useSelector(selectSelectedOrgId);

  useEffect(() => {
    if (selectedOrgId !== FILTER_ORG_ID) navigate('/');
  }, [navigate, selectedOrgId]);

  return (
    <div className="grid grid-cols-12 gap-x-6 gap-y-10">
      <div className="col-span-12">
        <div className="mb-3 flex flex-col gap-y-3 md:h-10 md:flex-row md:items-center">
          <div className="text-base font-medium group-[.mode--light]:text-white">
            Meetings
          </div>
        </div>
        <div className="box box--stacked flex flex-col">
          <InterviewsTable allowToPost />
        </div>
      </div>
    </div>
  );
}

export { Meetings };
