import { Outlet } from 'react-router-dom';

import {
  SettingsHeader,
  SettingsMenu,
} from '@modules/organization/organizationSettings';

function OrganizationSettingsLayout() {
  return (
    <div className="grid grid-cols-12 gap-x-6 gap-y-10">
      <div className="col-span-12">
        <SettingsHeader />
      </div>
      <div className="col-span-12 mt-3.5 grid grid-cols-12 gap-x-6 gap-y-10">
        <div className="relative col-span-12 xl:col-span-3">
          <SettingsMenu />
        </div>
        <div className="col-span-12 flex flex-col gap-y-7 xl:col-span-9">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export { OrganizationSettingsLayout };
