import { Breadcrumb } from '@kerplunkai/common-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { NAV_ROUTES } from '@constants';
import { selectOrgInterview, selectSelectedOrgId } from '@store/selectors';
import { useMemo } from 'react';

interface MeetingBreadcrumbProps {
  interviewId: string;
  key?: number;
}

function MeetingBreadcrumb({
  interviewId,
  key,
  ...props
}: MeetingBreadcrumbProps) {
  const orgId = useSelector(selectSelectedOrgId);
  const interview = useSelector(
    selectOrgInterview(orgId as string, interviewId),
  );

  const to = useMemo(() => {
    if (interviewId === 'new') return `${NAV_ROUTES.MEETINGS}/${interviewId}`;

    return `${NAV_ROUTES.MEETINGS}/${interview?.id}`;
  }, [interviewId, interview]);

  const linkText = useMemo(() => {
    if (interviewId === 'new') return 'Create a Meeting';

    return interview?.title as string;
  }, [interviewId, interview]);

  if (interviewId !== 'new' && !interview) return null;

  return (
    <Breadcrumb.Link forwardedKey={key} {...props}>
      <Link to={to}>{linkText}</Link>
    </Breadcrumb.Link>
  );
}

export { MeetingBreadcrumb };
