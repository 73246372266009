/* eslint-disable no-underscore-dangle */
import {
  Button,
  Disclosure,
  FormControl,
  FormSwitch,
  LoadingIcon,
  Lucide,
  Tippy,
} from '@kerplunkai/common-components';
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { useField } from 'formik';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { INITIAL_JOB_QUESTION } from '@constants';
import {
  JobInterviewQuestionsTable,
  StaticJobInterviewQuestion,
} from '@modules/jobs/jobForms/jobInterviewQuestions';
import { JobQuestion, JobRequest } from '@typings';
import { selectSelectedOrgId } from '@store/selectors';

interface JobInterviewFormProps {
  isLoading: boolean;
  job: JobRequest;
  onChange: (name: string, value: JobQuestion[] | boolean) => void;
  onGenQuestions: () => void;
}

function JobInterviewForm({
  isLoading,
  job,
  onChange,
  onGenQuestions,
}: JobInterviewFormProps) {
  const [, questionsMeta] = useField('interview_questions_attributes');

  const orgId = useSelector(selectSelectedOrgId);

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {}),
  );

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);

  const draggableItems = useMemo(
    () => job.interview_questions_attributes.map(({ position }) => position),
    [job.interview_questions_attributes],
  );

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      const oldIndex = draggableItems.indexOf(active.id as number);
      const newIndex = draggableItems.indexOf(over.id as number);

      onChange(
        'interview_questions_attributes',
        arrayMove(job.interview_questions_attributes, oldIndex, newIndex).map(
          (question, index) => {
            return {
              ...question,
              position: index + 1,
            };
          },
        ),
      );
    }

    setActiveId(null);
  };

  const handleDragCancel = () => {
    setActiveId(null);
  };

  const selectedQuestion = useMemo(() => {
    if (!activeId) {
      return null;
    }

    const question = job.interview_questions_attributes.find(
      curQuestion => curQuestion.position === activeId,
    );

    return question;
  }, [activeId, job]);

  return (
    <div className="mt-5">
      <FormControl
        className="mt-5 xl:!items-start"
        childrenClassName="flex"
        description="Allow our AI to generate questions for your specific job posting. Like a question you see? Just lock it in and hit generate again as many times as you want until you get all the questions you need for this job."
        label="Generate AI Questions"
      >
        <Button disabled={isLoading} variant="primary" onClick={onGenQuestions}>
          <Lucide icon="Wand2" className="mr-2" />
          Generate Questions
        </Button>
        {isLoading && (
          <div className="ml-2 size-8 self-center">
            <LoadingIcon icon="oval" />
          </div>
        )}
      </FormControl>
      <div className="mt-5 rounded-[0.6rem] border-dashed border-slate-200/60">
        <Disclosure.Group selectedIndex={1} variant="boxed">
          <Disclosure className="rounded-[0.6rem]">
            <Disclosure.Button>
              <div className="flex items-center border-b border-dashed border-slate-200/60 pb-5 text-[0.94rem] font-medium text-[#181D2C]">
                <Lucide
                  icon="ChevronDown"
                  className="mr-2 size-5 stroke-[1.3]"
                />
                Advanced Options
              </div>
            </Disclosure.Button>
            <Disclosure.Panel>
              <div className="grid grid-cols-12 gap-5">
                <div className="col-span-4 flex flex-col gap-3.5 rounded-lg border-slate-200 bg-slate-50 p-6">
                  <Tippy content="Coming Soon">
                    <div className="flex items-center">
                      <FormSwitch.Input
                        checked={false}
                        disabled={isLoading}
                        type="checkbox"
                        onChange={() => {}}
                      />
                      <div className="ml-5 text-sm font-medium text-slate-600 ">
                        Résumé Specific Questions
                      </div>
                    </div>
                  </Tippy>
                  <div className="text-xs font-normal text-slate-500">
                    Allow our custom AI to automatically generate 1-2 questions
                    that are specifically tailored to the candidate&apos;s
                    résumé.
                  </div>
                  <div className="text-xs font-normal text-slate-500">
                    If candidate does not have a résumé or provide one these
                    questions will not populate in the interview. Note: These
                    questions will not be visible to you prior to their
                    interview.
                  </div>
                </div>
                <div className="col-span-4 flex flex-col gap-3.5 rounded-lg border-slate-200 bg-slate-50 p-6">
                  <div className="flex items-center">
                    <FormSwitch.Input
                      checked={job.generate_coding_questions}
                      disabled={isLoading}
                      type="checkbox"
                      onChange={() =>
                        onChange(
                          'generate_coding_questions',
                          !job.generate_coding_questions,
                        )
                      }
                    />
                    <div className="ml-5 text-sm font-medium text-slate-600 ">
                      Coding Specific Questions
                    </div>
                  </div>
                  <div className="text-xs font-normal text-slate-500">
                    Generate questions that are specifically tailored to help
                    gain an understanding of the candidate&apos;s coding
                    abilities.
                  </div>
                </div>
              </div>
            </Disclosure.Panel>
          </Disclosure>
        </Disclosure.Group>
      </div>

      {!job.genQuestions && (
        <FormControl
          className="mt-5 xl:!items-start"
          description="Edit the AI-generated questions or enter your own here."
          isRequired
          label="Preview Interview Questions"
        >
          <>
            <DndContext
              collisionDetection={closestCenter}
              modifiers={[restrictToVerticalAxis]}
              sensors={sensors}
              onDragEnd={handleDragEnd}
              onDragStart={handleDragStart}
              onDragCancel={handleDragCancel}
            >
              <div className="overflow-x-auto">
                <JobInterviewQuestionsTable
                  isLoading={isLoading}
                  job={job}
                  onChange={onChange}
                />
              </div>
              <DragOverlay>
                {activeId && selectedQuestion && (
                  <table style={{ width: '100%' }}>
                    <tbody>
                      <StaticJobInterviewQuestion
                        orgId={orgId as string}
                        question={selectedQuestion}
                      />
                    </tbody>
                  </table>
                )}
              </DragOverlay>
            </DndContext>
            <Button
              className="mt-4 w-full border-dashed"
              disabled={isLoading}
              variant="soft-sky-outline"
              onClick={() =>
                onChange('interview_questions_attributes', [
                  ...job.interview_questions_attributes,
                  {
                    ...INITIAL_JOB_QUESTION,
                    position: job.interview_questions_attributes.length + 1,
                  },
                ])
              }
            >
              <Lucide icon="Plus" className="mr-2 size-4 stroke-[1.3]" />
              Add Another Question
            </Button>
            {questionsMeta.error && (
              <div className="mt-4 text-xs text-danger">
                {questionsMeta.error}
              </div>
            )}
          </>
        </FormControl>
      )}
    </div>
  );
}

export { JobInterviewForm };
