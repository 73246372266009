import { Breadcrumb } from '@kerplunkai/common-components';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { NAV_ROUTES } from '@constants';
import { selectOrgClient, selectSelectedOrgId } from '@store/selectors';

interface ClientBreadcrumbProps {
  clientId: string;
  key?: number;
}

function ClientBreadcrumb({ clientId, key, ...props }: ClientBreadcrumbProps) {
  const orgId = useSelector(selectSelectedOrgId);
  const client = useSelector(selectOrgClient(orgId as string, clientId));

  const to = useMemo(() => {
    if (clientId === 'new') return `${NAV_ROUTES.CLIENTS}/${clientId}`;

    return `${NAV_ROUTES.CLIENTS}/${client?.id}`;
  }, [clientId, client]);

  const linkText = useMemo(() => {
    if (clientId === 'new') return 'Add a Client';

    return client?.name;
  }, [clientId, client]);

  if (clientId !== 'new' && !client) return null;

  return (
    <Breadcrumb.Link forwardedKey={key} {...props}>
      <Link to={to}>{linkText}</Link>
    </Breadcrumb.Link>
  );
}

export { ClientBreadcrumb };
