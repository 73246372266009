import { Auth, Terms } from '@modules/auth';
import {
  AuthCompliance,
  AuthEncryption,
  AuthForm,
  AuthHero,
  AuthPaper,
  Divider,
  LoginForm,
  LoginInfo,
  SocialLoginButton,
} from '@kerplunkai/common-components';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { COMPLIANCE_DATA, NAV_ROUTES } from '@constants';
import { fakers, getGoogleAuthUrl } from '@utilities';
import { selectInviteToken } from '@store/selectors';
import { setInviteToken } from '@store/slices';
import { useAppDispatch } from '@store/hooks';
import { useCheckTokenMutation, useLoginMutation } from '@store/services';

import googleLogo from '@assets/images/google.svg';
import logo from '@assets/images/logos/Kerplunk-Logo-blk.svg';

function Login() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const invitationToken = useSelector(selectInviteToken);

  const [login, { isLoading: isLoggingIn, error: loginError }] =
    useLoginMutation();
  const [checkToken, { data: checkTokenData }] = useCheckTokenMutation();

  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');

  useEffect(() => {
    if (sessionId) {
      localStorage.setItem('stripe_session_id', sessionId);
    }
  }, [sessionId]);

  useEffect(() => {
    if (invitationToken !== undefined) {
      checkToken(invitationToken);
      dispatch(setInviteToken(undefined));
    }
  }, [dispatch, checkToken, invitationToken]);

  useEffect(() => {
    if (loginError) {
      enqueueSnackbar({
        message:
          (
            (loginError as unknown as FetchBaseQueryError)?.data as {
              messages: string[];
            }
          )?.messages[0] || 'An error occurred. Please try again.',
        variant: 'error',
      });
    }
  }, [loginError]);

  const handleLogin = useCallback(
    async ({ email, password }: LoginInfo) => {
      try {
        const response = await login({
          email,
          password,
        }).unwrap();

        if (response.token) {
          localStorage.setItem('token', response.token);
          return;
        }

        if (response.message) {
          enqueueSnackbar({
            message: response.message,
            variant: 'error',
          });
        }
      } catch (e) {
        console.log('login error:', e);
      }
    },
    [login],
  );

  const handleGoogleLogin = () => {
    window.location.replace(getGoogleAuthUrl());
  };

  // const handleSendMagicLink = async () => {
  //   try {
  //     // clear previous user information before making auth request
  //     // dispatch(resetUser());

  //     console.log('sending magic link TODO');
  //     // TODO:
  //     // useSendMagicLinkMutation({
  //     //   email: loginInfo.email,
  //     // });
  //   } catch (e) {
  //     console.log('E', e);
  //   }
  // };

  return (
    <Auth>
      <AuthPaper logo={logo}>
        <AuthForm title="Sign In">
          <div className="mb-6 mt-12 max-xs:mb-2 max-xs:mt-6">
            <SocialLoginButton
              icon={googleLogo}
              providerDisplayName="Google"
              onClick={handleGoogleLogin}
            />
          </div>

          <div className="flex">
            <Divider className="grow max-xs:my-4" />
            <div className="mx-4 self-center">or</div>
            <Divider className="grow max-xs:my-4" />
          </div>

          <LoginForm
            onForgotPasswordClick={() => navigate(NAV_ROUTES.FORGOT_PASSWORD)}
            onManualLogin={handleLogin}
            onNavigateToSignup={() => navigate(NAV_ROUTES.SIGNUP)}
            // onSendMagicLink={handleSendMagicLink} // uncommenting this will un-hide magic link
            isLoading={isLoggingIn}
          />
        </AuthForm>
        <div className="mt-10 grid justify-items-center">
          <AuthEncryption
            className="max-xs:w-[280px] lg:break-words"
            textClassName="max-xs:text-[8px]"
            icons="LockKeyhole"
            text="Secured by 256-bit AES and 256-bit SSL/TLS encryption"
          />
          <div className="mt-2 flex flex-wrap justify-center gap-2">
            {COMPLIANCE_DATA.map(item => (
              <AuthCompliance
                key={item.id}
                text={item.text}
                icons={item.icon}
              />
            ))}
          </div>
        </div>
        <Terms />
      </AuthPaper>
      <AuthHero
        tokenOrg={checkTokenData ? checkTokenData[0] : undefined}
        subtitle="Unlock the future of staffing, where organizations across all sectors leverage AI to source, recruit, and retain the world's top talent. Join us today to connect with leading companies and accelerate your career on Kerplunk."
        title="Build your career on Kerplunk."
        heroUserList={fakers.fakeUsers()}
      />
    </Auth>
  );
}

export { Login };
