import { Location } from 'react-router-dom';

export const getHashParam = (paramName: string, location: Location) => {
  return (
    new URLSearchParams(location.hash.slice(1)).get(paramName) || undefined
  );
};

export const downloadFile = (url: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const formatCurrency = (number: number, currency: string) => {
  const options = {
    style: 'currency',
    currency,
    notation: 'compact',
    compactDisplay: 'short',
  } as Intl.NumberFormatOptions;
  const formatter = new Intl.NumberFormat('en-US', options);
  return formatter.format(number);
};

export const isNumber = (str: string): boolean => {
  const num = parseFloat(str);
  return !Number.isNaN(num);
};

export const getFormattedTime = (timeString: string): string => {
  const totalSeconds = parseFloat(timeString);

  if (Number.isNaN(totalSeconds)) {
    console.warn(
      'Invalid timestamp input: the input should be a valid number representing seconds',
    );
  }

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');

  if (hours > 0) {
    return `${hours}:${formattedMinutes}:${formattedSeconds}`;
  }

  return `${minutes}:${formattedSeconds}`;
};

export const copyContent = (
  contentElement: HTMLDivElement | null,
  setIsTextCopied: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  if (contentElement) {
    const htmlContent = contentElement.innerHTML;

    navigator.clipboard
      .write([
        new ClipboardItem({
          'text/html': new Blob([htmlContent], { type: 'text/html' }),
          'text/plain': new Blob([contentElement.textContent || ''], {
            type: 'text/plain',
          }),
        }),
      ])
      .then(() => {
        setIsTextCopied(true);
      })
      .catch(err => {
        console.error('Failed to copy:', err);
      });
  }
};
